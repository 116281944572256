
import {
  RESET_CHECKOUT, 
  SET_CHECKOUT_PAYMENT_DETAILS, 
  SET_CHECKOUT_BILLING_DETAILS
} from '../../constants/constants';

const defaultState = {
  billing: {},
  payment: {
    type: 'paystack',
    name: ''
  }
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SET_CHECKOUT_BILLING_DETAILS:
      return {
        ...state,
        billing: action.payload
      };
    case SET_CHECKOUT_PAYMENT_DETAILS:
      return {
        ...state,
        payment: action.payload
      };
    case RESET_CHECKOUT:
      return defaultState;
    default:
      return state;
  }
};
 