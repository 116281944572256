
import React from 'react';
import { 
	BrowserRouter as Router,
	// unstable_HistoryRouter as HistoryRouter,
	Routes, 
	Route
} from 'react-router-dom';

import { createBrowserHistory } from 'history';

import { 
	Home, About, FindDeal, 
	Contact, Blog, TradeFair, 
	AdminDashboard, Categories, SubCategory,
	EditCategories, AllProducts, Products, 
	EditProduct, VerifyProduct, EditSubCategories,
	ViewProductDetails, Orders, ViewOrder, 
	VendorAddProduct, VendorProducts, VendorEditProduct,
	ProductDetails, Cart, VendorDetails, 
	Checkout, Delivery, Paymemt, Profile, 
	ChangePassword, OrderHistory, 
	CategoryProducts, SearchProducts, SignUp, 
	SignIn, SignUpVendor,
	AllBlogs, AddBlog, EditBlog, BlogDetails,
	AddVendor, EditVendor, AllVendors, Vendors, VendorOrder,
	VendorDashboard, PaystackCallback, VendorProfile,
	CashOnDeliveryCallback, ForgotPassword, ResetPassword,
	Users, SignUpVendorComplete, OrderDetails,
	ViewVendor, VerifyVendors, CreateAdmin, ManageAdmins, 
	EditAdmins, VendorProduct, SignUpVendorsDetails,
	UsersGuide, OwnersGuide
 }  from '../pages';

import * as ROUTES from '../constants/routes';
import PublicRoute from './PublicRoute';
import StaticRoute from './StaticRoute';
import ClientRoute from './ClientRoute';
import AdminRoute from './AdminRoute';
import VendorRoute from './VendorRoute';
// import NotFound from '../components/default/NotFound';

export const history = createBrowserHistory();

const AppRouter = () => (
	<Router history={history}>
		<>
			<Routes>
				<Route element={<StaticRoute />}>
					<Route
						element={<SearchProducts />}
						exact
						path={ROUTES.SEARCH}
					/>
					<Route
						element={<Home />}
						path={ROUTES.HOME}
					/>
					<Route
						element={<FindDeal />}
						path={ROUTES.FIND_YOUR_DEAL}
					/>
					<Route
						element={<About />}
						path={ROUTES.ABOUT_BEST_DEAL_NAIJA}
					/>
					<Route
						element={<Blog />}
						path={ROUTES.BLOG}
					/>
					<Route
						element={<BlogDetails />}
						path={`${ROUTES.BLOG_DETAILS}/:slug`}
					/>
					<Route
						element={<Contact />}
						path={ROUTES.CONTACT}
					/>
					<Route
						element={<TradeFair />}
						path={ROUTES.BEST_DEAL_TRADE_FAIR}
					/>
					<Route
						element={<Products />}
						path={ROUTES.PRODUCTS}
					/>	
					<Route
						element={<CategoryProducts />}
						path={`${ROUTES.CATEGORY_PRODUCTS}/:slug`}
					/>					
					<Route
						element={<VendorProduct />}
						path={`${ROUTES.VENDOR_PRODUCTS_VIEW}/:slug`}
					/>				
					<Route
						element={<ProductDetails />}
						path={`${ROUTES.PRODUCT_DETAILS}/:slug`}
					/>					
					<Route
						element={<Cart />}
						path={ROUTES.CART}
					/>					
					<Route
						element={<Vendors />}
						path={ROUTES.VENDORS}
					/>			
					<Route
						element={<VendorDetails />}
						path={`${ROUTES.VENDORDETAILS}/:slug`}
					/>					
					<Route
						element={<Checkout />}
						path={ROUTES.CHECKOUT}
					/>
					<Route
						element={<Delivery />}
						path={ROUTES.DELIVERY}
					/>
					<Route
						element={<Paymemt />}
						path={ROUTES.PAYMENT}
					/>
					<Route
						element={<PaystackCallback />}
						path={ROUTES.ORDER_CALLBACK}
					/>
					<Route
						element={<CashOnDeliveryCallback />}
						path={ROUTES.CASH_ORDER_CALLBACK}
					/>
					<Route
						element={<UsersGuide />}
						path={ROUTES.USERS_GUIDE}
					/>
					<Route
						element={<OwnersGuide />}
						path={ROUTES.OWNERS_GUIDE}
					/>
				</Route>
				<Route
					path={ROUTES.SIGNUP}
					element={
						<PublicRoute 
							component={SignUp} 
							path={ROUTES.SIGNUP}
						/>
					}
				/>
				<Route
					path={ROUTES.SIGNUP_VENDOR}
					element={
						<PublicRoute 
							component={SignUpVendor} 
							path={ROUTES.SIGNUP_VENDOR} 
						/>
					}
				/>
				<Route
					path={ROUTES.SIGNIN}
					element={
						<PublicRoute 
							component={SignIn} 
							path={ROUTES.SIGNIN} 
						/>
					}
				/>
				<Route
					path={ROUTES.FORGOT_PASSWORD}
					element={
						<PublicRoute 
							component={ForgotPassword} 
							path={ROUTES.SIGNIN} 
						/>
					}
				/>
				<Route
					path={ROUTES.RESET_PASSWORD}
					element={
						<PublicRoute 
							component={ResetPassword} 
							path={ROUTES.RESET_PASSWORD} 
						/>
					}
				/>
				<Route
					path={ROUTES.PROFILE}
					element={
						<ClientRoute 
							component={Profile} 
							path={ROUTES.PROFILE} 
						/>
					}
				/>
				<Route
					path={ROUTES.CHANGEPASSWORD}
					element={
						<ClientRoute 
							component={ChangePassword} 
							path={ROUTES.CHANGEPASSWORD} 
						/>
					}
				/>
				<Route
					path={ROUTES.ORDER_HISTORY}
					element={
						<ClientRoute 
							component={OrderHistory} 
							path={ROUTES.ORDER_HISTORY} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ORDER_DETAILS}/:id/:ref`}
					element={
						<ClientRoute 
							component={OrderDetails} 
							path={`${ROUTES.ORDER_DETAILS}/:id/:ref`} 
						/>
					}
				/>
				<Route
					path={ROUTES.VIEW_ORDER}
					element={
						<ClientRoute 
							component={OrderHistory} 
							path={ROUTES.VIEW_ORDER} 
						/>
					}
				/>
				<Route
					path={ROUTES.SIGNUP_VENDOR_DETAILS}
					element={
						<VendorRoute 
							component={SignUpVendorsDetails} 
							path={ROUTES.SIGNUP_VENDOR_DETAILS} 
						/>
					}
				/>
				<Route element={<StaticRoute />}>
					<Route
						element={<SignUpVendorComplete />}
						exact
						path={ROUTES.SIGNUP_VENDOR_COMPLETE}
					/>
				</Route>		
				<Route
					path={ROUTES.VENDOR_DASHBOARD}
					element={
						<VendorRoute 
							component={VendorDashboard} 
							path={ROUTES.VENDOR_DASHBOARD} 
						/>
					}
				/>
				<Route
					path={ROUTES.VENDOR_PROFILE}
					element={
						<VendorRoute 
							component={VendorProfile} 
							path={ROUTES.VENDOR_PROFILE} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_DASHBOARD}
					element={
						<AdminRoute 
							component={AdminDashboard} 
							path={ROUTES.ADMIN_DASHBOARD} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_CATEGORIES}
					element={
						<AdminRoute 
							component={Categories} 
							path={ROUTES.ADMIN_CATEGORIES} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_SUB_CATEGORIES}
					element={
						<AdminRoute 
							component={SubCategory} 
							path={ROUTES.ADMIN_SUB_CATEGORIES} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_EDIT_CATEGORIES}/:id`}
					element={
						<AdminRoute 
							component={EditCategories} 
							path={`${ROUTES.ADMIN_EDIT_CATEGORIES}/:id`} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_EDIT_SUB_CATEGORIES}/:id`}
					element={
						<AdminRoute 
							component={EditSubCategories} 
							path={`${ROUTES.ADMIN_EDIT_SUB_CATEGORIES}/:id`} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_PRODUCTS}
					element={
						<AdminRoute 
							component={AllProducts} 
							path={ROUTES.ADMIN_PRODUCTS} 
						/>
					}
				/>
				<Route
					path={ROUTES.VENDOR_ADD_PRODUCTS}
					element={
						<VendorRoute 
							component={VendorAddProduct} 
							path={ROUTES.VENDOR_ADD_PRODUCTS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.VENDOR_EDIT_PRODUCTS}/:id`}
					element={
						<VendorRoute 
							component={VendorEditProduct} 
							path={`${ROUTES.VENDOR_EDIT_PRODUCTS}/:id`} 
						/>
					}
				/>
				<Route
					path={ROUTES.VENDOR_PRODUCTS}
					element={
						<VendorRoute 
							component={VendorProducts} 
							path={ROUTES.VENDOR_PRODUCTS} 
						/>
					}
				/>
				<Route
					path={ROUTES.VENDOR_ORDERS}
					element={
						<VendorRoute 
							component={VendorOrder} 
							path={ROUTES.VENDOR_ORDERS} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_VERIFY_PRODUCTS}
					element={
						<AdminRoute 
							component={VerifyProduct} 
							path={ROUTES.ADMIN_VERIFY_PRODUCTS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_VIEW_PRODUCT}/:id`} 
					element={
						<AdminRoute 
							component={ViewProductDetails} 
							path={`${ROUTES.ADMIN_VIEW_PRODUCT}/:id`} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_EDIT_PRODUCTS}/:id`}
					element={
						<AdminRoute 
							component={EditProduct} 
							path={`${ROUTES.ADMIN_EDIT_PRODUCTS}/:id`} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_VENDORS}
					element={
						<AdminRoute 
							component={AllVendors} 
							path={ROUTES.ADMIN_VENDORS} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_VERIFY_VENDORS}
					element={
						<AdminRoute 
							component={VerifyVendors} 
							path={ROUTES.ADMIN_VERIFY_VENDORS} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_ADD_VENDORS}
					element={
						<AdminRoute 
							component={AddVendor} 
							path={ROUTES.ADMIN_ADD_VENDORS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_EDIT_VENDORS}/:id/:slug`}
					element={
						<AdminRoute 
							component={EditVendor} 
							path={ROUTES.ADMIN_EDIT_VENDORS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_VIEW_VENDORS}/:id/:slug`}
					element={
						<AdminRoute 
							component={ViewVendor} 
							path={ROUTES.ADMIN_VIEW_VENDORS} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_ORDERS}
					element={
						<AdminRoute 
							component={Orders} 
							path={ROUTES.ADMIN_ORDERS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.VIEW_ORDERS}/:id/:trans_id`}
					element={
						<AdminRoute 
							component={ViewOrder} 
							path={ROUTES.VIEW_ORDERS} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_BLOGS}
					element={
						<AdminRoute 
							component={AllBlogs} 
							path={ROUTES.ADMIN_BLOGS} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_ADD_BLOGS}
					element={
						<AdminRoute 
							component={AddBlog} 
							path={ROUTES.ADMIN_ADD_BLOGS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_EDIT_BLOGS}/:id`}
					element={
						<AdminRoute 
							component={EditBlog} 
							path={`${ROUTES.ADMIN_EDIT_BLOGS}/:id`} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_USERS}
					element={
						<AdminRoute 
							component={Users} 
							path={ROUTES.ADMIN_USERS} 
						/>
					}
				/>	
				<Route
					path={ROUTES.ADMIN_CREATE_ADMIN}
					element={
						<AdminRoute 
							component={CreateAdmin} 
							path={ROUTES.ADMIN_CREATE_ADMIN} 
						/>
					}
				/>
				<Route
					path={ROUTES.ADMIN_MANAGE_ADMINS}
					element={
						<AdminRoute 
							component={ManageAdmins} 
							path={ROUTES.ADMIN_MANAGE_ADMINS} 
						/>
					}
				/>
				<Route
					path={`${ROUTES.ADMIN_EDIT_ADMINS}/:id`}
					element={
						<AdminRoute 
							component={EditAdmins} 
							path={`${ROUTES.ADMIN_EDIT_ADMINS}/:id`} 
						/>
					}
				/>
			</Routes>
		</>
	</Router>	
);
  
export default AppRouter;
 