
/* eslint-disable indent */
import {
	call, put, select
  } from 'redux-saga/effects';
import {
	GET_CATEGORY_PRODUCTS,
	REMOVE_PRODUCT, GET_PRODUCTS, SEARCH_PRODUCT
} from '../../constants/constants';
import { PRODUCT_API } from '../../services';
import { getProductsSuccess, removeProductSuccess, searchProductSuccess } from '../actions/productActions';
import { setLoading, setRequestStatus} from '../actions/miscActions';

function* initRequest() {
	yield put(setLoading(true));
	yield put(setRequestStatus(null));
}

function* handleError(e) {
	yield put(setLoading(false));
	yield put(setRequestStatus(e?.message || 'Failed to fetch products'));
	console.log('ERROR: ', e);
}

function* productSaga({type, payload}) {
	switch (type) {
    case GET_CATEGORY_PRODUCTS:
      try {
        yield initRequest();
        const response = yield call(PRODUCT_API.getCategoryProducts, payload);
        if (response.results.length === 0) {
          handleError('No items found.');
        } else {
          yield put(getProductsSuccess({
            products: response.results
          }));
          yield put(setRequestStatus(''));
        }
        yield put(setLoading(false));
      } catch (e) {
        console.log(e);
        yield handleError(e);
      }
	break;
	case GET_PRODUCTS: {
		try {
			yield initRequest();
			const state = yield select();
			const response = yield call(PRODUCT_API.getProducts, payload);
			
			if (response.results.length === 0) {
				handleError('No items found.');
			} else {
				yield put(getProductsSuccess({
					products: response.results,
					nextPage: response.next_page,
					prevPage: response.prev_page,
					lastKey: response.last_product_id ? response.last_product_id : state.products.lastRefKey
				}));
				yield put(setRequestStatus(''));
			}
			yield put(setLoading(false));
		} catch (e) {
			console.log(e);
			yield handleError(e);
		}
		break;
	}
	case REMOVE_PRODUCT: {
		try {
			yield initRequest();
			yield put(removeProductSuccess(payload));
			yield put(setLoading(false));
		} catch (e) {
			yield handleError(e);
		}
		break;
	}
	case SEARCH_PRODUCT: {
		try {
			yield initRequest();
			const state = yield select();
			const response = yield call(PRODUCT_API.searchProducts, payload.searchKey, payload.lastRefKey);
			if (response) {
				if (response.results.length === 0) {
					handleError('No items found.');
				} else {
					yield put(searchProductSuccess({
						products: response.results,
						nextPage: response.next_page,
						prevPage: response.prev_page,
						lastKey: response.last_product_id ? response.last_product_id : state.products.lastRefKey
					}));
					yield put(setRequestStatus(''));
				}
			}
			yield put(setLoading(false));
		} catch (e) {
			yield handleError(e);
		}
		break;
	}
    default: {
      throw new Error(`Unexpected action type ${type}`);
    }
  }
}

export default productSaga;
 