
import React, { useCallback, useEffect, useState } from "react";
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import axios from 'axios'
import swal from 'sweetalert';

import * as ROUTE from '../../../constants/routes';
import { FaPlus } from 'react-icons/fa';

const ManageAdmins = () => {
  const [admins, setAdmins] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();

  const { accessToken } = useSelector((state) => ({
    accessToken: state.profile.access_token
  }), shallowEqual);

  const onNext = () => {
    if (nextPage != null) {
      fetchAdmins(nextPage);
    }
  };

  const onPrevious = () => {
    if (prevPage != null) {
      fetchAdmins(prevPage);
    }
  };

  const onPageChange = (page) => {
    if (page != null) {
      fetchAdmins(page);
    }
  };

  const fetchAdmins = (page) => {
    setLoading(true)
    fetch(`${ROUTE.USER_API}/user/sub-admin?pagination=1&page=${page}`, {
      headers:{
        'Content-Type':'application/json',
        'Authorization': 'Bearer ' + accessToken    
      }
    })
    .then((res) => res.json())
    .then((res) => {
      setAdmins(res)
      setNextPage(res.next_page)
      setPrevPage(res.prev_page)
      setCurrentPage(res.current_page)
      setLoading(false)
    })
    .catch(error => {
      console.log(error)
      setLoading(false)
    })
  }
  const first_item_on_page_number = (admins?.current_page * admins?.items_per_page) - admins?.items_per_page + 1;
  const last_item_on_page_number = first_item_on_page_number + admins?.results?.length -1;

  useEffect(() => {
    fetchAdmins(currentPage)
  }, [])

  const deleteAdmin = useCallback( async (id)  => {
    if(confirm('Are you sure you want to delete this admin?')){
      axios.delete(
        `${ROUTE.USER_API}/admin/${id}`,{
            method : 'DELETE',
            body : JSON.stringify({
                id : id
            }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => res)
        .then(data =>{
          successAlert(data)
        })
        .catch(err => errorAlert(err)
        )
    }

  }, []);

  const successAlert = (response) => {
    return(
      swal({
        title: "Info!",
        text: response.data.message,
        icon: "success"
      }).then(function () {
        fetchAdmins(currentPage)
      })
    )
  }
  const errorAlert = (error) => {
    return(
      swal({
        title: "Error!",
        text: error,
        icon: "error"
      }).then(function () {
        fetchAdmins(currentPage)
      })          
    )
  }
  
  return (
    <>
      <div className="w-full md:w-[95%] m-2 text-md p-5">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
          <div className="flex items-center space-x-2 text-gray-400">
            <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
            <span>/</span>
            <span className="tracking-wide text-md">
                <span className="text-base">Manage Admins</span>
            </span>
            <span>/</span>
          </div>
        </div>
        <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
          <h1 className="font-medium text-2xl">Manage Admins</h1>
          <Link to={ROUTE.ADMIN_CREATE_ADMIN} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> 
            <span><FaPlus className="inline-block w-4 h-3"/></span> 
            Create new admin
          </Link>
        </div>
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">Name</th>
                <th scope="col" className="px-6 py-3">Gender</th>
                <th scope="col" className="px-6 py-3">Phone</th>
                <th scope="col" className="px-6 py-3">Email</th>
                <th scope="col" className="px-6 py-3">Joined</th>
                <th scope="col" className="px-6 py-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && admins?.results?.length <= 0 && (
                <>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan={6}>
                      <div className="flex flex-col justify-center items-center my-4 space-y-3">No admins registered yet</div>
                    </td>
                  </tr>
                </>
              )}
              {!isLoading && admins?.results?.length >= 1 && admins?.results?.map((admin) => (
                <>
                  <tr key={admin.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                   <th scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {admin.first_name} {admin.last_name}
                    </th>
                    <td className="px-6 py-4">{admin.gender}</td>
                    <td className="px-6 py-4">{admin.mobile_number ? admin.mobile_number : 'N/A'}</td>
                    <td className="px-6 py-4">{admin.email}</td>
                    <td className="px-6 py-4">{admin.created_at.substring(0,17)}</td>
                    <td className="flex items-center px-6 py-4">
                      <Link to={`${ROUTE.ADMIN_EDIT_ADMINS}/${admin.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                        Edit
                      </Link>
                      <a onClick={() => deleteAdmin(admin.id)}
                        className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer ms-3"
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                </>
              ))}
              {isLoading && (
                <>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan={6}>
                      <div className="flex flex-col justify-center items-center my-4 space-y-3">Fetching records...</div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {!isLoading && admins?.results?.length >= 1 && (
            <nav
              className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                {`${first_item_on_page_number}-${last_item_on_page_number}`}
                </span> of <span className="font-semibold text-gray-900 dark:text-white">{admins?.total_records}</span>
              </span>
              <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                {
                  admins?.prev_page && (
                    <li>
                      <a onClick={onPrevious}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </a>
                    </li>
                  )
                }
                {admins?.iter_pages.map((iter_page) => (
                  <>
                    {iter_page != null ?
                      <li>
                        <a onClick={() => onPageChange(iter_page)}
                          className={`${admins?.current_page === iter_page ? 'flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                          : 'flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                        >
                          {iter_page}
                        </a>
                      </li> : 
                      <li>
                        <a href="#"
                          className='flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        >
                          {'...'}
                        </a>
                      </li>
                    }
                  </>
                ))}
                {
                  admins?.next_page && (
                    <li>
                      <a onClick={onNext}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </a>
                    </li>
                  )
                }
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};
export default ManageAdmins;
 