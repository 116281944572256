
import {
  RESET_CHECKOUT, 
  SET_CHECKOUT_PAYMENT_DETAILS, 
  SET_CHECKOUT_BILLING_DETAILS,
  ADD_ORDER, UPDATE_ORDER,
  UPDATE_ORDER_TRANSACTION
} from '../../constants/constants';

export const setBillingDetails = (details) => ({
  type: SET_CHECKOUT_BILLING_DETAILS,
  payload: details
});

export const addOrder = (details) => ({
  type: ADD_ORDER,
  payload: {
    user: details.user,
    guest: details.guest,
    type: details.type
  }
});

export const updateOrder = (details) => ({
  type: UPDATE_ORDER,
  payload: {
    order: details.order,
    type: details.type
  }
});

export const updateOrderTransaction = (payload) => ({
  type: UPDATE_ORDER_TRANSACTION,
  payload: payload
});

export const setPaymentDetails = (details) => ({
  type: SET_CHECKOUT_PAYMENT_DETAILS,
  payload: details
});

export const resetCheckout = () => ({
  type: RESET_CHECKOUT
});
 