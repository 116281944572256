
import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import * as CONSTANT from '../../constants/constants'

import { 
    clearCart, removeFromCart,
    addQtyItem, minusQtyItem, updateCartProduct
 } from '../../redux/actions/cartActions';
import * as ROUTE from '../../constants/routes';
import img from '../../images/tradefair/tradefairbg.jpg';
import Banner  from '../../components/default/Banner/index';
import { useDocumentTitle } from '../../hooks';
import CartSummary from '../../components/cart/CartSummary';
import { Timeline } from './components';
import { clearProducts, getProducts } from '../../redux/actions/productActions';

const Cart = () => {
    useDocumentTitle('My Cart | BestDealNaija');
    const { cart, products } = useSelector((state) => ({
		cart: state.cart,
		products: state.products
	}), shallowEqual);
	const dispatch = useDispatch();

    const handleRemoveFromCart = (cartItem) => {
        dispatch(removeFromCart(cartItem));
    }
    const btnIncreaseQtyCartList = (cartItem) => {
        if (cartItem.cart_quantity < cartItem.quantity) {
			dispatch(addQtyItem(cartItem.id));
			const product_update = products?.items?.find((product) => cartItem.id === product.id)
			dispatch(updateCartProduct(product_update))
		}
    }
    const btnDecreaseQtyCartList = (cartItem) => {
		if ((cartItem.quantity >= cartItem.cart_quantity) && cartItem.cart_quantity !== 0) {
			dispatch(minusQtyItem(cartItem.id));
			const product_update = products?.items?.find((product) => cartItem.id === product.id)
			dispatch(updateCartProduct(product_update))
		}
    }
    const btnClearCart = () => {
		if (cart.length !== 0) {
			dispatch(clearCart());
		}
    }
	const nf = new Intl.NumberFormat();
 
    useEffect(() => {
		dispatch(clearProducts()); //clear existing products to fetch latest product
		setTimeout(() => {
			dispatch(getProducts(products.lastRefKey));
		}, 2000);
	}, [])
 
	return (
		<>
			<Banner subtitleText='My Cart' backgroundImage={img} />
			{cart.length === 0 ? (
				<div className="text-center py-[50px] my-4">   
					<div className="text-center py-10 mt-20 md:mt-4">
						<h3 className="text-6xl luday_deals">Your cart is empty!</h3>
					</div>
					<Link to={ROUTE.PRODUCTS} className="bg-[#9c0] border-[#490] p-2 px-6 font-bold hover:bg-[#98c01d] text-white rounded 
					cursor-pointer">
					<span>Start Shopping</span>
					</Link>
				</div>
            ) : (
			<div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">
				<Timeline current={1} />                   
				<div className="flex flex-col md:flex-row py-[50px]">	
					<div className="w-full md:w-3/4 m-2">
						<div className="overflow-x-auto">
							<table className="w-full whitespace-nowrap">
								<thead className="bg-gray-50" >
									<tr>
										<th className="px-6 py-2 text-left whitespace-nowrap">Product</th>
										<th className="px-6 py-2">Price</th>
										<th className="px-6 py-2">Total</th>
										<th className="px-6 py-2">Quantity</th>
									</tr>
								</thead>
								<tbody>
								{cart?.map(cartItem => (  
									<tr key={cartItem.id} className="border border-black-600 whitespace-nowrap">
										<td className="">
											<div className="flex flex-row">
												<div className="bg-white py-2 h-30 w-32">
													<Link to={`${ROUTE.PRODUCT_DETAILS}/${cartItem.slug}`}>
														<img className="h-32 w-32" src={`${CONSTANT.IMAGE_STORE}/${cartItem.image_path}`}/> 
													</Link>
												</div>
												<div className="bg-white py-2">
													<Link to={`${ROUTE.PRODUCT_DETAILS}/${cartItem.slug}`}>
														<h2 className="px-6 text-left text-lg w-[300px] overflow-hidden text-ellipsis md:text-xl">{cartItem.name}</h2>
													</Link>
													<button onClick={() => handleRemoveFromCart(cartItem.id)} 
														className="px-6 py-2 text-left text-sm font-bold text-[#9c0]">Remove item</button>
												</div>
											</div>
										</td>										
										{cartItem?.sale_price ? (
											<td className="px-6 py-2 text-right">&#8358; {nf.format(cartItem.sale_price)} </td>) 
										: (
											<td className="px-6 py-2 text-right">&#8358; {nf.format(cartItem.price)} </td>
										)}
										{cartItem?.sale_price ? (
											<td className="px-6 py-2 text-right">&#8358;{nf.format(cartItem.sale_price * cartItem.cart_quantity)}</td>) 
										: (
											<td className="px-6 py-2 text-right">&#8358;{nf.format(cartItem.price * cartItem.cart_quantity)}</td>
										)}
										
										<td className="px-6 py-2 text-right">
											<div className="custom-number-input h-10 w-32">
												<div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
													<button onClick={() => btnDecreaseQtyCartList(cartItem)} className="bg-[#98c01d] text-2xl border-[#98c01d] hover:bg-[#88af14] 
														text-white h-full w-32 rounded-l cursor-pointer outline-none"
														disabled={cartItem.cart_quantity === 1}>
													<span className="m-auto text-2xl font-thin">−</span>
													</button>
													<input type="text" value={cartItem.cart_quantity} className="border-[#98c01d] text-center w-full bg-white font-semibold text-md hover:text-black 
														focus:text-black md:text-basecursor-default flex items-center text-gray-700" name="custom-input-number" />
													<button onClick={() => btnIncreaseQtyCartList(cartItem)} className="bg-[#98c01d] text-2xl border-[#88af14] 
														hover:bg-[#9c0] text-white h-full w-32 rounded-r cursor-pointer"
														disabled={cartItem.quantity === cartItem.cart_quantity}>
														<span className="m-auto text-2xl font-thin">+</span>
													</button>
												</div>
											</div>
										</td>										
									</tr>
								))}
								</tbody>
							</table>					
							<div className="cart-summary">
								<button className="button button--theme overflow-hidden [#9c0] font-bold 
									border border-[#98c01d]-600 hover:bg-[#88af14] text-[#98c01d] text-sm transition-all ease-in-out duration-300 
									rounded py-3 px-5 my-2 flex items-center flex-nowrap" 
									onClick={btnClearCart}
									type="button"
									disabled={cart.length === 0}>Clear all items</button>
							</div>
						</div>
					</div>
					<CartSummary />
				</div>
			</div>			
			)}
		</>
	);  
}
export default Cart;
 