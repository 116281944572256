
import React from 'react';
const Owners_Topic6 = () => {
  return (
    <>
      <div><p className="userguide_header">Managing Blogs</p></div>

      <div><p className="userguide_subheader anchor_link_section" id="6.1">View all Blog Posts</p></div>
      <div>
        <p>To view all blog posts click on the Blog option on the navigation menu then click on all blogs. You will be routed to a page with a tabulated outline of all blog posts.
Creating a new blog post</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="6.2">Creating a new blog post</p></div>
      <div>
        <p>To create a new blog posts click on the Blog option on the navigation menu then click on “post blog”. You will be routed to a page with a form. Fill out the details of the post you want to create including the title, a blog summary, content of the post and a 
        corresponding image. After filling out the form click on the “Post Blog” button.</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="6.3">Editing a blog post</p></div>
      <div>
        <p>To edit a blog post navigate to the list of all blogs. On the tabulated view of the outline of blog 
        posts under the action column click on “Edit” next to the post you want to edit. This routes you to a form 
        where you can fill out the details you wish to change. After making your changes to the blog post form 
        click on “Update Blog”.</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="6.4">Deleting a blog post</p></div>
      <div>
        <p>To delete a blog post navigate to the list of all blogs. On the tabulated view of the outline of blog posts 
        under the action column click on “Delete” option  next to the post you want to delete. A popup box appears 
        confirming that you want to delete a post. Click on okay. The post will then be removed from the list of blog posts.</p>
      </div>
    </>
  )
}
export default Owners_Topic6
 