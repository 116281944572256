
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {  connect, useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';

import PropType from 'prop-types';

import { 
	ADMIN_DASHBOARD, SIGNIN 
} from '../constants/routes';
import { Footer, Navigation } from '../components/default';
import Alert from '../helpers/Alert';
import { useDidMount } from '../hooks';

/**
 * Authentication guard for routes
 * 
 * @param {PropTypes.bool} isAuth element/bool
 * @param {PropTypes.string} user_type element/string
 * @param {PropTypes.func} component element/func
 * 
 */

const ClientRoute = ({ 
	isAuth, user_type, component: Component
 }) => {
	let location = useLocation();
	const didMount = useDidMount(true);
	let _from = location.state?.from?.pathname || "/";

	const { requestStatus } = useSelector((store) => ({
		requestStatus: store?.app?.requestStatus
	}));

	if (didMount && isAuth && user_type === 'user') {
		return (
			<>
				<Navigation />
				{requestStatus?.message && (
					<nav className='fixed z-20 w-full top-0 bg-white border-gray-200'>
						{requestStatus?.timeout ? (
							<Alert
								status={requestStatus.status}
								mssg={requestStatus.message}
								type={requestStatus.type}
								timeout={requestStatus.timeout}
							/>
							) : (
							<Alert
								status={requestStatus.status}
								mssg={requestStatus.message}
								type={requestStatus.type}
							/>
						)}
					</nav>
				)}
					<Component />
				<Footer />
			</>
		);
	}
	if (didMount && isAuth && user_type === 'super-admin') {
		return <Navigate to={ADMIN_DASHBOARD} replace state={{ location }} />;
	}

	return (
		<Navigate 
			to={SIGNIN} 
			replace 
			state={{from: _from }} />
	);
}

ClientRoute.defaultProps = {
  isAuth: false,
  user_type: 'user',
  is_active: false
};

ClientRoute.propTypes = {
  isAuth: PropType.bool,
  user_type: PropType.string,
//   is_active: PropType.bool,
  component: PropType.func.isRequired
};

const mapStateToProps = ({ auth }) => ({
  isAuth: !!auth,
  user_type: auth?.user_type || ''
//   is_active: auth?.is_active || false
});

export default connect(mapStateToProps)(ClientRoute);
 