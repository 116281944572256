
import React from 'react';
const Owners_Topic5 = () => {
  return (
    <>
      <div><p className="userguide_header">Managing Products</p></div>

      <div><p className="userguide_subheader anchor_link_section" id="5.1">View all products</p></div>
      <div>
        <p>To view, all products click on the products option on the navigation menu. From the dropdown that appears, 
        select all products. You will be routed to a page where all products are outlined in tabulated form.</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="5.2">Creating Products</p></div>
      <div>
        <p>An admin cannot add products to bestdealnaija, the admin can only verify products that vendors have added. 
        Only vendors can add products.</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="5.3">Editing Products</p></div>
      <div>
        <p>An admin cannot edit product information on bestdealnaija, administrators can only verify products that 
        vendors have added or switch the status of the product to unverified. Only vendors can edit product details.</p>
      </div>
      
      <div><p className="userguide_subheader anchor_link_section" id="5.4">Deleting Products</p></div>
      <div>
        <p>Administrators cannot delete products from bestdealnaija. Only vendors can delete products that they have 
        uploaded. This is done from the vendors account dashboard.</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="5.5">Product Vendors</p></div>
      <div>
        <p>Vendors can only create product listings once their vendor account has been verified. Similarly, Products 
        that are visible on bestdealnaija have to be verified by the administrators before they can be purchased.</p>
      </div>

      <div><p className="userguide_subheader anchor_link_section" id="5.6">Verifying Products</p></div>
      <div>
        <p>To verify a product, navigate to the products page. From the table of products under the actions column 
        click on the verify button next to the product you want to verify. To unverify a product click on the unverify 
        button next to the product.</p>
      </div>
    </>
  )
}
export default Owners_Topic5
 