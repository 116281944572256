
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useParams, Link, useNavigate } from 'react-router-dom';
import RecommendedDeals from '../deals/FindDeal';
import { addToCart } from '../../redux/actions/cartActions';
import { clearSearchState, getProductsSuccess } from '../../redux/actions/productActions';

import {Banner} from '../../components/default/index'
import img from '../../images/tradefair/tradefairbg.jpg';
import * as CONSTANT from '../../constants/constants'
import * as ROUTE from '../../constants/routes';
import './styles/CategoryProduct.css';
import { useDocumentTitle } from '../../hooks';
import { setRequestStatus } from '../../redux/actions/miscActions';

const CategoryProducts = () => {
    const default_products_state = {
        lastRefKey: null,
        nextPage: null,
        prevPage: null,
        items: []
    }
    const [products, setProducts] = useState(default_products_state);
    const [isLoading, setLoading] = useState(false);
    const {slug} = useParams();
    const [search_query, setSearch_query] = useState('')

    const dispatch = useDispatch();
    const nf = new Intl.NumberFormat();
    var myStr = slug;
    var newStr = myStr.replace("-", " & ")
    useDocumentTitle(`Best deals in ${newStr} | BestDealNaija`);
   
    const handleInitialGetCategoryProductsRequest = async () => {
        //set products to default when new slug is passed to the URL
        setProducts(default_products_state)
        // const category_products = getCategoryProducts()
        const response = await fetch(`${ROUTE.PRODUCTS_API}/categories/${slug}/products?pagination=1`)
            .then((res) => (res.json()))
        console.log(response)
        const _state = {
            items: response.results,
            nextPage: response.next_page,
            prevPage: response.prev_page,
            lastRefKey: response.last_product_id
        }
        setProducts(_state);
        setLoading(false);
        // setting product store with category products for searching
        dispatch(getProductsSuccess({
            products: response.results,
            nextPage: response.next_page,
            prevPage: response.prev_page,
            lastKey: response.last_product_id
        }));
    }

    const handleGetCategoryProductsPaginationRequest = async () => {
        const response = await fetch(`${ROUTE.PRODUCTS_API}/categories/${slug}/products?last_key=${products.lastRefKey}&pagination=1`)
            .then((res) => (res.json()))
        const _state = {
            ...products,
            items: [...products.items, ...response.results],
            nextPage: response.next_page,
            prevPage: response.prev_page,
            lastRefKey: response.last_product_id ? response.last_product_id : products.lastRefKey
        }
        setProducts(_state);
        setLoading(false);
        // setting product store with category products for searching
        dispatch(getProductsSuccess({
            products: response.results,
            nextPage: response.next_page,
            prevPage: response.prev_page,
            lastKey: response.last_product_id ? response.last_product_id : products.lastRefKey
        }));
    }

    useEffect(() => {
        handleInitialGetCategoryProductsRequest();
        window.scrollTo(0, 0)
    }, [slug])

    const btnIncreaseQtyCartList = (product) => {
        dispatch(addToCart(product));
        dispatch(setRequestStatus({
			success: true,
			type: 'cart',
			isError: false,
			status: 'success',
			message: 'Added to cart.'
		}))
    }

    const navigate = useNavigate()

    const searchItems = () => {
        dispatch(clearSearchState())
        navigate(`/search/${search_query.trim().toLowerCase()}`);
    }
    
    let productsList = ''
    if (products.items.length > 0) {
        productsList = (<div className="px-3 md:px-[120px] lg:px-[120px] py-[20px] pb-[80px] luday_wrap">  
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">                        
            {products.items.map(product =>
                <div className="place-self-stretch" key={product.id}>
                    <div className="group mx-0 px-2 py-2">
                        <div className='products'>
                            <Link to={`${ROUTE.PRODUCT_DETAILS}/${product.slug}`}>
                                <div className="pb-[5px] text-center bg-white shadow-xl">
                                    <img className="lazy h-60 w-full" src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`} alt={product.name} />            
                                    <div className="gis-text text-center pt-4 overflow-hidden w-full h-20">
                                        <p className="text-lg md:text-xl lg:text-xl font-bold overflow-hidden text-ellipsis whitespace-nowrap px-3">{product.name}</p>
                                        <div className="row text-center">
                                            <p className="text-md font-medium order-first text-bold text-center">
                                                {product.sale_price ? (
                                                    <span>&#8358;{nf.format(product.sale_price)}</span>
                                                ) : ''
                                                } &nbsp;  
                                                <span className={
                                                product.sale_price ? "line-through font-light text-center text-sm mr-1 text-gray-500" : "" }>
                                                    &#8358;{nf.format(product.price)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="row px-[10px] py-2 text-center bg-white">                                       
                                <div className="custom-number-input w-full pb-3">
                                    <div className="row w-full rounded-lg relative bg-transparent">
                                        <button onClick={() => btnIncreaseQtyCartList(product)} className="w-full mt-1 font-semibold leading-none text-white py-4 px-10 bg-[#98c01d] rounded-md hover:bg-[#88af14] focus:ring-2 focus:ring-offset-2 focus:outline-none cursor-pointer md:w-2/3">Add to cart</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            )}
        </div>
    </div>) 
    }
    else {
        productsList = (
            <div className="text-center">
                <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">
                    <h1 className="text-4xl">No products in this category</h1>
                </div>
            </div>
        )
    }
    let introbanner = ''
    if (products.length > 0) {
        introbanner=(
          <Banner subtitleText={products[0]['product_category'] } backgroundImage={`${CONSTANT.IMAGE_STORE}/${products[0].product_category_img}`} />
      )
    }
    else{
        introbanner=(
            <Banner subtitleText='Products' backgroundImage={img} />
        )
    }
    
  return (
    <>
        {introbanner}
        <div className="text-center py-10 luday_deals bg-gray-50">
            <div className="flex justify-center py-8">
                <form id="submitForm" onSubmit={(e) => searchItems(e.target.value)}>
                    <div className="flex items-center flex-nowrap">
                        <input name="search_product" id="productName" value={search_query} onChange={(e) => setSearch_query(e.target.value)} type="search" className="focus:ring-green-500 w-full" placeholder="Search" aria-label="Search"
                        aria-describedby="search-addon" />
                        <button name="searchBtn" value="1" type="submit" className="bg-transparent hover:bg-gray-200 text-green-700 font-semibold hover:text-white py-2 px-2 border border-green-500"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="hover:text-white"><path d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"></path></svg></button>
                    </div>
                </form>
            </div>
        </div>
        
        <div className="w-full pb-[80px]">
            {isLoading ?
                <div className="py-2">
                    <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">  
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-9">
                            <div className="w-full flex items-stretch flex-col p-[10px] pb-[5px]">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex items-stretch flex-col p-[10px] pb-[5px]">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full  items-stretch flex-col p-[10px] pb-[5px] hidden md:flex">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full items-stretch flex-col p-[10px] pb-[5px] hidden md:flex">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            products &&
                (
                    
                    <section className="bg-gray-50 pb-[35px]">
                        {
                            productsList
                        }
                        {products.nextPage && (
                            <div className="flex space-x-2 justify-center pb-10">
                                <button 
                                    type="button"
                                    disabled={isLoading}
                                    onClick={handleGetCategoryProductsPaginationRequest}
                                    className="inline-block px-6 py-2.5 bg-[#98c01d] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-[#88af14] hover:shadow-lg focus:[#88af14] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#98c01d] active:shadow-lg transition duration-150 ease-in-out">
                                    {isLoading ? 'Fetching Items...' : 'Show More Items'}
                                </button>
                            </div>
                        )}
                        <div className="justify-center mx-auto text-center">Click <Link to={`${ROUTE.SIGNUP_VENDOR}`} className="text-[#88af14]"> HERE</Link> to start SELLING your PRODUCTS now </div>
                        {/* <Pagination productsPerPage={productsPerPage} totalProducts={products.length}/> */}
                    </section> 
                )
            }
        </div>
      <RecommendedDeals />
    </>
  )  
}
export default CategoryProducts;
 