
import React from 'react';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import Slider from '../../components/default/Slider';
import FeaturedProduct from '../../components/productsCard/featuredCard';
import RecommendedDeals from '../../pages/deals/FindDeal';
import { Search } from '../../components/default';


function Home() {
  useDocumentTitle('Home | BestDealNaija');
  useScrollTop();
  return (
    <div>
      < Slider />
      <div className="text-center mt-12 md:mt-5">
        <Search />
      </div>
      < FeaturedProduct />
      < RecommendedDeals />
    </div>
  )
}
export default Home;
