
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {  connect } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';

import PropType from 'prop-types';

import { 
	ADMIN_DASHBOARD, SIGNIN 
} from '../constants/routes';
import * as CONSTANT from '../constants/constants'
import { Sidebar, Header } from '../components/default/Vendor';
import { Footer, Navigation } from '../components/default';
import './styles/admin_route.css'
import { useDidMount } from '../hooks';

/**
 * Authentication guard for routes
 * 
 * @param {PropTypes.bool} isAuth element/bool
 * @param {PropTypes.string} user_type element/string
 * @param {PropTypes.func} component element/func
 * 
 */

const VendorRoute = ({ 
	isAuth, user_type, is_active, is_admin_verified,
	component: Component
 }) => {
	let location = useLocation();
	const didMount = useDidMount(true);
	let _from = location.state?.from?.pathname || "/";

	if (didMount && isAuth && is_active && user_type === CONSTANT.VENDOR_ROLE) {
		return (
			<>
				<Sidebar />
				<main className="main-wrap bg-gray-100 h-screen overflow-y-auto">
					<Header />
					<Component />
				</main>
			</>
		);
	}
	if (didMount && isAuth && !is_active && !is_admin_verified && user_type === CONSTANT.VENDOR_ROLE) {
		return (
			<>
				<Navigation />
					<Component />
				<Footer />
			</>
		);
	}
	if (didMount && isAuth && user_type === CONSTANT.ADMIN_ROLE) {
		return <Navigate to={ADMIN_DASHBOARD} replace state={{ location }} />;
	}

	return (
		<Navigate 
			to={SIGNIN} 
			replace 
			state={{from: _from }} />
	);
}

VendorRoute.defaultProps = {
  isAuth: false,
  user_type: CONSTANT.USER_ROLE,
  is_active: false,
  is_admin_verified: false
};

VendorRoute.propTypes = {
  isAuth: PropType.bool,
  user_type: PropType.string,
  is_active: PropType.bool,
  is_admin_verified: PropType.bool,
  component: PropType.func.isRequired
};

const mapStateToProps = ({ auth }) => ({
  isAuth: !!auth,
  user_type: auth?.user_type || '',
  is_active: auth?.is_active || false,
  is_admin_verified: auth?.is_admin_verified || false
});

export default connect(mapStateToProps)(VendorRoute);
 