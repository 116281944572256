
import React from 'react';
import image1 from '../../images/tradefair/image1.jpeg';
import image2 from '../../images/tradefair/image2.jpeg';
import image3 from '../../images/tradefair/image3.jpeg';
import image4 from '../../images/tradefair/image4.jpeg';
import image5 from '../../images/tradefair/image5.jpeg';

import { useDocumentTitle, useScrollTop } from '../../hooks';
import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/tradefair/tradefairbg.jpg';

const TradeFair = () => {
  useDocumentTitle('Best Deals Trade Fair | BestDealNaija');
  useScrollTop();

  const title = 'B E S T  &nbsp;  D E A L S  &nbsp;  I N  &nbsp;  N I G E R I A';
  const subtitle = 'Best Deals Trade Fair';

	return (
		<>
			<Banner backgroundImage={bannerImage} titleText={title} subtitleText={subtitle}/>
			<div className="py-2 p-3">    

                <div id="google_translate_element"></div>

			<div className="py-2 p-3">    
				<div className="flex items-center flex-col-reverse lg:flex-row lg:justify-between gap-9">
					<div className="group py-12 lg:px-16 lg:w-1/2 lg:text-justify">
						<p className="tracking-widest mb-2.5 text-xs">A N N U A L  &nbsp;  O F F L I N E  &nbsp;  S A L E S</p>
						<h4>BEST DEAL NAIJA TRADE FAIR</h4>
						<p className="my-4">The first of its kind pop-up sale initiative of Best Deal Naija</p>
						<p className="my-4">Bestdealnaija Trade Fair is our annual offline sales fair where sellers and business owners give their products and goods for sale at %50 percent discount. Call our rep to know more.</p>		
					</div>
					<div className="lg:px-4 lg:w-1/2">
						<div className="grid gap-3 pb-2 grid-cols-2">
							<img className="lazy h-aufullto min-h-[40%] bg-cover bg-center" src={image1} alt="" />
							<img className="lazy h-full min-h-[40%] bg-cover bg-center" src={image2} alt="" />
						</div>
						<div className="grid gap-3 pt-2 grid-cols-3">
							<img className="lazy height_top min-h-[40%] bg-cover bg-center" src={image3} alt="" />
							<img className="lazy height_top min-h-[40%] bg-cover bg-center" src={image4} alt="" />
							<img className="lazy height_top min-h-[40%] bg-cover bg-center" src={image5} alt="" />
						</div>
					</div>
				</div>
              </div>
			</div>
		</>
	);
}
export default TradeFair;
 