
import React, { Suspense } from 'react';
import { useSelector } from 'react-redux';

import { LoadingOutlined } from '@ant-design/icons';


const Dashboard = React.lazy(() => import('./components/Dashboard'));

const AdminDashboard = () => {
    const isLoading = useSelector((state) => state.app.loading);
    return (
        <div>
        <Suspense fallback={(
            <div className="loader" style={{ minHeight: '80vh' }}>
            <h6>Loading...</h6>
            <br />
            <LoadingOutlined />
            </div>
        )}>
            <Dashboard isLoading={isLoading} />
        </Suspense>
        </div>
    );
}

export default AdminDashboard;
 