
import React from 'react';

import PropType from 'prop-types';
import { usePaystackPayment } from 'react-paystack';

import * as ROUTE from '../constants/routes';
import * as CONSTANT from '../constants/constants';

const updateOrder = async (ref) => {
	if (ref) {
		let status = CONSTANT.ORDER_PAYMENT_PENDING
		if (ref.status  && ref.status === 'success') {
			status = CONSTANT.ORDER_SUCCESS
		}
		if (ref.status  && ref.status === 'failed') {
			status = CONSTANT.ORDER_PAYMENT_FAILED
		}
		try {
			const requestOptions = {
				method: "PUT",
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: JSON.stringify(
					{
						"ref_transaction_id": ref.transaction,
						"ref_id": ref.trxref,
						"status": status
					}
				)
			}
			await fetch(`${ROUTE.PRODUCTS_API}/order/trxref`, requestOptions)
				.then(res => res.json())
				.then(data => {
					if(ref.redirecturl && data){
						window.location.href = `${process.env.REACT_APP_PAYSTACK_CALLBACK_URL}?trxref=${ref.transaction}&reference=${ref.trxref}`;// eslint-disable-line
					}
				})
				.catch(err=> {
                    console.error("Error:", err);
					location.reload()
                })
		} catch (e) {
			console.log(e)
		}	
	}
};

const handlePaystackSuccessAction = (reference) => {
	if (reference && reference.trxref) {
		updateOrder(reference)
	} else {
		console.log("No transaction reference:: Contact Admin")
	}
};

const handlePaystackCloseAction = () => {
	console.log('closed')
}

const PayWithPaystack = ({state, subTotal}) => {
	const accept_payment_config = {
		reference: state.billing.ref_id,
		email: state.billing.email ?? state.profile.email,
		amount: subTotal * 100, // set in kobo!
		metadata: {
			first_name: state.billing.first_name ?? state.profile.first_name,
			last_name: state.billing.last_name ?? state.profile.last_name,
			mobile_number: state.billing.mobile_number ?? state.profile.mobile_number,
			custom_field: [{ profile_id: state?.profile.id ? state.profile.id : null }]
		},
		publicKey: `${process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}` // eslint-disable-line
	};

	const split_code_config = {
		reference: state.billing.ref_id,
		email: state.billing.email ?? state.profile.email,
		amount: subTotal * 100, // set in kobo!
		split_code: state?.billing?.split_code,
		metadata: {
			first_name: state.billing.first_name ?? state.profile.first_name,
			last_name: state.billing.last_name ?? state.profile.last_name,
			mobile_number: state.billing.mobile_number ?? state.profile.mobile_number,
			custom_field: [{ profile_id: state?.profile.id ? state.profile.id : null }]
		},
		publicKey: `${process.env.REACT_APP_PAYSTACK_PUBLIC_KEY}` // eslint-disable-line
	};

	const initConfig = process.env.REACT_APP_PAYSTACK_SPLIT_PAYMENT == 'yes' ? split_code_config : accept_payment_config; // eslint-disable-line

	const initializePayment = usePaystackPayment(initConfig);
	return (
		<button onClick={() => {
			initializePayment(handlePaystackSuccessAction, handlePaystackCloseAction)
			}}
			className="button button--theme overflow-hidden bg-[#9c0] 
			hover:bg-yellow-300 text-white text-sm transition-all ease-in-out duration-300 
			rounded py-3 px-5 m-2 flex items-center flex-nowrap w-full md:w-1/2">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current mr-1">
				<path d="M21 4H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 11a3 3 0 0 0-3 3H7a3 3 0 0 0-3-3V9a3 3 0 0 0 3-3h10a3 3 0 0 0 3 3v6z">
				</path>
				<path d="M12 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
				</path>
			</svg> 
			<span>Pay & Complete Order</span>
		</button>
	);

};

PayWithPaystack.propTypes = {
	state: PropType.object.isRequired,
	subTotal: PropType.any.isRequired
};
export default PayWithPaystack;
 