
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import { shallowEqual, useSelector } from 'react-redux';

import * as ROUTE from '../../../constants/routes'

const Users = () => {
  const [users, setUsers] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();

  const { accessToken } = useSelector((state) => ({
    accessToken: state.profile.access_token
  }), shallowEqual);

  const onNext = () => {
    if (nextPage != null) {
      fetchUsers(nextPage);
    }
  };

  const onPrevious = () => {
    if (prevPage != null) {
      fetchUsers(prevPage);
    }
  };

  const onPageChange = (page) => {
    if (page != null) {
      fetchUsers(page);
    }
  };

  const fetchUsers = (page) => {
    setLoading(true)
    fetch(`${ROUTE.USER_API}/users?pagination=1&page=${page}`, {
      headers:{
        'Content-Type':'application/json',
        'Authorization': 'Bearer ' + accessToken    
      }
    })
    .then((res) => res.json())
    .then((res) => {
      setUsers(res)
      setNextPage(res.next_page)
      setPrevPage(res.prev_page)
      setCurrentPage(res.current_page)
      setLoading(false)
    })
    .catch(error => {
      console.log(error)
      setLoading(false)
    })
  }
  const first_item_on_page_number = (users?.current_page * users?.items_per_page) - users?.items_per_page + 1;
  const last_item_on_page_number = first_item_on_page_number + users?.results?.length -1;

  useEffect(() => {
    fetchUsers(currentPage)
  }, [])
  
  return (
    <>
      <div className="w-full md:w-[95%] m-2 text-md p-5">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
          <div className="flex items-center space-x-2 text-gray-400">
            <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
            <span>/</span>
            <span className="tracking-wide text-md">
                <span className="text-base">Customers</span>
            </span>
            <span>/</span>
          </div>
        </div>
        <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
          <h1 className="font-medium text-2xl">Customers</h1>
        </div>
        <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-3">Customer Name</th>
                <th scope="col" className="px-6 py-3">Gender</th>
                <th scope="col" className="px-6 py-3">Phone</th>
                <th scope="col" className="px-6 py-3">Email</th>
                <th scope="col" className="px-6 py-3">Joined</th>
              </tr>
            </thead>
            <tbody>
              {!isLoading && users?.results?.length <= 0 && (
                <>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan={5}>
                      <div className="flex flex-col justify-center items-center my-4 space-y-3">No customer registered yet</div>
                    </td>
                  </tr>
                </>
              )}
              {!isLoading && users?.results?.length >= 1 && users?.results?.map((user) => (
                <>
                  <tr key={user.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                   <th scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {user.first_name} {user.last_name}
                    </th>
                    <td className="px-6 py-4">{user.gender}</td>
                    <td className="px-6 py-4">{user.mobile_number ? user.mobile_number : 'N/A'}</td>
                    <td className="px-6 py-4">{user.email}</td>
                    <td className="px-6 py-4">{user.created_at.substring(0,17)}</td>
                  </tr>
                </>
              ))}
              {isLoading && (
                <>
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                    <td colSpan={5}>
                      <div className="flex flex-col justify-center items-center my-4 space-y-3">Fetching records...</div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
          {!isLoading && users?.results?.length >= 1 && (
            <nav
              className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
              aria-label="Table navigation"
            >
              <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                Showing{" "}
                <span className="font-semibold text-gray-900 dark:text-white">
                {`${first_item_on_page_number}-${last_item_on_page_number}`}
                </span> of <span className="font-semibold text-gray-900 dark:text-white">{users?.total_records}</span>
              </span>
              <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                {
                  users?.prev_page && (
                    <li>
                      <a onClick={onPrevious}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </a>
                    </li>
                  )
                }
                {users?.iter_pages.map((iter_page) => (
                  <>
                    {iter_page != null ?
                      <li>
                        <a onClick={() => onPageChange(iter_page)}
                          className={`${users?.current_page === iter_page ? 'flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                          : 'flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                        >
                          {iter_page}
                        </a>
                      </li> : 
                      <li>
                        <a href="#"
                          className='flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                        >
                          {'...'}
                        </a>
                      </li>
                    }
                  </>
                ))}
                {
                  users?.next_page && (
                    <li>
                      <a onClick={onNext}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </a>
                    </li>
                  )
                }
              </ul>
            </nav>
          )}
        </div>
      </div>
    </>
  );
};
export default Users;
 