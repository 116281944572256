
import React from 'react';
const Topic4 = () => {
  return (
    <>
      <div>
        <p className="userguide_header">Managing your Account</p>
        <p className="userguide_subheader anchor_link_section" id="4.1">1. Log in to your account</p>
        <p>To log in to your account, click on the account option and select login. Provide your email and password
         then click login. You will be automatically logged into your bestdealnaija account.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.2">2. View your transaction history</p>
        <p>To view your transaction history simply login to your dashboard and click on “My Order History”. 
        You will be routed to a page where you will be able to view all orders and transactions.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.3">3. View your vendor accounts</p>
        <p>To view your vendor account log in using the email and password used to create your vendor account. User accounts cannot coexist with vendor
         accounts which means even if you have a user account on bestdealnaija your vendor account will have different login details.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.4">4. Editing profile information</p>
        <p>To edit your profile information log in to your account and go to the profile page. Click on the edit button and a form drops down which allows you to update your 
        profile information. You can update all your information including your name, email, phone number, and address.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.5">5. Change your password</p>
        <p>To change your password log in to your profile and click on the change password button.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.6">6. Manage your payment information</p>
        <p>For users payment information is only required when making a purchase. For vendors, payment information 
        is required to set up a receiving account when funds will be transferred after payment for a product has 
        been made.</p>
      </div>
    </>
  )
}
export default Topic4
 