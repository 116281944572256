
import React, { Suspense, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';

import { LoadingOutlined } from '@ant-design/icons';
import { useVendorByUserId } from '../../hooks';

const VendorProfileComponent = React.lazy(() => import('./components/VendorProfile'));

const VendorProfile = () => {
  const { profile } = useSelector((state) => ({
    profile: state.profile,
    auth: state.auth,
    isLoading: state.app.isLoading
  }), shallowEqual);
  
  const user_id = profile.id
  const {vendor, fetchVendorByUserId} = useVendorByUserId();
  
  useEffect(() => {
    fetchVendorByUserId(user_id)
  }, [fetchVendorByUserId, user_id]);

  return (
    <>
      {vendor?.id && (
        <Suspense fallback={(
          <div className="loader" style={{ minHeight: '80vh' }}>
            <h6>Loading...</h6>
            <br />
            <LoadingOutlined />
          </div>
        )}>
          <VendorProfileComponent vendor={vendor}/>
        </Suspense>
      )}
    </>
	);
}
export default VendorProfile;
 