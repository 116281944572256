
import {
  ON_AUTHSTATE_FAIL,
  SET_AUTH_PERSISTENCE,
  ON_AUTHSTATE_SUCCESS,
  SIGNIN, SIGNOUT, SIGNUP, 
  SIGNUP_VENDOR, SIGNUP_VENDOR_DETAILS,
  VENDOR_ROLE, USER_ROLE, FORGOT_PASSWORD, RESET_PASSWORD
} from '../../constants/constants';
import { USER_API } from '../../services';
import { call, put } from 'redux-saga/effects';
import { signInSuccess, signOutSuccess } from '../actions/authActions';
import { clearCart } from '../actions/cartActions';
import { resetCheckout } from '../actions/checkoutActions';
import { setAuthenticating, setAuthStatus, setLoading} from '../actions/miscActions';
import { clearProfile, setProfile } from '../actions/profileActions';

function* handleError(e) {
  const obj = { success: false, type: 'auth', isError: true };
  yield put(setAuthenticating(false));

  if (e.json?.email) {
    yield put(setAuthStatus({ ...obj, status: 'error', message: 'Email is already in use. Please use another email' }));
  }
}

function* initRequest() {
  yield put(setAuthenticating());
  yield put(setAuthStatus({}));
}

function* authSaga({ type, payload }) {
  switch (type) {
    case SIGNIN:
      try {
        yield initRequest();

        const auth = {
          email: payload.email,
          password: payload.password
        };
        const auth_user = yield call(USER_API.signIn, auth);
        const user_snapshot = yield call(USER_API.getAuthenticatedUser, {token: auth_user.access_token});
        if (user_snapshot) { 
          const user = user_snapshot;
          localStorage.setItem('user', JSON.stringify(user));
          localStorage.setItem('auth_tokens', JSON.stringify(auth_user));

          yield put(setProfile(user, auth_user));

          yield put(signInSuccess({
            id: user_snapshot.id,
            user_type: user_snapshot.user_type,
            is_active: user_snapshot.is_active,
            is_admin_verified: user_snapshot.is_admin_verified,
            provider: user_snapshot.provider
          }));
  
          yield put(setAuthStatus({
            success: true,
            status: 'info',
            type: 'auth',
            isError: false,
            message: 'Successfully signed in. Redirecting...'
          }));
        }

      } catch (e) {
        console.log(e)
        yield put(setAuthenticating(false));
        yield put(setAuthStatus({ 
          success: false, 
          type: 'auth', 
          isError: true, 
          status: 'error', 
          message: 'Incorrect email or password' 
        }));
      }
      break;
      
    case SIGNUP:
      try {
        yield initRequest();
        const user = {
            first_name: payload.first_name,
            last_name: payload.last_name,
            email: payload.email,
            password: payload.password,
            gender: payload.gender,
            mobile_number: payload.mobile_number,
            user_type: USER_ROLE,
            is_active: payload.is_active
          };
        
        const auth = {
          email: payload.email,
          password: payload.password
        };
        const ref_user = yield call(USER_API.signUp, user);
        const auth_token = yield call(USER_API.signIn, auth);
        localStorage.setItem('user', JSON.stringify(ref_user));
        localStorage.setItem('auth_tokens', JSON.stringify(auth_token));

        yield put(setProfile(ref_user, auth_token));

        yield put(signInSuccess({
          id: ref_user.id,
          user_type: user.user_type,
          is_active: user.is_active,        
          provider: user.provider
        }));

        yield put(setAuthStatus({
          success: true,
          status: 'info',
          type: 'auth',
          isError: false,
          message: 'Successfully signed up. Redirecting...'
        }));

        yield put(setAuthenticating(false));
      } catch (e) {
        yield handleError(e);
      }
    break;

    case SIGNUP_VENDOR:
      try {
        yield initRequest();
        
        const vendor = {
          first_name: payload.first_name,
          last_name: payload.last_name,
          email: payload.email,
          password: payload.password,
          gender: payload.gender,
          mobile_number: payload.mobile_number,
          user_type: VENDOR_ROLE,
          is_active: payload.is_active
        };
        const auth = {
          email: payload.email,
          password: payload.password
        };
        const ref_vendor = yield call(USER_API.signUp, vendor);
        const auth_token = yield call(USER_API.signIn, auth);
        localStorage.setItem('user', JSON.stringify(ref_vendor));
        localStorage.setItem('auth_tokens', JSON.stringify(auth_token));

        yield put(setProfile(ref_vendor, auth_token));

        yield put(signInSuccess({
          id: ref_vendor.id,
          user_type: vendor.user_type,
          is_active: vendor.is_active,
          provider: vendor.provider
        }));

        yield put(setAuthStatus({
          success: true,
          type: 'auth',
          isError: false,
          message: 'Successfully signed up. Redirecting...'
        }));

        yield put(setAuthenticating(false));
      } catch (e) {
        yield handleError(e);
      }
    break;
    
    case SIGNUP_VENDOR_DETAILS: {
      try {
        yield initRequest();

        let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
        yield call(USER_API.signUpVendorDetails, payload, {token: auth_tokens.access_token});

        yield put(setAuthStatus({
          success: true,
          type: 'auth',
          isError: false,
          message: 'Successfully signed details.'
        }));
        yield put(setAuthenticating(false));
      } catch (e) {
        console.log(e)
        yield handleError(e);
      }
      break;
    }

    case SIGNOUT: {
      try {
        yield initRequest();

        localStorage.removeItem('user');
        let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
        localStorage.removeItem('auth_tokens');

        yield put(clearCart());
        yield put(clearProfile());
        yield put(resetCheckout());
        yield put(signOutSuccess());
        yield put(setAuthenticating(false));
        yield call(USER_API.signOut, {token: auth_tokens.access_token});
      } catch (e) {
        console.log(e);
      }
      break;
    }

    case FORGOT_PASSWORD: {
      try {
        yield initRequest();

        yield call(USER_API.forgotUserPassword, payload);
        yield put(setAuthStatus({
          success: true,
          type: 'reset',
          status: 'success',
          isError: false,
          message: 'Password reset email has been sent to your provided email.'
        }));
        yield put(setAuthenticating(false));
      } catch (e) {
        console.log(e);
        yield put(setAuthStatus({
          success: false,
          type: 'reset',
          status: 'error',
          isError: true,
          message: 'Email not found.'
        }));
        yield put(setAuthenticating(false));
      }
      break;
    }

    case RESET_PASSWORD: {
      try {
        yield initRequest();

        const data = {
          token: payload.token,
          new_password: payload.new_password
        };
        yield call(USER_API.resetUserPassword, data);
        yield put(setAuthStatus({
          success: true,
          type: 'reset',
          status: 'success',
          message: 'Password reset was successful.'
        }));
        yield put(setAuthenticating(false));
      } catch (e) {
        console.log(e)
        yield put(setAuthenticating(false));
        yield put(setAuthStatus({ 
          success: false, 
          type: 'auth', 
          isError: true, 
          status: 'error', 
          message: 'Expired or invalid reset token' 
        }));
      }
      break;
    }

    case ON_AUTHSTATE_SUCCESS: {
      try {
        let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
        const user_shot = yield call(USER_API.getAuthenticatedUser, {token: auth_tokens?.access_token});
        const user = user_shot;

        yield put(setProfile(user, auth_tokens));
        yield put(signInSuccess({
          id: payload.id,
          user_type: user.user_type,
          is_active: user.is_active,
          is_admin_verified: user.is_admin_verified,
          provider: user.provider
        }));

        yield put(setAuthStatus({
          success: true,
          type: 'auth',
          isError: false,
          message: 'Successfully signed in. Redirecting...'
        }));
        yield put(setAuthenticating(false));
      } catch (e) {
        localStorage.removeItem('user');
        localStorage.removeItem('auth_tokens');
        yield put(clearCart());
        yield put(clearProfile());
        yield put(resetCheckout());
        yield put(signOutSuccess());
        yield put(setAuthenticating(false));
        yield put(setLoading(false));
        // console.log(e);
      }
      break;
    }
  
    case ON_AUTHSTATE_FAIL: {
      localStorage.removeItem('user');
      localStorage.removeItem('auth_tokens');
      yield put(clearProfile());
      yield put(signOutSuccess());
      break;
    }

    case SET_AUTH_PERSISTENCE: {
      yield put(setLoading(true));
      try {
        let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
        let set_auth_persistence = yield call(USER_API.getRefreshToken, auth_tokens);

        if (set_auth_persistence) {
          localStorage.setItem('auth_tokens', JSON.stringify(set_auth_persistence));

          let refresh_auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
          const user_shot = yield call(USER_API.getAuthenticatedUser, {token: refresh_auth_tokens?.access_token});
          
          if (user_shot) { 
            const user = user_shot;
            yield put(setProfile(user, refresh_auth_tokens));
          }
          yield put(setLoading(false));
        }        
      } catch (e) {
        yield put(setLoading(false));
        console.log(e);
        localStorage.removeItem('user');
        localStorage.removeItem('auth_tokens');
        yield put(clearCart());
        yield put(clearProfile());
        yield put(resetCheckout());
        yield put(signOutSuccess());
        yield put(setAuthenticating(false));
      }
      break;
    }
    default: {
      throw new Error('Unexpected Action Type.');
    }
  }
}

export default authSaga;
 