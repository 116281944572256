const DeliveryStatuslist =[
   
    {
        label: "Pending",
        value: "PENDING"
    },
    {
        label: "Shipped",
        value: "SHIPPED"
    },
    {
        label: "Delivered",
        value: "DELIVERED"
    }
];

export default DeliveryStatuslist;