
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux';

import swal from 'sweetalert'
import axios from 'axios'

import * as ROUTE from '../../../constants/routes';

const SubCategory = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState();
    const [prevPage, setPrevPage] = useState();

    const {register, handleSubmit, reset, formState: { errors } } = useForm();
    const { accessToken } = useSelector((state) => ({
        accessToken: state.profile.access_token
    }), shallowEqual);

    const onNext = () => {
        if (nextPage != null) {
            getSubCategory(nextPage);
        }
      };
    
      const onPrevious = () => {
        if (prevPage != null) {
            getSubCategory(prevPage);
        }
      };
    
      const onPageChange = (page) => {
        if (page != null) {
            getSubCategory(page);
        }
      };

    const submitForm = async (data) => {
        try {
            const requestOptions = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(
                    {
                        "category_id": data.category,
                        "name": data.name
                    }
                )
            }
            await fetch(`${ROUTE.PRODUCTS_API}/categories/sub`, requestOptions)
                .then(res => res.json())
                .then(() => {
                    const msg = "Category successfully added"
                    successAlert(msg)
                })
                .catch(err => {
                    console.error("Error:", err);
                })
            reset()
        } catch (e) {
			console.log(e)
		}
    };

    const successAlert = (msg) => {
        return(
            swal({
                title: "Info!",
                text: msg,
                icon: "success"
            }).then(function () {
                getSubCategory()
            })
        )
    }
    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error,
              icon: "error"
          }).then(function () {
            getSubCategory()
          })          
        )
    }

    const getCategories = () => {
        fetch(`${ROUTE.PRODUCTS_API}/categories`)
            .then((res) => res.json())
            .then((res) => {
            setCategories(res.results)
        })
    }
    const getSubCategory = (page) => {
        setIsLoading(true)
        fetch(`${ROUTE.PRODUCTS_API}/categories/sub?pagination=1&page=${page}`, {
            headers:{
              'Content-Type':'application/json',
              'Authorization': 'Bearer ' + accessToken    
            }
          })
        .then((res) => res.json())
        .then((res) => {
            setSubCategory(res)
            setNextPage(res.next_page)
            setPrevPage(res.prev_page)
            setCurrentPage(res.current_page)
            setIsLoading(false)
        })
        .catch(error => {
            console.log(error)
            setIsLoading(false)
        })
    }

    const first_item_on_page_number = (subCategory?.current_page * subCategory?.items_per_page) - subCategory?.items_per_page + 1;
    const last_item_on_page_number = first_item_on_page_number + subCategory?.results?.length -1;

    useEffect(() => {
        getCategories()
        getSubCategory(currentPage)
    }, [])
    
    const deleteSubCategory = useCallback( async (id)  => {
        if(window.confirm('Are you sure you want to delete this sub-category?')){
        axios.delete(
            `${ROUTE.PRODUCTS_API}/categories/sub/${id}`,{
                method : 'DELETE',
                body : JSON.stringify({
                    id : id
                }),
                headers: {
                    'Content-type': 'application/json'
                }
            })
            .then(res => res)
            .then(() =>{
                const msg = "Category successfully deleted"
                successAlert(msg)
            })
            .catch(err => errorAlert(err)
            )
        }
    }, []);

    let categoryDropdown = ''
        categoryDropdown = categories.map((category, i) => {
            return (
                <option className="my-2 hover:bg-gray-50" key={i} value={category.id}> 
                    {category.name} 
                </option>
            )
        }
    )
    
    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">Product Sub Categories</span>
                    </span>
                    <span>/</span>
                </div>
            </div>
            <div className="header my-3 h-12 px-10 flex items-center justify-between">
                <h1 className="font-medium text-2xl">Product Sub Categories</h1>
            </div>
            <div className="flex flex-col mx-3 mt-6 lg:flex-row">
                <div className="w-full lg:w-1/3 m-1">
                    {/* Display add category form */}
                    <form className="w-full bg-white shadow-md p-6">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category'>Category</label>
                                <select className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" 
                                    name="category" {...register("category", { required: true })}
                                >
                                    <option value={''}>Select category</option>
                                    { categoryDropdown }
                                </select>

                                {errors.category && <small className="text-red-500 text-xs italic">Select at least one category</small>}
                            </div>
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category_name'>Sub Category Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" 
                                    type='text' name="name" placeholder="Sub Category Name"
                                    {...register("name", { required: true})}
                                    required 
                                />
                                {errors.name && <small className="text-red-500 text-xs italic">Sub-category name is required</small>}
                                {errors.name?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 25 </small></p>}
                            </div>
                            <div className="w-full md:w-full px-3 mb-6">
                                <button className="appearance-none block w-full bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                                    onClick={handleSubmit(submitForm)}>
                                    Add Sub Category
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="w-full lg:w-2/3 m-1">
                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3">Sub-Category</th>
                                <th scope="col" className="px-6 py-3">Created At</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!isLoading && subCategory?.results?.length <= 0 && (
                                <>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={3}>
                                    <div className="flex flex-col justify-center items-center my-4 space-y-3">No sub-category added yet</div>
                                    </td>
                                </tr>
                                </>
                            )}
                            {!isLoading && subCategory?.results?.length >= 1 && subCategory?.results?.map((category) => (
                                <>
                                <tr key={category.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                
                                    <td className="px-6 py-4">{category.name}</td>
                                    <td className="px-6 py-4">{category.description}</td>
                                    <td className="px-6 py-4">{category.created_at.substring(0,17)}</td>
                                    <td className="flex items-center px-6 py-4">
                                    <Link to={`${ROUTE.ADMIN_EDIT_SUB_CATEGORIES}/${category.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                        Edit
                                    </Link>
                                    <a onClick={() => deleteSubCategory(category.id)}
                                        className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer ms-3"
                                    >
                                        Delete
                                    </a>
                                    </td>
                                </tr>
                                </>
                            ))}
                            {isLoading && (
                                <>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td colSpan={3}>
                                            <div className="flex flex-col justify-center items-center my-4 space-y-3">Fetching records...</div>
                                        </td>
                                    </tr>
                                </>
                            )}
                            </tbody>
                        </table>
                        {!isLoading && subCategory?.results?.length >= 1 && (
                            <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
                                aria-label="Table navigation"
                            >
                                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                                    Showing{" "}
                                    <span className="font-semibold text-gray-900 dark:text-white">
                                        {`${first_item_on_page_number}-${last_item_on_page_number}`}
                                    </span> of <span className="font-semibold text-gray-900 dark:text-white">{subCategory?.total_records}</span>
                                </span>
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    {
                                    subCategory?.prev_page && (
                                        <li>
                                            <a onClick={onPrevious}
                                                className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            >
                                                Previous
                                            </a>
                                        </li>
                                    )
                                    }
                                    {subCategory?.iter_pages.map((iter_page) => (
                                        <>
                                            {iter_page != null ?
                                            <li>
                                                <a onClick={() => onPageChange(iter_page)}
                                                    className={`${subCategory?.current_page === iter_page ? 'flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                                        : 'flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                                                >
                                                    {iter_page}
                                                </a>
                                            </li> : 
                                            <li>
                                                <a href="#"
                                                    className='flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                                >
                                                {'...'}
                                                </a>
                                            </li>
                                            }
                                        </>
                                    ))}
                                    {
                                    subCategory?.next_page && (
                                        <li>
                                            <a onClick={onNext}
                                                className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            >
                                                Next
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SubCategory;
 