
import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from "@sentry/react";

import './index.css';
import configureStore from './redux/store/store';
import { onAuthStateFail, onAuthStateSuccess
} from './redux/actions/authActions';
import App from './App';
import reportWebVitals from './reportWebVitals';

const { store, persistor } = configureStore();
const root = document.getElementById('root');

let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
let user = JSON.parse(localStorage.getItem('user'));

if (user && auth_tokens) {
  store.dispatch(onAuthStateSuccess(user));
} else {
  store.dispatch(onAuthStateFail('Failed to authenticate'));
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS, // eslint-disable-line
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^https:\/\/testbestdeal\.luday\.xyz\//, /^https:\/\/bestdealnaija\.com\//]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.render( //eslint-disable-line
  <React.StrictMode>
    <App store={store} persistor={persistor} />
  </React.StrictMode>,
  root
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
