
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_CATEGORY_PRODUCTS = 'GET_CATEGORY_PRODUCTS';
export const SEARCH_PRODUCT = 'SEARCH_PRODUCT';
export const SEARCH_PRODUCT_SUCCESS = 'SEARCH_PRODUCT_SUCCESS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const ADD_PRODUCT_SUCCESS = 'ADD_PRODUCT_SUCCESS';
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT';
export const REMOVE_PRODUCT_SUCCESS = 'REMOVE_PRODUCT_SUCCESS';
export const EDIT_PRODUCT = 'EDIT_PRODUCT';
export const EDIT_PRODUCT_SUCCESS = 'EDIT_PRODUCT_SUCCESS';
export const CANCEL_GET_PRODUCTS = 'CANCEL_GET_PRODUCTS';
export const CLEAR_SEARCH_STATE = 'CLEAR_SEARCH_STATE';
export const CLEAR_PRODUCTS = 'CLEAR_PRODUCTS';
export const SET_LAST_REF_KEY = 'SET_LAST_REF_KEY';
export const EDIT_VENDOR = 'EDIT_VENDOR';

export const SET_CART_ITEMS = 'SET_CART_ITEMS';
export const ADD_TO_CART = 'ADD_TO_CART';
export const REMOVE_FROM_CART = 'REMOVE_FROM_CART';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_QTY_ITEM = 'ADD_QTY_ITEM';
export const MINUS_QTY_ITEM = 'MINUS_QTY_ITEM';
export const UPDATE_CART_PRODUCT = 'UPDATE_CART_PRODUCT';

export const SET_CHECKOUT_BILLING_DETAILS = 'SET_CHECKOUT_BILLING_DETAILS';
export const ADD_ORDER = 'ADD_ORDER';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const UPDATE_ORDER_TRANSACTION = 'UPDATE_ORDER_TRANSACTION';
export const SET_CHECKOUT_PAYMENT_DETAILS = 'SET_CHECKOUT_PAYMENT_DETAILS';
export const RESET_CHECKOUT = 'RESET_CHECKOUT';

export const SIGNIN = 'SIGNIN';
export const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS';
export const SIGNUP = 'SIGNUP';
export const SIGNUP_VENDOR = 'SIGNUP_VENDOR';
export const SIGNUP_VENDOR_DETAILS = 'SIGNUP_VENDOR_DETAILS';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS';
export const SET_AUTH_STATUS = 'SET_AUTH_STATUS';
export const SIGNIN_WITH_GOOGLE = 'SIGNIN_WITH_GOOGLE';
export const SIGNIN_WITH_FACEBOOK = 'SIGNIN_WITH_FACEBOOK';
export const ON_AUTHSTATE_CHANGED = 'ON_AUTHSTATE_CHANGED';
export const SET_AUTH_PERSISTENCE = 'SET_AUTH_PERSISTENCE';
export const ON_AUTHSTATE_SUCCESS = 'ON_AUTHSTATE_SUCCESS';
export const ON_AUTHSTATE_FAIL = 'ON_AUTHSTATE_FAIL';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const UPDATE_EMAIL = 'UPDATE_EMAIL';
export const SET_PROFILE = 'SET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_VENDOR_PROFILE = 'UPDATE_VENDOR_PROFILE';
export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ADDRESS_SUCCESS = 'UPDATE_PROFILE_ADDRESS_SUCCESS';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const SET_GUEST = 'SET_GUEST';

export const REGISTER_USER = 'REGISTER_USER';
export const GET_USER = 'GET_USER';
export const ADD_USER = 'ADD_USER';
export const DELETE_USER = 'DELETE_USER';
export const EDIT_USER = 'EDIT_USER';

export const LOADING = 'LOADING';
export const ORDER_LOADING = 'ORDER_LOADING';
export const IS_AUTHENTICATING = 'IS_AUTHENTICATING';
export const SET_REQUEST_STATUS = 'SET_REQUEST_STATUS';
export const SET_ORDER_STATUS = 'SET_ORDER_STATUS';

// Refernce: api/products/app/constants.py
export const ORDER_NEW = 'New';
export const ORDER_PAYMENT_PENDING = 'Pending';
export const ORDER_PAYMENT_FAILED = 'Failed';
export const ORDER_SUCCESS = 'Successful';
export const DELIVERY_PENDING = 'Pending';
export const DELIVERY_PROCESSING = 'Shipped';
export const DELIVERY_COMPLETE = 'Delivered';
export const CASH_ORDER_TYPE = 'Cash';
export const ONLINE_ORDER_TYPE = 'Online';

/* Production image store URL */
export const IMAGE_STORE = process.env.REACT_APP_IMAGE_STORE_URL; // eslint-disable-line
/* Test image store URL should be the windows location of the folder */
// export const IMAGE_STORE = 'C:/react/bestdealnaija';

// Refernce: api/user/app/constants.py
export const USER_ROLE = 'user';
export const VENDOR_ROLE = 'vendor';
export const ADMIN_ROLE = 'super-admin';
export const SUB_ADMIN_ROLE = 'sub-admin';
 
export const GOOGLE_MAP_I_FRAME_LINK = 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1981.6505630758354!2d3.3483426691859357!3d6.609455499423803!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b9232a9017a1d%3A0xeb2a238c4b80568b!2s2%20Siyanbola%20Cl%2C%20Oba%20Akran%2C%20Ikeja%20101233%2C%20Lagos!5e0!3m2!1sen!2sng!4v1706271345821!5m2!1sen!2sng';
export const COMPANY_EMAIL = 'info@bestdealnaija.com';
export const COMPANY_PHONE_NUMBER = '08141737402';
export const COMPANY_PHONE_NUMBER_ALT = '07084330574';
export const COMPANY_ADDRESS = '2, Siyanbola Close, Akora Estate Off Adeniyi Jones';
export const COMPANY_WORK_HOURS = 'Monday - Friday: 09:00 - 17:00';
 