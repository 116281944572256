
import React, { useEffect, useState, useCallback} from "react";
import { useDispatch } from 'react-redux';
import { useParams, Link, useNavigate, createSearchParams } from 'react-router-dom';
import RecommendedDeals from '../deals/FindDeal';
import { addToCart } from '../../redux/actions/cartActions';
import { getProductsSuccess } from '../../redux/actions/productActions';

import {Banner, Pagination} from '../../components/default/index'
import img from '../../images/tradefair/tradefairbg.jpg';
import * as CONSTANT from '../../constants/constants'
import * as ROUTE from '../../constants/routes';
import './styles/CategoryProduct.css';

const VendorProducts = () => {
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage] = useState(1);
    const [productsPerPage] = useState(20);
    const {slug} = useParams();
    const [search_query, setSearch_query] = useState('')
    const [vendor, fetchVendors] = useState([]);
    //const [searchInput, setSearchInput] = useState('');
    
    const dispatch = useDispatch();
    const nf = new Intl.NumberFormat();

    const getData = () => {
        //setLoading(true)
      fetch(`${ROUTE.USER_API}/user/vendors/${slug}`)
        .then((res) => res.json())
        .then((res) => {
            fetchVendors(res.vendor)
            getProducts(res.vendor[0].id)
        })
      }

    const getProducts = useCallback(async (vendor_id) => {
        await fetch(`${ROUTE.PRODUCTS_API}/vendors/products/${vendor_id}/verified`)
        .then((res) => res.json())
        .then((res) => {
            setProducts(res.results);
            setLoading(false);
            dispatch(getProductsSuccess({
                products: res.results
            }));
        })
    })

    useEffect(() => {
        getData()
    }, [])

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstPost = indexOfLastProduct - productsPerPage;
    const currentProducts = products.slice(indexOfFirstPost, indexOfLastProduct)

    const btnIncreaseQtyCartList = (product) => {
        dispatch(addToCart(product));
    }

    const navigate = useNavigate()
    const params = { sort: search_query };

    const searchItems = () => {
        navigate({
            pathname: '/search',
            search: `?${createSearchParams(params)}`
          });
    } 
    
    let productsList = ''
    if (products.length > 0) {
        productsList = (<div className="px-3 md:px-[120px] lg:px-[120px] py-[20px] pb-[80px] luday_wrap">  
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">                        
            {currentProducts.map(product =>
                <div className="place-self-stretch" key={product.id}>
                    <div className="group mx-0 px-2 py-2">
                        <div className='products'>
                            <Link to={`${ROUTE.PRODUCT_DETAILS}/${product.slug}`}>
                                <div className="pb-[5px] text-center bg-white shadow-xl">
                                    <img className="lazy h-60 w-full" src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`} alt={product.name} />            
                                    <div className="gis-text text-center pt-4 overflow-hidden w-full h-20">
                                        <p className="text-lg md:text-xl lg:text-xl font-bold overflow-hidden text-ellipsis whitespace-nowrap px-3">{product.name}</p>
                                        <div className="row text-center">
                                            <p className="text-md font-medium order-first text-bold text-center">
                                                {product.sale_price ? (
                                                    <span>&#8358;{nf.format(product.sale_price)}</span>
                                                ) : ''
                                                } &nbsp;  
                                                <span className={
                                                product.sale_price ? "line-through font-light text-center text-sm mr-1 text-gray-500" : "" }>
                                                    &#8358;{nf.format(product.price)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="row px-[10px] py-2 text-center bg-white">                                       
                                <div className="custom-number-input w-full pb-3">
                                    <div className="row w-full rounded-lg relative bg-transparent">
                                        <button onClick={() => btnIncreaseQtyCartList(product)} className="w-full mt-1 font-semibold leading-none text-white py-4 px-10 bg-[#98c01d] rounded-md hover:bg-[#88af14] focus:ring-2 focus:ring-offset-2 focus:outline-none cursor-pointer md:w-2/3">Add to cart</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            )}
        </div><br />
        <div className="justify-center mx-auto text-center">Click <Link to={`${ROUTE.SIGNUP_VENDOR}`} className="text-[#88af14]"> HERE</Link> to start SELLING your PRODUCTS now  </div>
    </div>) 
    }
    else {
        productsList = (
            <div className="text-center">
                <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">
                    <h1 className="text-4xl">This vendor has no product</h1>
                </div>
            </div>
        )
    }
    let introbanner = ''
    if (vendor.length > 0) {
        introbanner=(
            <Banner subtitleText={vendor[0]['business_name']} backgroundImage={`${CONSTANT.IMAGE_STORE}/${vendor[0]['image']}` } />
        )
    }
    else{
        introbanner=(
            <Banner subtitleText='Products' backgroundImage={img} />
        )
    }
    
  return (
    <>
        {introbanner}
        <div className="text-center py-10 luday_deals bg-gray-50">
            <div className="flex justify-center py-8">
                <form id="submitForm" onSubmit={(e) => searchItems(e.target.value)}>
                    <div className="flex items-center flex-nowrap">
                        <input name="search_product" id="productName" value={search_query} onChange={(e) => setSearch_query(e.target.value)} type="search" className="focus:ring-green-500 w-full" placeholder="Search" aria-label="Search"
                        aria-describedby="search-addon" />
                        <button name="searchBtn" value="1" type="submit" className="bg-transparent hover:bg-gray-200 text-green-700 font-semibold hover:text-white py-2 px-2 border border-green-500"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="hover:text-white"><path d="M19.023 16.977a35.13 35.13 0 0 1-1.367-1.384c-.372-.378-.596-.653-.596-.653l-2.8-1.337A6.962 6.962 0 0 0 16 9c0-3.859-3.14-7-7-7S2 5.141 2 9s3.14 7 7 7c1.763 0 3.37-.66 4.603-1.739l1.337 2.8s.275.224.653.596c.387.363.896.854 1.384 1.367l1.358 1.392.604.646 2.121-2.121-.646-.604c-.379-.372-.885-.866-1.391-1.36zM9 14c-2.757 0-5-2.243-5-5s2.243-5 5-5 5 2.243 5 5-2.243 5-5 5z"></path></svg></button>
                    </div>
                </form>
            </div>
        </div>
        
        <div className="w-full">
            {loading ?
                <div className="py-2">
                    <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">  
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-9">
                            <div className="w-full flex items-stretch flex-col p-[10px] pb-[5px]">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex items-stretch flex-col p-[10px] pb-[5px]">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full  items-stretch flex-col p-[10px] pb-[5px] hidden md:flex">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full items-stretch flex-col p-[10px] pb-[5px] hidden md:flex">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
            products &&
                (
                    
                    <section className="bg-gray-50">
                        {
                            productsList
                        }
                        <Pagination productsPerPage={productsPerPage} totalProducts={products.length}/>
                    </section> 
                )
            }
        </div>
        <RecommendedDeals />
    </>
  )
}
export default VendorProducts;
 