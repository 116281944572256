
import React from 'react';

const Owners_Topic = () => { 
  return (
    <>
      <div><p className="userguide_header">Managing Admin Accounts</p></div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.1">Dashboard Overview</p>
        <p>BestDealNaija admin dashboard is a powerful tool designed to help administrators manage activity on 
        the website. The dashboard provides an overview of all that goes on on the website including viewing and 
        managing user activity. It also includes tools for managing other admins who may have access to the 
        dashboard. With the dashboard, administrators can access the necessary information to gain insights into user activity. Get a comprehensive view of the website and allows easy management of website activity.</p>
        
        <p id="1.1.1" className="userguide_subheader anchor_link_section">Getting Started</p>
        <p>To get started using the admin dashboard navigate to the log in page and log in by clicking the user icon at 
        the top right corner of the screen and login with your administrator credentials. Once logged in you can access 
        administrator privileges on all modules including, Admin users, Vendors, Categories, Blogs, Orders, and Products. </p>

        <p className="userguide_subheader anchor_link_section" id="1.2">Managing Admin Accounts</p>

        <p className="userguide_subheader anchor_link_section" id="1.2.1">1. How can I add new admin users to the website?</p>
<p>To add a new admin click on the Users tab and click on create admin from the dropdown menu that appears. This 
will route you to a page where you can create a new admin account by filling out the relevant admin user details 
including names, email, and Passwords. A message containing the login details for the admin user created is sent by 
mail to the email address used to create the account, which the admin can use to access the account created and 
gain access to the admin dashboard.
</p>
        <p className="userguide_subheader anchor_link_section" id="1.3">2. How do I delete existing admin users from the website?</p>
<p>To remove admin accounts created, click on users and navigate to manage admins. This routes you to a page where 
all admins are visible in a tabulated list. From the action column on the table, you can choose to delete or edit 
an admin account. To remove an admin account simply click on “delete” next to the administrators profile.
</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.4">3. How do I manage admin user permissions?</p>
<p>You cannot manage admin user permissions. Once an admin account is created the admin has access to the same privileges as all other administrator accounts.
</p>
        <p className="userguide_subheader anchor_link_section" id="1.5">4. What types of admin roles are available?</p>
<p>There is only one admin role which consists of a general set of  privileges available to all administrators.
</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.6">5. How can I reset admin passwords?</p>
<p>To reset the admin password, click on the forgot password link on the admin login page and enter your administrator email address. An email will be forwarded to you including a password reset link and instructions on how to reset your password.
</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.7">6. How can I manage my admin profile?</p>
<p>You can manage your admin profile from the admin list. Click on users on the dashboard menu, then click on manage 
admins. From the table of listed admins select the admin profile you want to manage and click Edit. Here you can 
update the information on the corresponding admin profile.
</p>
      </div>
    </>
  )
}
export default Owners_Topic
 