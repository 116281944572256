
import React, { useState } from 'react';
import { Field, useField } from 'formik';

import CustomInput from './CustomInput';

const CustomAccountCheckbox = (props) => {
  const [field, meta, helpers] = useField(props);
  const { touched, error } = meta;
  const { setValue } = helpers;

  const [isPreviousCheckoutValue, setPreviousCheckoutValue] = useState(false);
  const [isPasswordDivShown, setisPasswordDivShown] = useState(false);

  const handleChange = () => {
	if (!isPreviousCheckoutValue) {
		setPreviousCheckoutValue(true)
		setValue(true);
	} else {
		setPreviousCheckoutValue(false)
		setValue(false);
	}
	setisPasswordDivShown(!isPasswordDivShown);
  };

  return (
	<>
		<div className="flex flex-row itmes-center mt-2">
			<input
				type="checkbox"
				id={field.name}
				className={`text-black shadow-sm bg-white rounded mr-1
					${touched && error && 'border-red-500'}`}
				{...field}
				{...props}
				onChange={handleChange}
			/>
			<p className="pl-2"> Create my account with these details</p>
			{touched && error && (
				<span className="border-red-300 text-red-500">{error?.value}</span>
			)}
		</div>
		{isPasswordDivShown && <div className="flex flex-col md:flex-row justify-between">
			<div className="mt-3 mb-3 w-auto md:w-[400px]">
				<Field
					id="password" 
					name="password"
					placeholder="Your Password"
					type="password"
					label="Password"
					component={CustomInput}
					required={true}
				/>
			</div>
			<div className="mt-3 mb-3 w-auto md:w-[400px]">
				<Field
					id="password-confirmation" 
					name="password_confirmation"
					placeholder="Confirm Password"
					type="password"
					label="Confirm Password"
					component={CustomInput}
					required={true}
				/>
			</div>
		</div>}
	</>
  );  
};

export default CustomAccountCheckbox;
 