
import React, { useEffect } from 'react';
import {  useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Form, Formik, Field } from 'formik';
import * as Yup from 'yup';

import * as ROUTES from '../../constants/routes';
import { 
    setAuthenticating, setAuthStatus 
} from '../../redux/actions/miscActions';
import { useDocumentTitle, useFileHandler, useScrollTop } from '../../hooks';
import { SignUpVendorDetails } from '../../redux/actions/authActions';
import { 
    CustomBankAccountNameInput,
    CustomFileInput,
    CustomInput, 
    CustomSelectBank, 
    CustomSelectCity, 
    CustomSelectState,
    CustomTextarea
} from '../../components/formik';
import sc from '../../helpers/state-city/sc';
import Banks from '../../helpers/bank-ng/bank';

const SignUpVendorDetailsSchema = Yup.object().shape({
    bank_name: Yup.object()
		// .required('Bank name is required.')
		.shape({
			label: Yup.string(),
			value: Yup.string()
		}),
    bank_acct_number: Yup.string()
        // .required('Bank account number is required.')
        .min(10, 'Number should be at least 10 characters.'),
    bank_account_name: Yup.string()
        // .required('Bank account name is required.')
        .min(10, 'Number should be at least 10 characters.'),
    title: Yup.string()
        .required('Business title is required.'),
	address: Yup.string()
        .required('Address is required.'),
    description: Yup.string()
        .required('Business description is required.'),
	state: Yup.object()
		.shape({
			countryCode: Yup.string(),
			isoCode: Yup.string(),
			label: Yup.string(),
			latitude: Yup.string(),
			longitude: Yup.string(),
			name: Yup.string(),
			value: Yup.string().required('State is required.')
		})
		.required('State is required.'),
	city: Yup.object()
		.shape({
			countryCode: Yup.string(),
			stateCode: Yup.string(),
			name: Yup.string(),
			value: Yup.string().required('City is required.')
		})
		.required('City is required.')
});

const SignUpVendorsDetails = () => {
    const { profile, auth, authStatus, isAuthenticating } = useSelector((state) => ({
        profile: state.profile,
        auth: state.auth,
        authStatus: state.app.authStatus,
        isAuthenticating: state.app.isAuthenticating
    }));
    const dispatch = useDispatch();
    let navigate = useNavigate();
    
    useDocumentTitle('Sign Up Vendor Details | BestDealNaija');
    useScrollTop();

    useEffect(() => {
        dispatch(setAuthStatus(null));
        dispatch(setAuthenticating(false));
    }, []);

    useEffect(() => {
        if (authStatus?.message == "Successfully signed details.") navigate(ROUTES.SIGNUP_VENDOR_COMPLETE)
    }, [authStatus]);

    const initFormikValues = {
        bank_name: {
            label: profile?.bank_name?.label || '',
            value: profile?.bank_name?.value || ''
        },
        bank_acct_number: profile?.bank_acct_number || '',
        bank_account_name: profile?.bank_account_name || '',
        address: profile?.address || '',
        title: profile?.title || '',
        description: profile?.description || '',
        state: {
			countryCode: '',
			isoCode: '',
			label: '',
			latitude: '',
			longitude: '',
			name: profile?.ref_state || '',
			value: profile?.ref_state || ''
		},
		city: {
			countryCode: '',
			stateCode: '',
			name: profile?.ref_local_govt || '',
			value: profile?.ref_local_govt || ''
		}
	};

    const _states = sc.getAllStates();

	const updatedStates = _states.map((country) => ({
		label: country.name,
		value: country.name,
		...country
	}));
	const updatedCities = (stateId) =>
		sc
		.getLocalGovtOfState(stateId)
		.map((city) => ({ label: city.name, value: city.name, ...city })
	);

	const banks = Banks.getAllBanks();
	const updatedBanks = banks.map((bank) => ({
		label: bank.label,
		value: bank.value
	}));

    const {
		imageFile,
		onFileChange
	} = useFileHandler({ display_pics: {} });
    
    const onFormSubmit = (form) => {
        if (imageFile.display_pics.file) {
            let formData = new FormData();

            if (form.bank_name.label != '' && form.bank_name.value != '' && form.bank_acct_number.length > 8) {
                formData.set('business_bank_name', form.bank_name.label)
                formData.set('business_bank_name_value', form.bank_name.value)
                formData.set('business_account_no', form.bank_acct_number)
                formData.set('business_account_name', form.bank_account_name)   
            }
            formData.set('business_address', form.address)
            formData.set('business_city', form.city.value)
            formData.set('business_state', form.state.value)
            formData.set('business_state_value', form.state.isoCode)
            formData.set('name', form.title)
            formData.set('business_description', form.description)
            formData.set('image_file', imageFile.display_pics.file)
            formData.set('user_id', auth.id)

            dispatch(SignUpVendorDetails(formData));
        } else {
            // eslint-disable-next-line no-alert
            alert('Business image is required.');
        }
    };

	return (
		<>
			<div className='pt-20 md:pt-32 py-2 px-[10px] md:px-[60px] lg:px-[90px]'>
                <Formik
                    initialValues={initFormikValues}
                    validateOnChange
                    validationSchema={SignUpVendorDetailsSchema}
                    onSubmit={onFormSubmit}
                >
                    {({values}) => (
                        <div className="flex flex-col items-center min-h-screen sm:justify-center sm:pt-0">
                            <div className="">
                                <a href={ROUTES.HOME}>
                                    <h3 className="text-4xl font-bold text-[#9c0]">
                                      Vendor Details 
                                    </h3>
                                </a>
                            </div>
                            <div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
                                <Form encType="multipart/form-data">
                                    <h1 className='flex justify-center text-gray-400'>Business Address (Required)</h1>                                
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="mt-3 mb-3 w-auto md:w-1/2 md:mr-3 w-full">
                                            <CustomSelectState disabled={isAuthenticating} name='state' label='State' placeholder='Select State' defaultValue={values.state} options={updatedStates} />
                                        </div>
                                        <div className="mt-3 mb-3 w-auto md:w-1/2 w-full">
                                            <CustomSelectCity disabled={isAuthenticating} name='city' label='City' placeholder='Select City' defaultValue={values.city} options={updatedCities(values.state ? values.state.isoCode : null)} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="mt-3 mb-3 w-auto w-full">
                                            <Field disabled={isAuthenticating} name='address' placeholder='Enter Address' label='Address' component={CustomInput} required={true} />
                                        </div>
                                    </div>
                                    
                                    <h1 className='flex justify-center text-gray-400'>Business Details (Required)</h1>
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="mt-3 mb-3 w-auto md:w-1/2 md:mr-3">
                                            <Field disabled={isAuthenticating} id='title'  name='title' placeholder='Enter Business Title' style={{ textTransform: 'capitalize' }} type='text' label='Business Title' component={CustomInput} required={true} />
                                        </div>
                                        <div className="mt-3 mb-3 w-auto md:w-1/2">
                                            <CustomFileInput disabled={isAuthenticating} name='image_file' label='Business Image' onChange={(e) => onFileChange(e, { name: 'display_pics' })} readOnly={isAuthenticating} required />
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="mt-3 mb-3 w-auto md:w-full">
                                            <Field disabled={isAuthenticating} name='description' placeholder='Enter Business Description' label='Business Description' component={CustomTextarea} required={true} />
                                        </div>
                                    </div>
                                    <h1 className='flex justify-center text-gray-400'>Bank Details (Optional)</h1>
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="mt-3 mb-3 w-auto md:w-1/2 md:mr-3">
                                            <CustomSelectBank disabled={isAuthenticating} name='bank_name' label='Bank Name' placeholder='Select Bank' defaultValue={values.bank_name} options={updatedBanks} />
                                        </div>
                                        <div className="mt-3 mb-3 w-auto md:w-1/2">
                                            <Field disabled={isAuthenticating} id='bank-acct-number'  name='bank_acct_number' placeholder='Enter Bank Account Number' type='text' label='Bank Account Number' component={CustomInput} />
                                        </div>
                                    </div>
                                    <div className="flex flex-col md:flex-row justify-between">
                                        <div className="mt-3 mb-3 w-auto w-full">
                                            <CustomBankAccountNameInput name='bank_account_name' />
                                        </div>
                                    </div>
                                    <div className="mt-10 flex justify-center">
                                        <button 
                                            disabled={isAuthenticating} 
                                            className="button button--theme overflow-hidden bg-primary hover:bg-secondary text-white text-sm transition-all ease-in-out duration-300 rounded py-3 px-5 m-2 flex items-center flex-nowrap w-80 justify-center"
                                            type="submit"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current mr-2" ><path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path></svg>
                                            <span>{isAuthenticating ? 'Signing Up' : 'Continue'}</span>
                                        </button>
                                    </div>
                                </Form>
                            </div>				
                        </div>
                    )}
                </Formik>
            </div>
		</>
	);
}
export default SignUpVendorsDetails;
 