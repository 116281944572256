
import React, { useEffect, useState }  from 'react'
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { FaShoppingBasket, FaExternalLinkAlt, FaAngleRight } from 'react-icons/fa';
import { MdStarOutline, MdStar } from 'react-icons/md';

import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';

const VendorDashboard = () => {
  const [countOfProducts, setCountOfProducts] = useState([]);
  const [countOfOders, setCountOfOders] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();

  const { userId, accessToken } = useSelector(
    (state) => ({
      accessToken: state.profile.access_token,
      userId: state.profile.id
    }),
    shallowEqual
  );

  const onNext = () => {
    if (nextPage != null) {
      fetchProducts(nextPage);
    }
  };

  const onPrevious = () => {
    if (prevPage != null) {
      fetchProducts(prevPage);
    }
  };

  const onPageChange = (page) => {
    if (page != null) {
      fetchProducts(page);
    }
  };
  const fetchProducts = (page) => {
    setLoading(true);
    fetch(
      `${ROUTE.PRODUCTS_API}/vendors/${userId}/products?pagination=1&page=${page}`
    )
      .then((res) => res.json())
      .then((res) => {
        setProducts(res);
        setCountOfProducts(res?.results.length);
        setNextPage(res?.next_page);
        setPrevPage(res?.prev_page);
        setCurrentPage(res?.current_page);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const getOders = () => {
    fetch(`${ROUTE.PRODUCTS_API}/order/vendor/id?user_id=${userId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setCountOfOders(res?.results?.length);
      });
  };

  const first_item_on_page_number =
    products?.current_page * products?.items_per_page -
    products?.items_per_page +
    1;
  const last_item_on_page_number =
    first_item_on_page_number + products?.results?.length - 1;

  useEffect(() => {
    fetchProducts(currentPage);
    getOders();
  }, []);

  // TODO: implement Admin navigation menus and remove reloadwindow()
  const reloadWindow = () => {
    const reloadCount = sessionStorage.getItem('reloadCount');
    sessionStorage.setItem('reloadCount', '0');
    if (typeof reloadCount !== 'undefined' && reloadCount < 1) {
      setTimeout(location.reload.bind(location), 200);
      sessionStorage.setItem('reloadCount', reloadCount + 1);
    }
  }

  useEffect(() => {
    reloadWindow()
  }, [])

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 p-4 mt-3 gap-4">
        <div className="bg-white shadow-lg rounded-md px-3 pt-3 border-b-4 border-green-600 text-green-600 font-medium group hover:scale-105 transition-all duration-400">
          <div className="flex items-center justify-between mb-3">
            <div className="flex justify-center items-center w-14 h-14 bg-gray-100 rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <svg
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="stroke-current transform transition-transform duration-500 ease-in-out"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                ></path>
              </svg>
            </div>
            <div className="text-right">
              <p className="text-2xl">{countOfOders > 0 ? countOfOders : 0}</p>
              <p>Orders</p>
            </div>
          </div>
          <div className="flex items-center text-sm mb-1 justify-start text-green-700">
            <Link to={ROUTE.VENDOR_ORDERS}>View all orders</Link>{" "}
            <span>
              <FaExternalLinkAlt className="ml-1 h-3 w-3" />
            </span>
          </div>
        </div>

        <div className="bg-white shadow-lg rounded-md px-3 pt-3 border-b-4 border-green-600 text-green-600 font-medium group hover:scale-105 transition-all duration-400 flex-col">
          <div className="flex items-center justify-between mb-3">
            <div className="flex justify-center items-center w-14 h-14 bg-gray-100 rounded-full transition-all duration-300 transform group-hover:rotate-12">
              <FaShoppingBasket className="w-8 h-8" />
            </div>
            <div className="text-right">
              <p className="text-2xl">{countOfProducts}</p>
              <p>Products</p>
            </div>
          </div>
          <div className="flex items-center text-sm mb-1 justify-start text-green-700">
            <Link to={ROUTE.VENDOR_PRODUCTS}>View all products</Link>{" "}
            <span>
              <FaExternalLinkAlt className="ml-1 h-3 w-3" />
            </span>
          </div>
        </div>
      </div>

      {/* Recently added products tab */}
      <div className="heade px-10 flex items-center justify-between"></div>
      <div className="flex flex-col mt-6 mx-1 lg:flex-row">
        <div className="w-full m-4 bg-white shadow-lg text-lg rounded-sm border border-gray-200">
          <div className="overflow-x-auto shadow-md sm:rounded-lg">
          <div className="header py-3 px-2 flex items-center justify-between">
            <h3 className="font-medium text-xl">Recently added products</h3>
            <Link className="focus:outline-none text-white p-1 px-2 rounded-md text-sm bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100" to={ROUTE.VENDOR_PRODUCTS}>View all <span><FaAngleRight className="inline-flex w-3 h-3"/></span></Link>
          </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3"></th>
                  <th scope="col" className="px-6 py-3">
                    Product
                  </th>
                  <th scope="col" className="px-6 py-3">
                    SKU
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Price
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Featured
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && products?.results?.length <= 0 && (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={7}>
                        <div className="flex flex-col justify-center items-center my-4 space-y-3">
                          No products added yet
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                {!isLoading &&
                  products?.results?.length >= 1 &&
                  products?.results?.map((product) => (
                    <>
                      <tr
                        key={product.id}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-4">
                          <img
                            src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`}
                            className="w-8 h-8 mx-auto overflow-hidden"
                            alt={product.name}
                          />
                        </td>
                        <td className="px-6 py-4">{product.name}</td>
                        <td className="px-6 py-4">{product.sku}</td>
                        <td className="px-6 py-4">
                          <span
                            className={
                              product.sale_price ? "line-through mr-1" : ""
                            }
                          >
                            {product.price}
                          </span>{" "}
                          {product.sale_price ? product.sale_price : ""}
                        </td>
                        <td className="px-6 py-4">
                          {product.product_category}
                        </td>
                        <td className="px-6 py-4">
                          {product.featured === 1 ? (
                            <span>
                              <MdStar className="mx-auto h-6 w-6 text-green-600 text-center" />
                            </span>
                          ) : (
                            <span>
                              <MdStarOutline className="mx-auto h-6 w-6 text-green-600 text-center" />
                            </span>
                          )}
                        </td>
                        <td className="px-6 py-4">
                          {product.is_verified == 1 ? (
                            <div className="flex items-center">
                              <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>{" "}
                              Verified
                            </div>
                          ) : (
                            <div className="flex items-center">
                              <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>{" "}
                              Unverified
                            </div>
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
                {isLoading && (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={7}>
                        <div className="flex flex-col justify-center items-center my-4 space-y-3">
                          Fetching records...
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {!isLoading && products?.results?.length >= 1 && (
              <nav
                className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {`${first_item_on_page_number}-${last_item_on_page_number}`}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {products?.total_records}
                  </span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                  {products?.prev_page && (
                    <li>
                      <a
                        onClick={onPrevious}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </a>
                    </li>
                  )}
                  {products?.iter_pages.map((iter_page) => (
                    <>
                      {iter_page != null ? (
                        <li>
                          <a
                            onClick={() => onPageChange(iter_page)}
                            className={`${
                              products?.current_page === iter_page
                                ? "flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                : "flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            }`}
                          >
                            {iter_page}
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a
                            href="#"
                            className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                          >
                            {"..."}
                          </a>
                        </li>
                      )}
                    </>
                  ))}
                  {products?.next_page && (
                    <li>
                      <a
                        onClick={onNext}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default VendorDashboard;
 