
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import { signUpVendor } from '../../redux/actions/authActions';
import { setAuthenticating, setAuthStatus } from '../../redux/actions/miscActions';
import { SIGNIN } from '../../constants/routes';
import { CustomInput, CustomMobileInput } from '../../components/formik';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import Navigation from '../../components/default/Navigation/main';
import Alert from '../../helpers/Alert';


const SignUpVendorSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.'),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password length should be at least 8 characters.')
      .matches(/[A-Z\W]/g, 'Password should contain at least 1 uppercase letter.'),
    password_confirmation: Yup.string()
      .required('Confirm password is required.')
      .oneOf([Yup.ref('password')], 'Passwords does not match'),
    first_name: Yup.string()
      .required('First name is required.')
      .min(2, 'Name should be at least 2 characters.'),
    last_name: Yup.string()
      .required('Last name is required.')
      .min(2, 'Name should be at least 2 characters.'),
    gender: Yup.string()
      .required('Gender is required.'),
    mobile_number: Yup.string()
        .required('Mobile is required.')
        .min(12, 'Mobile number should be at least 11 characters.')
        .max(13, 'Mobile number should be only be 11 characters long.')
});

const SignUpVendor = () => {
    const { isAuthenticating, authStatus } = useSelector((state) => ({
        isAuthenticating: state.app.isAuthenticating,
        authStatus: state.app.authStatus
    }));

    const dispatch = useDispatch();
    let navigate = useNavigate();
    
	useDocumentTitle('Sign Up Vendor | BestDealNaija');
	useScrollTop();

    useEffect(() => {
        dispatch(setAuthStatus(null));
        dispatch(setAuthenticating(false));
    }, []);

    const onClickSignIn = () => navigate(SIGNIN);

    const onFormSubmit = (form) => {
        dispatch(signUpVendor({
            first_name: form.first_name.trim(),
            last_name: form.last_name.trim(),
            email: form.email.trim().toLowerCase(),
            password: form.password.trim(),
            gender: form.gender.trim(),
            mobile_number: form.mobile_number.trim(),
            is_active: false
        }));
    };

	return (
		<>
            <Navigation/>
            {authStatus?.message && (
                <nav className='fixed z-20 w-full top-0 bg-white border-gray-200'>
                    <Alert
                        status={authStatus.status}
                        mssg={authStatus.message}
                        type={authStatus.type}
                    />
                </nav>
            )}
			<div className='pt-20 bg-gray-50'>
                <div className="flex flex-col items-center min-h-screen sm:justify-center sm:pt-0">
                    <div>
                        <h1 className="text-4xl font-bold text-[#9c0]">
                            Vendor Sign Up
                        </h1>
                    </div>
                    <Formik
                        initialValues={{
                            first_name: '',
                            last_name: '',
                            email: '',
                            password: '',
                            gender: '',
                            mobile_number: ''
                        }}
                        validateOnChange
                        validationSchema={SignUpVendorSchema}
                        onSubmit={onFormSubmit}
                    >
                        <div className="w-full px-6 py-4 my-6 overflow-hidden bg-white shadow-md sm:max-w-lg sm:rounded-lg">
                            <Form>
                                <div>
                                    <Field
                                        disabled={isAuthenticating}
                                        id="first-name" 
                                        name="first_name"
                                        placeholder="Enter First Name"
                                        style={{ textTransform: 'capitalize' }}
                                        type="text"
                                        label="First Name"
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Field
                                        disabled={isAuthenticating}
                                        id="last-name" 
                                        name="last_name"
                                        placeholder="Enter Last Name"
                                        style={{ textTransform: 'capitalize' }}
                                        type="text"
                                        label="Last Name"
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Field
                                        disabled={isAuthenticating}
                                        id="email" 
                                        name="email"
                                        placeholder="Enter e-mail"
                                        type="email"
                                        label="Email"
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Field
                                        disabled={isAuthenticating}
                                        id="password" 
                                        name="password"
                                        placeholder="Your Password"
                                        type="password"
                                        label="Password"
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Field
                                        disabled={isAuthenticating}
                                        id="password-confirmation" 
                                        name="password_confirmation"
                                        placeholder="Confirm Password"
                                        type="password"
                                        label="Confirm Password"
                                        component={CustomInput}
                                    />
                                </div>
                                <div className="mt-4">
                                    <CustomMobileInput 
                                        name='mobile_number' 
                                        disabled={isAuthenticating} 
                                        required={true} 
                                    />
                                </div>
                                <div className="mt-4">
                                    <label className="block text-sm font-medium text-gray-700 undefined" htmlFor="gender">
                                        {"Gender"}
                                    </label>
                                    {/* TODO: map gender from constant class */}
                                    <div role="group" aria-labelledby="my-radio-group">
                                        <span className="mr-4">
                                            <Field className="mr-2" 
                                                type="radio"
                                                id="gender-male"
                                                name="gender" 
                                                value="male" 
                                            />
                                            Male
                                        </span>
                                        <span>
                                            <Field className="mr-2" 
                                                type="radio" 
                                                id="gender-female"
                                                name="gender" 
                                                value="female"
                                            />
                                            Female
                                        </span>
                                    </div>
                                </div>
                                <div className="flex items-center mt-4">
                                    <button 
                                        disabled={isAuthenticating}
                                        type="submit"
                                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-[#9c0] rounded-md hover:bg-[#84b000] focus:outline-none focus:bg-green-600">
                                        {isAuthenticating ? 'Signing Up' : 'Sign Up'}
                                    </button>
                                </div>
                            </Form>
                            <div className="mt-4 text-grey-600">
                                Already have an account?{" "}
                                <span>
                                    <a className="text-[#9c0] hover:underline" 
                                        disabled={isAuthenticating}
                                        onClick={onClickSignIn}
                                    >
                                        Log In
                                    </a>
                                </span>
                            </div>
                            {/* <div className="flex items-center w-full my-4">
                                <hr className="w-full" />
                                <p className="px-3 ">@@SpanText4@@</p>
                                <hr className="w-full" />
                            </div>
                            <div className="my-6 space-y-2">
                                <button
                                    aria-label="Login with Google"
                                    type="button"
                                    className="flex items-center justify-center w-full p-2 space-x-4 border rounded-full focus:ring-2 focus:ring-offset-1 dark:border-gray-400 focus:ring-green-600"
                                >
                                    @@SignInOptionIcon1@@
                                    <p>@@SignUpOptionText1@@</p>
                                </button>
                                <button
                                    aria-label="Login with GitHub"
                                    role="button"
                                    className="flex items-center justify-center w-full p-2 space-x-4 border rounded-full focus:ring-2 focus:ring-offset-1 dark:border-gray-400 focus:ring-green-600"
                                >
                                    @@SignInOptionIcon2@@
                                    <p>@@SignUpOptionText2@@</p>
                                </button>
                            </div> */}
                        </div>
                    </Formik>
                </div>
            </div>
		</>
	);
}
export default SignUpVendor;
 