
import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as ROUTE from '../../constants/routes';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import { signOut } from '../../redux/actions/authActions';


const SignUpComplete = () => {
    useDocumentTitle('Sign Up Completed | BestDealNaija');
    useScrollTop();

    const dispatch = useDispatch();
    let navigate = useNavigate();
    let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));

    const handleLogout = () => {
        dispatch(signOut())
    };

    useEffect(() => {
        if (auth_tokens) {
        handleLogout();
        } else {
        navigate(ROUTE.HOME)
        }
    }, [auth_tokens]);

    return (
        <>
        <div className='flex py-2 px-[10px] md:px-[60px] lg:px-[90px] h-screen'>
                <div className='rounded-md mx-auto my-auto shadow-md p-8 items-center justify-center'>
                    <div className='appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed items-center justify-center'>
                        <h2 className="text-center text-4xl uppercase font-bold text-[#98c01d]">Sign Up completed!</h2>
                        <p className='text-center'>Your request to be a vendor on BestDealNaija have been received. We will get back to you via the email address you provided when signing up.</p>
                    </div>  
                    <a href="/" className=" mt-8 mx-auto appearance-none w-full md:w-1/3  block bg-gray-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-gray-600 focus:outline-none focus:bg-white focus:border-gray-500 text-center">Back to home page</a>
                </div>
            </div>
        </>
    )
}
export default SignUpComplete;
 