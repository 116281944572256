
import React from 'react';
import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/bdn_about_image_2.jpg';
import Accnav from '../../components/default/Navigation/account/index';
import { useDocumentTitle } from '../../hooks';
import OrderItem from './orders/ViewOrder';

const OrderDetails = () => {
    useDocumentTitle('Order Deatails | BestDealNaija');
    return (
        <>
            <Banner backgroundImage={bannerImage} subtitleText='My Order History'/>
            <div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">                    
                <div className="flex flex-col md:flex-row py-[50px]">
                    <div className="w-full md:w-1/3 m-2 border border-black-600 drop-shadow-md p-5 h-1/2">
                    <Accnav/>
                    </div>
                    <div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
                        <div className="overflow-x-auto">
                            <OrderItem/>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default OrderDetails;
 