
import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';
import { FaBlog } from 'react-icons/fa';
import { useDocumentTitle, useScrollTop } from '../../hooks';

function Blog() {
  useDocumentTitle('Blog | BestDealNaija');
  useScrollTop();

  const [loading, setLoading] = useState(false);
  const [blogs, fetchBlogs] = useState([]);
  const getData = () => {
      setLoading(true)
      fetch(`${ROUTE.BLOGS_API}/blogs`)
        .then((res) => res.json())
        .then((res) => {
          fetchBlogs(res.results)
          //console.log(res.results)
          setLoading(false)
      })
  }
  useEffect(() => {
      getData()
  }, []);

  let blogList = ''
  if(blogs.length > 0){
      blogList =  (
          <section className="bg-gray-50 pt-20 md:pt-30">
              <div className="px-36 py-[100px] pb-[80px] luday_wrap">                    
                  <div className="grid grid-cols-3 gap-6 luday_grid_wrap">
                  {blogs.map(item => 
                      <div key={item.id} className="h-84 group bg-white shadow-sm">
                          <Link to={`${ROUTE.BLOG_DETAILS}/${item.slug}`}>
                          <article>
                              <div>
                                  <img className="lazy" src={`${CONSTANT.IMAGE_STORE}/${item.image_path}`} alt="" />
                              </div>
                              <div className="px-[30px] pt-8">
                                  <h4 className="hover:text-[#98c01d] text-xl tracking-tight">{item.title}</h4>
                                  <div>
                                      <p className="py-3 text-xs text-gray-500 tracking-tight">{item.created_at}</p>
                                  </div>
                                  <p className="h-36 text-ellipsis overflow-y-hidden"
                                  > {item.summary}</p>
                                  
                              </div>
                          </article>
                          </Link>
                      </div>
                  )}
                  </div>
              </div>
          </section>
      )
  } else {
    blogList =  (
      <div className="flex flex-col space-y-5 -mt-36 justify-center items-center text-xl font-medium tracking-wide text-green-700 text-center h-screen">
          <FaBlog className="w-32 h-32"/>
          <h1 className="font-semibold text-3xl tracking-wider">No blogs added yet</h1>
      </div>)    
  }

  let blogsLoading = (
      <>
          <section className="bg-gray-100 pt-20 md:pt-30">
              <div className="px-36 py-[100px] pb-[80px] luday_wrap">                    
                  <div className="grid grid-cols-3 gap-9 luday_grid_wrap"> 
                      <div className="group bg-white">    
                          <article>
                              <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                              </div>
                              <div className="p-[30px]">
                                  <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                  <div>
                                      <p  data-paceholder className="h-6 py-3 font-bold text-sm"></p>
                                  </div>
                                  <div data-placeholder className="w-full h-32 bg-gray-200 overflow-hidden relative">
                                  </div>
                              </div>
                          </article>
                      </div>
                      <div className="group bg-white">    
                          <article>
                              <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                              </div>
                              <div className="p-[30px]">
                                  <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                  <div>
                                      <p  data-paceholder className="h-6 py-3 font-bold text-sm"></p>
                                  </div>
                                  <div data-placeholder className="w-full h-32 bg-gray-200 overflow-hidden relative">
                                  </div>
                              </div>
                          </article>
                      </div>
                      <div className="group bg-white">    
                          <article>
                              <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                              </div>
                              <div className="p-[30px]">
                                  <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                  <div>
                                      <p  data-paceholder className="h-6 py-3 font-bold text-sm"></p>
                                  </div>
                                  <div data-placeholder className="w-full h-32 bg-gray-200 overflow-hidden relative">
                                  </div>
                              </div>
                          </article>
                      </div>
                  </div>
              </div>
          </section>
      </>
  )

  return (
    <div className="bg-gray-100">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
              <div className="flex items-center space-x-2 text-gray-400">
                <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                <span>/</span>
                <span className="tracking-wide text-md">
                    <span className="text-base">Blogs</span>
                </span>
                <span>/</span>
              </div>
        </div>
          { loading
          ? blogsLoading
          : blogList }
    </div>
  )  
}
export default Blog;
 