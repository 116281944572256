
import React from "react";
import PropType from 'prop-types';

import ProductItem from "../../../pages/product/ProductItem";
import { Search } from "../../default";
import { shallowEqual, useSelector } from "react-redux";

const ProductCard = ({ products }) => {
    const store = useSelector((state) => ({
        products: state.products,
        isLoading: state.app.loading
    }), shallowEqual);

    return (
        <div className="">
            <div className="text-center py-4 mt-2 md:mt-4 luday_deals">
                <Search />
            </div>
            <section className="bg-gray-50">
                <div className="px-3 md:px-[120px] lg:px-[120px] py-[30px] pb-[30px] luday_wrap">  
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-2"> 
                        {store.isLoading && products.length === 0 ? new Array(6).fill({}).map((product, index) => (
                            <ProductItem key={`empty-product ${index}`} product={product} />
                        )): store.products.items.map((product) => (
                            <ProductItem key={product.id} product={product} />
                        ))}
                    </div>
                </div>
            </section> 
        </div>
    )
};

ProductCard.propTypes = {
    products: PropType.array.isRequired
};

export default ProductCard;
 