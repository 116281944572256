
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FaAngleLeft,FaTimes } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import * as ROUTE from '../../../constants/routes';
import swal from 'sweetalert';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import * as CONSTANT from '../../../constants/constants';

const VendorEditProduct = () => {
  const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [categories, fetchCategories] = useState([]);
    const [productId, setProductId] = useState('');
    const [productName, setProductName] = useState('');
    const [productCategoryId, setProductCategoryId] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productSalePrice, setProductSalePrice] = useState('');
    const [productQuantity, setProductQuantity] = useState('');
    const [productDescription, setProductDescription] = useState('');
    const [productImage, setProductImage] = useState('');
    const [selectedImage, setSelectedImage] = useState();
    const [newProductImage, setNewProductImage] = useState();
    let formData = new FormData();

    const [selectedImages, setSelectedImages] = useState([]);
    const [productGalleryImage, setProductGalleryImage] = useState([]);

    const handleChange = (e, editor) => {
        setProductDescription(editor.getData())
    }

    const onSelectMultipleImages = useCallback( async (e)  => {
        const selectedFilesArray = Array.from(e.target.files)
        selectedFilesArray.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            setProductGalleryImage((productGalleryImage) =>[...productGalleryImage, file]);     
            reader.onloadend = () => {
                setSelectedImages((selectedImages) =>[...selectedImages, reader.result]);
            };
        })
    }, []);

    const removeImageFromDB = (image, e) => {
        e.preventDefault();
        const requestOptions = {
            headers: {
              'Content-type': 'application/json'
            }
        }
        axios.delete(
            `${ROUTE.PRODUCTS_API}/products/${image}/gallery`,
            requestOptions
        ).then(res => res)
        .then(data =>{
          successAlert(data)
        })
        .catch(err => errorAlert(err))
        getData();
    }
    const getData = async () => {
        setLoading(true)
        await fetch(`${ROUTE.PRODUCTS_API}/products/${id}`)
          .then((res) => res.json())
          .then((res) => {
            setProductId(res.data[0].id)
            setProductName(res.data[0].name)
            setProductPrice(res.data[0].price)
            setProductSalePrice(res.data[0].sale_price)
            setProductQuantity(res.data[0].quantity)
            setProductDescription(res.data[0].description)
            setProductImage(res.data[0].image_path)
            setProductCategoryId(res.data[0].product_category_id)
            res.data[0].products_gallery.map(item => {
                setProductGalleryImage((productGalleryImage) =>[...productGalleryImage, item])
            })
            setLoading(false) 
          })
    }

    const {register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const getCategories = () => {
        fetch(`${ROUTE.PRODUCTS_API}/categories`)
          .then((res) => res.json())
          .then((res) => {
            fetchCategories(res.results)
          })
    }

    useEffect(() => {
        getCategories()
        getData()
    }, [])

    useEffect(() => {
        setTimeout(() => 
            setValue("name", productName),
            setValue("category", productCategoryId),
            setValue("price", productPrice),
            setValue("sale_price", productSalePrice),
            setValue("quantity", productQuantity),
            setValue("description", productDescription)
        );
      }, [loading]);

    const onSelectFile = useCallback( async (e)  => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setNewProductImage(file)
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
    }, []);

    const submitForm = (data) => {
        formData.append('product_image', newProductImage)
        formData.append('name', data.name);
        formData.append('description', productDescription)
        formData.append('price', data.price)
        formData.append('sale_price', data.sale_price)
        formData.append('quantity', data.quantity)
        formData.append('category_id', data.category)
        Array.from(productGalleryImage).forEach(item => {
            formData.append('product_gallery_image', item)
        })
        const requestOptions = {
            method: "POST",
            headers: {
              'Content-type': 'multipart/form-data'
            }
        }
        axios.put(
            `${ROUTE.PRODUCTS_API}/products/${productId}`,
            formData,
            requestOptions
        ).then(res => res)
        .then(data =>{
          successAlert(data)
        })
        .catch(err => errorAlert(err))
      reset()
      setSelectedImage('');
    }

    const handleCategoryChange = (e) => {
        setProductCategoryId(e.target.value);
      };

    const successAlert = (response) => {
        return(
          swal({
              title: "Info!",
              text: response.data.message,
              icon: "success"
          }).then(function () {
                window.location.reload()
          })
        )
    }
    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error,
              icon: "error"
          }).then(function () {
            window.location.reload()
          })          
        )
    }

    let categoryDropdown = categories.map((item, i) => {
        return (<option className="my-2 hover:bg-gray-50" key={i} value={item.id}> {item.name} </option>)
    }) 

  return (
    <div className="bg-gray-100">
      <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
              <div className="flex items-center space-x-2 text-gray-400">
              <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.VENDOR_DASHBOARD}>Home</Link></span>
              <span>/</span>
              <span className="tracking-wide text-md">
                  <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.VENDOR_PRODUCTS}>Products</Link></span>
              </span>
              <span>/</span>
              <span className="tracking-wide text-md">
                  <span className="text-base"> Edit Products</span>
              </span>
              </div>
      </div>
      <div className="header my-3 h-12 px-4 lg:px-10 py-8  flex items-center justify-between">
          <h1 className="font-medium text-2xl">Edit Products</h1>
          <Link to={ROUTE.VENDOR_PRODUCTS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
          </span> Back to products</Link>
      </div>
      {loading
      ? (<div className="h-full flex justify-center items-center">
              <h3 className="font-bold text-green-600 text-2xl mx-auto ">Loading...</h3>
          </div>
      )
      :
      (
          <div className="flex flex-col mx-3 lg:flex-row">
          <form className="w-full lg:w-3/5 bg-white shadow-md p-6">
              <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full px-3 mb-6">
                      <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='name'>Product Name</label>
                      <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" type='text' name="name" placeholder="Enter product Name"
                      defaultValue={productName} {...register("name", { required: true })} required onChange={(e) => setProductName(e.target.value)} />
                      {errors.product_name && <small className="text-red-500 text-xs italic">Product name is required</small>}
                  </div>

                  <div className="w-full px-3 mb-6">
                      <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category'>Category</label>
                      <select className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" name="category"
                      onChange={handleCategoryChange}
                      defaultValue={productCategoryId || ""} {...register("category", { required: true })}
                      required>
                          { categoryDropdown }
                      </select>

                      {errors.category && <small className="text-red-500 text-xs italic">Select at least one category</small>}
                  </div>

                  <div className="w-full px-3 mb-6">
                      <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='price'>Price</label>
                      <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="price" placeholder="Enter price"
                      {...register("price", { required: true })} required onChange={(e) => setProductPrice(e.target.value)} />
                        {errors.price && <small className="text-red-500 text-xs italic">Price is required</small>}
                  </div>

                  <div className="w-full px-3 mb-6">
                      <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='sale_price'>Sale Price</label>
                      <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="sale_price" placeholder="Enter price"
                      value={productSalePrice} {...register("sale_price")} onChange={(e) => setProductSalePrice(e.target.value)} />
                  </div>

                  <div className="w-full px-3 mb-6">
                      <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='quantity'>Count in stock</label>
                      <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='number' name="quantity" placeholder="Enter count in stock"
                      required value={productQuantity} {...register("quantity", { required: true })} onChange={(e) => setProductQuantity(e.target.value)} />
                      {errors.quantity && <small className="text-red-500 text-xs italic">Count in stock is required</small>}
                  </div>
                  
                  <div className="w-full px-3 mb-6">
                      <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Description</label>
                      <CKEditor 
                        editor={ClassicEditor}
                        onChange={handleChange} data={productDescription} className="w-full"
                      />
                      {errors.description && <small className="text-red-500 text-xs italic">Description is required</small>}
                  </div>
                  <div className="w-full px-3 mb-12">
                      <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='product_image'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>

                      <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Product image</h2>

                      <p className="mt-2 text-gray-500 tracking-wide">Upload or drag & drop your file SVG, PNG, JPG or GIF. </p>

                      <input name="product_image" id="product_image" type="file" className="hidden" onChange={onSelectFile} accept="image/png, image/jpeg, image/webp"/>
                      </label>
                      {errors.product_image && <small className="text-red-500 text-xs italic">Product image is required</small>}
                  </div>
                  {errors.product_image && <small className="text-red-500 text-xs italic">Product image is required</small>}

                  <div className="w-full mx-12 flex justify-center mb-5">
                      {productImage &&
                          (
                              <img src={`${CONSTANT.IMAGE_STORE}/${productImage}`} className={selectedImage ? "hidden" : `w-32 h-32 $`}/>
                          )
                      }
                      {selectedImage &&
                          (
                              <img src={selectedImage} className="w-32 h-32"/>
                          )

                      } 
                  </div>
                  <div className="w-full px-3 flex justify-end mb-5 text-green-500">
                      <p className="flex mx-4 "> Add product gallery images</p>
                  </div>

                  <div className="w-full px-3 mb-6">
                  <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='product_gallery_images'>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                      </svg>

                      <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Product gallery images</h2>

                      <p className="mt-2 text-gray-500 tracking-wide">Upload or drag multiple images SVG, PNG, JPG or GIF. </p>

                      <input type="file" className="hidden" name="product_gallery_images" id="product_gallery_images" onChange={onSelectMultipleImages} multiple accept="image/png, image/jpeg, image/webp"/>
                      </label>
                  </div>
                  <div className="overflow-x-auto flex justify-center mb-5 mx-auto">
                      {productGalleryImage &&
                          
                          productGalleryImage.map((image, index) => {
                              return (
                                  <>
                                      <img key={index}  src={`${CONSTANT.IMAGE_STORE}/${image.path}`} className="w-32 h-32"/>
                                      <span className="relative"><button className="text-red-500 font-weight-bolder top-0" onClick={(e) => removeImageFromDB(image.id, e)}><FaTimes/></button></span>
                                  </>
                              )
                          })
                      }
                      {selectedImages &&
                          selectedImages.map((image, index) => {
                              return (
                                  <>
                                      <img key={index} src={image} className="w-32 h-32 relative"/>
                                  </>
                              )                                    
                          })
                      }
                  </div>
                  <div className="w-full px-3 mb-6">
                      <button className="appearance-none w-full block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                      onClick={handleSubmit(submitForm)}
                      >Update Product</button>
                  </div>
              </div>
          </form>          
      </div>
    )}         
  </div>
  )  
}
export default VendorEditProduct;
 