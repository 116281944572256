
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from 'react-router-dom';

import * as ROUTE from '../../../constants/routes';
import img from '../../../images/tradefair/tradefairbg.jpg';
import Banner  from '../../../components/default/Banner/index';
import { useDocumentTitle, useOrderById } from '../../../hooks';
import { clearCart } from '../../../redux/actions/cartActions';
import { OrderDetails } from '../components';

const CashOnDeliveryCallback = () => {
	useDocumentTitle('My Order | BestDealNaija');
    const { cart, billing } = useSelector((state) => ({
		cart: state.cart,
		billing: state.checkout.billing
	}));
	if (!billing) {
		return <Navigate to={ROUTE.CHECKOUT} />;
	}	

	const { order, isLoading } = useOrderById(billing.ref_order_id);
	const dispatch = useDispatch();

	if (cart.length) {
		dispatch(clearCart());
	}
	return (
		<>
			<Banner subtitleText='My Order' backgroundImage={img} />
			<OrderDetails order={order} isLoading={isLoading} />
		</>
	);
}
export default CashOnDeliveryCallback;
 