
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import img from '../../images/tradefair/tradefairbg.jpg';
import * as ROUTE from '../../constants/routes';
import Banner  from '../../components/default/Banner/index';
import { useDocumentTitle } from '../../hooks';

const Delivery = () => {
    useDocumentTitle('Delivery details | BestDealNaija');
    const cart = useSelector((state) => state.cart);
	const nf = new Intl.NumberFormat();

	return (
        <>
            <Banner subtitleText='Delivery' backgroundImage={img} />
            <div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">                    
                <div className="flex flex-col-reverse md:flex-row py-[50px]">	
                    <div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
                        <div className="">
                            <h4 className="mb-2">Delivery Details</h4>
                            <hr/>
                        </div>
                        <div className="flex flex-col md:flex-row">
                            <div className="mt-3 mb-3 w-auto md:w-full">
                                <label htmlFor="address">Delivery address
                                <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <textarea name="address" type="text" className="w-full text-black shadow-sm bg-white rounded" id="address" placeholder="Address line" rows="3" required/>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col md:flex-row justify-between">
                            <div className="mt-3 mb-3 w-auto md:w-[400px]">
                                <label htmlFor="postal">Country</label>
                                <div className="input-group">
                                    <select name="conutry_id" id="countryid" className="w-full p-2 h-10 text-black shadow-sm bg-white rounded" required>
                                        <option value="">Select Country</option>
                                        <option value="">Nigeria</option>
                                    </select>
                                </div>
                            </div>
                            <div className="mt-3 mb-3 w-auto md:w-[400px]">
                                <label htmlFor="postal">State
                                <span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <select name="state_id" id="stateid" className="w-full p-2 h-10 text-black shadow-sm bg-white rounded" required>
                                        <option value="">Select State</option>
                                        <option value="">Abuja</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="mt-10 flex flex-col md:flex-row">
                            <Link to={ROUTE.CHECKOUT} className="button button--theme overflow-hidden border border-[#9c0]-600
                            hover:bg-yellow-300 text-[#9c0] text-sm transition-all ease-in-out duration-300 
                            rounded py-3 px-5 m-2 flex items-center flex-nowrap w-full md:w-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" ><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
                            <span>Previous</span>
                            </Link>
                            <Link to="/payment" className="button button--theme overflow-hidden bg-[#9c0] 
                            hover:bg-yellow-300 text-white text-sm transition-all ease-in-out duration-300 
                            rounded py-3 px-5 m-2 flex items-center flex-nowrap w-full md:w-1/2">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current mr-2" ><path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path></svg>
                            <span>Save and Continue</span>
                            </Link>
                        </div>
                    </div>
                    <div className="w-full md:w-1/4 m-2 border border-black-600 drop-shadow-md p-5 h-1/2">	
                        <h2 className="text-2xl mb-5">Cart Summary</h2>
                        <p>VAT <span>&#8358;0.00</span></p>
                        <p className="mb-5">Subtotal <br /><span>&#8358;{nf.format(cart.cartTotalAmount)}</span></p>
                        <hr/>
                        <h2 className="text-2xl mt-5">Grand Total</h2>
                        <h2 className="text-3xl mt-5 font-bold">&#8358;{nf.format(cart.cartTotalAmount + 0.00)}</h2>
                        <div className="mt-4 mx-0">
                            <Link to={ROUTE.CART} className="button button--theme overflow-hidden [#490] border border-[#9c0]-600
                            hover:bg-yellow-300 text-[#490] text-sm transition-all ease-in-out duration-300 
                            rounded py-3 px-5 my-2 flex items-center flex-nowrap">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" ><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
                            <span>Back to Cart</span>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
export default Delivery;
 