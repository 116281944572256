
import React, {useState, useEffect, useCallback} from 'react';
import { Link } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';

import { FaEye } from 'react-icons/fa';
// import { FaMoneyCheckAlt } from 'react-icons/fa';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import * as ROUTE from '../../../constants/routes';
import DeliveryStatuslist from '../../../assets/delivery_status';
import Paymentlist from '../../../assets/payment_status';
import Sortlist from '../../../assets/order_sort_type';

const getOrderDeliveryStaus = (status) => {
  switch (status) {
    case "Pending":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-yellow-500 me-2"></div>{" "}
            Pending
          </div>
        </>
      );
    case "Shipped":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-blue-500 me-2"></div>{" "}
            Shipped
          </div>
        </>
      );
    case "Delivered":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>{" "}
            Delivered
          </div>
        </>
      );

    default:
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div> Not
            Delivered
          </div>
        </>
      );
  }
};

const getOrderStaus = (status) => {
  switch (status) {
    case "New":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-blue-500 me-2"></div>{" "}
            New
          </div>
        </>
      );
    case "Pending":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-yellow-500 me-2"></div>{" "}
            Pending
          </div>
        </>
      );
    case "Failed":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div>{" "}
            Failed
          </div>
        </>
      );
    case "Successful":
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div>{" "}
            Successful
          </div>
        </>
      );

    default:
      return (
        <>
          <div className="flex items-center">
            <div className="h-2.5 w-2.5 rounded-full bg-gray-500 me-2"></div>{" "}
            Payment not initiated
          </div>
        </>
      );
  }
};

const Orders = () => {
  const [orders, setOrders] = useState([]);
  // const [totalSum, setTotalSum] = useState("");
  const [sortType, setSortType] = useState([]);
  const [searchRef, setSearchRef] = useState();
  const [paymentType, setPaymentType] = useState();
  const [deliveryStatus, setDeliveryStatus] = useState();
  const [sortDate, setSortDate] = useState();
  const nf = new Intl.NumberFormat();

  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();

  const { accessToken } = useSelector(
    (state) => ({
      accessToken: state.profile.access_token
    }),
    shallowEqual
  );

  const onNext = () => {
    if (nextPage != null) {
      if (!!searchRef == true) {
        searchRefID(nextPage);
      }
      if (!!paymentType == true) {
        handPaymentChange(paymentType, nextPage);
      }
      if (!!deliveryStatus == true) {
        handDeliverStatusChange(deliveryStatus, nextPage);
      }
      if (!!sortDate == true) {
        handleDateChange(sortDate, nextPage);
      }
      if (
        !!searchRef == false &&
        !!paymentType == false &&
        !!deliveryStatus == false &&
        !!sortDate == false
      ) {
        fetchOrders(nextPage);
      }
    }
  };

  const onPrevious = () => {
    if (prevPage != null) {
      if (!!searchRef == true) {
        searchRefID(prevPage);
      }
      if (!!paymentType == true) {
        handPaymentChange(paymentType, prevPage);
      }
      if (!!deliveryStatus == true) {
        handDeliverStatusChange(deliveryStatus, prevPage);
      }
      if (!!sortDate == true) {
        handleDateChange(sortDate, prevPage);
      }
      if (
        !!searchRef == false &&
        !!paymentType == false &&
        !!deliveryStatus == false &&
        !!sortDate == false
      ) {
        fetchOrders(prevPage);
      }
    }
  };

  const onPageChange = (page) => {
    if (page != null) {
      if (!!searchRef == true) {
        searchRefID(page);
      }
      if (!!paymentType == true) {
        handPaymentChange(paymentType, page);
      }
      if (!!deliveryStatus == true) {
        handDeliverStatusChange(deliveryStatus, page);
      }
      if (!!sortDate == true) {
        handleDateChange(sortDate, page);
      }
      if (
        !!searchRef == false &&
        !!paymentType == false &&
        !!deliveryStatus == false &&
        !!sortDate == false
      ) {
        fetchOrders(page);
      }
    }
  };

  const searchRefID = (page) => {
    searchOrderByRefId(searchRef, page);
  };

  const handleDateChange = (data, page) => {
    setSortDate(data);
    searchOrderByDate(data, page);
  };

  const handPaymentChange = (selectedOption, page) => {
    setPaymentType(selectedOption);
    searchOrderByPayment(selectedOption.value, page);
  };

  const handDeliverStatusChange = (selectedOption, page) => {
    setDeliveryStatus(selectedOption);
    searchOrderByDelivery(selectedOption.value, page);
  };

  const searchOrderByRefId = (param, page) => {
    if (param !== "" && page) {
        const url = `${ROUTE.PRODUCTS_API}/order/search/${param}?pagination=1&page=${page}`
        searchOrderQuery(url)
        setPaymentType(false);
        setDeliveryStatus(false);
        setSortDate(false);
    } else {
        const order_first_page = 1
        fetchOrders(order_first_page)
    }
  };

  const searchOrderByPayment = (param, page) => {
    if (param !== "" && page) {
        const url = `${ROUTE.PRODUCTS_API}/order/payment/${param}?pagination=1&page=${page}`
        searchOrderQuery(url)
        setSearchRef();
        setDeliveryStatus(false);
        setSortDate(false);
    } else {
        const order_first_page = 1
        fetchOrders(order_first_page)
    }
  };

  const searchOrderByDelivery = (param, page) => {
    if (param !== "" && page) {
        const url = `${ROUTE.PRODUCTS_API}/order/delivery/${param}?pagination=1&page=${page}`
        searchOrderQuery(url)
        setSearchRef();
        setPaymentType(false);
        setSortDate(false);
    } else {
        const order_first_page = 1
        fetchOrders(order_first_page)
    }
  };

  const searchOrderByDate = (param, page) => {
    if (param && page) {
        var search_data = param.toString().slice(0, 24);
        const url = `${ROUTE.PRODUCTS_API}/order/sort?date=${search_data}&pagination=1&page=${page}`
        searchOrderQuery(url)
        setSearchRef();
        setPaymentType(false);
        setDeliveryStatus(false);
        setSortDate(false);
    } else {
        const order_first_page = 1
        fetchOrders(order_first_page)
    }
  };

  const fetchOrders = (page) => {
    setLoading(true);
    fetch(`${ROUTE.PRODUCTS_API}/orders?pagination=1&page=${page}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + accessToken
      }
    })
      .then((res) => res.json())
      .then((res) => {
        setOrders(res);
        // setTotalSum(res.results.reduce((a, v) => (a = a + v.amount), 0));
        setNextPage(res.next_page);
        setPrevPage(res.prev_page);
        setCurrentPage(res.current_page);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const searchOrderQuery = useCallback(async (url) => {
    if (url) {
      setLoading(true);
      fetch(`${url}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + accessToken
          }
        }
      )
        .then((res) => res.json())
        .then((res) => {
          setOrders(res);
          // setTotalSum(res.results.reduce((a, v) => (a = a + v.amount), 0));
          setNextPage(res.next_page);
          setPrevPage(res.prev_page);
          setCurrentPage(res.current_page);
          setLoading(false);
        });
    }
  }, []);

  const first_item_on_page_number =
    orders?.current_page * orders?.items_per_page - orders?.items_per_page + 1;
  const last_item_on_page_number =
    first_item_on_page_number + orders?.results?.length - 1;

  useEffect(() => {
    fetchOrders(currentPage);
  }, []);

  useEffect(() => {
    if (sortType.value == 0) {
      setSearchRef();
      setPaymentType(false);
      setDeliveryStatus(false);
      setSortDate(false);
      fetchOrders(currentPage);
    }
  }, [sortType]);

  return (
    <div className="bg-gray-100">
      <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
        <div className="flex items-center space-x-2 text-gray-400">
          <span className="text-green-700 tracking-wider font-thin text-md">
            <Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link>
          </span>
          <span>/</span>
          <span className="tracking-wide text-md">
            <span className="text-base">Orders</span>
          </span>
          <span>/</span>
        </div>
      </div>
      <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
        <h1 className="font-medium text-2xl">Orders</h1>
        {/* <div className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100 overflow-x-auto">
          {" "}
          <span className=" whitespace-nowrap overflow-x-auto items-center flex-nowrap">
            <FaMoneyCheckAlt className="inline-block w-5 h-5 mr-3" />
          </span>
          &#8358;{nf.format(totalSum)}
        </div> */}
      </div>

      <div className="flex flex-col mx-3 lg:flex-row">
        <div className="w-full m-4 bg-white shadow-lg text-lg rounded-sm border border-gray-200">
          <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <div className="flex flex-col md:flex-row w-full my-6">
              <div className="w-full md:w-2/3 px-3 mb-6">
                <label className="sr-only">Search</label>
                <div className="mt-1">
                  <input
                    type="text"
                    id="table-search"
                    className="block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Search Ref ID."
                    value={searchRef}
                    onChange={(e) => setSearchRef(e.target.value)}
                    onKeyUp={() => searchRefID(currentPage)}
                  />
                </div>
              </div>
              <div className="w-full md:w-2/3 px-3 mb-6">
                <Select
                  placeholder="Filter by..."
                  defaultValue={sortType}
                  onChange={setSortType}
                  options={Sortlist}
                  styles={{ menu: (provided) => ({ ...provided, zIndex: 10 }) }}
                  className="mt-1 block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                />
              </div>
              {sortType.value == 1 ? (
                <div className="w-full md:w-2/3 px-3 mb-6">
                  <DatePicker
                    placeholderText="Select date"
                    selected={sortDate}
                    onChange={(date) => handleDateChange(date, 1)}
                    className="mt-1 block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              ) : sortType.value == 2 ? (
                <div className="w-full md:w-2/3 px-3 mb-6">
                  <Select
                    placeholder="Select payment"
                    defaultValue={paymentType}
                    options={Paymentlist}
                    onChange={(e) => handPaymentChange(e, 1)}
                    className="mt-1 block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              ) : sortType.value == 3 ? (
                <div className="w-full md:w-2/3 px-3 mb-6">
                  <Select
                    placeholder="Select delivery"
                    defaultValue={deliveryStatus}
                    onChange={(e) => handDeliverStatusChange(e, 1)}
                    options={DeliveryStatuslist}
                    className="mt-1 block text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                </div>
              ) : (
                " "
              )}
            </div>
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Ref ID
                  </th>
                  <th scope="col" className="px-6 py-3">
                    User
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Total
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Payment
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Delivery Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && orders?.results?.length <= 0 && (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={8}>
                        <div className="flex flex-col justify-center items-center my-4 space-y-3">
                          No record found
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                {!isLoading &&
                  orders?.results?.length >= 1 &&
                  orders?.results?.map((order, index) => (
                    <>
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-4">{order.ref_id}</td>
                        <td className="px-6 py-4">
                          {order.first_name} {order.last_name}
                        </td>
                        <td className="px-6 py-4">{order.email}</td>
                        <td className="px-6 py-4">
                          &#8358;{nf.format(order.amount)}
                        </td>
                        <td className="px-6 py-4">
                          {getOrderStaus(order.status)}
                        </td>
                        <td className="px-6 py-4">
                          {order.created_at.substring(0, 17)}
                        </td>
                        <td className="px-6 py-4">
                          {getOrderDeliveryStaus(order.delivery_status)}
                        </td>
                        <td className="flex items-center px-6 py-4">
                          <Link
                            to={`${ROUTE.VIEW_ORDERS}/${order.id}/${order.ref_transaction_id}`}
                            className="rounded-md hover:bg-gray-100 text-green-600 p-2 flex justify-between items-center"
                          >
                            <span>
                              <FaEye className="w-4 h-4 mr-1" />
                            </span>{" "}
                            View
                          </Link>
                        </td>
                      </tr>
                    </>
                  ))}
                {isLoading && (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={8}>
                        <div className="flex flex-col justify-center items-center my-4 space-y-3">
                          Fetching records...
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {!isLoading && orders?.results?.length >= 1 && (
              <nav
                className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {`${first_item_on_page_number}-${last_item_on_page_number}`}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {orders?.total_records}
                  </span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                  {orders?.prev_page && (
                    <li>
                      <a
                        onClick={onPrevious}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </a>
                    </li>
                  )}
                  {orders?.iter_pages.map((iter_page) => (
                    <>
                      {iter_page != null ? (
                        <li>
                          <a
                            onClick={() => onPageChange(iter_page)}
                            className={`${
                              orders?.current_page === iter_page
                                ? "flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                : "flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            }`}
                          >
                            {iter_page}
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a
                            href="#"
                            className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                          >
                            {"..."}
                          </a>
                        </li>
                      )}
                    </>
                  ))}
                  {orders?.next_page && (
                    <li>
                      <a
                        onClick={onNext}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Orders;
 