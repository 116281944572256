
import { useEffect, useState } from 'react';

import useDidMount from './useDidMount';
import * as ROUTE from '../constants/routes';

const useOrders = (token) => {      
	const [orders, setOrders] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setLoading] = useState(false);
	const didMount = useDidMount(true);

	const fetchOrders = (async () => {
		try {
			setError('');
			const requestOptions = {
				method: "GET",
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json',
					'Authorization': `Bearer ${token}`
				}
			}
			setLoading(true)
			const _orders =  await fetch(`${ROUTE.PRODUCTS_API}/order/user`, requestOptions)
				.then(res => res.json())
				.then(data =>{
					return data
				})
				.catch(err => console.log(err))

			if (_orders.length == 0) {
				if (didMount) {
					setError('No order for authenticated user.');
					setLoading(false);
				}
			} else {
				const data = _orders;
				if (didMount) {
					setLoading(false);
					setOrders(data);
				}
			}
		} catch (e) {
			if (didMount) {
				setError('Failed to fetch orders');
				setLoading(false);
			}
		}
	});

	useEffect(() => {
		if (orders.length === 0 && didMount) {
			fetchOrders();
		}
	}, []);


	return {
		orders, fetchOrders, isLoading, error
	};
};

export default useOrders;
 