
import React, { Suspense } from 'react';
import { useDocumentTitle } from '../../hooks';
import { LoadingOutlined } from '@ant-design/icons';


const AccountProfile = React.lazy(() => import('./components/AccountProfile'));

const Profile = () => {
    useDocumentTitle('My Profile | BestDealNaija');

    return (
		<div>
			<Suspense fallback={(
				<div className="loader" style={{ minHeight: '80vh' }}>
					<h6>Loading...</h6>
					<br />
					<LoadingOutlined />
				</div>
			)}>
				<AccountProfile />
			</Suspense>
		</div>
	);
}
export default Profile;
 