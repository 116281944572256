
import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import * as CONSTANT from '../../constants/constants';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import * as ROUTE from '../../constants/routes';
import { FaBlog } from 'react-icons/fa';

function BlogDetails() {
  useDocumentTitle('Blog Details| BestDealNaija');
  useScrollTop();

  const {slug} = useParams();
  const [blog, fetchBlog] = useState([]);
  const [loading, setLoading] = useState(false);

  const getData = () => {
    setLoading(true)
  fetch(`${ROUTE.BLOGS_API}/sblog/${slug}`)
    .then((res) => res.json())
    .then((res) => {
      fetchBlog(res.data[0])
      
      setLoading(false)
    })
  }
  useEffect(() => {
    getData()
  }, []);
  
  let blogdetails = ''
  if(blog){
    blogdetails =  (
      <div>
        <h1 className="py-2 mb-4 text-center text-4xl leading-10 md:text-6xl "><b>{blog.title}</b></h1>
        <div className="flex justify-center">
          <img className="lazy h-2/3 w-2/3" src={`${CONSTANT.IMAGE_STORE}/${blog.image_path}`} alt={blog.title}/>
        </div>
        <div className="h-auto mt-5" dangerouslySetInnerHTML={{ __html: blog.content }} />
      </div>
    )
  }
  else{
    blogdetails =  (
      <div className="flex flex-col space-y-5 -mt-36 justify-center items-center text-xl font-medium tracking-wide text-green-700 text-center h-screen">
          <FaBlog className='w-32 h-32'/>
          <h1 className="font-semibold text-3xl tracking-wider">Blog no longer exist</h1>
      </div>)
  }

  let blogsLoading = (
    <>
      <section className="bg-gray-100 pt-5">
          <div className="px-36 py-[10px] pb-[80px] luday_wrap">     
            <div className="group bg-white">    
                <article>
                  <div>
                      <p  data-paceholder className="h-6 py-[30px] my-10  bg-gray-200 font-bold text-sm"></p>
                  </div>
                  <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                  </div>
                  <div className="py-[30px]">
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                  </div>
                  <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                  </div>
                  <div className="py-[30px]">
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                    <p data-placeholder className="h-7 mb-2 w-full bg-gray-200 overflow-hidden relative"></p>
                  </div>
                </article>
            </div>
          </div>
      </section>
    </>
  )

  return (
      <>
        <div className="post-body my-20 pt-10 px-6 lg:px-[200px]">
        { loading
          ? blogsLoading
          : blogdetails }
        </div>
      </>
  );
}
export default BlogDetails;
 