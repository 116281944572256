
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import swal from 'sweetalert'
import axios from 'axios'
import * as ROUTE from '../../../constants/routes'
import * as CONSTANTS from '../../../constants/constants'

const EditAdmins = () => {
    const { id } = useParams();
    const [loading, setLoading] = useState(false);
    const [adminId, setAdminId] = useState('');
    const [adminFirstName, setAdminFirstName] = useState('');
    const [adminLastName, setAdminLastName] = useState('');
    const [adminGender, setAdminGender] = useState('');
    const [adminMobileNumber, setAdminMobileNumber] = useState('');
    const [adminUserType, setAdminUserType] = useState('');
    const [adminEmail, setAdminEmail] = useState('');
    
    const {register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const getData = async () => {
        setLoading(true)
        await fetch(`${ROUTE.USER_API}/user/${id}`)
          .then((res) => res.json())
          .then((res) => {
            // const response = res.first_name
            // console.log("Response :::",response)
            setAdminId(res.id)
            setAdminFirstName(res.first_name)
            setAdminLastName(res.last_name)
            setAdminGender(res.gender)
            setAdminMobileNumber(res.mobile_number)
            setAdminUserType(res.user_type)
            setAdminEmail(res.email)
        })
        setLoading(false)
    }
    //console.log(getData)
    
    useEffect(() => {
        getData()
    }, [])
    
    useEffect(() => {
        setTimeout(() => 
            setValue("first_name", adminFirstName),
            setValue("last_name", adminLastName),
            setValue("gender", adminGender),
            setValue("mobile_number", adminMobileNumber),
            setValue("user_type", adminUserType),
            setValue("email", adminEmail)
        );
    }, [loading]);

    const submitForm = (data) => {
        let _data = {
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            email: data.email.trim().toLowerCase(),
            mobile_number: data.mobile_number.trim(),
            user_type: data.user_type.trim()
        } 

        const requestOptions = {
            method: "POST",
            headers: {
                'Accept': '*/*',
                'Content-Type': 'application/json'
            }
        }
        axios.put(
            `${ROUTE.USER_API}/admin/${adminId}`,
            _data,
            //console.log(_data),
            requestOptions
        ).then(res => res)
        .then(data =>{
            if (data.status == 200 || data.status == 302) {
                successAlert(data)
            }
            else {
                errorAlert(data.message)
            }
        })
        .catch(err => errorAlert(err))
  
      reset()
    }

    const successAlert = (response) => {
        return(
          swal({
              title: "Successfully updated!",
              text: response.data.message,
              icon: "success"
          }).then(function () {
                getData()
          })
        )
    }
    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error,
              icon: "error"
          }).then(function () {
            getData()
          })          
        )
    }

  return (
    <div className="bg-gray-100">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Back to Admins</Link></span>
                <span>/</span>
                <span className="tracking-wide text-md">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_MANAGE_ADMINS}>Admins</Link></span>
                </span>
                <span>/</span>
                <span className="tracking-wide text-md">
                    <span className="text-base">Edit Admins</span>
                </span>
                </div>
        </div>
        <div className="header my-3 h-12 px-4 lg:px-10 py-8  flex items-center justify-between">
            <h1 className="font-medium text-2xl">Edit Admins</h1>
            <Link to={ROUTE.ADMIN_MANAGE_ADMINS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
            </span> Back to Admins</Link>
        </div>
        <div className="flex flex-col mx-3 lg:flex-row">
            <form className="w-full lg:w-3/5 bg-white shadow-md p-6">
                <div className='flex flex-col md:flex-row w-full'>
                    <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='first_name'>First Name</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] focus:ring-[#98c01d]" type='text' name="first_name"
                        {...register("first_name", { required: true })} required onChange={(e) => setAdminFirstName(e.target.value)} />
                        {errors.first_name && <small className="text-red-500 text-xs italic">First name is required</small>}
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='last_name'>Last Name</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] focus:ring-[#98c01d]" type='text' name="last_name"
                        {...register("last_name", { required: true })} required onChange={(e) => setAdminLastName(e.target.value)} />
                        {errors.last_name && <small className="text-red-500 text-xs italic">ast name is required</small>}
                    </div>
                </div>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='mobile_number'>Phone number</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] focus:ring-[#98c01d]" type='text' name="mobile_number"
                        {...register("mobile_number")} onChange={(e) => setAdminMobileNumber(e.target.value)} />
                    </div>
                    <div className="w-full md:w-1/2 px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='email'>Email</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] focus:ring-[#98c01d]" type='email' name="email"
                        {...register("email", { required: true })} required onChange={(e) => setAdminEmail(e.target.value)} />
                        {errors.last_name && <small className="text-red-500 text-xs italic">Last name is required</small>}
                    </div>
                </div>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className="w-full md:w-1/2 px-3 mb-6">
                        <fieldset>
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='user_type'>User Type</label>
                            <div>
                                <input type="radio" id="user_type1"
                                    name="user_type" value={CONSTANTS.SUB_ADMIN_ROLE} className='m-2' {...register("user_type", { required: true })} />
                                <label htmlFor="user_type1">Admin</label>

                                <input type="radio" id="user_type2"
                                    name="user_type" value="" className='m-2' {...register("user_type", { required: true })} />
                                <label htmlFor="user_type2">Other</label>
                                {errors.user_type && <small className="text-red-500 text-xs italic">User Type is required</small>}
                            </div>
                        </fieldset>
                    </div>
                </div>
                <div className='flex flex-col md:flex-row w-full'>
                    <div className="w-full md:w-1/2 px-3 mb-6">
                        <button className="appearance-none w-full block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                        onClick={handleSubmit(submitForm)}
                        >Update Admin</button>
                    </div>
                </div>
            </form>          
        </div>      
    </div>
  )
}
export default EditAdmins;
 