
import React from "react";

import * as ROUTE from "../../../constants/routes";
import "./styles/footer.css";
import * as CONSTANTS from "../../../constants/constants";
import { FaEnvelope, FaFacebookSquare, FaHome, FaInstagram, FaPhoneSquare } from "react-icons/fa";

function Footer() {
  const { COMPANY_EMAIL, COMPANY_ADDRESS, COMPANY_PHONE_NUMBER, COMPANY_PHONE_NUMBER_ALT } = CONSTANTS
  return (
    <footer className="footer">
      <div className="flex flex-wrap text-left lg:text-left">
        <div className="w-full lg:w-6/12 px-4 mb-2">
          <span className="block uppercase text-blueGray-200 text-sm font-semibold mb-2">
            Stay Connected
          </span>
          <div className="mt-3 lg:mb-0 mb-3">
            <a
              href="https://www.facebook.com/bestdealnaija"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="bg-white shadow-sm font-normal h-7 w-7 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button"
              >
                <FaFacebookSquare className='text-blue-700 w-5 h-5 inline-block' />
              </button>
            </a>
            <a
              href="https://www.instagram.com/bestdealnaijaofficial"
              target="_blank"
              rel="noreferrer"
            >
              <button
                className="bg-white shadow-sm font-normal h-7 w-7 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                type="button"
              >
                <FaInstagram className='text-pink-700 w-5 h-5 inline-block' />
              </button>
            </a>
          </div>
        </div>
        <div className="w-full lg:w-6/12 px-4">
          <div className="flex flex-wrap items-top lg:gap-0 gap-4">
            <div className="w-full lg:w-4/12 lg:px-4 ml-auto">
              <span className="block uppercase text-blueGray-200 text-sm font-semibold mt-2">
                Useful Links
              </span>
              <ul className="list-unstyled">
                <li className="mt-2">
                  <a
                    className="text-blueGray-600 hover:text-white block pb-2 text-sm"
                    href={ROUTE.SIGNUP}
                  >
                    Sign Up
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="text-blueGray-600 hover:text-white block pb-2 text-sm"
                    href={ROUTE.VENDORS}
                  >
                    Vendors
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="text-blueGray-600 hover:text-white block pb-2 text-sm"
                    href={ROUTE.SIGNUP_VENDOR}
                  >
                    Register Vendor
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-4/12 lg:px-4">
              <span className="block uppercase text-blueGray-200 text-sm font-semibold mt-2">
                Resources
              </span>
              <ul className="list-unstyled">
                <li className="mt-2">
                  <a
                    className="text-blueGray-600 hover:text-white block pb-2 text-sm"
                    href={ROUTE.USERS_GUIDE}
                  >
                    User&apos;s Guide
                  </a>
                </li>
                <li className="mt-2">
                  <a
                    className="text-blueGray-600 hover:text-white block pb-2 text-sm"
                    href={ROUTE.CONTACT}
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>
            <div className="w-full lg:w-4/12 lg:px-4">
              <span className="block uppercase text-blueGray-200 text-sm font-semibold mt-2">
                Contact
              </span>
              <ul className="list-unstyled">
                <li className="mt-2">
                  <a href="#" className="!text-sm !flex hover:text-white pb-2 !items-start !gap-2">
                      <FaPhoneSquare className='w-4 h-4 mt-1 inline-block' />
                      <span className="text-blueGray-600 hover:text-white">{COMPANY_PHONE_NUMBER}<br/>{COMPANY_PHONE_NUMBER_ALT}</span>
                  </a>
                </li>
                <li className="mt-2">
                  <a href="#" className="items-center !text-sm !flex hover:text-white pb-2 items-start !gap-2">
                    <FaEnvelope className='w-4 h-4 inline-block' />
                    <span className="text-blueGray-600 hover:text-white block text-sm">{COMPANY_EMAIL}</span>
                  </a>
                </li>
                <li className="mt-2">
                  <a href="#" className="!flex hover:text-white items-start !gap-2">
                    <FaHome className='w-5 h-5 lg:w-7 lg:h-7 inline-block' />
                    <span className="text-blueGray-600 hover:text-white block text-sm mt-0 lg:mt-1">{COMPANY_ADDRESS}</span>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-3 border-gray-100 opacity-50"></hr>
      &copy; {new Date().getFullYear()} | Best Deal Naija | All rights reserved.
    </footer>
  );
}
export default Footer;


// @Constants starts
// @Constants ends
 