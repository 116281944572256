
import React from 'react';
import image2_3 from '../../images/imgs_userguide/image2_3.jpeg';
const Topic2 = () => { 
  return (
    <>
      <div>
        <p className="userguide_header">User Accounts</p>
        <p className="userguide_subheader anchor_link_section" id="2.1">1. Creating a new user account?</p>
        <p>To create an account, click on the Sign Up button to create an account. You will need to provide basic 
        information such as your name, email address, and password. Once your account is created, you can start using 
        the site. 
        </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.2">2. How long does it take for a user account to be approved?</p>
        <p>Once you create an account and verify your email you can start performing transactions on bestdealnaija. 
        Vendor accounts however take anywhere from 24 hours to 7 Days for an account to undergo review and be 
        approved.
        </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.3">3. What information is required to create a user account?</p>
        <p>To create an account on Best Deal Naija you need basic personal information such as your name, email, and Gender. 
        Once you have created your account you will be routed to a profile page where you can edit your profile 
        information. To complete your profile you will need to provide additional information such as your phone 
        number, your state, and your address.</p>
        <img src={image2_3} style={{width:"40%"}} className="text-success py-3" />
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.4">4. How can users reset or change their passwords?</p>
        <p>To reset or change your password, navigate to the login page, click on the forgot password link, and enter your 
        email address registered with Bestdealnaija. A password reset link will be sent to your email. Click on the 
        password reset link and choose a new password. You can access your account with the new password that you 
        have created.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.5">5. Is there a limit to the number of accounts that can be created?</p>
        <p>No. There is no limit to the number of user accounts that can be created. However, each account must 
        have a different email address as no two accounts can have the same email.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.6">6. Are user accounts associated with any form of payment?</p>
        <p>No. User accounts are not associated with any form of payment. Users can choose from multiple payment 
        options when trying to make a purchase but no payment needs to be made nor payment options set when creating
         an account. </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.7">7. Are user accounts linked to any third-party services?</p>
        <p>No. User accounts are not linked to any third-party services. </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.8">8. Are user accounts subject to any privacy policies?</p>
        <p>Yes. User accounts are subject to the privacy policy of bestdealnaija. Refer to the bestdealnaija privacy policy document for more information.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.9">9. Is it possible to delete a user account?</p>
        <p>No, it is not possible to delete an account from bestdealnaija, however, you can deactivate it.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.10">10. Are there any rules regarding the use of user accounts?</p>
        <p>All user accounts are subject to the Terms and Conditions of Bestdealnaija. Refer to the Terms and Conditions for more information.</p>
      </div>
    </>
  )
}
export default Topic2
 