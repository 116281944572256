
import React, { useCallback, useState, useEffect} from 'react';
import { Link } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import * as ROUTE from '../../../constants/routes'
import { useForm } from 'react-hook-form'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import swal from 'sweetalert'
import axios from 'axios'
import Select from 'react-select'
import BankList from '../../../assets/banks'
import stateList from '../../../assets/states'
import localGovtList from '../../../assets/local_govt'

const AddVendor = () => {
  const {register, handleSubmit, reset, formState: { errors }, clearErrors } = useForm();
    const [selectedImage, setSelectedImage] = useState();
    const [vendorImage, setVendorImage] = useState();
    const [vendorDescription, setVendorDescription] = useState('');
    const [bank_id, setBank_id] = useState([]);
    const [account_no, setAccount_no] = useState('');
    const [accountName, setAccountName] = useState('');
    const [statelst, setStateLst] = useState([]);
    const [selectedState, setSelectedState] = useState([]);
    const [selectedStateValue, setSelectedStateValue] = useState([]);
    const [city, setCity] = useState([]);
    let formData = new FormData();

    const onSelectFile = useCallback(async (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setVendorImage(file)
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
    }, []);

    const handleChange = (e, editor) => {
        clearErrors('content');
        setVendorDescription(editor.getData())
    }

    const submitForm = (data) => {
        if (accountName){
            formData.append('vendor_first_name', data.vendor_first_name)
            formData.append('vendor_last_name', data.vendor_last_name)
            formData.append('vendor_gender', data.vendor_gender);
            formData.append('vendor_email', data.vendor_email);
            formData.append('vendor_phone', data.vendor_phone);
            formData.append('vendor_image', vendorImage)
            formData.append('vendor_business_name', data.vendor_business_name);
            formData.append('vendor_description', vendorDescription);
            formData.append('vendor_address', data.vendor_business_address);
            formData.append('vendor_bank', bank_id.label);
            formData.append('vendor_bank_value', bank_id.value);
            formData.append('vendor_state', selectedState[0].name);
            formData.append('vendor_state_value', selectedStateValue[0].isoCode);
            formData.append('vendor_city', data.vendor_city);
            formData.append('vendor_account_number', data.vendor_account_no);
            formData.append('vendor_account_name', accountName);

            const requestOptions = {
                headers: {
                    'Content-type': 'multipart/form-data'
                }
            }
            axios.post(
                `${ROUTE.USER_API}/user/vendors`,
                formData,
                requestOptions
            ).then(res => res)
            .then(data =>{
                successAlert(data)
            })
            .catch(err => errorAlert(err))
        }
        else{
            console.log("account name not resolved")
        }
    }
   
    const successAlert = (response) => {
        return(
          swal({
              title: "Info!",
              text: response.data.message,
              icon: "success"
          }).then(function() {
            reset()
            setSelectedImage('')
          })           
        )
    }

    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error.message,
              icon: "error"
          })              
        )
    }

    const accountNokeyup = () => {
        const timer = setAccount_no(() => {
            if(bank_id.value){
                resolveAccount();
            }
        }, 1000)
    
        return () => clearTimeout(timer)
      }

    const resolveAccount = () => { 
        const result2 = (account_no || 123).length;
        if (result2 === 10){
        fetch(`https://api.paystack.co/bank/resolve?account_number=${account_no}&bank_code=${bank_id.value}`, {
        'method':'GET',
        headers:{
        'Content-Type':'application/json',
        'Authorization': `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}` // eslint-disable-line
        }
        }).then(resp => resp.json())
        .then(resp => setAccountName(resp.data.account_name))
        .catch(error => console.log(error),  setAccountName(''))
        }
    }  

    useEffect(() => {
        setStateLst(stateList)
     },[])
   
    const handleSelectedState = (sCode) => {
        const sdt = stateList.filter(y => y.isoCode === sCode)
        const dt = localGovtList.filter(x => x.stateCode === sCode)
        setCity(dt);
        setSelectedState(sdt)
        setSelectedStateValue(sdt)
    }
    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_VENDORS}>Vendor</Link></span>
                    </span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">Add Vendor</span>
                    </span>
                </div>
            </div>
            <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
                <h1 className="font-medium text-2xl">Add Vendor</h1>
                <Link to={ROUTE.ADMIN_VENDORS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5" />
                </span> Back to Vendor</Link>
            </div>
            <div className="flex flex-col mx-3 lg:flex-row">
                <form className="w-full lg:w-3/5 bg-white shadow-md p-6" encType="multipart/form-data">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_first_name'>Vendors First Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="vendor_first_name" placeholder="Enter Vendors' First Name"
                                    {...register("vendor_first_name", { required: true })}
                                    required />
                                {errors.vendor_first_name && <small className="text-red-500 text-xs italic">Vendors first name is required</small>}
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_last_name'>Vendors Last Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="vendor_last_name" placeholder="Enter Vendors' Last Name"
                                    {...register("vendor_last_name", { required: true })}
                                    required />
                                {errors.vendor_last_name && <small className="text-red-500 text-xs italic">Vendors last name is required</small>}
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_email'>Vendors Email</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="vendor_email" placeholder="you@example.com"
                                    {...register("vendor_email", { required: true })}
                                    required />
                                {errors.vendor_email && <small className="text-red-500 text-xs italic">Vendors email is required</small>}
                            </div>

                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_phone'>Vendors Phone</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="vendor_phone" placeholder="080X XXX XXXX"
                                    {...register("vendor_phone", { required: true })}
                                    required />
                                {errors.vendor_phone && <small className="text-red-500 text-xs italic">Vendors phone is required</small>}
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <fieldset>
                                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_gender'>Vendors Gender</label>
                                    <div>
                                        <input type="radio" id="vendorGender1"
                                            name="vendor_gender" value="male" className='m-2' {...register("vendor_gender", { required: true })} />
                                        <label htmlFor="vendorGender1">Male</label>

                                        <input type="radio" id="vendorGender2"
                                            name="vendor_gender" value="female" className='m-2' {...register("vendor_gender", { required: true })} />
                                        <label htmlFor="vendorGender2">Female</label>
                                        {errors.vendor_gender && <small className="text-red-500 text-xs italic">Vendors gender is required</small>}
                                    </div>
                                </fieldset>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='Bank_name'>Bank Name</label>
                                {errors.Bank_name && <small className="text-red-500 text-xs italic">Select at least one Bank</small>}
                                <Select defaultValue={bank_id} onChange={setBank_id} options={BankList}/>
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_no'>Bank Account Number</label>
                                <div className='flex flex-row'>
                                        {/* <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 leading-tight focus:outline-none focus:border-[#98c01d]" name="vendor_account_no" placeholder="05X XXX XXXX"
                                        {...register("vendor_account_no", { required: true })} defaultValue={5}
                                        required type="number" value={account_no} onChange={inputChanged} /> */}
                                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 leading-tight focus:outline-none focus:border-[#98c01d]" name="vendor_account_no" placeholder="05X XXX XXXX"
                                        {...register("vendor_account_no", { required: true })} defaultValue={5}
                                        required type="number" value={account_no} onChange={(e) => setAccount_no(e.target.value)} onKeyUp={accountNokeyup}/>
                                        {errors.vendor_account_no && <small className="text-red-500 text-xs italic">Vendors account number is required</small>}
                                    
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_name'>Vendors Account Name</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                                >{accountName}</p>
                            </div>
                        </div>

                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_business_address'>Vendors Business Address</label>
                            <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="vendor_business_address" placeholder="Enter Business Address"
                                {...register("vendor_business_address", { required: true })}
                                required />
                            {errors.vendor_business_address && <small className="text-red-500 text-xs italic">Vendor business address is required</small>}
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='business_state'>State</label>                                
                                <select id="ddlstate" className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" name="business_state" {...register("business_state", { required: true })} onChange={(e) => handleSelectedState(e.target.value)}>
                                    <option value={'0'}>Select State</option>
                                    {statelst &&
                                    statelst !== undefined ?
                                    statelst.map((st,i) => {
                                        return(
                                            <option key={i} value={st.isoCode}>{st.name}</option>
                                        )
                                    }):
                                    "No State"
                                    }
                                </select>

                            {errors.business_state && <small className="text-red-500 text-xs italic">Select at least one state</small>}
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_last_name'>City</label>
                                <select id="ddlcity" className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" name="Bank_name" {...register("vendor_city", { required: true })}>
                                    <option value={'0'}>Select City</option>
                                    {/* { cityDropDown } */}
                                    {city &&
                                    city !== undefined ?
                                    city.map((st,i) => {
                                        return(
                                            <option key={i} value={st.name}>{st.name}</option>
                                        )
                                    }):
                                    "No City"
                                    }
                                </select>

                            {errors.vendor_city && <small className="text-red-500 text-xs italic">Select at least one city</small>}
                            </div>
                        </div>

                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_business_name'>Vendors Business Title</label>
                            <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="vendor_business_name" placeholder="Enter Business Title"
                                {...register("vendor_business_name", { required: true })}
                                required />
                            {errors.vendor_business_name && <small className="text-red-500 text-xs italic">Vendor business title is required</small>}
                        </div>

                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Business Description</label>
                            <div className="w-full overflow-x-auto mx-3">
                                <CKEditor
                                    editor={ClassicEditor}
                                    onChange={handleChange} 
                                    data={vendorDescription} 
                                />
                            </div>
                        </div>


                        <div className="w-full mx-auto px-3 mb-12">
                            <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='product_image'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>

                                <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Vendor image</h2>

                                <p className="mt-2 text-gray-500 tracking-wide">Upload or drag & drop your file SVG, PNG, JPG or GIF. </p>

                                <input name="product_image" id="product_image" type="file" className="hidden" onChange={onSelectFile} accept="image/png, image/jpeg, image/webp" />
                            </label>
                            {errors.product_image && <small className="text-red-500 text-xs italic">Vendor image is required</small>}
                        </div>
                        <div className="w-full flex justify-center mb-5">
                            {selectedImage &&
                                (
                                    <img src={selectedImage} className="w-32 h-32" />
                                )
                            }
                        </div>
                        <div className="w-full px-3 mb-6">
                            <button className="appearance-none w-full block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                                onClick={handleSubmit(submitForm)}>Add Vendor</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default AddVendor;
 