
import { REQUEST_METHOD } from ".";
import * as ROUTES from '../constants/routes';

const PRODUCT_ROUTE = `${ROUTES.PRODUCTS_API}`

export function getCategoryProducts(data) {
	return REQUEST_METHOD.get(`${PRODUCT_ROUTE}/categories/${data}/products`)
}

export function searchProducts(searchKey, lastKeyRef) {
	if(lastKeyRef !== null){
		return REQUEST_METHOD.get(`${PRODUCT_ROUTE}/products/search?q=${searchKey}&last_key=${lastKeyRef}&pagination=1`)
	}
	else {
		return REQUEST_METHOD.get(`${PRODUCT_ROUTE}/products/search?q=${searchKey}&pagination=1`)
	}
}

export function getProducts(lastKeyRef) {
	if (lastKeyRef !== null) {
		return REQUEST_METHOD.get(`${PRODUCT_ROUTE}/products/verified?last_key=${lastKeyRef}&pagination=1`)
	} 
	else {
		return REQUEST_METHOD.get(`${PRODUCT_ROUTE}/products/verified?pagination=1`)
	}
}

export function addOrder(data, kwargs) {
	return REQUEST_METHOD.post(`${PRODUCT_ROUTE}/order/user`, data, kwargs)
}

export function addGuestOrder(data) {
	return REQUEST_METHOD.post(`${PRODUCT_ROUTE}/order/guest`, data)
}

export function updateOrderBillingAddressIdByRefId(data) {
	return REQUEST_METHOD.put(`${PRODUCT_ROUTE}/order/billing-address`, data)
}

export function updateOrderTypeByRefId(data) {
	return REQUEST_METHOD.put(`${PRODUCT_ROUTE}/order/type`, data)
}
 