
/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import * as ROUTE from '../../constants/routes';
import { MdOutlineProductionQuantityLimits } from "react-icons/md";
import * as CONSTANT from '../../constants/constants';

const OrderList = (props) => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [postCode, setPostCode] = useState('');
    const [readState, setReadSate] = useState('');
    const [readCity, setReadCity] = useState('');
    const [createdDate, setCreatedDate] = useState('');
    const [amount, setAmount] = useState('');
    const [oderStatus, setOderStatus] = useState('');
    const [paymentStatus, setPaymentStatus] = useState('');  
    const [products, setProducts] = useState([]);
    const nf = new Intl.NumberFormat();

    const getData = () => {
        fetch(`${ROUTE.PRODUCTS_API}/order/id?order_id=${props.orderId}`)
            .then((res) => res.json())
            .then((res) => {
                setFirstName(res.first_name)
                setLastName(res.last_name)
                setEmail(res.email)
                setPhone(res.mobile_number)
                setAddress(res.address.street)
                setCreatedDate(res.created_at)
                setAmount(res.amount)
                setPostCode(res.address.postal_code)
                setReadCity(res.address.lga)
                setReadSate(res.address.ref_state)
                setOderStatus(res.delivery_status)
                setPaymentStatus(res.status)
                setProducts(res.products)
        })
    } 

    useEffect(() => {
        getData()
    }, [])

    let orderList;
    if(products.length > 0){
        orderList = products.map((item, i) => {
            return (
                <tr key={i}>
                    <td className="p-2">
                        {item.product_sku}
                    </td>
                    <td className="p-2 w-8">
                    <img src={`${CONSTANT.IMAGE_STORE}/${item.product_image_path}`} className="w-8 h-8 mx-auto overflow-hidden" alt={item.name} />
                    </td>
                    <td className="p-2">
                        <p className="text-center">{item.name}</p>
                    </td>
                    <td className="p-2">
                    {item.quantity}
                    </td>
                    <td className="p-2">
                    &#8358;{nf.format(item.price)}
                    </td>
                    
                    <td className="p-2">
                        <p className="text-center">{ item.vendor_business_name }</p>
                    </td>
                    
                    
                </tr>
            )
        }

        );
    } else {
      orderList =  (<tr className=''><td colSpan={8} className="">
        <div className="flex flex-col my-4 space-y-3 justify-center items-center text-xl font-medium tracking-wide py-4 text-green-700 text-center">
            <MdOutlineProductionQuantityLimits className="w-16 h-16" />
            <p>No Record added yet</p>
        </div>
        </td></tr>)
    }

    return (
        <>
            <div className="flex flex-col mx-3 lg:flex-row ">
                <div className="w-full bg-white shadow-md p-6">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_first_name'>First Name</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{firstName}</p>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_last_name'>Last Name</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{lastName}</p>
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                                <div className="w-full md:w-1/2 px-3 mb-6">
                                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_email'>Email</label>
                                    <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{email}</p>
                                </div>
                                <div className="w-full md:w-1/2 px-3 mb-6">
                                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_phone'>Phone</label>
                                    <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{phone}</p>
                                </div>
                        </div>

                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_business_address'>Address</label>
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{address} {postCode}</p>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='business_state'>State</label>                                
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{readState}</p>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_last_name'>City</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                                >{readCity}</p>
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_no'>Order Date</label>
                                <div className='flex flex-row'>
                                    <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                                    >{createdDate}</p>
                                </div>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_name'>Total Amount</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                                >{amount}</p>
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>

                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_name'>Order Payment Status</label>
                                {paymentStatus == "New" ?
                                <p className='text-center bg-blue-200 text-blue-900 px-2 py-1 rounded-md'> New</p>
                                :
                                paymentStatus == "Pending" ?
                                <p className='text-center bg-yellow-200 text-yellow-900 px-2 py-1 rounded-md'> Pending</p>
                                :
                                paymentStatus == "Failed" ?
                                <p className='text-center bg-red-200 text-red-900 px-2 py-1 rounded-md'> Failed</p>
                                :
                                paymentStatus == "Successful" ?
                                <p className='text-center bg-lime-200 text-lime-900 px-2 py-1 rounded-md'> Successful</p>
                                :
                                <p className='text-center'> Payment not initiated</p>
                                }
                               
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_name'>Order Delivery Status</label>
                                {oderStatus == "Pending" ?
                                <p className=' text-center bg-red-200 text-red-800 px-2 py-1 rounded-full'> Pending</p>
                                :
                                oderStatus == "Shipped" ?
                                <p className='text-center bg-blue-200 text-blue-800 px-2 py-1 rounded-full'> Shipped</p>
                                :
                                oderStatus == "Delivered" ?
                                <p className='text-center bg-green-200 text-green-800 px-2 py-1 rounded-full'> Delivered</p>
                                :
                                <p className=''> Not Delivered</p>
                                }
                                
                            </div>
                        </div>
                    </div>
                    <div className='overflow-x-scroll'>
                        <table className="table-auto w-full mt-3">
                        <thead className="text-sm font-semibold uppercase text-gray-800 bg-gray-50">
                            <tr className="font-semibold">
                                <th className='text-left'>ID</th>
                                <th className='text-center'>Image</th>
                                <th className="p-2">Product</th>
                                <th className="p-2 text-left">Quantity</th>
                                <th className="p-2 text-left">Price</th> 
                                <th></th> 
                                <th className="p-2">Vendor</th>     
                                <th></th>     
                            </tr>
                        </thead>

                        <tbody className="text-sm divide-y divide-gray-100">
                        {
                            orderList
                        }
                        
                        
                        </tbody>
                        </table>
                    </div>
                </div>
                    
            </div>
        </>
    )
}
export default OrderList;
 