
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import * as ROUTE from '../../../constants/routes';
import swal from 'sweetalert';
import { FaAngleLeft } from 'react-icons/fa';


const EditSubCategories = () => {
    const [categoryId, setCategoryId] = useState("");
    const [subCategoryId, setSubCategoryId] = useState("");
    const [categories, fetchCategories] = useState([]);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const {register, handleSubmit, reset, setValue, formState: { errors } } = useForm();

    const { id } = useParams();

    const getData = async () => {
        setLoading(true)
        await fetch(`${ROUTE.PRODUCTS_API}/categories/sub/${id}`)
          .then((res) => res.json())
          .then((res) => {
            setSubCategoryId(res.data.id)
            setCategoryId(res.data.category_id)
            setName(res.data.name)

            setLoading(false)
          })
    }

    const getCategories = () => {
        fetch(`${ROUTE.PRODUCTS_API}/categories`)
            .then((res) => res.json())
            .then((res) => {
            fetchCategories(res.results)
        })
    }

    useEffect(() => {
        getData()
        getCategories()
    }, [])

    useEffect(() => {
        setTimeout(() => 
            setValue("name", name),
            setValue("category", categoryId),
        );
    }, [loading]);

    const submitForm = async (data) => {
        let authTokens = localStorage.getItem('auth_tokens') ? JSON.parse(localStorage.getItem('auth_tokens')) : null;  
        const requestOptions = {
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Bearer ${authTokens.access_token}`
            },
            body: JSON.stringify(
                {
                    "_id": subCategoryId,
                    "category_id": data.category*1, // converting to integer
                    "name": data.name
                }
            )
        }
        await fetch(`${ROUTE.PRODUCTS_API}/categories/sub`, requestOptions)
            .then(res => res.json())
            .then(() => {
                const msg = "Category successfully updated"
                successAlert(msg)
            })
            .catch(err => {
                console.error("Error:", err);
                err => errorAlert(err)
            })
  
        reset()
    }

    const successAlert = (mssg) => {
        return(
            swal({
                title: "Info!",
                text: mssg,
                icon: "success"
            }).then(function () {
                getData()
            })
        )
    }
    const errorAlert = (error) => {
        return(
            swal({
                title: "Error!",
                text: error,
                icon: "error"
            }).then(function () {
                getData()
            })          
        )
    }

    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                    <div className="flex items-center space-x-2 text-gray-400">
                        <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                        <span>/</span>
                        <span className="text-green-700 tracking-wider font-thin text-md">
                            <Link to={ROUTE.ADMIN_SUB_CATEGORIES}>Sub Categories</Link>
                        </span>
                        <span>/</span>
                        <span className="tracking-wide text-md">
                            <span className="text-base">Edit Sub Category</span>
                        </span>
                    </div>
            </div>
            <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
                    <h1 className="font-medium text-2xl">Edit Sub Categories</h1>
                    <Link to={ROUTE.ADMIN_SUB_CATEGORIES} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
                    </span> Back to sub categories</Link>
                </div>
            { loading ? (
                <div className="h-full flex justify-center items-center">
                    <h3 className="font-bold text-green-600 text-2xl mx-auto">Loading...</h3>
                </div>
            ) : (
                <div className="w-full lg:w-1/2 mx-auto m-1">
                    {/* Display edit sub-category form */}
                    <form className="w-full bg-white shadow-md p-6" encType="multipart/form-data">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category'>Category</label>
                                <select className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" 
                                    name="category" {...register("category", { required: true })}
                                >
                                    {  categories.map((category, i) =>(
                                            <option className="my-2 hover:bg-gray-50" 
                                                key={i} value={category.id}
                                                selected={categoryId == category.id}
                                            > 
                                                {category.name} 
                                            </option>
                                        ))
                                    }
                                </select>

                                {errors.category && <small className="text-red-500 text-xs italic">Select at least one category</small>}
                            </div>
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='name'>Sub Category Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" 
                                    type='text' name="name" placeholder="Category Name" 
                                    {...register("name", { required: true})}
                                    required onChange={(e) => setName(e.target.value)} 
                                />
                                {errors.name && <small className="text-red-500 text-xs italic">Sub-category name is required</small>}
                                {errors.name?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 25 </small></p>}
                            </div>
                            <div className="w-full md:w-full px-3 mb-6">
                                <button className="appearance-none block w-full bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                                    onClick={handleSubmit(submitForm)}
                                >
                                    Update Sub Category
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            )}
        </div>
    )
}
export default EditSubCategories;
 