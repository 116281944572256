
import React from 'react';
const Topic5 = () => {
  return (
    <>
      <div>
        <p className="userguide_header">Managing Orders</p>
        <p className="userguide_subheader anchor_link_section" id="5.1">1. How do I process customer orders?</p>
        <p>You can process customer orders from your vendor dashboard. Each order contains the information of the 
        customer who placed the order and information on the order thats been placed including product, cost 
        and fulfillment preferences.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="5.2">2. How do I set shipping and pricing information?</p>
        <p>You set the pricing information of a product when you create or add the product from your vendor 
        dashboard. To add a product you</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="5.3">3. How can I manage inventory?</p>
        <p>Bestdealnaija does not allow for inventory management and hence does not include support for such 
        features.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="5.4">4. How do I issue refunds?</p>
        <p>To issue refunds for a product, the product has to be returned. Refunds depend largely on the refund 
        policy of the vendor and quality assurance is handled on the vendor&pos;s end.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="5.5">5. How do I track customer payments?</p>
        <p>Customer payments can be tracked from your vendor dashboard. Once a sale is completed the registered 
        bank account is credited with the transaction history is logged and is visible on the vendor account dashboard.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="5.6">6. How do I set up email notifications for orders?</p>
        <p>Bestdealnaija does not support email notifications for orders. Order tracking is maintained from the vendor dashboard.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="5.7">7. What security measures are in place to protect customer information?</p>
        <p>Bestdealnaija is securely built using the industry best practices security protocols to ensure the safety of its users.  It runs on a secure database which keeps all user information safe guarded against intrusions as well as SSL security to ensure 
        safety against transactional fraud. Additionally, bestdealnaija keeps all its user information provate</p>
      </div>
    </>
  )
}
export default Topic5
 