
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import * as ROUTES from '../../constants/routes';
import { signIn } from '../../redux/actions/authActions';
import { setAuthenticating, setAuthStatus } from '../../redux/actions/miscActions';
import { SIGNUP } from '../../constants/routes';
import { CustomInput } from '../../components/formik';
import { useDocumentTitle, useScrollTop } from '../../hooks';


const SignInSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.'),
    password: Yup.string()
      .required('Password is required.')
});

const SignIn = () => {
    const { isAuthenticating } = useSelector((state) => ({
        isAuthenticating: state.app.isAuthenticating
    }));
  
    const dispatch = useDispatch();
    
	useDocumentTitle('Sign In | BestDealNaija');
    useScrollTop();

    useEffect(() => {
        dispatch(setAuthStatus(null));
        dispatch(setAuthenticating(false));
    }, []);

    const onFormSubmit = (form) => {
        dispatch(signIn(form.email, form.password));
    };

    const onClickLink = (e) => {
        if (isAuthenticating) e.preventDefault();
    };

	return (
		<>
            <div className='pt-20 md:pt-32 bg-gray-50'>
            <div className="flex flex-col items-center min-h-screen sm:justify-center sm:pt-0">
                <div>
                    <a href={ROUTES.HOME}>
                        <h1 className="text-4xl font-bold text-[#9c0]">
                            Sign In
                        </h1>
                    </a>
                </div>
                <Formik
                    initialValues={{
                    email: '',
                    password: ''
                    }}
                    validateOnChange
                    validationSchema={SignInSchema}
                    onSubmit={onFormSubmit}
                >
                    <div className="w-full px-6 py-4 my-6 overflow-hidden bg-white shadow-md sm:max-w-lg sm:rounded-lg">
                        <Form>
                            <div>
                                <Field
                                    disabled={isAuthenticating}
                                    id="email" 
                                    name="email"
                                    placeholder="Enter e-mail"
                                    type="email"
                                    label="Email"
                                    component={CustomInput}
                                />
                            </div>
                            <div className="mt-4">
                                <Field
                                    disabled={isAuthenticating}
                                    id="password" 
                                    name="password"
                                    placeholder="Your Password"
                                    type="password"
                                    label="Password"
                                    component={CustomInput}
                                />
                            </div>
                            <div className="mt-2">
                            <Link
                                onClick={onClickLink}
                                className="text-xs text-green-600 hover:underline"
                                to={ROUTES.FORGOT_PASSWORD}
                                >
                                <span>Forgot password?</span>
                            </Link>
                            </div>
                            <div className="flex items-center mt-4">
                                <button 
                                    disabled={isAuthenticating}
                                    type="submit"
                                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-green-600">
                                    {isAuthenticating ? 'Signing In' : 'Sign In'}
                                </button>
                            </div>
                        </Form>
                        <div className="mt-4 text-grey-600">
                            Don&apos;t have an account?
                            <Link to={SIGNUP} 
                                className="text-primary hover:underline"
                                disabled={isAuthenticating}>
                                <span>
                                    &nbsp;Sign Up
                                </span>
                            </Link>
                        </div>
                        {/* <div className="flex items-center w-full my-4">
                            <hr className="w-full" />
                            <p className="px-3 ">@@SpanText4@@</p>
                            <hr className="w-full" />
                        </div>
                        <div className="my-6 space-y-2">
                            <button
                                aria-label="Login with Google"
                                type="button"
                                className="flex items-center justify-center w-full p-2 space-x-4 border rounded-full focus:ring-2 focus:ring-offset-1 dark:border-gray-400 focus:ring-green-600"
                            >
                                @@SignInOptionIcon1@@
                                <p>@@SignInOptionText1@@</p>
                            </button>
                            <button
                                aria-label="Login with GitHub"
                                role="button"
                                className="flex items-center justify-center w-full p-2 space-x-4 border rounded-full focus:ring-2 focus:ring-offset-1 dark:border-gray-400 focus:ring-green-600"
                            >
                                @@SignInOptionIcon2@@
                                <p>@@SignInOptionText2@@</p>
                            </button>
                        </div>  */}
                    </div>
                </Formik>
            </div>
        </div>
		</>
	);
}
export default SignIn;
 