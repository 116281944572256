
import React from 'react';
import PropType from 'prop-types';
import * as CONSTANT from "../../../constants/constants"
import { displayMoney } from '../../../helpers/utils';
const OrderDetails = ({order, isLoading}) => {
  const nf = new Intl.NumberFormat();
	return (
		<>
			{order.length === 0 ? (
				<>
					<div className="text-center py-[50px] my-4">   
						<div className="text-center py-10 mt-20 md:mt-4">
							<p className="text-lg">{isLoading ? 'Fetching...' : 'No record for transaction'}</p>
						</div>
					</div>
				</>
            ) : (
			<div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">
				<div className="flex flex-col md:flex-row py-[50px]">	
					<div className="w-full md:w-3/4 m-2">
						<div className="overflow-x-auto">
							<table className="w-full whitespace-nowrap">
								<thead className="bg-gray-50" >
									<tr>
										<th className="px-6 py-2 text-left whitespace-nowrap">Product</th>
										<th className="px-6 py-2">Price</th>
										<th className="px-6 py-2">Quantity</th>
										<th className="px-6 py-2">Payment Status</th>
										<th className="px-6 py-2">Total</th>
									</tr>
								</thead>
								<tbody>
								{order.products?.map(product => (  
									<tr key={product.id} className="border border-black-600 whitespace-nowrap">
										<td className="">
											<div className="flex flex-row">
												<div className="bg-white py-2 h-30 w-32">
													<img className="h-32 w-32" src={`${CONSTANT.IMAGE_STORE}/${product.product_image_path}`}/> 
												</div>
												<div className="bg-white py-2">
													<h4 className="px-6 text-left">{product.name}</h4>
													<span className="pl-6 py-2 text-left text-sm font-bold text-[#9c0]">
														Product ID: </span>{product.product_sku}
												</div>
											</div>
										</td>										
										<td className="px-6 py-2 text-right">&#8358;{nf.format(product.price)}</td>
										<td className="px-6 py-2 text-right">{nf.format(product.quantity)}</td>
										<td className="text-center"><span className="bg-green-200 text-green-800 px-2 py-1 rounded-full">{order.status}</span></td>
										<td className="px-6 py-2 text-right">&#8358;{nf.format(product.price * product.quantity)}</td>
									</tr>
								))}
								</tbody>
							</table>
						</div>
					</div>
					<div className="w-full md:w-1/4 m-2 border border-black-600 drop-shadow-md p-5 h-1/2">
						<h2 className="text-2xl mb-5">Order Summary</h2>
						<p><b>Order ID</b> <span>#{order.ref_id}</span></p>
                        {/* <p><b>Payment Method:</b> <span>Payment on delivery</span></p> */}
						<p className="mb-5"><b>Subtotal</b> <span>
								&#8358;{displayMoney(order.amount)}
							</span>
						</p>
						<hr/>
						<h2 className="text-2xl mt-5"><b>Grand Total</b></h2>
						<h2 className="text-3xl mt-5 font-bold">
						&#8358;{displayMoney(order.amount)}
						</h2>
					</div>
				</div>
			</div>			
			)}
		</>
	);  
};

OrderDetails.propTypes = {
	order: PropType.any.isRequired,
	isLoading: PropType.bool.isRequired
  };

export default OrderDetails;
 