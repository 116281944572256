
import React, {
  useState, useEffect, 
  Fragment
} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink, Link, useNavigate } from "react-router-dom";

import { 
  LogoutIcon, UserIcon,
  LoginIcon, UserAddIcon
} from '@heroicons/react/solid'
import { Menu, Transition } from '@headlessui/react'

import { signOut } from '../../../../redux/actions/authActions';
import * as ROUTE from '../../../../constants/routes';
import Logo from '../../../../images/logo/logo.png';
import Badge from '../../Badge';

function classNames(...classNamees) {
  return classNamees.filter(Boolean).join(' ')
}

const Navigation = () => {  
  const [click, setClick] = useState(false);
  const [logout, setLogout] = useState(false);
  const [top, setTop] = useState(true);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const store = useSelector((state) => ({
    cartTotalQuantity: state.cart.length,
    products: state.products,
    cart: state.cart,
    user: state.auth,
    profile: state.profile,
    isAuthenticating: state.app.isAuthenticating
  }));

  const handleLogout = () => {
    setLogout(true)
    dispatch(signOut())
  };

  useEffect(() => {
    if (logout) navigate(ROUTE.SIGNIN);
    setLogout(false);
  }, [logout]);

  useEffect(() => {
    const scrollHandler = () => {
      window.pageYOffset > 10 ? setTop(false) : setTop(true)
    };
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, [top]);

    return (
      <>
      <nav className={`fixed z-30 w-full top-0 bg-white border-gray-200 sm:px-4 sm:py-2.5 md:py-0 nav ${!top && 'bg-white shadow-lg'}`}>
          <div className="container flex flex-wrap justify-between items-center mx-auto">
            <a href={ROUTE.HOME} className="flex items-center w-[159px] md:w-60 py-4 md:py-6">
                <span className="self-center pl-4 font-semibold whitespace-nowrap ">
                <img src={Logo} className="logo-img" /></span>
            </a>
            <NavLink to={ROUTE.CART} onClick={closeMobileMenu} className="flex justify-center lg:hidden">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M21 4H2v2h2.3l3.521 9.683A2.004 2.004 0 0 0 9.7 17H18v-2H9.7l-.728-2H18c.4 0 .762-.238.919-.606l3-7A.998.998 0 0 0 21 4z"></path>
                <circle cx="10.5" cy="19.5" r="1.5"></circle>
                <circle cx="16.5" cy="19.5" r="1.5"></circle>
              </svg> 
              <Badge count={store.cartTotalQuantity}></Badge>
            </NavLink>
            <Link to='#' onClick={closeMobileMenu} className="flex justify-center lg:hidden dropdown-toggle" data-bs-toggle="dropdown">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path>
              </svg>
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="items-center rounded-full text-base text-gray-500 transition duration-75 group hover:bg-gray-100 font-medium">
                    <svg 
                      className="w-4 h-4" 
                      fill="currentColor" 
                      viewBox="0 0 20 20" 
                      xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" 
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                          clipRule="evenodd">
                        </path>
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {!store.user ? (
                      <>
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to={ROUTE.SIGNIN}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'inline-flex w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                              <LoginIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Sign In
                              </NavLink>
                            )}
                          </Menu.Item>
                        </div>
                        <hr />
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to={ROUTE.SIGNUP}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'inline-flex w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                              <UserAddIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Sign Up
                              </NavLink>
                            )}
                          </Menu.Item>
                        </div>
                      </>
                    ) : (
                      <>
                      {store.profile.user_type !== 'admin' && (
                        <>
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={ROUTE.PROFILE}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'inline-flex w-full text-left px-4 py-2 text-sm'
                                  )}
                                >
                                <UserIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                  My Account
                                </NavLink>
                              )}
                            </Menu.Item>
                          </div>
                          <hr />
                          </>
                      )}
                        <div className="py-1">
                          <form method="POST" action="#">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="submit"
                                  onClick={() => 
                                    handleLogout()
                                  }
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'inline-flex w-full text-left px-4 py-2 text-sm'
                                  )}
                                >
                                <LogoutIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </form>
                        </div>
                      </>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>
            </Link>
          <button data-collapse-toggle="mobile-menu" type="button" className="inline-flex items-center ml-3 text-sm text-gray-500 lg:hidden  focus:outline-none transform-[translate(-60%, 70%)] duration-300 ease-out" aria-controls="mobile-menu-2" aria-expanded="false" onClick={handleClick}>
            <span className="sr-only">Open main menu</span>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={3}>
              <path strokeLinecap="round" strokeLinejoin="round" d={click ? "M6 18L18 6M6 6l12 12" :"M4 6h16M4 12h8m-8 6h16" } />
            </svg>
        </button>
        <div className={click ? " w-full lg:block lg:w-auto mobile-menu" : " hidden w-full lg:block lg:w-auto mobile-menu"} id="mobile-menu">
          <ul className="flex flex-col mt-2 lg:flex-row lg:mt-0 lg:text-sm md:font-medium nav">
            <NavLink to={ROUTE.PRODUCTS} onClick={closeMobileMenu} className="border-t-2 md:border-none">
              <li className="border-t-2 pr-4 pl-6 pb-2 pt-2 font-medium rounded md:py-6 px-28 pb-2 pt-2 border-none font-normal bg-transparent text-black hover:bg-gray-100">Products</li>
            </NavLink>
            <NavLink to={ROUTE.FIND_YOUR_DEAL} onClick={closeMobileMenu} className="border-t-2 md:border-none">
              <li className="border-t-2 pr-4 pl-6 pb-2 pt-2 font-medium rounded md:py-6 px-28 pb-2 pt-2 border-none font-normal bg-transparent text-black hover:bg-gray-100">Find your deal</li>
            </NavLink>
            <NavLink to={ROUTE.ABOUT_BEST_DEAL_NAIJA} onClick={closeMobileMenu} className="border-t-2 md:border-none">
              <li className="border-t-2 pr-4 pl-6 pb-2 pt-2 font-medium rounded md:py-6 px-28 pb-2 pt-2 border-none font-normal bg-transparent text-black hover:bg-gray-100 py-6 px-28">About BestDealNaija</li>
            </NavLink>
            <NavLink to={ROUTE.BLOG} onClick={closeMobileMenu} className="border-t-2 md:border-none">
              <li className="border-8 pr-4 pl-6 pb-2 pt-2 font-medium rounded md:py-6 px-28 pb-2 pt-2 border-none font-normal bg-transparent text-black hover:bg-gray-100 py-6 px-28">Blog</li>
            </NavLink>
            <NavLink to={ROUTE.CONTACT} onClick={closeMobileMenu} className="border-t-2 md:border-none">
              <li className="border-t-2 pr-4 pl-6 pb-2 pt-2 font-medium rounded md:py-6 px-28 pb-2 pt-2 border-none font-normal bg-transparent text-black hover:bg-gray-100 py-6 px-28">Contact</li>
            </NavLink>
            <NavLink to={ROUTE.BEST_DEAL_TRADE_FAIR} onClick={closeMobileMenu} className="border-t-2 md:border-none">
              <li className="border-t-2 pr-4 pl-6 pb-2 pt-2 font-medium rounded md:py-6 px-28 pb-2 pt-2 border-none font-normal bg-transparent text-black hover:bg-gray-100 py-6 px-28">BestDeal Trade Fair</li>
            </NavLink>            
            <li className="flex flex-row whitespace-nowrap pr-4 pl-4 rounded md:bg-transparent text-black  py-6 px-28">
              <NavLink to={ROUTE.CART} onClick={closeMobileMenu} className="hidden lg:justify-center lg:block hover:bg-gray-100">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className='mr-6'>
                  <path d="M21 4H2v2h2.3l3.521 9.683A2.004 2.004 0 0 0 9.7 17H18v-2H9.7l-.728-2H18c.4 0 .762-.238.919-.606l3-7A.998.998 0 0 0 21 4z"></path>
                  <circle cx="10.5" cy="19.5" r="1.5"></circle>
                  <circle cx="16.5" cy="19.5" r="1.5"></circle>
                </svg>
              <Badge count={store.cartTotalQuantity}></Badge>
              </NavLink>
            </li>
            <li className="flex flex-row whitespace-nowrap pr-4 pl-4  rounded md:bg-transparent text-black  py-6 px-28">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="hidden md:flex items-center rounded-full text-base text-gray-500 transition duration-75 group hover:bg-gray-100 font-medium">
                  <Link to="#" data-bs-toggle="dropdown" 
                      className="hidden md:justify-center md:block dropdown-toggle">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                      <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path>
                    </svg>
                  </Link>
                    <svg
                      className="w-4 h-4" 
                      fill="currentColor" 
                      viewBox="0 0 20 20" 
                      xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" 
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                          clipRule="evenodd">
                        </path>
                    </svg>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    {!store.user ? (
                      <>
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to={ROUTE.SIGNIN}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'inline-flex w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                              <LoginIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Sign In
                              </NavLink>
                            )}
                          </Menu.Item>
                        </div>
                        <hr />
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <NavLink
                                to={ROUTE.SIGNUP}
                                className={classNames(
                                  active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                  'inline-flex w-full text-left px-4 py-2 text-sm'
                                )}
                              >
                              <UserAddIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                Sign Up
                              </NavLink>
                            )}
                          </Menu.Item>
                        </div>
                      </>
                    ) : (
                      <>
                      {store.profile.user_type !== 'admin' && (
                        <>
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <NavLink
                                  to={ROUTE.PROFILE}
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'inline-flex w-full text-left px-4 py-2 text-sm'
                                  )}
                                >
                                <UserIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                  My Account
                                </NavLink>
                              )}
                            </Menu.Item>
                          </div>
                          <hr />
                          </>
                      )}
                        <div className="py-1">
                          <form method="POST" action="#">
                            <Menu.Item>
                              {({ active }) => (
                                <button
                                  type="submit"
                                  onClick={() => 
                                    handleLogout()
                                  }
                                  className={classNames(
                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'inline-flex w-full text-left px-4 py-2 text-sm'
                                  )}
                                >
                                <LogoutIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
                                  Sign out
                                </button>
                              )}
                            </Menu.Item>
                          </form>
                        </div>
                      </>
                    )}
                  </Menu.Items>
                </Transition>
              </Menu>
            </li>
          </ul>
        </div>
      </div>
    </nav>
    </>
  );
};

export default Navigation;
 