
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as Yup from 'yup';
import { Field, Formik, Form } from 'formik';

import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/bdn_about_image_2.jpg';
import Accnav from '../../components/default/Navigation/account/index';
import { useDocumentTitle} from '../../hooks';
import { setLoading } from '../../redux/actions/miscActions';
import { updateProfile } from '../../redux/actions/profileActions';
import { CustomInput } from '../../components/formik';


const UpdatePasswordSchema = Yup.object().shape({
    old_password: Yup.string()
      .required('Current password is required.'),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password length should be at least 8 characters.')
      .matches(/[A-Z\W]/g, 'Password should contain at least 1 uppercase letter.'),
    password_confirm: Yup.string()
        .required('Confirm password is required.')
        .oneOf([Yup.ref('password')], 'Passwords does not match')
});

const ChangePassword = () => {
  useDocumentTitle('Change password | BestDealNaija');
  const { profile, isLoading } = useSelector((state) => ({
		profile: state.profile,
		isLoading: state.app.loading
	}));
    const dispatch = useDispatch();

    useEffect(() => () => {
		dispatch(setLoading(false));
	}, []);

    const update = (credentials) => {
        dispatch(updateProfile({
			updates: {
				first_name: profile.first_name,
				last_name: profile.last_name,
				email: profile.email,
				gender: profile.gender,
				mobile_number: profile.mobile_number
			},
			address : {
				state_id: profile.state ? profile.state : '',
				city_id: profile.city ? profile.city : ''
			},
			credentials
		}));
	};

    const onSubmitUpdate = (form) => {
		// check if data has changed
		const fieldsChanged = Object.keys(form).some((key) => profile[key] !== form[key]);
		if (fieldsChanged) {
            let password = form.password
            let old_password = form.old_password
			update({password, old_password});
		}
	};
  return (
	<>
		<Banner backgroundImage={bannerImage} subtitleText='Change Password'/>
		<div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">                    
			<div className="flex flex-col md:flex-row py-[50px]">	
                <div className="w-full md:w-1/3 m-2 border border-black-600 drop-shadow-md p-5 h-1/2">
                    <Accnav/>
                </div>
                <Formik
                    initialValues={{
                        old_password: '',
                        password: '',
                        password_confirm: ''
                    }}
                    validateOnChange
                    validationSchema={UpdatePasswordSchema}
                    onSubmit={onSubmitUpdate}
                >
                    <div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
                        <Form>
                            <div className="overflow-x-auto">
                                <div className="flex flex-col md:flex-row">
                                    <div className="mt-3 mb-3 w-auto md:w-full">
                                        <Field disabled={isLoading} name='old_password' type='password' label='Old Password' placeholder='Enter current password' component={CustomInput} required={true} />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row">
                                    <div className="mt-3 mb-3 w-auto md:w-full">
                                        <Field disabled={isLoading} name='password' type='password' label='Password' placeholder='Enter new password' component={CustomInput} required={true} />
                                    </div>
                                </div>
                                <div className="flex flex-col md:flex-row">
                                    <div className="mt-3 mb-3 w-auto md:w-full">
                                        <Field disabled={isLoading} name='password_confirm' type='password' label='Confirm Password' placeholder='Confirm new password' component={CustomInput} required={true} />
                                    </div>
                                </div>
                                <div className="mt-10 flex justify-center">
                                    <button 
                                        type="submit"
                                        disabled={isLoading}
                                        className="button button--theme overflow-hidden bg-[#9c0] 
                                        hover:bg-yellow-300 text-white text-sm transition-all ease-in-out duration-300 
                                        rounded py-3 px-5 m-2 flex items-center flex-nowrap w-80 justify-center">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current mr-2" ><path d="M5 21h14a2 2 0 0 0 2-2V8a1 1 0 0 0-.29-.71l-4-4A1 1 0 0 0 16 3H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2zm10-2H9v-5h6zM13 7h-2V5h2zM5 5h2v4h8V5h.59L19 8.41V19h-2v-5a2 2 0 0 0-2-2H9a2 2 0 0 0-2 2v5H5z"></path></svg>
                                        <span>{isLoading ? 'Saving' : 'Save'}</span>
                                    </button>
                                </div>
                            </div>
                        </Form>
                    </div>  
                </Formik>
			</div>
		</div>
	</>
  );  
}
export default ChangePassword;
 