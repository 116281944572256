
import { useEffect, useState } from 'react';

import { setAuthPersistence } from '../redux/actions/authActions';

const useSetAuthPersistence = (store) => {
    let [isLoading, setIsLoading] = useState(true);
	let auth_tokens = localStorage.getItem('auth_tokens') ? JSON.parse(localStorage.getItem('auth_tokens')) : null;

    let setAuthPersist = ()=> {
        if (auth_tokens) {
            store.dispatch(setAuthPersistence())
            if(isLoading){
                setIsLoading(false)
            }
        }
    }

	useEffect(()=> {
        let _store = store.getState()
        let isAuthenticating = _store.app.isAuthenticating
        if (!isAuthenticating) {
            let fourtyFourMinutes = 1000 * 60 * 44
            let interval =  setInterval(()=> {
                if(auth_tokens){
                    setAuthPersist()
                }
            }, fourtyFourMinutes)
            return () => clearInterval(interval)
        }

    }, [auth_tokens, isLoading])

    return { isLoading };
};

export default useSetAuthPersistence;
 