
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { CheckOutlined, LoadingOutlined } from '@ant-design/icons';
import { FaArrowLeft } from 'react-icons/fa';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

import * as ROUTES from '../../constants/routes';
import { CustomInput } from '../../components/formik';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import { forgotPassword } from '../../redux/actions/authActions';
import { setAuthStatus, setAuthenticating } from '../../redux/actions/miscActions';


const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email is not valid.')
      .required('Email is required.')
});

const ForgotPassword = () => {
    useDocumentTitle('Forgot Password | BestDealNaija');
    useScrollTop();

    const { authStatus, isAuthenticating } = useSelector((state) => ({
        isAuthenticating: state.app.isAuthenticating,
        authStatus: state.app.authStatus
    }));
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setAuthStatus(null));
        dispatch(setAuthenticating(false));
    }, []);

    const onFormSubmit = (form) => {
        dispatch(forgotPassword(form.email));
    };

	return (
		<>
            <div className='pt-20 md:pt-2 lg:pt-2 bg-gray-50'>
                <div className="flex flex-col items-center min-h-screen sm:justify-center sm:pt-0">
                    <div>
                        <h1 className="text-4xl font-bold text-[#9c0]">
                            Recover Password
                        </h1>
                    </div>
                    <Formik
                        initialValues={{
                            email: ''
                        }}
                        validateOnChange
                        validationSchema={ForgotPasswordSchema}
                        onSubmit={onFormSubmit}
                    >
                        <div className="w-full px-6 py-4 my-6 overflow-hidden bg-white shadow-md sm:max-w-lg sm:rounded-lg">
                            <Form>
                                <div>
                                    <Field
                                        id="email" 
                                        name="email"
                                        placeholder="Enter your email"
                                        type="email"
                                        label="Email"
                                        maxLength={40}
                                        component={CustomInput}
                                        readOnly={isAuthenticating}
                                    />
                                </div>
                                
                                <div className="mt-5">
                                <Link className=" text-xs text-primary hover:underline" to={ROUTES.SIGNIN}>
                                    <span className='flex flex-row items-center text-base'><FaArrowLeft/>Back to Login</span>
                                </Link>
                                </div>
                                <div className="flex items-center mt-4">
                                    <button 
                                        type="submit"
                                        disabled={isAuthenticating}
                                        className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-green-600">
                                        {isAuthenticating ? <LoadingOutlined /> : <CheckOutlined />}
                                        &nbsp;
                                        {isAuthenticating ? 'Sending Password Reset Email' : 'Send Password Reset Email'}
                                    </button>
                                </div>
                            </Form>
                            <div className="mt-4 text-grey-600">
                                Don&apos;t have an account?{" "}
                                <Link to={ROUTES.SIGNUP} 
                                    className="text-primary hover:underline"
                                    disabled={isAuthenticating || authStatus?.success}>
                                    <span>
                                        Sign Up
                                    </span>
                                </Link>
                            </div>
                        </div>
                    </Formik>
                </div>
            </div>
		</>
	);
}
export default ForgotPassword;
 