
import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import { Navigation, Footer } from '../components/default';
import Alert from '../helpers/Alert';

const StaticRoute = () => {
  const { isOrderStatus, requestStatus } = useSelector((store) => ({
    isOrderStatus: store?.app?.orderStatus,
    requestStatus: store?.app?.requestStatus
  }));

  return (
    <>
      <Navigation />
        {isOrderStatus?.message && (
          <nav className='fixed z-30 w-full top-0'>
            {isOrderStatus?.timeout ? (
              <Alert
                status={isOrderStatus.status}
                mssg={isOrderStatus.message}
                type={isOrderStatus.type}
                timeout={isOrderStatus.timeout}
              />
            ) : (
              <Alert
                status={isOrderStatus.status}
                mssg={isOrderStatus.message}
                type={isOrderStatus.type}
              />
            )}
          </nav>
        )}
        {requestStatus?.message && (
          <nav className='fixed z-20 w-full top-0 bg-white border-gray-200'>
            {requestStatus?.timeout ? (
              <Alert
                status={requestStatus.status}
                mssg={requestStatus.message}
                type={requestStatus.type}
                timeout={requestStatus.timeout}
              />
            ) : (
              <Alert
                status={requestStatus.status}
                mssg={requestStatus.message}
                type={requestStatus.type}
              />
            )}
          </nav>
        )}
        <Outlet />
      <Footer />
    </>
  )
};

export default StaticRoute;
 