
import React, { useEffect,useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import img from '../../images/tradefair/tradefairbg.jpg';
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';
import Banner  from '../../components/default/Banner/index';
import PaymentMethods from './components/PaymentMethods';
import { useDocumentTitle } from '../../hooks';
import { Timeline } from './components';
import { CartSummary } from '../../components/cart';
import { updateOrder, setPaymentDetails } from '../../redux/actions/checkoutActions';
import { setOrderLoading, setRequestStatus } from '../../redux/actions/miscActions';


const FormSchema = Yup.object().shape({
	payment_type: Yup.string().required('Please select payment method')
});

const Payment = () => {
  useDocumentTitle('Payment Method | BestDealNaija');
  const state = useSelector((store) => ({
		profile: store?.profile,
		cart: store?.cart,
		isAuth: !!store?.auth?.id && !!store?.auth?.user_type,
		billing: store?.checkout?.billing,
		requestStatus: store?.app.requestStatus,
		isLoading: store?.app?.loading
	}));

	const dispatch = useDispatch();
	const [isPaystackPayment, setPaystackPayment] = useState(false);

	useEffect(() => {
		dispatch(setRequestStatus(null));
        dispatch(setOrderLoading(false));
	}, []);

	if (state.requestStatus) {
		return <Navigate to={ROUTE.CASH_ORDER_CALLBACK} />;
	}
	if (!state.billing || !state.billing.isDone) {
		return <Navigate to={ROUTE.CHECKOUT} />;
	}
	if (!isPaystackPayment && state.cart.length === 0) {
		return<Navigate to={ROUTE.PRODUCTS} />;
	}

	const initFormikValues = {
		payment_type: state.billing.type || 'paystack'
	};

	const onConfirm = (form) => {
		const _billing_address_id = state?.profile?.ref_address_id ? state.profile.ref_address_id :state.billing.billing_address_id

		if (form.payment_type == 'cash') {
			dispatch(updateOrder({
				order: {
					first_name: state.billing.first_name,
					last_name: state.billing.last_name,
					ref_transaction_id:  state.billing.ref_transaction_id,
					ref_id: state.billing.ref_id,
                    ref_type: CONSTANT.CASH_ORDER_TYPE,
					mobile_number: state.billing.mobile_number,
					amount: state.billing.amount,
					tax: state.billing.tax,
					status: state.billing.status,
					billing_address_id: _billing_address_id
				},
				type: 'cash'
			}));
			dispatch(setPaymentDetails({
				type: 'cash',
				name: state.billing.first_name+ ' ' +state.billing.last_name
			}))
		}
		if (form.payment_type == 'paystack') {
			setPaystackPayment(true);
			dispatch(updateOrder({
				order: {
					first_name: state.billing.first_name,
					last_name: state.billing.last_name,
					ref_transaction_id:  state.billing.ref_transaction_id,
					ref_id: state.billing.ref_id,
                    ref_type: CONSTANT.ONLINE_ORDER_TYPE,
					mobile_number: state.billing.mobile_number,
					amount: state.billing.amount,
					tax: state.billing.tax,
					status: state.billing.status,
					billing_address_id: _billing_address_id
				},
				user: {},
				type: 'paystack'
			}));
			dispatch(setPaymentDetails({
				type: 'paystack',
				name: state.billing.first_name+ ' ' +state.billing.last_name
			}))
		}
	};

	return (
	<>
        <Banner subtitleText='Payment' backgroundImage={img} />
		<div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">  
			<Timeline current={3} />
			<Formik
				initialValues={initFormikValues}
				validateOnChange
				validationSchema={FormSchema}
				onSubmit={onConfirm}
			>
				{() => (
					<Form>          
						<div className="flex flex-col-reverse md:flex-row py-[50px]">	
							<div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
								<div>
									<h4 className="mb-2" >Payment Method</h4>
									<hr/>
								</div>
                                <PaymentMethods state={state} />
							</div>
                            <CartSummary />
						</div>
					</Form> 
				)}
			</Formik>
		</div>
	</>
  );
}
export default Payment;
 