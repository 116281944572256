
import React from 'react';
import { Link } from "react-router-dom";
import PropType from 'prop-types';
import { FaEye } from "react-icons/fa";

import * as ROUTE from '../../../constants/routes';

const OrderItem = ({ order }) => {
const nf = new Intl.NumberFormat();
	return (
		<>
			<td className="items-center p-3 w-[200px]">
				<p className="text-sm">{order.ref_id}</p>
			</td>
			<td className="text-center">
				{order.status == "Pending" ?
					<span className="text-sm bg-yellow-200 text-yellow-800 px-2 py-1 rounded-full">{order.status}</span>
					:
					order.status == "Failed" ?
						<span className="text-sm bg-red-200 text-red-800 px-2 py-1 rounded-full">{order.status}</span>
					:
					order.status == "Successful" ?
					<span className="text-sm bg-green-200 text-green-800 px-2 py-1 rounded-full">{order.status}</span>
					:
					<span className="">not completed</span>
				}
			</td>
			<td className="text-center">
				{	order.delivery_status == "Pending" ?
						<span className="text-sm bg-yellow-200 text-yellow-800 px-2 py-1 rounded-full">{order.delivery_status}</span>
					:
					order.delivery_status == "Shipped" ?
						<span className="text-sm bg-blue-200 text-blue-800 px-2 py-1 rounded-full">{order.delivery_status}</span>
					:
					order.delivery_status == "Delivered" ?
						<span className="text-sm bg-green-200 text-green-800 px-2 py-1 rounded-full">{order.delivery_status}</span>
					: ''
				}
			</td>
			<td className="text-center">
				<p className="text-sm"><b>&#8358;{nf.format(order.amount)}</b></p>
			</td>
			
			<td className="text-center">
				<p className="text-sm">{order.created_at.toString().slice(0, 16)}</p>
			</td>
			<td className="float-right mx-5">
				<Link to={`${ROUTE.ORDER_DETAILS}/${order.order_id}/${order.ref_id}`}
					className="m-2 float-right">
					<FaEye className="text-green-800 mx-2 h-5 w-5" />
				</Link>
			</td>
		</>
	);
};

OrderItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  order: PropType.object.isRequired
};

export default OrderItem;
 