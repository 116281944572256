
 import React, { useState, useEffect, StrictMode } from 'react';
 import PropType from 'prop-types';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import CacheBuster from 'react-cache-buster';
import packageInfo from '../package.json';

import { IoIosArrowUp } from 'react-icons/io';

import './App.css';
import { useScrollTop, useSetAuthPersistence } from './hooks';
import AppRouter from './routers/AppRouter';
import { Preloader } from './components/default';


function App({ store, persistor }){
  useScrollTop();
  useSetAuthPersistence(store);

  // eslint-disable-next-line no-undef
  const isProduction = false;
  
  const [showButton, setShowButton] = useState(false);
  const scrollHandler = () => {
    if (window.pageYOffset > 300) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // for smoothly scrolling
    });
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler);
    return () => window.removeEventListener('scroll', scrollHandler);
  }, []);

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<></>}
      metaFileDirectory={'.'}
    >
        <div className="p-0">
        <StrictMode>
            <Provider store={store}>
            <PersistGate loading={<Preloader />} persistor={persistor}>
                <AppRouter />
            </PersistGate>
            </Provider>
        </StrictMode>     

        {showButton && (
            <button
            onClick={scrollToTop}
            className="fixed bottom-0 right-0 z-20 flex items-center justify-center w-10 h-10 mb-8 mr-8
                overflow-hidden bg-[#9c0]  hover:bg-[#84b000] text-gray-200 transition-all ease-in-out duration-300 rounded"
            ><IoIosArrowUp />
            </button>
            )
        }
        </div>
    </CacheBuster>
  )
}

App.propTypes = {
  store: PropType.any.isRequired,
  persistor: PropType.any.isRequired
};

export default App;
