
import { useField } from 'formik';
import PropType from 'prop-types';
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import '../../pages/account/styles/profile.css'

const CustomMobileInput = (props) => {
   const [field, meta, helpers] = useField(props);
  const { label, placeholder, required } = props;
  const { touched, error } = meta;
  const { setValue } = helpers;
  
  return (
    <div className="">
		{required ?
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{label}<span className='text-red-500'> *</span>
			</label> :
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{label}
			</label>
		}
		<PhoneInput
			id={field.name}
			country={'ng'}
			inputClass="block text-gray-700 focus:ring focus:ring-green-200 focus:ring-opacity-50 
			shadow-sm border focus:border-green-300 border-gray-300 rounded py-2 px-4"
			style={{
			border: touched && error ? 'border-red-500' : 'border-green-300'
			}}
			inputExtraProps={{ required: true }}
			onChange={setValue}
			placeholder={placeholder}
			value={field.value}
		/>
		{touched && error && (
			<span className="border-red-300 text-red-500">{error}</span>
		)}
    </div>
  );
};

CustomMobileInput.defaultProps = {
  label: 'Mobile Number',
  placeholder: '8081122233',
  required: false
};

CustomMobileInput.propTypes = {
  label: PropType.string,
  required: PropType.bool,
  placeholder: PropType.string
};

export default CustomMobileInput;
 