
import React from 'react';
import { Link } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import * as ROUTE from '../../../constants/routes'
import * as CONSTANT from '../../../constants/constants'
import { useForm } from 'react-hook-form'
import swal from 'sweetalert'

const CreateAdmin = () => {
    const {register, handleSubmit, reset, formState: { errors } } = useForm();
    const submitForm = (data) => {
        let _data = {
            first_name: data.first_name.trim(),
            last_name: data.last_name.trim(),
            gender: data.gender.trim(),
            email: data.email.trim().toLowerCase(),
            mobile_number: data.mobile_number.trim(),
            user_type: CONSTANT.SUB_ADMIN_ROLE,
            password: data.password.trim()
        } 

        fetch(`${ROUTE.USER_API}/user`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(_data)
        })
        .then(res => res.json())
        .then(data => {
            if (data?.errors?.json.email) {
                errorAlert(data.errors.json.email[0])
            }
            if (data?.errors?.json?.mobile_number) {
                errorAlert(data.errors.json.mobile_number[0])
            }
            if (data?.email) {
                successAlert()
            }
        })
        .catch(err => {
            console.log(err)
        })
    }
   
    const successAlert = () => {
        return(
          swal({
              title: "Successfully added!",
              text: "Admin created.",
              icon: "success"
          }).then(function() {
            reset()
          })           
        )
    }

    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error,
              icon: "error"
          })              
        )
    }

    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_USERS}>Users</Link></span>
                    </span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">Create Admin</span>
                    </span>
                </div>
            </div>
            <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
                <h1 className="font-medium text-2xl">Create Admin</h1>
                <Link to={ROUTE.ADMIN_USERS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5" />
                </span> Back to Users</Link>
            </div>
            <div className="flex flex-col mx-3 lg:flex-row">
                <form className="w-full lg:w-3/5 bg-white shadow-md p-6">
                    <div className="flex flex-wrap -mx-3 mb-6">
                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='first_name'>First Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="first_name" placeholder="Enter First Name"
                                    {...register("first_name", { required: true })}
                                    required />
                                {errors.first_name && <small className="text-red-500 text-xs italic">First name is required</small>}
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='last_name'>Last Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="last_name" placeholder="Enter Last Name"
                                    {...register("last_name", { required: true })}
                                    required />
                                {errors.last_name && <small className="text-red-500 text-xs italic">Last name is required</small>}
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_email'>Email</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='email' name="email" placeholder="you@example.com"
                                    {...register("email", { required: true })}
                                    required />
                                {errors.email && <small className="text-red-500 text-xs italic">Email is required</small>}
                            </div>

                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='mobile_number'>Phone number</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="mobile_number" placeholder="080X XXX XXXX"
                                    {...register("mobile_number", { required: true })}
                                    required />
                                {errors.mobile_number && <small className="text-red-500 text-xs italic">Phone number is required</small>}
                            </div>
                        </div>

                        <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='password'>Password</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='password' name="password"
                                    {...register("password", { required: true })}
                                    required />
                                {errors.password && <small className="text-red-500 text-xs italic">Password is required</small>}
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <fieldset>
                                    <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='gender'>Gender</label>
                                    <div>
                                        <input type="radio" id="gender1"
                                            name="gender" value="male" className='m-2' {...register("gender", { required: true })} />
                                        <label htmlFor="gender1">Male</label>

                                        <input type="radio" id="gender2"
                                            name="gender" value="female" className='m-2' {...register("gender", { required: true })} />
                                        <label htmlFor="gender2">Female</label>
                                        {errors.vendor_gender && <small className="text-red-500 text-xs italic">Gender is required</small>}
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="w-full px-3 mb-6">
                            <button className="appearance-none w-full block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                                onClick={handleSubmit(submitForm)}>Create Admin</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateAdmin;
 