
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom'

import axios from 'axios'
import swal from 'sweetalert'
import { FaAngleLeft } from 'react-icons/fa'

import * as ROUTE from '../../../constants/routes'
import * as CONSTANT from '../../../constants/constants'

const ViewVendor = () => {
    const { id, slug } = useParams();
    const [loading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [vendorsName, setVendorsName] = useState('');
    const [vendorsDescription, setVendorDescription] = useState('');
    const [vendortImage, setVendorImage] = useState('');
    const [accountName, setAccountName] = useState('');
    const [address, setAddress] = useState('');
    const [readBank, setReadBank] = useState('');
    const [readState, setReadSate] = useState('');
    const [readCity, setReadCity] = useState('');
    const [readAccountNo, setReadAccountNo] = useState('');
    const [status, setStatus] = useState('');
    const [userId, setUserId] = useState('');
   
    const getData = () => {
        setLoading(true)
        fetch(`${ROUTE.USER_API}/user/vendors/${id}/${slug}`)
          .then((res) => res.json())
          .then((res) => {
            setFirstName(res.vendor[0].first_name)
            setLastName(res.vendor[0].last_name)
            setEmail(res.vendor[0].email)
            setPhone(res.vendor[0].phone)
            setVendorsName(res.vendor[0].business_name)
            setVendorDescription(res.vendor[0].business_description)
            setVendorImage(res.vendor[0].image)
            setAddress(res.vendor[0].business_address)
            setAccountName(res.vendor[0].business_account_name)
            setReadCity(res.vendor[0].business_city)
            setReadSate(res.vendor[0].business_state)
            setReadBank(res.vendor[0].business_bank)
            setReadAccountNo(res.vendor[0].business_account_no)
            setStatus(res.vendor[0].is_admin_verified)
            setUserId(res.vendor[0].user_id)
        })
        setLoading(false)
    }

    useEffect(() => {
        getData()
    }, [])


    const verifyVendor = useCallback(async (e, userId) => {
        e.preventDefault();
        axios.put(
            `${ROUTE.USER_API}/user/vendors/verify/${userId}`,{
                method : 'PUT',
                headers: {
                    'Content-type': 'application/json'
                }
            })
            .then(res => res)
            .then((res) => {
                setStatus(1)
                successAlert(res)
            })
            .catch(err => errorAlert(err));
    }, []);

    const unVerifyVendor = useCallback(async (e, userId) => {
        e.preventDefault();
        axios.put(
            `${ROUTE.USER_API}/user/vendors/unverify/${userId}`,{
                method : 'PUT',
                headers: {
                    'Content-type': 'application/json'
                }
            })
            .then(res => res)
            .then((res) => {
                setStatus(1)
                successAlert(res)
            })
            .catch(err => errorAlert(err));
    }, []);

    const successAlert = (response) => {
        return(
            swal({
                title: "Info!",
                text: response.data.message,
                icon: "success"
            }).then(function () {
                getData()
            })
            .catch(err => console.log(err))
        )
    }
    const errorAlert = (error) => {
        return(
            swal({
                title: "Error!",
                text: error,
                icon: "error"
            }).then( function () {
                getData()
            })     
            .catch(err => console.log(err))   
        )
    }
    
  return (
    <div className="bg-gray-100">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_VENDORS}>Vendors</Link></span>
                    </span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">View Vendor</span>
                    </span>
                </div>
            </div>
        <div className="header my-3 h-12 px-4 lg:px-10 py-8  flex items-center justify-between">
            <h1 className="font-medium text-2xl">View Vendor</h1>
            <Link to={ROUTE.ADMIN_VENDORS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5" />
                </span> Back to Vendors</Link>
        </div>
        {loading
        ? (<div className="h-full flex justify-center items-center">
                <h3 className="font-bold text-green-600 text-2xl mx-auto ">Loading...</h3>
            </div>
        )
        :
        (<div className="flex flex-col mx-3 lg:flex-row">
            <form className="w-full lg:w-3/5 bg-white shadow-md p-6">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className='flex flex-col md:flex-row w-full'>
                        <div className="w-full md:w-1/2 px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_first_name'>Vendors First Name</label>
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{firstName}</p>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_last_name'>Vendors Last Name</label>
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{lastName}</p>
                        </div>
                    </div>

                    <div className='flex flex-col md:flex-row w-full'>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_email'>Vendors email</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{email}</p>
                            </div>
                            <div className="w-full md:w-1/2 px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_phone'>Vendors Phone</label>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{phone}</p>
                            </div>
                    </div>

                    <div className='flex flex-col md:flex-row w-full'>
                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2 mr-2" htmlFor='Bank_name'>Bank Name</label>
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                            >{readBank}</p>
                        </div>
                    </div>
                    <div className='flex flex-col md:flex-row w-full'>
                        <div className="w-full md:w-1/2 px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_no'>Bank account number</label>
                            <div className='flex flex-row'>
                                <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                                >{readAccountNo}</p>
                            </div>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_account_name'>Vendors Account Name</label>
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                            >{accountName}</p>
                        </div>
                    </div>

                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_business_address'>Vendors Business Address</label>
                        <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{address}</p>
                    </div>

                    <div className='flex flex-col md:flex-row w-full'>
                        <div className="w-full md:w-1/2 px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='business_state'>State</label>                                
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed">{readState}</p>
                        </div>
                        <div className="w-full md:w-1/2 px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='vendor_last_name'>City</label>
                            <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] cursor-not-allowed" 
                            >{readCity}</p>
                        </div>
                    </div>

                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='name'>Business Name</label>
                        <p className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" name="vendor_business_name" placeholder="Enter Business Name">{vendorsName}</p>
                    </div>
                    
                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Business Description</label>
                        <div className="w-full overflow-x-auto mx-3">
                            <div className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" dangerouslySetInnerHTML={{ __html: vendorsDescription }}/>
                        </div>
                    </div>
                   
                    <div className="flex justify-center w-full px-3 mb-12">
                        <label className="cursor-pointer flex w-fit max-w-lg flex-col items-center justify-start rounded-xl border-2 border-dashed border-green-400 bg-white p-6" htmlFor='vendor_image'>Vendor Image
                            <div className="w-full mx-12 flex justify-center mb-5">
                            <img src={`${CONSTANT.IMAGE_STORE}/${vendortImage}`} className={`w-32 h-32 $`}/>
                            </div>
                        </label>
                    </div>
                    <div className="w-full flex flex-row px-3 mb-6">
                    <Link to={`${ROUTE.ADMIN_EDIT_VENDORS}/${id}/${slug}`} className="appearance-none w-1/2  block bg-gray-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-gray-600 focus:outline-none focus:bg-white focus:border-gray-500 text-center">Edit Vendor</Link>
                    { status == 1 ?
                        <button className="appearance-none w-1/2 block bg-red-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-red-600 focus:outline-none focus:bg-white focus:border-gray-500 text-center" onClick={(e) => unVerifyVendor(e, userId)}> Unverify Vendor</button>
                        :
                        <button className="appearance-none w-1/2 block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500 text-center" onClick={(e) => verifyVendor(e, userId)}> Verify Vendor</button>
                    }
                    </div>
                </div>
            </form>          
        </div> 
        )
        }      
    </div>
  )
}
export default ViewVendor;
 