
import React, { Fragment, useEffect } from 'react';
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import $ from "jquery";
import { Menu, Transition } from '@headlessui/react'
import { FaBars } from "react-icons/fa";

import { signOut } from '../../../redux/actions/authActions';

const Header = () => {
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(signOut())
  };

  useEffect(() => {
    $("[data-trigger]").on("click", function (e) {
      e.preventDefault();
      e.stopPropagation();

      var offcanvas_id = $(this).attr("data-trigger");
      $(offcanvas_id).toggleClass("show");
    });

    $(".btn-aside-minimize").on("click", function () {
      if (window.innerWidth < 768) {
        $("body").removeClass("aside-mini");
        $(".navbar-aside").removeClass("show");
      } else {
        // minimize sidebar on desktop
        $("body").toggleClass("aside-mini");
      }
    });
  }, []);

  return (
    <nav className="w-full flex flex-wrap items-center justify-between bg-white min-h-min bg-whte border-b-2 border-[#4fa607] sticky top-0 z-50">
      <div>
        <button
            className="block lg:hidden"
            data-trigger="#offcanvas_aside"
          >
            <FaBars className="w-6 h-6 ml-2 text-gray-500"/>
          </button>
      </div>
      <div className="flex flex-row items-center">
        <ul className="flex flex-row items-center">
          <li className="flex flex-row whitespace-nowrap pr-4 pl-4  rounded md:bg-transparent text-black  py-6 px-28">
            <Menu as="div" className="relative inline-block text-left">
              <div>
                <Menu.Button className="flex items-center rounded-full text-base font-normal text-gray-500 transition duration-75 group hover:bg-gray-100">
                    <Link className="hidden md:justify-center md:block dropdown-toggle" data-bs-toggle="dropdown" to="#">
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24">
                      <path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path>
                      </svg>
                    </Link>
                    <svg 
                      className="w-4 h-4" 
                      fill="currentColor" 
                      viewBox="0 0 20 20" 
                      xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" 
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                          clipRule="evenodd">
                      </path>
                    </svg>
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <>
                    <div className="py-1">
                      <form method="POST" action="#">
                        <Menu.Item>
                          <button 
                            type="submit"
                            className="flex items-center p-2 pl-11 w-full h-full text-base font-normal transition duration-75 group hover:bg-gray-100 text-red-500" 
                            onClick={() => handleLogout()}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 fill-current mr-4"><path d="m2 12 5 4v-3h9v-2H7V8z"></path><path d="M13.001 2.999a8.938 8.938 0 0 0-6.364 2.637L8.051 7.05c1.322-1.322 3.08-2.051 4.95-2.051s3.628.729 4.95 2.051 2.051 3.08 2.051 4.95-.729 3.628-2.051 4.95-3.08 2.051-4.95 2.051-3.628-.729-4.95-2.051l-1.414 1.414c1.699 1.7 3.959 2.637 6.364 2.637s4.665-.937 6.364-2.637c1.7-1.699 2.637-3.959 2.637-6.364s-.937-4.665-2.637-6.364a8.938 8.938 0 0 0-6.364-2.637z"></path></svg>
                              Logout
                          </button>
                        </Menu.Item>
                      </form>
                    </div>
                  </>
                </Menu.Items>
              </Transition>
            </Menu>
          </li>
        </ul>
      </div>
    </nav>
  )
};

export default Header ;
 