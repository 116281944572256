
import React from 'react';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/bdn_about_image_2.jpg'

function About() {
  const title = 'B E S T   D E A L S  I N   N I G E R I A ';
  const subtitle = 'About BestDealNaija';
  useDocumentTitle('About BestDealNaija | BestDealNaija');
  useScrollTop();

  return (
    <div>
     <Banner backgroundImage={bannerImage} titleText={title} subtitleText={subtitle}/> 
      <div className="px-[300px] pb-[80px] luday_wrap text-center content_about">
        <div className="text-center py-10 pt-20">
          <p className="tracking-widest text-xs text-gray-500">B E S T   D E A L S  I N   N I G E R I A </p>
          <h3 className="text-6xl luday_deals">About BestDealNaija</h3>
        </div>
        <p><span className="font-bold">BestDealNaija.com</span> provides advertisement and listings for local businesses organized by category and deals they offer. It currently offers a broad range of marketing tools including online presence, local search, display ads and direct marketing.</p>

        <p>BestDealNaija - BestDealNaija - Your guide to the best deals, discounts and retail shopping experience in NIGERIA. BestDealNaija also serves as an advertisement and marketing hub for business owners to advertise their products and services.</p>

        <h6><b>Features:</b></h6>
        * Drive traffic to business owners products, goods and services.<br />
        * Directory and guide to businesses in NIGERIA.<br />
        * Search engine optimization/marketing.<br />
        * Top ranking and visibility rating online.<br />
        * Free social media and digital promotion.<br />
        * Discounts & Deal Listing.<br />
      </div>
    </div>
  )
}
export default About;
