
import React from 'react';
const Owners_Topic3 = () => {
  return (
    <>
      <div><p className="userguide_header">Managing Orders</p></div>
      <div>
      <p className="userguide_subheader anchor_link_section" id="3.1">1. How can I view all orders in the BestDealNaija admin dashboard?</p>
<p>To view orders, click on orders on the navigation menu. You will be routed to an order page where you can view all orders as a tabulated list. Here you can view order details.</p>

      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.2">2. How can I update orders with new information?</p>
<p>It is not possible to modify order information</p>
      </div>

      <div>
        <p className="userguide_subheader anchor_link_section" id="3.3">3. How can I cancel orders in the dashboard?</p>
<p>It is not possible to cancel orders from the admin dashboard. </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.4">4. What specific privileges can I assign to each order?</p> 
<p>Privileges cannot be assigned to any order that has been placed.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.5">5. How can I monitor payments for orders?</p>     
<p>Information about all orders including payments and ... </p>  
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.6">6. How can I track the shipping status of each order?</p>    
<p>Bestdealnaija does not provide support for order tracking. Order tracking has to be from the end of the vendor.  </p>
      </div>
    </>
  )
}
export default Owners_Topic3
 