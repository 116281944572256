
import { useCallback, useEffect, useState } from 'react';

import useDidMount from './useDidMount';
import * as ROUTE from '../constants/routes';

const useOrderById = (order_id) => {      
	const [order, setOrder] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setLoading] = useState(false);
	const didMount = useDidMount(true);

	const fetchOrderById = useCallback(async () => {
		try {
			setError('');
			const requestOptions = {
				method: "GET",
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				}
			}
			setLoading(true)
			const _order =  await fetch(`${ROUTE.PRODUCTS_API}/order/id?order_id=${order_id}`, 
								requestOptions)
				.then(res => res.json())
				.then(data =>{
					return data
				})
				.catch(err => console.log(err))

			if (_order.length == 0) {
				if (didMount) {
					setError('No order for guest user.');
					setLoading(false);
				}
			} else {
				const data = _order;
				if (didMount) {
					setLoading(false);
					setOrder(data);
				}
			}
		} catch (e) {
			if (didMount) {
				setError('Failed to fetch order');
			}
		}
	}, [didMount]);

	useEffect(() => {
		if (order.length === 0 && didMount) {
			fetchOrderById();
		}
	}, []);

	return {
		order, fetchOrderById, isLoading, error
	};
};

export default useOrderById;
 