
import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useSearchParams } from 'react-router-dom';

import * as ROUTE from '../../../constants/routes';
import img from '../../../images/tradefair/tradefairbg.jpg';
import Banner  from '../../../components/default/Banner/index';
import { useDocumentTitle, useOrderByRef } from '../../../hooks';
import { clearCart } from '../../../redux/actions/cartActions';
import { OrderDetails } from '../components';

const PaystackCallback = () => {
	useDocumentTitle('My Order | BestDealNaija');
    const { cart } = useSelector((state) => ({
		cart: state.cart
	}));
	const [searchParams] = useSearchParams();
	const trxref = searchParams.get('trxref');
	const reference = searchParams.get('reference');

	if (!trxref && !reference) {
		return <Navigate to={ROUTE.CHECKOUT} />;
	}

	const { order, isLoading } = useOrderByRef(trxref, reference);
	const dispatch = useDispatch();

	if (cart.length) {
		dispatch(clearCart());
	}
	return (
		<>
			<Banner subtitleText='My Order' backgroundImage={img} />
			<OrderDetails order={order} isLoading={isLoading} />
		</>
	);
}
export default PaystackCallback;
 