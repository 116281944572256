
import { call, put } from 'redux-saga/effects';

import {ADD_ORDER, UPDATE_ORDER } from '../../constants/constants';
import { setBillingDetails } from '../actions/checkoutActions';
import { PRODUCT_API } from '../../services';
import { setOrderLoading, setOrderStatus, setRequestStatus } from '../actions/miscActions';
// import { clearCart } from '../actions/cartActions';
import { clearProfile } from '../actions/profileActions';

function* initRequest() {
	yield put(setRequestStatus(null));
	yield put(setOrderLoading(true));
}

function* checkoutSaga({type, payload}) {
	switch (type) {
		case ADD_ORDER:
			try {
				yield initRequest();
				yield put(setOrderStatus({}));
				if (Object.keys(payload.guest).length) {
					const ref_add_order = yield call(PRODUCT_API.addGuestOrder, payload.guest);
					const set_billing_details = yield put(setBillingDetails({...ref_add_order, isDone: true}));
					if (set_billing_details) {
						yield put(setOrderStatus({
							success: true,
							type: 'order',
							isError: false,
							status: 'success',
							message: 'Order added.'
						}));
						yield put(setOrderLoading(false));
					}
				}
				if (Object.keys(payload.user).length) {
					let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
					if (auth_tokens) {
						const ref_add_order = yield call(PRODUCT_API.addOrder, payload.user, {token: auth_tokens.access_token});
						const set_billing_details = yield put(setBillingDetails({...ref_add_order, isDone: true}));
						if (set_billing_details) {
							yield put(setOrderStatus({
								success: true,
								type: 'order',
								isError: false,
								status: 'success',
								message: 'Order added.'
							}));
							yield put(setOrderLoading(false));
						}
					}
				}
			} catch (e) {
				console.log(e.code)
				if(e.code === 403) { //403 implies qty are out of stock from backend.
					yield put(setOrderStatus({ 
						success: false,
						type: 'order', 
						isError: true, 
						status: 'error', 
						message: 'Some cart items are out of stock!' 
					}));
					yield put(setOrderLoading(false));
				}
				if(e.code === 401) {
					yield put(setOrderStatus({ 
						success: false,
						type: 'order', 
						isError: true, 
						status: 'error', 
						message: 'Unauthorized! Kindly login again...' 
					}));
					yield put(setOrderLoading(false));
				}
				if (e.code !== 403 && e.code != 401) {
					console.log(e.code)
					yield put(setOrderStatus({ 
						success: false,
						type: 'support', 
						isError: true, 
						status: 'error', 
						message: 'Something went wrong.'
					}));
					yield put(setOrderLoading(false));
				}
			}
		break;

		case UPDATE_ORDER:
			try {
				yield initRequest();

				const order = yield call(PRODUCT_API.updateOrderBillingAddressIdByRefId, payload.order);
				yield call(PRODUCT_API.updateOrderTypeByRefId, payload.order);
				yield put(setBillingDetails({...order, isDone: true}));
				
				// if (payload.type === 'cash') {
				// 	yield put(setRequestStatus(true));
				// 	yield put(clearCart())
				// 	yield put(setOrderStatus({ 
				// 		success: true,
				// 		type: 'order', 
				// 		isError: false, 
				// 		status: 'success', 
				// 		message: 'Your order has been confirmed.',
				// 		timeout: 5000
				// 	}));
				// }
				let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
				// Clear guest user profile to avoid state persistence if the guest signs up.
				if (!auth_tokens) {
					yield put(clearProfile());
				}
				yield put(setOrderLoading(false));
			} catch (e) {
				console.log(e);
				yield put(setOrderLoading(false));
			}
		break;

		default: {
			throw new Error('Unexpected action type.');
		}
	}
}

export default checkoutSaga;
 