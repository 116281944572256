
import React, {useState, useEffect} from 'react';
import { Link, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/free-mode';
import 'swiper/css/thumbs';

import * as CONSTANT from '../../constants/constants'
import RecommendedDeals from '../deals/FindDeal';
import * as ROUTE from '../../constants/routes';
import Banner  from '../../components/default/Banner/index';
import { useDocumentTitle } from '../../hooks';
import { Navigation, FreeMode, Thumbs, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { displayMoney } from '../../helpers/utils';
import { addToCart } from '../../redux/actions/cartActions';
import { setRequestStatus } from '../../redux/actions/miscActions';

const ProductDetails = () => {
    useDocumentTitle('Product Details | BestDealNaija');
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [vendor, setVendor] = useState('');
    const {slug} = useParams();
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const [products, setProducts] = useState([]);

    const { cart } = useSelector((state) => ({
        cart: state.cart
    }));

    const getProductsBySlug = (slug) => {
        fetch(`${ROUTE.PRODUCTS_API}/products/details/${slug}`)
        .then((res) => res.json())
        .then((res) => {
            setProducts(res.data);
            getRelatedProducts(res.data[0].product_category_slug);
            getVendorData(res.data[0].vendor_id)
        })
        
        .catch((e) => {
            console.error(e);
            });
    }
    const getRelatedProducts = (catslug) => {
        fetch(`${ROUTE.PRODUCTS_API}/categories/${catslug}/products`)
        .then((res) => res.json())
        .then((res) => {
            setRelatedProducts(res.results)
        })
    }
    const getVendorData = (vendor_id) => {
        fetch(`${ROUTE.USER_API}/user/vendors/${vendor_id}/vendor`)
            .then((res) => res.json())
            .then((res) => {
            setVendor(res.vendor[0].slug)
            })
    }

    const dispatch = useDispatch();
    const btnAddToCart = (products) => {
        dispatch(addToCart(products));
        cart?.some((cartItem) => products.id === cartItem.id) ? console.log('Item in cart') :
        dispatch(setRequestStatus({
            success: true,
            type: 'cart',
            isError: false,
            status: 'success',
            message: 'Added to cart.'
        }))
    }

    useEffect(() => {
        getProductsBySlug(slug)
        window.scrollTo(0, 0)
    }, [slug])

    let relatedProductsList = ''
    if(relatedProducts.length > 0) {
        relatedProductsList = (
            <>
            <div className="px-3 md:px-4 lg:px-12 py-[50px] pb-[80px] luday_wrap" > 
                <Swiper 
                breakpoints={{
                    0: {
                        slidesPerView: 1
                    },
                    480: {
                        slidesPerView: 2
                    },
                    768: {
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 4
                    }
                }}
                tag="section"
                wrapperTag="ul"
                slidesPerView={relatedProducts?.length}
                modules={[FreeMode, Autoplay]}
                loop={relatedProducts?.length > 4 ? true : false} 
                autoplay={{delay: 2500, disableOnInteraction: false, pauseOnMouseEnter:true}} navigation={false} className="mx-auto">
                { relatedProducts.filter((relatedProducts) => relatedProducts.id !== products.id)?.map(product =>
                    <SwiperSlide className="" key={product.id} tag="li">
                        <div className="group bg-white mx-0 px-0 py-2">
                            <div className='products '>
                                <Link to={`${ROUTE.PRODUCT_DETAILS}/${product.slug}`}  >
                                    <div className="p-[10px] pb-[5px]">
                                        <img className="lazy h-60 w-full object-cover" src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`} alt={product.name} />            
                                        <div className="gis-text pt-4">
                                            <p className="text-lg md:text-xl overflow-hidden w-full h-8 text-left lg:text-xl font-bold">{product.name}</p>
                                            <div className="flex flex-row">
                                                <p className="text-md font-medium order-first">
                                                    <span className={
                                                    product.sale_price ? "line-through font-light text-sm mr-1" : "" }>
                                                        &#8358;{displayMoney(product.price)}
                                                    </span>
                                                </p>
                                                <p className="text-md font-medium">
                                                        {product.sale_price ? (
                                                            <span>&#8358;{displayMoney(product.sale_price)}</span>
                                                        ) : (<span><br/></span>)
                                                        }
                                                </p>
                                            </div>

                                        </div>
                                    </div>
                                </Link>
                            </div> 
                        </div>
                    </SwiperSlide>
                ) }
                </Swiper>
            </div>
            </>)
    }
    else {
        relatedProductsList = (
            <div className="text-center">
                <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">
                    <h2 className="text-4xl">No related products found</h2>
                </div>
            </div>
        )
    }
  

    return (
        <>
            {products.length > 0 ?
                (
                <>
                    <Banner subtitleText={products[0].name + ' Details' }  backgroundImage= {`${CONSTANT.IMAGE_STORE}/${products[0].image_path}` }/>
                    <div className="py-2 px-[15px] md:px-[60px] lg:px-[140px]"> 
                        <div className="grid grid-cols-1 md:grid-cols-2 luday_grid_wrap py-[60px]"> 
                            <div className="group">
                                <div className="grid grid-cols-1 md:p-5 pt-2">
                                    <div className="group bg-white">
                                    <Swiper
                                        spaceBetween={10}
                                        navigation={true}
                                        thumbs={{ swiper: thumbsSwiper }}
                                        modules={[FreeMode, Navigation, Thumbs, Autoplay]}
                                        autoplay={{
                                            delay: 5000,
                                            pauseOnMouseEnter: true,
                                            disableOnInteraction: false
                                        }}
                                        className="mySwiper2"
                                    >
                                        <SwiperSlide>
                                            <div className="w-full grid place-content-center">
                                                <img className="object-cover w-[300px] h-[300px] bg-black" src={`${CONSTANT.IMAGE_STORE}/${products[0].image_path}`} alt={products[0].name} />
                                            </div>
                                        </SwiperSlide>
                                        {products[0].products_gallery &&
                                        products[0].products_gallery.map(product_gallery =>
                                            <SwiperSlide key={product_gallery.id}>
                                                <div className="w-full grid place-content-center">
                                                    <img className="object-cover w-[300px] h-[300px] " src={`${CONSTANT.IMAGE_STORE}/${product_gallery.path}`} alt={product_gallery.name} />
                                                </div>
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                    <Swiper
                                        onSwiper={setThumbsSwiper}
                                        spaceBetween={10}
                                        slidesPerView={4}
                                        freeMode={true}
                                        watchSlidesProgress={true}
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        className="mySwiper mt-2"
                                    >
                                        <SwiperSlide className='border-solid border-2'>
                                            <img className="object-cover w-[150] h-[70px] md:h-28" src={`${CONSTANT.IMAGE_STORE}/${products[0].image_path}`} alt={products[0].name} />
                                        </SwiperSlide>
                                        {products[0].products_gallery &&
                                        products[0].products_gallery.map(product_gallery =>
                                            <SwiperSlide key={product_gallery.id}>
                                                <img className="object-cover  w-[150] h-[70px] md:h-28" src={`${CONSTANT.IMAGE_STORE}/${product_gallery.path}`} alt={product_gallery.name} />
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                </div>
                            </div> 
                            </div>
                            <div className="group md:pl-2">
                                <p className="tracking-widest mb-2.5 text-xs ">B E S T  &nbsp;  D E A L S </p>
                                <h3>{products[0].name}</h3>
                                <div className="inline-grid grid-cols-3 gap-3 whitespace-nowrap">
                                    <p className="text-[#9c0] text-xl pb-2"><b>Price:</b></p>
                                        {products[0].sale_price ? (
                                            <p className="text-[#9c0] text-xl pb-2">&#8358;{products[0].sale_price.toLocaleString()}</p>
                                        ) : (<span><br/></span>)
                                    }
                                    <p className={
                                        products[0].sale_price ? "line-through font-light -ml-2 text-gray-400 text-xl pb-2" : " -ml-16 text-[#9c0] text-xl pb-2" }>
                                        &#8358;{products[0].price.toLocaleString()}
                                    </p>
                                
                                </div>
                                <div className="h-auto mt-5" dangerouslySetInnerHTML={{ __html: products[0].description }} />
                                <p className="my-4"><Link to={`${ROUTE.VENDORDETAILS}/${vendor}`} className="py-3 text-gray-500">Visit Vendors</Link></p>
                                <div className="button-wrap">
                                    <button 
                                        onClick={() => btnAddToCart(products[0])} 
                                        className="w-full mt-9 font-semibold leading-none text-white py-4 px-10 bg-[#98c01d] rounded-md hover:bg-[#88af14] focus:ring-2 focus:ring-offset-2 focus:outline-none cursor-pointer md:w-2/3">
                                            Add to cart
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                )
            :(
                <div className="text-center">
                    <div className="px-3 md:px-10 lg:px-36 py-[200px] pb-[80px] luday_wrap">
                        <h2 className="text-2xl">Product/Service not found or have been removed by sell/provider</h2>
                    </div>
                </div>
            )}
            <div className="justify-center mx-auto text-center mb-10">Click <Link to={`${ROUTE.SIGNUP_VENDOR}`} className="text-[#88af14]"> HERE</Link> to start SELLING your PRODUCTS now </div>
            <section className="bg-gray-100">
                <div className="px-3 md:px-10 luday_wrap">
                    <div className="text-center py-5">
                        <p className="tracking-widest text-xs text-gray-500">B E S T  &nbsp;  D E A L S  &nbsp;  I N  &nbsp;  N I G E R I A</p>
                        <h3 className="text-6xl luday_deals">Related Products</h3>
                        <section className="bg-gray-100">
                            { relatedProductsList }
                        </section>
                    </div>
                </div>
            </section>
            <RecommendedDeals />
        </>
    );  
}
export default ProductDetails;
 