
import React from 'react';
import { NavLink } from "react-router-dom";
import * as ROUTE from '../../../../constants/routes';

const AccNavigation = () => {
    return (
        <ul>
            <li className="flex flex-row m-5"> 
                <NavLink to={ROUTE.PROFILE} className="flex flex-row whitespace-nowrap items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M7.5 6.5C7.5 8.981 9.519 11 12 11s4.5-2.019 4.5-4.5S14.481 2 12 2 7.5 4.019 7.5 6.5zM20 21h1v-1c0-3.859-3.141-7-7-7h-4c-3.86 0-7 3.141-7 7v1h17z"></path></svg>
                &nbsp; Profile </NavLink>
            </li>
            <li className="flex flex-row m-5"> 
                <NavLink to="/change-password" className="flex flex-row whitespace-nowrap items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="m18.988 2.012 3 3L19.701 7.3l-3-3zM8 16h3l7.287-7.287-3-3L8 13z"></path><path d="M19 19H8.158c-.026 0-.053.01-.079.01-.033 0-.066-.009-.1-.01H5V5h6.847l2-2H5c-1.103 0-2 .896-2 2v14c0 1.104.897 2 2 2h14a2 2 0 0 0 2-2v-8.668l-2 2V19z"></path></svg>
                &nbsp; Change Password </NavLink>
            </li>
            <li className="flex flex-row m-5"> 
                <NavLink to="/order-history" className="flex flex-row whitespace-nowrap items-center"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"><path d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zm-1 4v2h-5V7h5zm-5 4h5v2h-5v-2zM4 19V5h7v14H4z"></path></svg>
                &nbsp;My Order History </NavLink>
            </li>
        </ul>
    );
};

export default AccNavigation;
 