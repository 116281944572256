
import React from 'react';
import image1_1 from '../../images/imgs_userguide/image1_1.png';
import image1_2 from '../../images/imgs_userguide/image1_2.png';
import image1_5 from '../../images/imgs_userguide/image1_5.png';
import image1_5b from '../../images/imgs_userguide/image1_5b.png';

const Topic = () => { 
  return (
    <>
      <div>
        <p className="userguide_header">Overview of BestDealNaija</p>
        <p>BestDealNaija provides local businesses in Nigeria with the marketing tools they need to 
        increase their online visibility. We provide a variety of services such as online presence, local search, 
        display ads, direct marketing, and discounts. Our goal is to assist local businesses in promoting their 
        products and services as well as increasing website traffic. We strive to offer our users the best deals 
        and discounts, as well as the most enjoyable shopping experience in Nigeria.
        </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.1">How to get started:</p>

        <p className="userguide_subheader anchor_link_section" id="1.2">Step 1: Sign up for an account</p>
        <p>Visit BestDealNaija and click on the <b>Sign Up</b> button to create an account. You will need to provide 
        basic information such as your name, email address, and password. Once your account is created, you can 
        start using the site. </p>

        <img src={image1_1} style={{width:"80%"}} className="text-success py-3" />

        <p className="userguide_subheader anchor_link_section" id="1.3">Step 2: Search for deals and discounts</p>
        <p>Once you are logged in, you can search for deals and discounts on BestDealNaija. You can search by category, location, and more. You can also search for specific products and services. 
        </p>
        <img src={image1_2} style={{width:"80%"}} className="text-success py-3" />
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.4">Step 3: Browse and select deals</p>
        <p>Once you have found the deals you want, you can browse and select them. You can then purchase the deals 
        directly from the site or contact the business directly for more information.
        </p>
        <p className="userguide_subheader anchor_link_section" id="1.5">Step 4: Promote your business</p>
        <p>If you own a business, you can use BestDealNaija to promote it. You can create a profile for your business, 
        list your products and services, and offer discounts and deals to attract customers. You can also use the sites 
        marketing tools to reach new customers and build your brand.</p>
      </div>

      <div>
        <p className="userguide_subheader anchor_link_section" id="1.6">1. How can I find a product on BestDealNaija?</p>
        <p>To find products on Bestdealnaija, browse through the categories. Products on bestdealnaija are 
        categorized, making it easy to find products of a particular type by looking through the category the 
        product belongs to. Additionally, you can search for products by entering the product name into the search 
        box on the products page.</p>
        <img src={image1_5} style={{width:"80%"}} className="text-success py-3" />
        <br /><br />
        <img src={image1_5b} style={{width:"80%"}} className="text-success py-3" />
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.7">2. How do I search for deals and discounts?</p>
        <p>You can search for deals and discounts by navigating through respective categories under the 
        recommended deals section on the home page. You can also find deals by clicking on the “Find your deal” 
        link on the sites navigation menu.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.8">3. How can I create my own listing?</p>
        <p>To create your own listing you need to be a registered vendor on bestdealnaija. Once you have been 
        verified as a vendor on bestdealnaija you can start posting products from your vendor account dashboard. 
        To learn more about being a vendor see the section on becoming a vendor</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.9">4. How do I pay for products on BestDealNaija?</p>
        <p>You can pay for products on bestdealnaija in a variety of ways including using a debit card, 
        USSD Code or a Bank Transfer. To pay for a product, add the product to your shopping cart, then click 
        on checkout. This will send you to a page where you can select your preferred payment option.</p>
      </div>

      <div>
        <p className="userguide_subheader anchor_link_section" id="1.10">5. How can I contact customer service?</p>
        <p>You can contact customer service by email, or Phone call. 
        Our customer support line is [INSERT:contact], our support email is [INSERT:email].</p>
      </div>

      <div>
        <p className="userguide_subheader anchor_link_section" id="1.11">6. How do I change my account settings?</p>
        <p>You can change your account settings from your dashboard. 
        To change your account settings login to your account with your email and password. Once logged in you 
        can change your password and Edit your profile information. </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="1.12">7. What are the terms and conditions for using BestDealNaija?</p>
        <p>The terms and conditions for using Bestdealnaija can be found by navigating to the terms and conditions page. 
        [Link: Terms and Conditions].</p>
      </div>
    </>
  )
}
export default Topic
 