
import React from 'react';

import PropType from 'prop-types';
import { useField } from 'formik';

const CustomFileInput = (props) => {
	const [field, meta] = useField(props);
	const { label } = props;
	const { touched, error } = meta;
  
	return (
		<div className="">
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{label} {field.required && (<span className='text-red-500'> *</span>)}
			</label>
			<input
				type="file"
				hidden
				accept="image/png,image/x-png,image/jpeg,image/webp"
				className={`block w-full text-gray-700 cursor-pointer bg-gray-50 focus:ring focus:ring-green-200 focus:ring-opacity-50 
					shadow-sm border focus:border-green-300 border-gray-300 rounded px-4
					${touched && error && 'border-red-500'}`}
				{...field}
				{...props}
			/>
			{touched && error && (
				<span className="border-red-300 text-red-500">{error}</span>
			)}
		</div>
	); 
};
  
CustomFileInput.propTypes = {
	label: PropType.string
};

export default CustomFileInput;
 