
import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { useSelector } from "react-redux";

import * as ROUTE from '../../constants/routes'
import { displayMoney, calculateArrayTotal } from '../../helpers/utils';
const CartSummary = () => {
	const { cart } = useSelector((state) => ({
		cart: state.cart
	}));
	const { pathname } = useLocation();

	return (
		<div className="w-full md:w-1/4 m-2 border border-black-600 drop-shadow-md p-5 h-1/2">
			<h2 className="text-2xl mb-5">Cart Summary</h2>
			<p>VAT <span>&#8358;0.00</span></p>
			<p className="mb-5">Subtotal <br />
				<span>
					&#8358;{displayMoney(calculateArrayTotal(cart.map((product) => 
							product?.sale_price ? product.sale_price * product.cart_quantity
								: product.price * product.cart_quantity
						))
					)}
				</span>
			</p>
			<hr/>
			<h2 className="text-2xl mt-5">Grand Total</h2>
			<h2 className="text-3xl mt-5 font-bold">
				&#8358;{displayMoney(calculateArrayTotal(cart.map((product) => 
						product?.sale_price ? product.sale_price * product.cart_quantity
							: product.price * product.cart_quantity
					))
				)}
			</h2>
			<div className="mt-4 mx-0">
				{pathname == ROUTE.CART ? (
					<Link to={ROUTE.PRODUCTS} className="button button--theme overflow-hidden [#490] border border-[#9c0]-600
						text-[#98c01d] text-sm transition-all ease-in-out duration-300 
						rounded py-3 px-5 my-2 flex items-center flex-nowrap">
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" ><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
					<span>Continue Shopping</span>
					</Link>
					) :(
					<Link to={ROUTE.CART} className="button button--theme overflow-hidden [#490] border border-[#9c0]-600
						text-[#98c01d] text-sm transition-all ease-in-out duration-300 
						rounded py-3 px-5 my-2 flex items-center flex-nowrap">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" ><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
						<span>Back to Cart</span>
					</Link>
				)}
				{pathname == ROUTE.CART && (
					<Link to={ROUTE.CHECKOUT} className="button button--theme overflow-hidden bg-[#98c01d] 
							text-white transition-all ease-in-out duration-300 
							rounded py-3 px-5 my-2 flex items-center flex-nowrap">
						<span>Proceed to Checkout</span>
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current">
							<path d="m11.293 17.293 1.414 1.414L19.414 12l-6.707-6.707-1.414 1.414L15.586 11H6v2h9.586z"></path>
						</svg>
					</Link>
				)}
			</div>
		</div>
	)
}
export default CartSummary;
 