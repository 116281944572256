
/* eslint-disable react/prop-types */
/* eslint-disable no-extra-boolean-cast */
import React, { useEffect } from 'react';

import { Field, useField, useFormikContext } from 'formik';

async function fetchAccountName(acctNo, bankCode) {
	await new Promise((r) => setTimeout(r, 500));
	const requestOptions = {
		method: "GET",
		headers: {
			'Content-Type':'application/json',
			'Authorization': `Bearer ${process.env.REACT_APP_PAYSTACK_SECRET_KEY}` // eslint-disable-line
		}
	}
	const response = await fetch(`https://api.paystack.co/bank/resolve?account_number=${acctNo}&bank_code=${bankCode}`, requestOptions)
			.then(res => res.json())
			.then(data =>{
				return data
			})
			.catch(err => console.log(err))
	if (response && response.status == true) {
		return response.data.account_name;
	} else {
		alert('Account name not found.')
		return ''
	}
}

const CustomBankAccountNameInput = (props) => {
    const {
		values: { bank_name, bank_acct_number },
		setFieldValue
	} = useFormikContext();
	const [field, meta] = useField(props);

	useEffect(() => {
		let isCurrent = true;
		if (bank_name?.value !== '' && bank_acct_number.length == 10) {
			fetchAccountName(bank_acct_number, bank_name.value).then((bank_account_name) => {
				if (!!isCurrent) {
					// prevent setting old values
					setFieldValue(props.name, bank_account_name);
				}
			});
		}
		return () => {
			isCurrent = false;
		};
	}, [bank_acct_number, bank_name, setFieldValue, props.name]);

	return (
		<>
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{"Bank Account Name"}
			</label>
			<div className="flex flex-col items-start">
				<Field
					disabled={true}
					type="text"
					name={field.name}
					className={`block w-full text-gray-700 focus:ring focus:ring-green-200 focus:ring-opacity-50 
						shadow-sm border focus:border-green-300 border-gray-300 rounded py-2 px-4
						${!!meta.touched && !!meta.error && 'border-red-500'}`}
					{...field}
					{...props}
				/>
				{!!meta.touched && !!meta.error && (
					<span className="border-red-300 text-red-500">{meta.error}</span>
				)}
			</div>
		</>
	);
};

export default CustomBankAccountNameInput;
 