
import React, { useEffect, useState } from 'react'
import PropType from 'prop-types'
import { Link } from 'react-router-dom'
import * as ROUTE from '../../../constants/routes'
import { FaAngleLeft } from 'react-icons/fa'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import swal from 'sweetalert'
import { useSelector } from 'react-redux'

const EditGroup = ({ products, setEditProducts, fetchProducts, setselectedProducts }) => {
    const [categories, fetchCategories] = useState([]);
    const [editedProducts, setEditedProducts] = useState(products.map((product) => ({ 
        id: product.id,
        name: product.name,
        quantity: `${product.quantity}`,
        category_id: `${product?.product_category_id}`
    })));
    const { token } = useSelector((state) => ({ token: state.profile.access_token }));

    const handleInputChange = (index, field, value, editor) => {
        const updatedProducts = [...editedProducts];
        
        if (value === '') {
            updatedProducts[index] = {
                ...updatedProducts[index],
                [field]: ' '
            };
        } else if (field === 'description') {
            updatedProducts[index] = {
                ...updatedProducts[index],
                [field]: editor.getData()
            };
        } else {
            updatedProducts[index] = {
                ...updatedProducts[index],
                [field]: value
            };
        }
        setEditedProducts(updatedProducts);
    };

    const getCategories = () => {
        fetch(`${ROUTE.PRODUCTS_API}/categories`)
          .then((res) => res.json())
          .then((res) => {
            fetchCategories(res.results)
          })
    }

    useEffect(() => {
        getCategories()
    }, [])

    const submitForm = (async () => {
        const requestOptions = {
            method: "PUT",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify({
                products: editedProducts
            })
        }
        await fetch(`${ROUTE.PRODUCTS_API}/admin/products/update`, requestOptions)
            .then(res => {
                res.json()
                fetchProducts()
                successAlert()
            })
            .catch(err => {
                errorAlert(err)
            })
    })

    const successAlert = () => {
        return(
          swal({
              title: "Info!",
              text: "Products updated!",
              icon: "success"
          }).then(function () {
            setselectedProducts([])
            setEditProducts(false)
          })
        )
    }
    const errorAlert = (error) => {
        return(
          swal({
            title: "Error!",
            text: error?.response?.data?.message,
            icon: "error"
          }).then(function () {
            // fetchProducts()
          })          
        )
    }

  return (
    <div className="bg-gray-100">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
            <div className="flex items-center space-x-2 text-gray-400">
            <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
            <span>/</span>
            <span className="tracking-wide text-md">
                <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_PRODUCTS}>Products</Link></span>
            </span>
            <span>/</span>
            <span className="tracking-wide text-md">
                <span className="text-base">Edit Products</span>
            </span>
            </div>
        </div>
        <div className="header my-3 h-12 px-4 lg:px-10 py-8  flex items-center justify-between">
            <h1 className="font-medium text-2xl">Edit Products</h1>
            <Link onClick={() => setEditProducts(false)} to='' className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
            </span> Back to products</Link>
        </div>

        <div className="flex !flex-col gap-8 mx-3 lg:flex-row">

            {products?.map((product, index) => {
                return (
                    <form key={index} className="w-full lg:w-3/5 bg-white shadow-md p-6">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='name'>Product Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" type='text' name="name" placeholder="Enter product Name"
                                value={editedProducts[index]?.name || product.name} required onChange={(e) => handleInputChange(index, 'name', e.target.value)} />
                                {/* {errors.product_name && <small className="text-red-500 text-xs italic">Product name is required</small>} */}
                            </div>

                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category'>Category</label>
                                <select className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d] invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500" name="category_id"
                                onChange={(e) => handleInputChange(index, 'category_id', e.target.value)} value={editedProducts[index]?.product_category_id || product.product_category_id}
                                required>
                                    {categories.map((item, i) => {
                                        return (<option className="my-2 hover:bg-gray-50" key={i} value={item.id}> {item.name} </option>)
                                    })}
                                </select>
        
                                {/* {errors.category && <small className="text-red-500 text-xs italic">Select at least one category</small>} */}
                            </div>
        
                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='price'>Price</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="price" placeholder="Enter price"
                                value={editedProducts[index]?.price || product.price} required onChange={(e) => handleInputChange(index, 'price', e.target.value)} />
                                {/* {errors.price && <small className="text-red-500 text-xs italic">Price is required</small>} */}
                            </div>
        
                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='sale_price'>Discounted Price</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="sale_price" placeholder="Enter price"
                                value={editedProducts[index]?.sale_price || product.sale_price} onChange={(e) => handleInputChange(index, 'sale_price', e.target.value)} />
                            </div>

                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='quantity'>Count in stock</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='number' name="quantity" placeholder="Enter count in stock"
                                value={editedProducts[index]?.quantity || product.quantity} onChange={(e) => handleInputChange(index, 'quantity', e.target.value)} />
                                {/* {errors.quantity && <small className="text-red-500 text-xs italic">Count in stock is required</small>} */}
                            </div>
                            
                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Description</label>
                                <CKEditor 
                                    editor={ClassicEditor}
                                    onChange={(e, editor) => handleInputChange(index, 'description', e, editor)} data={editedProducts[index]?.description || product.description} className="w-full"
                                />
                                {/* {errors.description && <small className="text-red-500 text-xs italic">Description is required</small>} */}
                            </div>
                        </div>
                    </form>
                )
            })}
            <div className="w-full px-3 mb-6">
                <button className="appearance-none w-full block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                onClick={submitForm}
                >Update Products</button>
            </div>           
        </div>        
    </div>
  )
}

EditGroup.propTypes = {
    products: PropType.array.isRequired,
    fetchProducts: PropType.func.isRequired,
    setEditProducts: PropType.func.isRequired,
    setselectedProducts: PropType.func.isRequired
};

export default EditGroup;
 