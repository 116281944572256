
import React, {useEffect, useState, useCallback} from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios'
import swal from 'sweetalert'
import { ImCheckmark } from 'react-icons/im'
import { MdInfoOutline } from 'react-icons/md'

import * as ROUTE from '../../../constants/routes'
import * as CONSTANT from '../../../constants/constants'

const VerifyProduct = () => {
    const [isLoading, setLoading] = useState(false);
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState();
    const [prevPage, setPrevPage] = useState();

    const onNext = () => {
        if (nextPage != null) {
            fetchUnverifiedProducts(nextPage);
        }
    };

    const onPrevious = () => {
        if (prevPage != null) {
            fetchUnverifiedProducts(prevPage);
        }
    };

    const onPageChange = (page) => {
        if (page != null) {
            fetchUnverifiedProducts(page);
        }
    };

    const fetchUnverifiedProducts = (page) => {
        setLoading(true)
        fetch(`${ROUTE.PRODUCTS_API}/products/unverified?pagination=1&page=${page}`)
            .then((res) => res.json())
            .then((res) => {
                setProducts(res)
                setNextPage(res.next_page)
                setPrevPage(res.prev_page)
                setCurrentPage(res.current_page)
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setLoading(false)
            })
    }
  
    const first_item_on_page_number = (products?.current_page * products?.items_per_page) - products?.items_per_page + 1;
    const last_item_on_page_number = first_item_on_page_number + products?.results?.length - 1;

    useEffect(() => {
        fetchUnverifiedProducts(currentPage)
    }, [])

    const verifyProduct = useCallback( async (id)  => {
        axios.put(
        `${ROUTE.PRODUCTS_API}/products/${id}/verify`,{
            method : 'PUT',
            body : JSON.stringify({
                id : id
            }),
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => res)
        .then(data =>{
          successAlert(data)
        })
        .catch(err => errorAlert(err))
    }, []);

    const verifyAll = useCallback( async ()  => {
        axios.put(
        `${ROUTE.PRODUCTS_API}/products/verify/all`,{
            method : 'PUT',
            headers: {
                'Content-type': 'application/json'
            }
        })
        .then(res => res)
        .then(data =>{
            successAlert(data)
        })
        .catch(err => errorAlert(err))
    }, []);

    const successAlert = (response) => {
        return(
            swal({
                title: "Info!",
                text: response.data.message,
                icon: "success"
            }).then(function () {
                fetchUnverifiedProducts(currentPage)
            })
        )
    }

    const errorAlert = (error) => {
        return(
            swal({
                title: "Error!",
                text: error,
                icon: "error"
            }).then(function () {
                fetchUnverifiedProducts(currentPage)
            })          
        )
    }
    
    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_PRODUCTS}>Products</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">Verify Products</span>
                    </span>
                </div>
            </div>
            <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
                <h1 className="font-medium text-2xl">Verify Products</h1>
                <button onClick={() => verifyAll()} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"><span><ImCheckmark className="inline-block w-5 h-5"/></span>Verify all</button>
            </div>
            <div className="flex flex-col mx-3 lg:flex-row">
                <div className="w-full m-4 bg-white shadow-lg text-lg rounded-sm border border-gray-200">
                <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3"></th>
                                <th scope="col" className="px-6 py-3">Product</th>
                                <th scope="col" className="px-6 py-3">SKU</th>
                                <th scope="col" className="px-6 py-3">Price</th>
                                <th scope="col" className="px-6 py-3">Categories</th>
                                <th scope="col" className="px-6 py-3">Status</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                                {!isLoading && products?.results?.length <= 0 && (
                                    <>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td colSpan={7}>
                                        <div className="flex flex-col justify-center items-center my-4 space-y-3">No unverified product</div>
                                        </td>
                                    </tr>
                                    </>
                                )}
                                {!isLoading && products?.results?.length >= 1 && products?.results?.map((product, index) => (
                                    <>
                                        <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                            <td className="px-6 py-4">
                                                <img src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`} className="w-8 h-8 mx-auto overflow-hidden" alt={product.name} />
                                            </td>
                                            <td className="px-6 py-4">{product.name}</td>
                                            <td className="px-6 py-4">{product.sku}</td>
                                            <td className="px-6 py-4">
                                                <span className={product.sale_price ? "line-through mr-1" : "" }>
                                                    { product.price }
                                                </span> { product.sale_price ? product.sale_price : ''}
                                            </td>
                                            <td className="px-6 py-4">{product.product_category}</td>
                                            <td className="px-6 py-4">
                                                {product.is_verified == 1 
                                                    ? 
                                                        <div className="flex items-center">
                                                            <div className="h-2.5 w-2.5 rounded-full bg-green-500 me-2"></div> Verified
                                                        </div>
                                                    : 
                                                        <div className="flex items-center">
                                                            <div className="h-2.5 w-2.5 rounded-full bg-red-500 me-2"></div> Unverified
                                                        </div>
                                                }
                                            </td>
                                            <td className="flex items-center px-6 py-4">
                                                <Link to={`${ROUTE.ADMIN_VIEW_PRODUCT}/${product.id}`} className="rounded-md hover:bg-gray-100 text-green-600 p-2 flex justify-between items-center">
                                                    <span><MdInfoOutline className="w-4 h-4 mr-1"/></span> View
                                                </Link>
                                                <button className="rounded-md text-green-600 hover:bg-gray-200 p-2 flex justify-between items-center" value={product.id} onClick={() => verifyProduct(product.id)}>
                                                    <span><ImCheckmark className="w-4 h-4 mr-1" /></span> Verify
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                ))}
                                {isLoading && (
                                    <>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td colSpan={7}>
                                        <div className="flex flex-col justify-center items-center my-4 space-y-3">Fetching records...</div>
                                        </td>
                                    </tr>
                                    </>
                                )}
                            </tbody>
                        </table>
                        {!isLoading && products?.results?.length >= 1 && (
                            <nav
                                className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
                                aria-label="Table navigation"
                            >
                                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                                    Showing{" "}
                                    <span className="font-semibold text-gray-900 dark:text-white">
                                    {`${first_item_on_page_number}-${last_item_on_page_number}`}
                                    </span> of <span className="font-semibold text-gray-900 dark:text-white">{products?.total_records}</span>
                                </span>
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    {
                                        products?.prev_page && (
                                            <li>
                                                <a onClick={onPrevious}
                                                    className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                                >
                                                    Previous
                                                </a>
                                            </li>
                                        )
                                    }
                                    {products?.iter_pages.map((iter_page) => (
                                        <>
                                            {iter_page != null ?
                                            <li>
                                                <a onClick={() => onPageChange(iter_page)}
                                                    className={`${products?.current_page === iter_page ? 'flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                                    : 'flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                                                >
                                                    {iter_page}
                                                </a>
                                            </li> : 
                                            <li>
                                                <a href="#"
                                                    className='flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                                >
                                                    {'...'}
                                                </a>
                                            </li>
                                            }
                                        </>
                                    ))}
                                    {
                                        products?.next_page && (
                                            <li>
                                                <a onClick={onNext}
                                                    className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                                >
                                                    Next
                                                </a>
                                            </li>
                                        )
                                    }
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>                
            </div>        
        </div>
    )
}
export default VerifyProduct;
 