
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from 'react-redux';
import * as ROUTE from '../../../constants/routes';
import { useDocumentTitle } from "../../../hooks";
import { displayMoney } from "../../../helpers/utils";
import * as CONSTANT from '../../../constants/constants';

const ViewOrder = () => {
    const { id, ref } = useParams();
	const { token } = useSelector((state) => ({
		token: state.profile.access_token
	}));
	const [order, setOrder] = useState([]);
	const [orderInfo, setOrderInfo] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [totalSum, setTotalSum] = useState('');
	//const { order, isLoading } = useOrder(id, token);

	useEffect(() => {
		(async () => {
			try {
				const requestOptions = {
					method: "GET",
					headers: {
						'Content-Type': 'application/json',
						'Accept': 'application/json',
						'Authorization': `Bearer ${token}`
					}
				}
				await fetch(`${ROUTE.PRODUCTS_API}/order/mapping/${id}`, requestOptions)
				.then((res) => res.json())
				.then((res) => {
				//console.log(res.length)
				setOrder(res);
				setOrderInfo(res[0])
				setTotalSum((res.reduce((a,v) =>  a = a + v.product_sale_price , 0 )))
				setLoading(false)
				});
			} catch (err) {
				
					setLoading(false);
					setError(err?.message || 'Something went wrong.');
				
			}
		})();
	}, [id, token]);

	useDocumentTitle('My Profile | BestDealNaija');
	let OrderItemlist = ''
    if(order.length > 0){
        OrderItemlist =  (
            <>
			{order.map(item => 
				<tr key={item.id} className="border border-black-600 whitespace-nowrap">
					<td className="items-left">
						<div className="flex flex-row">
								<div className=" py-2 h-30 w-32">
									<img className="h-32 w-32" src={`${CONSTANT.IMAGE_STORE}/${item.product_image_path}`}/> 
								</div>
								<div className=" py-2">
										<h2 className="px-6 text-left text-lg w-[300px] overflow-hidden text-ellipsis md:text-xl">{item.product_name}</h2>
								</div>
						</div>
					</td>
					<td className="text-center">
						{item.order_product_mapping_quantity}
					</td>
					<td className="text-center">&#8358;{displayMoney(item.order_product_mapping_price)}</td>
				</tr>
				)}
			</>
        )
    } else {
		OrderItemlist =  (
        <>
			<tr className="border border-black-600 whitespace-nowrap">
				<p className="text-center">Loading...</p>
			</tr>
		</> 
	)}

	return (
		<>
			{order && !isLoading ?(
				<>
					<div className="">
						<h4 className="mb-2">My Order</h4>
						<div className="flex flex-col space-y-2">
							<p>Order id: {ref}</p>
							<p>Order status: 
								{orderInfo.status == "Pending" ?
										<span className="bg-yellow-200 text-yellow-800 px-2 py-1 rounded-full">{orderInfo.status} or Cash delivery</span>
									:
									orderInfo.status == "Failed" ?
										<span className="bg-red-200 text-red-800 px-2 py-1 rounded-full">{orderInfo.status}</span>
									:
									orderInfo.status == "Successful" ?
									<span className="bg-green-200 text-green-800 px-2 py-1 rounded-full">{orderInfo.status}</span>
									:
									<span className="">loading...</span>
								}
							</p>
							
							<p>Date: {orderInfo.created_at}</p>
						</div>
						<hr/><br/>
					</div>
					<div className="overflow-x-auto">
						<table className="divide-y divide-gray-300 md:w-full">
							<thead className="bg-gray-50" >
								<tr>
									<th className="px-6 py-2 text-left">Product</th>
									<th className="px-6 py-2 text-center">Quantity</th>
									<th className="px-6 py-2 ">Price</th>
								</tr>
							</thead>
							<tbody>
							{OrderItemlist}
							</tbody>
						</table>
					</div>
					<div className="float-right">
					<p className="text-2xl mt-5 ">Grand Total
						<span className=" ml-1">&#8358;{displayMoney(totalSum)}</span></p>
					</div>
				</>
			):
				<p>Loading..... {error}</p>
			}
		</>
	);
};

export default ViewOrder;
 