
import React, {useState, useEffect} from 'react';
import img from '../../images/tradefair/tradefairbg.jpg';
import * as CONSTANT from '../../constants/constants';
import * as ROUTE from '../../constants/routes';
import Banner  from '../../components/default/Banner/index';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import RecommendedDeals from '../deals/FindDeal';
import { Link, useParams} from 'react-router-dom';

const VendorDetails = () => {
  useDocumentTitle('Vendor Details| BestDealNaija');
  useScrollTop();

  const {slug} = useParams();
  const [vendors, fetchVendors] = useState([]);

  const getData = () => {
    //setLoading(true)
  fetch(`${ROUTE.USER_API}/user/vendors/${slug}`)
    .then((res) => res.json())
    .then((res) => {
        fetchVendors(res.vendor[0])
        //console.log(res.vendor)
        //setLoading(false)
    })
  }
  useEffect(() => {
    getData()
  }, []);

  
  return (
    <>
      <Banner subtitleText={vendors.business_name + ' Details'} backgroundImage={img} />
    
      <div className="py-2 px-[25px] md:px-[60px] lg:px-[140px]">                    
          <div className="grid grid-cols-1 md:grid-cols-2 luday_grid_wrap py-[100px]">		
            <div className="group">
              <p className="tracking-widest mb-2.5 text-xs ">B E S T  &nbsp;  D E A L S  &nbsp;  I N  &nbsp; N I G E R I A || <Link to={ROUTE.VENDORS} className="py-3 text-gray-500">View All Vendors</Link></p>
              <h3>{vendors.business_name}</h3>
              <div className="my-4 px-5" dangerouslySetInnerHTML={{ __html: vendors.business_description }}></div>
              <p className="my-4 pb-2"><Link to={`${ROUTE.VENDOR_PRODUCTS_VIEW}/${vendors.slug}`} className="py-3 text-gray-500">View all products from this Vendor</Link></p>	
            </div>		
            <div className="group md:gap-4">
              <img className="lazy w-full" src={`${CONSTANT.IMAGE_STORE}/${vendors.image}`} alt={vendors.business_name} />
            </div>
          </div>
      </div>
      <RecommendedDeals />
    </>
  );  
}
export default VendorDetails;
 