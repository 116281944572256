
import { 
  CLEAR_PROFILE, SET_PROFILE, 
  UPDATE_PROFILE_SUCCESS,UPDATE_PROFILE_ADDRESS_SUCCESS
} from '../../constants/constants';

export default (state = {}, action) => {
  switch (action.type) {
    case SET_PROFILE:
      return {
        ...state,
        ...action.token,
        ...action.payload
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_PROFILE_ADDRESS_SUCCESS:
      return {
        ...state,
        ...action.payload.user,
        ...action.payload.address
      };
    case CLEAR_PROFILE:
      return {};
    default:
      return state;
  }
};
 