
import React from 'react';
const Owners_Topic2 = () => { 
  return (
    <>
      <div><p className="userguide_header anchor_link_section" id="2.1">Managing Vendors</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.2">1. How can I view all current vendors in the BestDealNaija admin dashboard?</p>
<p>To view all current vendors in the BestDealNaija admin dashboard, click on “Vendors” in the left sidebar. Here, you will have a list of all current vendors, where you can view their profiles and account details.
</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.3">2. How can I add or remove vendors from the dashboard?</p>
<p>You cannot add a vendor from the admin dashboard. A vendor account has to be created from the vendor registration portal. To remove vendors from the dashboard, click on “Vendors” in the left sidebar then click on All Vendors. This routes you to a page with a tabulated list of all vendors, simply click on the “Delete” button next to the vendors name to remove the selected vendor.
</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.4">3. How can I edit vendor profiles in the dashboard?</p>
<p>To edit vendor profiles in the dashboard, click on “Vendors” and navigate to All Vendors. This routes you to a tabulated list of all vendors. Click on the “Edit” button next to the vendors name. Here you can edit the vendors details and then click “Save”.
</p>     
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.5">4. Verifying Vendor Accounts</p> 
<p>To edit vendor profiles in the dashboard, click on “Vendors” and navigate to All Vendors. This routes you to a tabulated list of all vendors. Click on the “Verify” button next to the vendors name to verify the vendors profile.
</p>       
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.6">5. How can I monitor vendor orders and transactions?</p> 
<p>You can monitor vendor orders and transactions by navigating to the “Orders” section in the left sidebar. Here you can view all orders placed and the associated transactions.
</p>    
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.7">6. How can I track the inventory of each vendor?</p>
<p>You can track the inventory of each vendor by navigating to the “Products” section of the dashboard. Here you can view the current stock levels for each vendor and manage stock levels for each product.
</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="2.8">7. What reporting and analytics tools are available to vendors?</p>
<p>There are currently no reporting tools available for vendors on bestdealnaija.</p>
      </div>
    </>
  )
}
export default Owners_Topic2
 