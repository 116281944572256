
import * as ACTION from '../../constants/constants';
import { takeLatest } from 'redux-saga/effects';
import authSaga from './authSaga';
import profileSaga from './profileSaga';
import productSaga from './productSaga';
import checkoutSaga from './checkoutSaga';

function* rootSaga() {
  yield takeLatest([
    ACTION.SIGNIN,
    ACTION.SIGNUP,
    ACTION.SIGNUP_VENDOR,
    ACTION.SIGNUP_VENDOR_DETAILS,
    ACTION.SIGNOUT,
    ACTION.SIGNIN_WITH_GOOGLE,
    ACTION.SIGNIN_WITH_FACEBOOK,
    ACTION.ON_AUTHSTATE_CHANGED,
    ACTION.ON_AUTHSTATE_SUCCESS,
    ACTION.ON_AUTHSTATE_FAIL,
    ACTION.SET_AUTH_PERSISTENCE,
    ACTION.FORGOT_PASSWORD,
    ACTION.RESET_PASSWORD
  ], authSaga);
  yield takeLatest([
    ACTION.ADD_PRODUCT,
    ACTION.SEARCH_PRODUCT,
    ACTION.REMOVE_PRODUCT,
    ACTION.EDIT_PRODUCT,
    ACTION.GET_PRODUCTS,
    ACTION.GET_CATEGORY_PRODUCTS
  ], productSaga);
  yield takeLatest([
    ACTION.UPDATE_EMAIL,
    ACTION.UPDATE_PROFILE,
    ACTION.UPDATE_VENDOR_PROFILE
  ], profileSaga);
  yield takeLatest([
    ACTION.ADD_ORDER,
    ACTION.UPDATE_ORDER,
    ACTION.UPDATE_ORDER_TRANSACTION
  ], checkoutSaga);
}

export default rootSaga;
 