
import React from "react";
import PropType from 'prop-types';
import useDidMount from "./useDidMount";

const Popup = props => {
  const didMount = useDidMount(true);
  if (didMount) {
    return (
      <div className="fixed bg-[#00000050] w-full h-[100vh] top-0 left-0 z-10">
        <div className="relative w-[70%] my-0 mx-auto h-auto max-h-[70vh] mt-[calc(100vh - 85vh - 20px)] top-40 bg-white rounded-md p-10 border border-[#999] overflow-auto">
          <span className="absolute content-['X'] cursor-pointer  right-4 top-2  bg-[#ededed] w-[25px] h-[25px] rounded-lg leading-5 text-center border-[#999] text-xl" onClick={props.handleClose}>x</span>
          {props.content}
        </div>
      </div>
    );
  }
};

Popup.propTypes = {
    content: PropType.object,
    handleClose: PropType.func
};

 
export default Popup;
 