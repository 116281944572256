
import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { shallowEqual, useSelector } from 'react-redux';

import swal from 'sweetalert'
import axios from 'axios'

import * as ROUTE from '../../../constants/routes';
import * as CONSTANT from '../../../constants/constants';

const Categories = () => {
    const [categories, fetchcategories] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [nextPage, setNextPage] = useState();
    const [prevPage, setPrevPage] = useState();

    const {register, handleSubmit, reset, formState: { errors } } = useForm();
    const [selectedImage, setSelectedImage] = useState();
    const [categoryImage, setCategoryImage] = useState();
    let formData = new FormData();

    const { accessToken } = useSelector((state) => ({
        accessToken: state.profile.access_token
    }), shallowEqual);

    const onSelectFile = useCallback( async (e)  => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setCategoryImage(file)
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
    }, []);

    const submitForm = (data) => {
        if (!categoryImage) {
            alert('Category Image is required')
        } else {
            formData.append('category_image', categoryImage)
            formData.append('name', data.name);
            formData.append('description', data.description)        
    
            const requestOptions = {
                method: "POST",
                headers: {
                  'Content-type': 'multipart/form-data'
                }
            }
            axios.post(
                `${ROUTE.PRODUCTS_API}/categories`,
                formData,
                requestOptions
            ).then(res => res)
            .then(data =>{
              successAlert(data)
            })
            .catch(err => console.log(err))
          reset()
          setSelectedImage('');
        }

    }
    const successAlert = (response) => {
        return(
          swal({
              title: "Info!",
              text: response.data.message,
              icon: "success"
          }).then(function () {
            fetchCategories(currentPage)
          })
        )
    }
    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error,
              icon: "error"
          }).then(function () {
            fetchCategories(currentPage)
          })          
        )
    }

    const onNext = () => {
        if (nextPage != null) {
            fetchCategories(nextPage);
        }
    };

    const onPrevious = () => {
        if (prevPage != null) {
            fetchCategories(prevPage);
        }
    };

    const onPageChange = (page) => {
        if (page != null) {
            fetchCategories(page);
        }
    };

    const fetchCategories = (page) => {
        setLoading(true)
        fetch(`${ROUTE.PRODUCTS_API}/categories?pagination=1&page=${page}`, {
            headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer ' + accessToken    
            }
        })
        .then((res) => res.json())
        .then((res) => {
            fetchcategories(res)
            setNextPage(res.next_page)
            setPrevPage(res.prev_page)
            setCurrentPage(res.current_page)
            setLoading(false)
        })
        .catch(error => {
            console.log(error)
            setLoading(false)
        })
    }
    const first_item_on_page_number = (categories?.current_page * categories?.items_per_page) - categories?.items_per_page + 1;
    const last_item_on_page_number = first_item_on_page_number + categories?.results?.length -1;


    useEffect(() => {
        fetchCategories(currentPage)
    }, [])

    const deleteCategory = useCallback( async (id)  => {
        if(window.confirm('Are you sure you want to delete this category?')){
            axios.delete(
                `${ROUTE.PRODUCTS_API}/categories/${id}`,{
                    method : 'DELETE',
                    body : JSON.stringify({
                        id : id
                    }),
                    headers: {
                        'Content-type': 'application/json'
                    }
                })
                .then(res => res)
                .then(data =>{
                    successAlert(data)
                })
                .catch(err => errorAlert(err)
                )
            }
    }, []);
    
    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">Categories</span>
                    </span>
                    <span>/</span>
                </div>
            </div>
            <div className="header my-3 h-12 px-10 flex items-center justify-between">
                <h1 className="font-medium text-2xl">Categories</h1>
            </div>
            <div className="flex mx-3 mt-6 lg:flex-row">
                <div className="w-full lg:w-1/3 m-1">
                    {/* Display add category form */}
                    <form className="w-full bg-white shadow-md p-6" encType="multipart/form-data">
                        <div className="flex flex-wrap -mx-3 mb-6">
                            <div className="w-full md:w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category_name'>Category Name</label>
                                <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="name" placeholder="Category Name"  {...register("name", { required: true})}
                                required />
                                {errors.name && <small className="text-red-500 text-xs italic">Category name is required</small>}
                                {errors.name?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 25 </small></p>}
                            </div>
                            
                            <div className="w-full px-3 mb-6">
                                <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Description</label>
                                <textarea rows="4" className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="description" {...register("description", { required: true })} required />
                                {errors.description && <small className="text-red-500 text-xs italic">Description is required</small>}
                            </div>
                            <div className="w-full px-3 mb-8">
                                <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='dropzone-file'>
                                <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                </svg>

                                <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Category image</h2>

                                <p className="mt-2 text-gray-500 tracking-wide">Upload or drag & drop your file SVG, PNG, JPG or GIF. </p>

                                <input id="dropzone-file" type="file" className="hidden" name="category_image" onChange={onSelectFile} accept="image/png, image/jpeg, image/webp"/>
                                </label>
                                {errors.category_image && <small className="text-red-500 text-xs italic">Category image is required</small>}
                            </div>
                            <div className="w-full flex justify-center mb-5">
                                {selectedImage &&
                                    (
                                        <img src={selectedImage} className="w-32 h-32"/>
                                    )
                                } 
                            </div>
                            <div className="w-full md:w-full px-3 mb-6">
                                <button className="appearance-none block w-full bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                                onClick={handleSubmit(submitForm)}
                                >Add Category</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="w-full lg:w-2/3 m-1">
                    <div className="overflow-x-auto shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                            <tr>
                                <th scope="col" className="px-6 py-3"></th>
                                <th scope="col" className="px-6 py-3">Category</th>
                                <th scope="col" className="px-6 py-3">Description</th>
                                <th scope="col" className="px-6 py-3">Created At</th>
                                <th scope="col" className="px-6 py-3">Action</th>
                            </tr>
                            </thead>
                            <tbody>
                            {!isLoading && categories?.results?.length <= 0 && (
                                <>
                                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                    <td colSpan={5}>
                                    <div className="flex flex-col justify-center items-center my-4 space-y-3">No category registered yet</div>
                                    </td>
                                </tr>
                                </>
                            )}
                            {!isLoading && categories?.results?.length >= 1 && categories?.results?.map((category) => (
                                <>
                                <tr key={category.id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                
                                    <td className="px-6 py-4"><img src={`${CONSTANT.IMAGE_STORE}/${category.image_path}`} className="object-cover h-8 w-16 mx-auto" /></td>
                                    <td className="px-6 py-4">{category.name}</td>
                                    <td className="px-6 py-4">{category.description}</td>
                                    <td className="px-6 py-4">{category.created_at.substring(0,17)}</td>
                                    <td className="flex items-center px-6 py-4">
                                    <Link to={`${ROUTE.ADMIN_EDIT_CATEGORIES}/${category.id}`} className="font-medium text-blue-600 dark:text-blue-500 hover:underline">
                                        Edit
                                    </Link>
                                    <a onClick={() => deleteCategory(category.id)}
                                        className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer ms-3"
                                    >
                                        Delete
                                    </a>
                                    </td>
                                </tr>
                                </>
                            ))}
                            {isLoading && (
                                <>
                                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                                        <td colSpan={5}>
                                            <div className="flex flex-col justify-center items-center my-4 space-y-3">Fetching records...</div>
                                        </td>
                                    </tr>
                                </>
                            )}
                            </tbody>
                        </table>
                        {!isLoading && categories?.results?.length >= 1 && (
                            <nav className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
                                aria-label="Table navigation"
                            >
                                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                                    Showing{" "}
                                    <span className="font-semibold text-gray-900 dark:text-white">
                                        {`${first_item_on_page_number}-${last_item_on_page_number}`}
                                    </span> of <span className="font-semibold text-gray-900 dark:text-white">{categories?.total_records}</span>
                                </span>
                                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                    {
                                    categories?.prev_page && (
                                        <li>
                                            <a onClick={onPrevious}
                                                className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            >
                                                Previous
                                            </a>
                                        </li>
                                    )
                                    }
                                    {categories?.iter_pages.map((iter_page) => (
                                        <>
                                            {iter_page != null ?
                                            <li>
                                                <a onClick={() => onPageChange(iter_page)}
                                                    className={`${categories?.current_page === iter_page ? 'flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                                    : 'flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'}`}
                                                >
                                                    {iter_page}
                                                </a>
                                            </li> : 
                                            <li>
                                                <a href="#"
                                                    className='flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white'
                                                >
                                                {'...'}
                                                </a>
                                            </li>
                                            }
                                        </>
                                    ))}
                                    {
                                    categories?.next_page && (
                                        <li>
                                            <a onClick={onNext}
                                                className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                            >
                                                Next
                                            </a>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>      
            </div>        
        </div>
    );
}
export default Categories;
 