
import React from 'react';

import { useField } from 'formik';
import PropType from 'prop-types';

import Select from 'react-select';

const CustomSelectState = (props) => {
	const [field, meta, helpers] = useField(props);
	const { label, placeholder, defaultValue, options } = props;
	const { touched, error } = meta;
	const { setValue } = helpers;

	const handleChange = (value) => {
		const state = {
			countryCode: value?.countryCode,
			isoCode: value?.isoCode,
			label: value?.label,
			latitude: value?.latitude,
			longitude: value?.longitude,
			name: value?.name,
			value: value?.name
		};

		setValue(state);
	};


	return (
		<div className="">
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{label}<span className='text-red-500'> *</span>
			</label>
			<Select
				name={field.name}
				id={field.name}
				className={`block w-full text-gray-700 focus:ring focus:ring-green-200 focus:ring-opacity-50 
					shadow-sm border focus:border-green-300 border-gray-300 rounded
					${touched && error && 'border-red-500'}`}
				inputExtraProps={{ required: true }}
				onChange={handleChange}
				placeholder={placeholder}
				defaultValue={{ 
					label: defaultValue?.name !== '' ? defaultValue.name : placeholder, 
					value: defaultValue.value
				}}
				options={options.map((state) => ({ 
					countryCode: state?.countryCode,
					isoCode: state?.isoCode,
					label: state?.label,
					latitude: state?.latitude,
					longitude: state?.longitude,
					name: state?.name,
					value: state?.value
				}))}
				theme={(theme) => ({
					...theme,
					borderRadius: 0,
					colors: {
						...theme.colors,
						primary25: '#B3B3B3',
						primary: 'green'
					}
				})}
			/>
			{touched && error && (
				<span className="border-red-300 text-red-500">{error.value}</span>
			)}
		</div>
	);
};

CustomSelectState.propTypes = {
  label: PropType.string,
  placeholder: PropType.string,
  defaultValue: PropType.any,
  options: PropType.any.isRequired
};

export default CustomSelectState;
 