
import React, { useState } from 'react';
// import * as ROUTE from '../../constants/routes';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/bdn_about_image_2.jpg';
import Owners_Topic from '../../components/default/Owners_Topic';
import Owners_Topic2 from '../../components/default/Owners_Topic2';
import Owners_Topic3 from '../../components/default/Owners_Topic3';
import Owners_Topic4 from '../../components/default/Owners_Topic4';
import Owners_Topic5 from '../../components/default/Owners_Topic5';
import Owners_Topic6 from '../../components/default/Owners_Topic6';

const OwnersGuide = () => { 
    const title = 'B E S T &nbsp;  D E A L S &nbsp; I N &nbsp; N I G E R I A';
    const subtitle = 'Owners Guide';
    useDocumentTitle('Owners Guide | BestDealNaija');
    useScrollTop();
    const [check, setCheck] = useState(null);
    
    const guide = () => {
        setCheck(<Owners_Topic />);
    } 
    const guide2 = () => {
      setCheck(<Owners_Topic2 />);
    }
    const guide3 = () => {
        setCheck(<Owners_Topic3 />);
    } 
    const guide4 = () => {
      setCheck(<Owners_Topic4 />);
    }
    const guide5 = () => {
        setCheck(<Owners_Topic5 />);
    } 
    const guide6 = () => {
      setCheck(<Owners_Topic6 />);
    }

    return (
      <>
        <Banner backgroundImage={bannerImage} titleText={title} subtitleText={subtitle} /> 
        <section className="pt-20 md:pt-30">
            <div className="lg:px-16"> 
                <div className="grid grid-cols-5 gap-9 luday_grid_wrap"> 
                    <div className="group col-span-2">
                    <div id="accordion-arrow-icon" data-accordion="collapse">
                        <p className="text-xl" id="accordion-arrow-icon-heading-1">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-900 bg-gray-100 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-1" 
                                aria-expanded="true" 
                                aria-controls="accordion-arrow-icon-body-1"
                            >
                            <span>Managing Admin Accounts</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-1" aria-labelledby="accordion-arrow-icon-heading-1">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                <p>Dashboard Overview</p>
                                <li><a onClick={guide} href="#1.2.1" className="accordion_links">1. How can I add new admin users to the website?</a></li>
                                <li><a onClick={guide} href="#1.3" className="accordion_links">2. How do I delete existing admin users from the website?</a></li>
                                <li><a onClick={guide} href="#1.4" className="accordion_links">3. How do I manage admin user permissions?</a></li>
                                <li><a onClick={guide} href="#1.5" className="accordion_links">4. What types of admin roles are available?</a></li>
                                <li><a onClick={guide} href="#1.6" className="accordion_links">5. How can I reset admin passwords?</a></li>
                                <li><a onClick={guide} href="#1.7" className="accordion_links">6. How can I manage my admin profile?</a></li>
                            </div>
                        </div>
                        <p className="text-xl" id="accordion-arrow-icon-heading-2">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-2" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-2"
                            >
                            <span>Managing Vendors</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-2" className="hidden" aria-labelledby="accordion-arrow-icon-heading-2">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide2} href="#2.1" className="accordion_links">1. How can I view all current vendors in the BestDealNaija admin dashboard?</a></li>
                                <li><a onClick={guide2} href="#2.2" className="accordion_links">2. How can I add or remove vendors from the dashboard?</a></li>
                                <li><a onClick={guide2} href="#2.3" className="accordion_links">3. How can I edit vendor profiles in the dashboard?</a></li>
                                <li><a onClick={guide2} href="#2.4" className="accordion_links">4. Verifying Vendor Accounts</a></li>
                                <li><a onClick={guide2} href="#2.5" className="accordion_links">5. How can I monitor vendor orders and transactions?</a></li>
                                <li><a onClick={guide2} href="#2.6" className="accordion_links">6. How can I track the inventory of each vendor?</a></li>
                                <li><a onClick={guide2} href="#2.7" className="accordion_links">7. What reporting and analytics tools are available to vendors?</a></li>
                            </div>
                        </div>


                        <p className="text-xl" id="accordion-arrow-icon-heading-3">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-2" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-3"
                            >
                            <span>Managing Orders</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-3" className="hidden" aria-labelledby="accordion-arrow-icon-heading-3">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide3} href="#3.1" className="accordion_links">1. How can I view all orders in the BestDealNaija admin dashboard?</a></li>
                                <li><a onClick={guide3} href="#3.2" className="accordion_links">2. How can I update orders with new information?</a></li>
                                <li><a onClick={guide3} href="#3.3" className="accordion_links">3. How can I cancel orders in the dashboard?</a></li>
                                <li><a onClick={guide3} href="#3.4" className="accordion_links">4. What specific privileges can I assign to each order?</a></li>
                                <li><a onClick={guide3} href="#3.5" className="accordion_links">5. How can I monitor payments for orders?</a></li>
                                <li><a onClick={guide3} href="#3.6" className="accordion_links">6. How can I track the shipping status of each order?</a></li>
                            </div>
                        </div>
                        <p className="text-xl" id="accordion-arrow-icon-heading-4">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-4" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-4"
                            >
                            <span>Managing Categories</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-4" className="hidden" aria-labelledby="accordion-arrow-icon-heading-4">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide4} href="#4.1" className="accordion_links">Understanding categories</a></li>
                                <li><a onClick={guide4} href="#4.2" className="accordion_links">Creating Categories</a></li>
                                <li><a onClick={guide4} href="#4.3" className="accordion_links">3. How can I cancel orders in the dashboard?</a></li>
                                <li><a onClick={guide4} href="#4.4" className="accordion_links">Deleting Categories</a></li>
                            </div>
                        </div>

                        <p className="text-xl" id="accordion-arrow-icon-heading-5">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-5" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-5"
                            >
                            <span>Managing Products</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-5" className="hidden" aria-labelledby="accordion-arrow-icon-heading-5">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide5} href="#5.1" className="accordion_links">View all products</a></li>
                                <li><a onClick={guide5} href="#5.2" className="accordion_links">Creating Products</a></li>
                                <li><a onClick={guide5} href="#5.3" className="accordion_links">Editing Products</a></li>
                                <li><a onClick={guide5} href="#5.4" className="accordion_links">Deleting Products</a></li>
                                <li><a onClick={guide4} href="#5.5" className="accordion_links">Editing Products</a></li>
                                <li><a onClick={guide5} href="#5.6" className="accordion_links">Deleting Products</a></li>
                            </div>
                        </div>

                        <p className="text-xl" id="accordion-arrow-icon-heading-6">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-6" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-6"
                            >
                            <span>Managing Blogs</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-6" className="hidden" aria-labelledby="accordion-arrow-icon-heading-6">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide6} href="#6.1" className="accordion_links">View all Blog Posts</a></li>
                                <li><a onClick={guide6} href="#6.2" className="accordion_links">Creating a new blog post</a></li>
                                <li><a onClick={guide6} href="#6.3" className="accordion_links">Editing a blog post</a></li>
                                <li><a onClick={guide6} href="#6.4" className="accordion_links">Deleting a blog post</a></li>
                            </div>
                        </div>

                      </div>                 
                    </div>
                    <div className="group col-span-3"> 
                        <div className="p-[15px]">    
                            <h1 className="userguide_header text-green-600">Owners Guide</h1>  
                            {check}    
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
}
export default OwnersGuide;
 