
import { call, put } from 'redux-saga/effects';

import {UPDATE_PROFILE, UPDATE_VENDOR_PROFILE } from '../../constants/constants';
import { USER_API } from '../../services';
import { 
	updateProfileSuccess,
	updateProfileAddressSuccess
} from '../actions/profileActions';
import { setRequestStatus, setLoading } from '../actions/miscActions';

function* profileSaga({type, payload}) {
	switch (type) {
		case UPDATE_PROFILE: {
			try {
				const { password, old_password } = payload.credentials;
		
				yield put(setLoading(true));
				yield put(setRequestStatus({}));
		
				let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
				if (auth_tokens) {
					if (password && old_password) {
						yield call(USER_API.updateUserDetails, 
							{...payload.updates, password, old_password}, 
							{token: auth_tokens.access_token});
						// console.log(change_password?.json.json)
						yield put(updateProfileSuccess(payload.updates));
						yield put(setRequestStatus({
							success: true,
							type: 'profile',
							isError: false,
							status: 'info',
							message: 'Password updated.'
						}));
					} else {
						yield call(USER_API.updateUserDetails, 
							payload.updates, 
							{token: auth_tokens.access_token});
						if (payload.address) {
							const address_response = yield call(USER_API.addOrUpdateAuthenticatedUserAddress, 
								payload.address, 
								{token: auth_tokens.access_token});
							yield put(updateProfileAddressSuccess(payload.updates, address_response));
						}
					}
				} else {
					if (payload.address) {
						const address_response = yield call(USER_API.addOrUpdateGuestUserAddress, 
							payload.address);
						yield put(updateProfileAddressSuccess(payload.updates, address_response));

					}
				}
		
				yield put(setLoading(false));

			} catch (e) {
				console.log(e);
				if (e.json?.old_password[0]) {
					yield put(setRequestStatus({
						success: false,
						type: 'profile',
						isError: true,
						status: 'error',
						message: 'Old Password is incorrect'
					}));
				}
				yield put(setLoading(false));
			}
			break;
		}
		case UPDATE_VENDOR_PROFILE: {
			try {
				yield put(setLoading(true));
				yield put(setRequestStatus({}));
		
				let auth_tokens = JSON.parse(localStorage.getItem('auth_tokens'));
				if (auth_tokens) {
					yield call(USER_API.updateVendorDetails, payload, {token: auth_tokens.access_token});
	
					yield put(updateProfileSuccess(payload));
					yield put(setRequestStatus({
						success: true,
						type: 'profile',
						isError: false,
						status: 'info',
						message: 'Business profile updated.'
					}));
					yield put(setLoading(false));
				}
			} catch (e) {
				console.log(e)
				if (e.message) {
					yield put(setRequestStatus({
						success: false,
						type: 'profile',
						isError: true,
						status: 'error',
						message: e.message
					}));
				}
				yield put(setLoading(false));
			}
			break;
		}
		default: {
			throw new Error('Unexpected action type.');
		}
	}
}

export default profileSaga;
 