
import {
  ADD_PRODUCT_SUCCESS,
  CLEAR_SEARCH_STATE, EDIT_PRODUCT_SUCCESS,
  GET_PRODUCTS_SUCCESS, REMOVE_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_SUCCESS, CLEAR_PRODUCTS
} from '../../constants/constants';

const defaultSearchState = {
  lastRefKey: null,
  nextPage: null,
  prevPage: null,
  items: []
};

const defaultState = {
  lastRefKey: null,
  nextPage: null,
  prevPage: null,
  items: [],
  searchedProducts: defaultSearchState
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        lastRefKey: action.payload.lastKey,
        nextPage: action.payload.nextPage,
        prevPage: action.payload.prevPage,
        items: [...state.items, ...action.payload.products]
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        items: [...state.items, action.payload.products]
      };
    case SEARCH_PRODUCT_SUCCESS:
      return {
        ...state,
        searchedProducts: {
          lastRefKey: action.payload.lastKey,
          nextPage: action.payload.nextPage,
          prevPage: action.payload.prevPage,
          items: [...state.searchedProducts.items, ...action.payload.products]
        }
      };
    case CLEAR_SEARCH_STATE:
      return {
        ...state,
        searchedProducts: defaultSearchState
      };
    case REMOVE_PRODUCT_SUCCESS:
      return {
        ...state,
        items: state.items.filter((product) => product.id !== action.payload)
      };
    case EDIT_PRODUCT_SUCCESS:
      return {
        ...state,
        items: state.items.map((product) => {
          if (product.id === action.payload.id) {
            return {
              ...product,
              ...action.payload.updates
            };
          }
          return product;
        })
      };
    case CLEAR_PRODUCTS:
      return defaultState;
    default:
      return state;
  }
};
 