
import React from 'react';
import { Link, useParams } from 'react-router-dom'
import { FaAngleLeft } from 'react-icons/fa'
import * as ROUTE from '../../../constants/routes'
import Orderlist from '../../../components/order/orderlists'

const ViewOrder = () => {
    const { id } = useParams();
    return (
        <div className="bg-gray-100">
            <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
                <div className="flex items-center space-x-2 text-gray-400">
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                    <span>/</span>
                    <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_ORDERS}>Order</Link></span>
                    <span>/</span>
                    <span className="tracking-wide text-md">
                        <span className="text-base">Order details</span>
                    </span>
                    <span>/</span>
                </div>
            </div>
            <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
                <h1 className="font-medium text-2xl">Order details</h1>
                <Link to={ROUTE.ADMIN_ORDERS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
            </span> Back to orders</Link>
            </div>
            <Orderlist orderId={id}/>
        </div>
    )  
}
export default ViewOrder;
 