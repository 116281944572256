
import React, {useEffect, useState, useCallback} from 'react';
import { Link } from 'react-router-dom'
import { FaPlus } from 'react-icons/fa'

import axios from 'axios'
import swal from 'sweetalert'

import * as ROUTE from '../../../constants/routes'
import * as CONSTANT from '../../../constants/constants'

const Blogs = () => {
  const [blogs, setBlogs] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState();
  const [prevPage, setPrevPage] = useState();

  const onNext = () => {
    if (nextPage != null) {
      fetchBlogs(nextPage);
    }
  };

  const onPrevious = () => {
    if (prevPage != null) {
      fetchBlogs(prevPage);
    }
  };

  const onPageChange = (page) => {
    if (page != null) {
      fetchBlogs(page);
    }
  };

  const fetchBlogs = (page) => {
    setLoading(true);
    fetch(`${ROUTE.BLOGS_API}/blogs?pagination=1&page=${page}`)
      .then((res) => res.json())
      .then((res) => {
        setBlogs(res);
        setNextPage(res.next_page);
        setPrevPage(res.prev_page);
        setCurrentPage(res.current_page);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const first_item_on_page_number =
    blogs?.current_page * blogs?.items_per_page - blogs?.items_per_page + 1;
  const last_item_on_page_number =
    first_item_on_page_number + blogs?.results?.length - 1;

  useEffect(() => {
    fetchBlogs(currentPage);
  }, []);

  const successAlert = (response) => {
    return swal({
      title: "Info!",
      text: response.data.message,
      icon: "success"
    }).then(function () {
      fetchBlogs(currentPage);
    });
  };
  const errorAlert = (error) => {
    return swal({
      title: "Error!",
      text: error,
      icon: "error"
    }).then(function () {
      fetchBlogs(currentPage);
    });
  };

  const deleteBlog = useCallback(async (id) => {
    if (confirm("Are you sure you want to delete this blog post?")) {
      axios
        .delete(`${ROUTE.BLOGS_API}/blogs/${id}`, {
          method: "DELETE",
          body: JSON.stringify({
            id: id
          }),
          headers: {
            "Content-type": "application/json"
          }
        })
        .then((res) => res)
        .then((data) => {
          successAlert(data);
        })
        .catch((err) => errorAlert(err));
    }
  }, []);

  return (
    <div className="bg-gray-100">
      <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
        <div className="flex items-center space-x-2 text-gray-400">
          <span className="text-green-700 tracking-wider font-thin text-md">
            <Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link>
          </span>
          <span>/</span>
          <span className="tracking-wide text-md">
            <span className="text-base">Blogs</span>
          </span>
          <span>/</span>
        </div>
      </div>
      <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
        <h1 className="font-medium text-2xl">All Blogs</h1>
        <Link
          to={ROUTE.ADMIN_ADD_BLOGS}
          className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"
        >
          {" "}
          <span>
            <FaPlus className="inline-block w-4 h-3" />
          </span>{" "}
          Post a new Blog
        </Link>
      </div>
      <div className="flex flex-col mx-3 lg:flex-row">
        <div className="w-full m-4 bg-white shadow-lg text-lg rounded-sm border border-gray-200">
          <div className="overflow-x-auto shadow-md sm:rounded-lg">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="fill-current w-5 h-5 mx-auto"
                    >
                      <path d="M6 22h12a2 2 0 0 0 2-2V8l-6-6H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2zm7-18 5 5h-5V4zm-4.5 7a1.5 1.5 0 1 1-.001 3.001A1.5 1.5 0 0 1 8.5 11zm.5 5 1.597 1.363L13 13l4 6H7l2-3z"></path>
                    </svg>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Blog Title
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Summary
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created At
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {!isLoading && blogs?.results?.length <= 0 && (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={8}>
                        <div className="flex flex-col justify-center items-center my-4 space-y-3">
                          No blogs added yet
                        </div>
                      </td>
                    </tr>
                  </>
                )}
                {!isLoading &&
                  blogs?.results?.length >= 1 &&
                  blogs?.results?.map((blog, index) => (
                    <>
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                      >
                        <td className="px-6 py-4">
                          <img
                            src={`${CONSTANT.IMAGE_STORE}/${blog.image_path}`}
                            className="h-8 w-16 mx-auto"
                          />
                        </td>
                        <td className="px-6 py-4">{blog.title}</td>
                        <td className="px-6 py-4">{blog.summary}</td>
                        <td className="px-6 py-4">
                          {blog.created_at.substring(0, 17)}
                        </td>

                        <td className="flex items-center px-6 py-4">
                          <Link
                            to={`${ROUTE.ADMIN_EDIT_BLOGS}/${blog.id}`}
                            className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                          >
                            Edit
                          </Link>
                          <a
                            onClick={() => deleteBlog(blog.id)}
                            className="font-medium text-red-600 dark:text-red-500 hover:underline cursor-pointer ms-3"
                          >
                            Delete
                          </a>
                        </td>
                      </tr>
                    </>
                  ))}
                {isLoading && (
                  <>
                    <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                      <td colSpan={8}>
                        <div className="flex flex-col justify-center items-center my-4 space-y-3">
                          Fetching records...
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </table>
            {!isLoading && blogs?.results?.length >= 1 && (
              <nav
                className="flex items-center flex-column flex-wrap md:flex-row justify-between m-4"
                aria-label="Table navigation"
              >
                <span className="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">
                  Showing{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {`${first_item_on_page_number}-${last_item_on_page_number}`}
                  </span>{" "}
                  of{" "}
                  <span className="font-semibold text-gray-900 dark:text-white">
                    {blogs?.total_records}
                  </span>
                </span>
                <ul className="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                  {blogs?.prev_page && (
                    <li>
                      <a
                        onClick={onPrevious}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Previous
                      </a>
                    </li>
                  )}
                  {blogs?.iter_pages.map((iter_page) => (
                    <>
                      {iter_page != null ? (
                        <li>
                          <a
                            onClick={() => onPageChange(iter_page)}
                            className={`${
                              blogs?.current_page === iter_page
                                ? "flex items-center justify-center cursor-pointer px-3 h-8 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                                : "flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                            }`}
                          >
                            {iter_page}
                          </a>
                        </li>
                      ) : (
                        <li>
                          <a
                            href="#"
                            className="flex items-center justify-center px-3 h-8 text-blue-600 border border-gray-300 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white"
                          >
                            {"..."}
                          </a>
                        </li>
                      )}
                    </>
                  ))}
                  {blogs?.next_page && (
                    <li>
                      <a
                        onClick={onNext}
                        className="flex items-center justify-center cursor-pointer px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      >
                        Next
                      </a>
                    </li>
                  )}
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blogs;
 