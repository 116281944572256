
import React from 'react';
import ProductCard from '../../components/productsCard/productCard';
import RecommendedDeals from '../deals/FindDeal';
import Banner  from '../../components/default/Banner/index';
import img from '../../images/tradefair/tradefairbg.jpg';
import { useDocumentTitle } from '../../hooks';
import { shallowEqual, useSelector } from 'react-redux';
import ProductList from '../../components/productsCard/productCard/ProductList';

const Product = () => {
    useDocumentTitle('Products | BestDealNaija');
    const store = useSelector((state) => ({
        products: state.products,
        requestStatus: state.app.requestStatus
    }), shallowEqual);

    return (
        <>
            <Banner subtitleText='Products' backgroundImage={img} />
            <section className="bg-gray-50">
                <div className="px-3 md:px-0 lg:px-0 py-[50px] pb-[80px] luday_wrap">
                <ProductList {...store}><ProductCard products={store.products.items} /></ProductList>
                </div>
            </section>
            
            <RecommendedDeals />
        </>
  );
}
export default Product;
 