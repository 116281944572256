
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormikContext } from 'formik';
import PropType from 'prop-types';

import cardpay from '../../../images/payments/visamasterverve.png';
import paycom from '../../../images/payments/paystack-preview.png';
// import paymoney from '../../../images/payments/money.png';
import PayWithPaystack from '../../../hooks/usePaystack';
import * as ROUTE from '../../../constants/routes';
import { calculateArrayTotal } from '../../../helpers/utils';

const PaymentMethods = ({state}) => {
    const { values, setValues } = useFormikContext();
	const [paystackBtn, setPaystackBtn] = useState(false);
	// const [cashBtn, setCashBtn] = useState(false);
	const navigate = useNavigate();

	const subtotal = calculateArrayTotal(
		state.cart.map((product) => 
			product?.sale_price ? product.sale_price * product.cart_quantity
			: product.price * product.cart_quantity
	));

	const onClickPrevious = () => {
		navigate(ROUTE.CHECKOUT);
	};
	const handlePaystackChange = (e) => {
		if (e.target.checked) {
			setValues({ payment_type: 'paystack' });
			// setCashBtn(false);
			setPaystackBtn(!paystackBtn);
		}
	};
	// const handleCashChange = (e) => {
	// 	if (e.target.checked) {
	// 		setValues({ payment_type: 'cash' });
	// 		setPaystackBtn(false);
	// 		setCashBtn(!cashBtn);
	// 	}
	// };

	return (
		<>
			<div className={`flex flex-col ${values.payment_type === 'paystack' ? 'is-selected-payment' : ''}`}>
				<p>How do you want to pay for your order?</p>
				<div className="mt-3 mb-1 w-full">
					<div className="flex flex-row itmes-center">
						<input 
							checked={values.payment_type === 'paystack'}
							type="radio" 
							name="payment_type" 
							className="text-black shadow-sm bg-white rounded mr-1" 
							id="paystack"
							onChange={handlePaystackChange} 
							required
						/>
						<img src={cardpay} alt="payment-method" className="h-6 mr-1"/>
						<p>Stay Safe, go cashless with card</p>
					</div>
				</div>
			</div>
			<div className="flex flex-row">
				<div className="mt-1 mb-3 w-full">
					<img src={paycom} alt="payment-method" className="h-50 mr-1"/>
				</div>
			</div>
			{/* <hr/> */}
			{/* <div className={`mt-5 mb-3 w-full ${values.payment_type === 'cash' ? 'is-selected-payment' : ''}`}>
				<div className="flex flex-row itmes-center">
					<input 
						checked={values.payment_type === 'cash'}
						type="radio" 
						name="payment_type" 
						className="text-black shadow-sm bg-white rounded mr-1" 
						id="cash"
						onChange={handleCashChange}  
						required
					/>
					<img src={paymoney} alt="payment-method" className="h-10 mr-1"/>
					<span>Cash on Delivery</span>
				</div>
			</div> */}
			<hr/>
			<div className="mt-10 flex flex-col md:flex-row">
				<button 
					disabled={state.isLoading}
					type="button" 
					className="button button--theme overflow-hidden border border-[#9c0]-600
						hover:bg-yellow-300 text-[#9c0] text-sm transition-all ease-in-out duration-300 
						rounded py-3 px-5 m-2 flex items-center flex-nowrap w-full md:w-1/2"
					onClick={() => onClickPrevious()}
				>
					<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" ><path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z"></path></svg>
					<span>Previous</span>
				</button>
				{(values.payment_type === 'paystack' || paystackBtn ) && 
					<PayWithPaystack state={state} subTotal={subtotal} />
				}
				{/* {(values.payment_type === 'cash' || cashBtn) &&
					<button
						disabled={state.isLoading}
						type="submit"
						className="button button--theme overflow-hidden bg-[#9c0] 
						hover:bg-yellow-300 text-white text-sm transition-all ease-in-out duration-300 
						rounded py-3 px-5 m-2 flex items-center flex-nowrap w-full md:w-1/2">
						<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" className="fill-current mr-1">
							<path d="M21 4H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1zm-1 11a3 3 0 0 0-3 3H7a3 3 0 0 0-3-3V9a3 3 0 0 0 3-3h10a3 3 0 0 0 3 3v6z">
							</path>
							<path d="M12 8c-2.206 0-4 1.794-4 4s1.794 4 4 4 4-1.794 4-4-1.794-4-4-4zm0 6c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2z">
							</path>
						</svg> 
						<span>{state.isLoading ? 'Confirming...' : 'Confirm'}</span>
					</button>
				} */}
			</div>
		</>
	);
};

PaymentMethods.propTypes = {
	state: PropType.object.isRequired
};

export default PaymentMethods;
 