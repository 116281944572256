
import {
  IS_AUTHENTICATING, LOADING,
  SET_AUTH_STATUS,
  SET_REQUEST_STATUS,
  SET_GUEST, SET_ORDER_STATUS, ORDER_LOADING
} from '../../constants/constants';

const initState = {
  loading: false,
  orderLoading: false,
  isAuthenticating: false,
  authStatus: null,
  orderStatus: null,
  requestStatus: null,
  guest: {}
};

export default (state = initState, action) => {
  switch (action.type) {
    case LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case ORDER_LOADING:
        return {
          ...state,
          orderLoading: action.payload
        };
    case IS_AUTHENTICATING:
      return {
        ...state,
        isAuthenticating: action.payload
      };
    case SET_REQUEST_STATUS:
      return {
        ...state,
        requestStatus: action.payload
      };
    case SET_AUTH_STATUS:
      return {
        ...state,
        authStatus: action.payload
      };
    case SET_ORDER_STATUS:
        return {
          ...state,
          orderStatus: action.payload
        };
    case SET_GUEST:
      return {
        ...state,
        guest: action.payload
      };
    default:
      return state;
  }
};
 