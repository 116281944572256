
/* API routes */
export const CONTACT_API = process.env.REACT_APP_CONTACT_API; // eslint-disable-line
export const USER_API = process.env.REACT_APP_USER_API; // eslint-disable-line
export const PRODUCTS_API = process.env.REACT_APP_PRODUCTS_API; // eslint-disable-line
export const BLOGS_API = process.env.REACT_APP_BLOGS_API; // eslint-disable-line

/* public routes */
export const HOME = '/';
export const FIND_YOUR_DEAL = '/find-your-deal';
export const ABOUT_BEST_DEAL_NAIJA = '/about-bestdealnaija';
export const BLOG = '/blog';
export const BLOG_DETAILS = '/blog-details';
export const CONTACT = '/contact';
export const CART = '/cart';
export const VENDORS = '/vendors';
export const VENDORDETAILS = '/vendor-details';
export const PRODUCTS = '/products';
export const SEARCH = '/search/:searchQuery';
export const VENDOR_PRODUCTS_VIEW = '/vendor-products';
export const CATEGORY_PRODUCTS = '/category';
export const PRODUCT_DETAILS = '/product-details';
export const CHECKOUT = '/checkout';
export const DELIVERY = '/delivery';
export const PAYMENT = '/payment';
export const TEST = '/test';
export const BEST_DEAL_TRADE_FAIR = '/best-deal-trade-fair';
export const USERS_GUIDE = '/users-guide';
export const OWNERS_GUIDE = '/owners-guide';

/* Vendor routes */
export const VENDOR_DASHBOARD = '/vendor';
export const VENDOR_PROFILE = '/vendor/profile';
export const ADD_PRODUCT = '/vendor/add';
export const EDIT_PRODUCT = '/vendor/edit';
export const VENDOR_ADD_PRODUCTS = '/vendor/addproducts';
export const VENDOR_EDIT_PRODUCTS = '/vendor/editproducts';
export const VENDOR_PRODUCTS = '/vendor/products';
export const VENDOR_ORDERS = '/vendor/order';

/* Auth routes */
export const SIGNIN = '/signin';
export const SIGNOUT = '/logout';
export const SIGNUP = '/signup';
export const SIGNUP_VENDOR = '/vendor/signup';
export const SIGNUP_VENDOR_DETAILS = '/vendor/signup/details';
export const SIGNUP_VENDOR_COMPLETE = '/vendor/signup/complete';
export const FORGOT_PASSWORD = '/forgot_password';
export const RESET_PASSWORD = '/reset_password/:token';

export const CHECKOUT_STEP_1 = '/checkout/step1';
export const CHECKOUT_STEP_2 = '/checkout/step2';
export const CHECKOUT_STEP_3 = '/checkout/step3';
export const VIEW_PRODUCT = '/product/:id';

/* Admin routes */
export const ADMIN_DASHBOARD = '/admin/home';
export const ADMIN_CATEGORIES = '/admin/categories';
export const ADMIN_SUB_CATEGORIES = '/admin/sub-category';
export const ADMIN_EDIT_CATEGORIES = '/admin/edit-categories';
export const ADMIN_EDIT_SUB_CATEGORIES = '/admin/sub-category/edit';
export const ADMIN_PRODUCTS = '/admin/products';
export const ADMIN_VERIFY_PRODUCTS = '/admin/verifyproducts';
export const ADMIN_VIEW_PRODUCT = '/admin/viewproduct';
export const ADMIN_EDIT_PRODUCTS = '/admin/editproducts';
export const ADMIN_ORDERS = '/admin/orders';
export const VIEW_ORDERS = '/admin/vieworders';
export const ADMIN_ADD_VENDORS = '/admin/addvendors';
export const ADMIN_EDIT_VENDORS = '/admin/editvendors';
export const ADMIN_VIEW_VENDORS = '/admin/viewvendors';
export const ADMIN_VERIFY_VENDORS = '/admin/verifyvendors';
export const ADMIN_VENDORS = '/admin/vendors';
export const ADMIN_BLOGS = '/admin/blogs';
export const ADMIN_ADD_BLOGS = '/admin/addblogs';
export const ADMIN_EDIT_BLOGS = '/admin/editblogs';
export const ADMIN_USERS = '/admin/users';
export const ADMIN_CREATE_ADMIN = '/admin/create-admin';
export const ADMIN_MANAGE_ADMINS = '/admin/manage-admins';
export const ADMIN_EDIT_ADMINS = '/admin/edit-admins';

/* protected user routes */
export const PROFILE = '/profile';
export const CHANGEPASSWORD = '/change-password';
export const ORDER_HISTORY = '/order-history';
export const ORDER_DETAILS = '/order-details';
export const ORDER_CALLBACK = '/order/callback';
export const CASH_ORDER_CALLBACK = '/order/cash-callback';
export const VIEW_ORDER = '/order/:id';
 