
import PropType from 'prop-types';
import React from 'react';

const CustomInput = ({
  field, form: { touched, errors }, label, required, inputRef, ...props
}) => (
   <div className="">
		{required ?
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{label}<span className='text-red-500'> *</span>
			</label> :
			<label className="block text-sm font-medium text-gray-700 undefined" 
				htmlFor={field.name}>
				{label}
			</label>
		}
		<div className="flex flex-col items-start">
			<input
				type="text"
				id={field.name}
				className={`block w-full text-gray-700 focus:ring focus:ring-green-200 focus:ring-opacity-50 
					shadow-sm border focus:border-green-300 border-gray-300 rounded py-2 px-4
					${touched[field.name] && errors[field.name] && 'border-red-500'}`}
				ref={inputRef}
				{...field}
				{...props}
			/>
			{touched[field.name] && errors[field.name] && (
				<span className="border-red-300 text-red-500">{errors[field.name]}</span>
			)}
		</div>
	</div> 
);

CustomInput.defaultProps = {
	required: false,
	inputRef: undefined
};

CustomInput.propTypes = {
  label: PropType.string.isRequired,
  required: PropType.bool,
  field: PropType.object.isRequired,
  form: PropType.object.isRequired,
  inputRef: PropType.oneOfType([
    PropType.func,
    PropType.shape({ current: PropType.instanceOf(Element) })
  ])
};

export default CustomInput;
 