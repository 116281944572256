
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";

import * as CONSTANT from '../../constants/constants'
import { FaStream, FaStoreAlt, FaHome, FaShoppingBag, FaList, FaUserAlt, FaCartPlus, FaBlog, FaPlusCircle } from "react-icons/fa";
import * as ROUTE from '../../constants/routes';
import { 
  BsFillCartCheckFill, BsFillCartXFill, 
  BsShop, BsShopWindow 
} from "react-icons/bs";
import logo from '../../images/logo/logo.png';

const Sidebar = () => {
    const { authUserType} = useSelector((state) => ({
        authUserType: state.auth.user_type
    }));
    
	return (
		<>
			<div>
				<aside className="navbar-aside" id="offcanvas_aside">
				<div className="aside-top">
					<Link to={ROUTE.ADMIN_DASHBOARD} className="brand-wrap">
					<img
						src={logo}
						style={{ height: "40", maxWidth: "150px" }}
						alt="Bestdealnaija dashboard"
					/>
					</Link>
					<div>
					<button className="btn-aside-minimize">
						<FaStream className="w-5 h-5 text-gray-500"/>
					</button>
					</div>
				</div>
				<nav>
					<ul className="menu-aside">
					<li className="menu-item">
						<NavLink
						className="menu-link"
						to={ROUTE.ADMIN_DASHBOARD}
						>
						<FaHome className="text-gray-400 mr-3 w-6 h-6 inline-block" />
						<span className="text">Dashboard</span>
						</NavLink>
					</li>
					{authUserType === CONSTANT.ADMIN_ROLE && (
						<li className="menu-item">
						<Menu as="div">
							<Menu.Button className="menu-link w-full text-left">
							<FaUserAlt className="text-gray-400 mr-3 w-6 h-6 inline-block" />
							<span className="text">Users</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 h-6 inline-block ml-3"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M19 9l-7 7-7-7"
								/>
							</svg>
							</Menu.Button>
							<Transition
								as={Fragment}
								enter="transition ease-out duration-100"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-75"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<Menu.Items>
								<div className="py-1">
									<Menu.Item>
									<NavLink
										activeclassname="active"
										className="menu-link"
										to={ROUTE.ADMIN_CREATE_ADMIN}
									>
										<FaCartPlus className="text-gray-400 mr-3 w-6 h-6 inline-block" />
										<i className="icon fas fa-home"></i>
										<span className="text">Create Admin</span>
									</NavLink>
									</Menu.Item>
									<Menu.Item>
									<NavLink
										activeclassname="active"
										className="menu-link"
										to={ROUTE.ADMIN_MANAGE_ADMINS}
									>
										<FaCartPlus className="text-gray-400 mr-3 w-6 h-6 inline-block" />
										<i className="icon fas fa-home"></i>
										<span className="text">Manage Admins</span>
									</NavLink>
									</Menu.Item>
									<Menu.Item>
									<NavLink
										activeclassname="active"
										className="menu-link"
										to={ROUTE.ADMIN_USERS}
									>
										<BsFillCartCheckFill className="text-gray-400 mr-3 w-6 h-6 inline-block" />
										<i className="icon fas fa-home"></i>
										<span className="text">All Customers</span>
									</NavLink>
									</Menu.Item>
								</div>
								</Menu.Items>
							</Transition>
						</Menu>
						</li>
					)}
					<li className="menu-item">
						<Menu as="div">
						<Menu.Button className="menu-link w-full text-left">
							<FaList className="text-gray-400 mr-3 w-6 h-6 inline-block" />
							<span className="text">Categories</span>
							<svg
							xmlns="http://www.w3.org/2000/svg"
							className="w-6 h-6 inline-block ml-3"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							strokeWidth={2}
							>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19 9l-7 7-7-7"
							/>
							</svg>
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items>
							<div className="py-1">
								<Menu.Item>
								<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_CATEGORIES}
								>
									<BsShop className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-cart-plus"></i>
									<span className="text">Category</span>
								</NavLink>
								</Menu.Item>
								<Menu.Item>
								<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_SUB_CATEGORIES}
								>
									<BsShopWindow className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-cart-plus"></i>
									<span className="text">Sub Category</span>
								</NavLink>
								</Menu.Item>
							</div>
							</Menu.Items>
						</Transition>
						</Menu>
					</li>
					<li className="menu-item">
						<Menu as="div">
						<Menu.Button className="menu-link w-full text-left">
							<FaShoppingBag className="text-gray-400 mr-3 w-6 h-6 inline-block" />
							<span className="text">Products</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 h-6 inline-block ml-3"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19 9l-7 7-7-7"
							/>
							</svg>
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items>
								<div className="py-1">
								<Menu.Item>
									<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_PRODUCTS}
									>
									<BsFillCartCheckFill className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-cart-plus"></i>
									<span className="text">All products</span>
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_VERIFY_PRODUCTS}
									>
									<BsFillCartXFill className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-cart-plus"></i>
									<span className="text">Unverified products</span>
									</NavLink>
								</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
						</Menu>
					</li>
					<li className="menu-item">
						<Menu as="div">
						<Menu.Button className="menu-link w-full text-left">
							<FaStoreAlt className="text-gray-400 mr-3 w-6 h-6 inline-block" />
							<span className="text">Vendors</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 h-6 inline-block ml-3"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19 9l-7 7-7-7"
							/>
							</svg>
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items>
								<div className="py-1">
								<Menu.Item>
									<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_VENDORS}
									>
									<BsFillCartCheckFill className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-home"></i>
									<span className="text">All vendors</span>
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_VERIFY_VENDORS}
									>
									<BsFillCartCheckFill className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-home"></i>
									<span className="text">Unverified vendors</span>
									</NavLink>
								</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
						</Menu>
					</li>
					<li className="menu-item">
						<NavLink
						activeclassname="active"
						className="menu-link"
						to={ROUTE.ADMIN_ORDERS}
						>
						<FaShoppingBag className="text-gray-400 mr-3 w-6 h-6 inline-block" />
						<span className="text">Orders</span>
						</NavLink>
					</li>
					<li className="menu-item">
						<Menu as="div">
						<Menu.Button className="menu-link w-full text-left">
							<FaBlog className="text-gray-400 mr-3 w-6 h-6 inline-block" />
							<span className="text">Blogs</span>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-6 h-6 inline-block ml-3"
								fill="none"
								viewBox="0 0 24 24"
								stroke="currentColor"
								strokeWidth={2}
							>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M19 9l-7 7-7-7"
							/>
							</svg>
						</Menu.Button>
						<Transition
							as={Fragment}
							enter="transition ease-out duration-100"
							enterFrom="transform opacity-0 scale-95"
							enterTo="transform opacity-100 scale-100"
							leave="transition ease-in duration-75"
							leaveFrom="transform opacity-100 scale-100"
							leaveTo="transform opacity-0 scale-95"
						>
							<Menu.Items>
								<div className="py-1">
								<Menu.Item>
									<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_BLOGS}
									>
									<FaBlog className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-cart-plus"></i>
									<span className="text">All blogs</span>
									</NavLink>
								</Menu.Item>
								<Menu.Item>
									<NavLink
									activeclassname="active"
									className="menu-link"
									to={ROUTE.ADMIN_ADD_BLOGS}
									>
									<FaPlusCircle className="text-gray-400 mr-3 w-6 h-6 inline-block" />
									<i className="icon fas fa-cart-plus"></i>
									<span className="text">Post blog</span>
									</NavLink>
								</Menu.Item>
								</div>
							</Menu.Items>
						</Transition>
						</Menu>
					</li>
					</ul>
					<br />
					<br />
				</nav>
				</aside>
			</div>
		</>
	);
};

export default Sidebar;
 