
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import {  connect, useSelector } from 'react-redux';
import { useLocation, Navigate } from 'react-router-dom';
import PropType from 'prop-types';

import { ADMIN_DASHBOARD, VENDOR_DASHBOARD,
  SIGNIN, SIGNUP, SIGNUP_VENDOR_DETAILS, SIGNUP_VENDOR_COMPLETE
} from '../constants/routes';
import * as ROUTE from '../constants/routes';
import * as CONSTANT from '../constants/constants';
import { Footer, Navigation } from '../components/default';
import { useDidMount } from '../hooks';
import Alert from '../helpers/Alert';

/**
 * Authentication guard for routes
 * 
 * @param {PropTypes.bool} isAuth element/bool
 * @param {PropTypes.string} user_type element/string
 * @param {PropTypes.func} component element/func
 * @param {PropTypes.string} path element/string
 * 
 */

const PublicRoute = ({ isAuth, user_type, is_active, is_admin_verified, component: Component, path }) => {
  
  let location = useLocation();
  const didMount = useDidMount(true);
  let from = location.state?.from?.pathname || ROUTE.PROFILE;
  const { authStatus } = useSelector((state) => ({
    authStatus: state.app.authStatus
  }));

  if (isAuth && user_type === CONSTANT.ADMIN_ROLE || isAuth && user_type === CONSTANT.SUB_ADMIN_ROLE) {
    if (didMount) {
      return <Navigate to={ADMIN_DASHBOARD} replace state={{ location }} />;
    }
  }
  if (isAuth && !is_admin_verified && !is_active && user_type === CONSTANT.VENDOR_ROLE) {
    if (didMount) {
      return <Navigate to={SIGNUP_VENDOR_DETAILS} replace state={{ location }} />;
    }
  }
  if (isAuth && !is_admin_verified && is_active && user_type === CONSTANT.VENDOR_ROLE) {
    if (didMount) {
      return <Navigate to={SIGNUP_VENDOR_COMPLETE} replace state={{ location }} />;
    }
  }
  if (isAuth && is_active && is_admin_verified && user_type === CONSTANT.VENDOR_ROLE) {
    if (didMount) {
      return <Navigate to={VENDOR_DASHBOARD} replace state={{ location }} />;
    }
  }
  if ((isAuth && user_type === CONSTANT.USER_ROLE) && (path === SIGNIN || path === SIGNUP)) {
    if (didMount) {
      return <Navigate to={from} replace state={{ location }} />;
    }
  }

  if (didMount) {
    return (
      <>
        <Navigation />
          {authStatus?.message && (
            <nav className='fixed z-30 w-full top-0'>
              <Alert
                status={authStatus.status}
                mssg={authStatus.message}
                type={authStatus.type}
            />
            </nav>
          )}
          <Component />
        <Footer />
      </>
    );
  }
}

PublicRoute.defaultProps = {
  isAuth: false,
  user_type: CONSTANT.USER_ROLE,
  is_active: false,
  is_admin_verified: false,
  path: '/'
};

PublicRoute.propTypes = {
  isAuth: PropType.bool,
  user_type: PropType.string,
  is_active: PropType.bool,
  is_admin_verified: PropType.bool,
  component: PropType.func.isRequired,
  path: PropType.string
};

const mapStateToProps = ({ auth }) => ({
  isAuth: !!auth,
  user_type: auth?.user_type || '',
  is_active: auth?.is_active || false,
  is_admin_verified: auth?.is_admin_verified || false
});

export default connect(mapStateToProps)(PublicRoute);
 