
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';

import * as Yup from 'yup';
import { Form, Formik } from 'formik';

import img from '../../images/tradefair/tradefairbg.jpg';
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';
import Banner  from '../../components/default/Banner/index';

import { useDocumentTitle } from '../../hooks';
import { CartSummary } from '../../components/cart';
import { Timeline } from './components';
import { BillingForm } from '..';
import { addOrder, setBillingDetails } from '../../redux/actions/checkoutActions';
import { signUp } from '../../redux/actions/authActions';
import { updateProfile } from '../../redux/actions/profileActions';
import { calculateArrayTotal } from '../../helpers/utils';
import { setOrderStatus, setRequestStatus } from '../../redux/actions/miscActions';


let UpdateCheckoutSchema = Yup.object().shape({
    email: Yup.string()
		.email('Email is not valid.')
		.required('Email is required.'),
    first_name: Yup.string()
		.required('First name is required.')
		.min(2, 'Name should be at least 2 characters.'),
    last_name: Yup.string()
		.required('Last name is required.')
		.min(2, 'Name should be at least 2 characters.'),
	address: Yup.string()
		.required('Billing address is required.'),
	mobile_number: Yup.string()
		.required('Mobile is required.')
		.min(12, 'Mobile number should be at least 11 characters.')
		.max(13, 'Mobile number should be only be 11 characters long.'),
	state: Yup.object()
		.shape({
			countryCode: Yup.string(),
			isoCode: Yup.string(),
			label: Yup.string(),
			latitude: Yup.string(),
			longitude: Yup.string(),
			name: Yup.string(),
			value: Yup.string().required('State is required.')
		})
		.required('State is required.'),
	city: Yup.object()
		.shape({
			countryCode: Yup.string(),
			stateCode: Yup.string(),
			name: Yup.string(),
			value: Yup.string().required('City is required.')
		})
		.required('City is required.'),
	check_box: Yup.boolean(),
	password: Yup.string().when('check_box', {
			is: true,
			then: (UpdateCheckoutSchema) => 
				UpdateCheckoutSchema.required('Password is required.')
					.min(8, 'Password length should be at least 8 characters.')
					.matches(/[A-Z\W]/g, 'Password should contain at least 1 uppercase letter.')
		}),
	password_confirmation: Yup.string().when('check_box', {
		is: true,
		then: (UpdateCheckoutSchema) => 
			UpdateCheckoutSchema.required('Confirm password is required.')
			.oneOf([Yup.ref('password')], 'Passwords does not match')
		})
});

const Checkouts = () => {
	useDocumentTitle('Check Out | BestDealNaija');
	const { 
		profile, auth, cart, 
		billing, isOrderLoading, isAuth,
		isOrderStatus
	} = useSelector((store) => ({
		profile: store?.profile,
		isAuth: !!store?.auth?.id && !!store?.auth?.user_type,
		auth: store?.auth,
		billing: store?.checkout?.billing,
		cart: store?.cart,
		isOrderLoading: store?.app?.orderLoading,
		isOrderStatus: store?.app?.orderStatus
	}));

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const subtotal = calculateArrayTotal(
		cart.map((product) => 
			product?.sale_price ? product.sale_price * product.cart_quantity
			: product.price * product.cart_quantity
		)
	);
	useEffect(() => {
        dispatch(setRequestStatus(null));
        if (isOrderStatus?.success && !isOrderStatus?.isError) {
            navigate(ROUTE.PAYMENT);
			dispatch(setOrderStatus(null));
        }
    }, [isOrderStatus]);

	const initFormikValues = {
		first_name: profile.first_name || billing.first_name || '',
		last_name: profile.last_name || billing.last_name || '',
		email: profile.email || billing.email || '',
		address: profile.address || billing.address || '',
		mobile_number: profile.mobile_number || billing.mobile_number || '',
		state: {
			countryCode: '',
			isoCode: '',
			label: '',
			latitude: '',
			longitude: '',
			name: profile?.ref_state || billing?.state,
			value: profile?.ref_state || billing?.state
		},
		city: {
			countryCode: '',
			stateCode: '',
			name: profile?.ref_local_govt || billing?.city || '',
			value: profile?.ref_local_govt || billing?.city || ''
		},
		check_box: false,
		password: '',
		password_confirmation: ''
	};

	const onSubmitForm = (form, credentials={}) => {
		let status = CONSTANT.ORDER_PAYMENT_PENDING

		if(form.check_box) {
			dispatch(signUp({
				first_name: form.first_name.trim(),
				last_name: form.last_name.trim(),
				email: form.email.trim().toLowerCase(),
				password: form.password.trim(),
				mobile_number: form.mobile_number.trim()
			}));
		}
		
		dispatch(setBillingDetails({
			first_name: form.first_name,
			last_name: form.last_name,
			email: form.email,
			address: form.address,
			mobile_number: form.mobile_number,
			state: form.state,
			city: form.city,
			isDone: true
		}));

		if (isAuth) {
			dispatch(updateProfile({
				updates: {
					first_name: form.first_name,
					last_name: form.last_name,
					email: form.email,
					mobile_number: form.mobile_number
				},
				address : {
					address: form.address,
					ref_local_govt: form.city.value,
					ref_state: form.state.value,
					user_id: auth.id
				},
				credentials
			}));

			dispatch(addOrder({
				guest: {},
				user: {
					first_name: form.first_name,
					last_name: form.last_name,
					ref_transaction_id:  null,
                    ref_type: null,
					mobile_number: form.mobile_number,
					amount: subtotal,
					currency:  'NGN',
					tax: 0,
					status: status,
					products: cart
				}
			}));
		} else {
			dispatch(updateProfile({
				updates: {
					first_name: form.first_name,
					last_name: form.last_name,
					email: form.email,
					mobile_number: form.mobile_number
					
				},
				address : {
					address: form.address,
					ref_local_govt: form.city.value,
					ref_state: form.state.value,
					email: form.email
				},
				credentials
			}));

			dispatch(addOrder({
				guest: {
					first_name: form.first_name,
					last_name: form.last_name,
					ref_transaction_id:  null,
                    ref_type: null,
					mobile_number: form.mobile_number,
					email: form.email,
					amount: subtotal,
					currency:  'NGN',
					tax: 0,
					status: status,
					products: cart
				},
				user: {}
			}));
		}
	};

	return (
		<>
			<Banner subtitleText='Check out' backgroundImage={img} />
			<div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">
				<Timeline current={2} />
				<Formik
					initialValues={initFormikValues}
					validateOnChange
					validationSchema={UpdateCheckoutSchema}
					onSubmit={onSubmitForm}
				>
					{() => (
						<div className="flex flex-col-reverse md:flex-row py-[50px]">
							<div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
								<div className="">
									<h4 className="mb-2">Billing Details</h4>
									<hr/>
								</div>
								{!isAuth && (
									<div className="mt-3 mb-3 w-auto">
										<p>Already have an account? <Link to={ROUTE.SIGNIN} 
											className="text-[#9c0]"> Sign in</Link> and you don&apos;t have to fill this </p>
									</div>
								)}
								<Form>
									<BillingForm auth={isAuth} isOrderLoading={isOrderLoading}/>
								</Form>
							</div>				
							<CartSummary />
						</div>
					)}
				</Formik>
			</div>
		</>
	);
}
export default Checkouts;
 