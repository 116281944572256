
const Sortlist =[
    {
        label: "All",
        value: "0"
    },
    {
        label: "Date",
        value: "1"
    },
    {
        label: "Payment",
        value: "2"
    },
    {
        label: "Status",
        value: "3"
    }
];

export default Sortlist;