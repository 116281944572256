
import React, {useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';
import { FaBlog } from 'react-icons/fa';
import img from '../../images/tradefair/tradefairbg.jpg';
import Banner  from '../../components/default/Banner/index';
import { useDocumentTitle, useScrollTop } from '../../hooks';

const Vendors = () => {
  useDocumentTitle('Vendors | BestDealNaija');
  useScrollTop();

  const [loading, setLoading] = useState(false);
    const [vendors, fetchVendors] = useState([]);
    
    const getData = () => {
        setLoading(true)
      fetch(`${ROUTE.USER_API}/user/vendors`)
        .then((res) => res.json())
        .then((res) => {
            fetchVendors(res.results)
            setLoading(false)
        })
    }
    useEffect(() => {
        getData()
    }, []);
            
    let vendorsList = ''
    if(vendors.length > 0){
        vendorsList =  (
            <section className="bg-gray-100">
                <div className="px-3 md:px-10 lg:px-36 py-[100px] pb-[80px] luday_wrap">                    
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9 luday_grid_wrap">
                        {vendors.map(item => 
                            <div key={item.id} className="group bg-white">
                                <article>
                                    <div>
                                        <img className="object-cover w-full h-[150px] md:h-[250px] lg:h-[300px]" src={`${CONSTANT.IMAGE_STORE}/${item.image}`} alt={item.business_name} />
                                    </div>
                                    <div className="p-[30px]">
                                        <div className="gis-text">
                                            <h2 className="text-2xl pb-2 font-semibold">{item.business_name}</h2>
                                            <div className="pb-4 text-ellipsis overflow-y-hidden h-8 overflow-x-hidden"
                                                dangerouslySetInnerHTML={{ __html: item.business_description }}></div>
                                            <div className="button-wrap mt-4">
                                                <Link to={`${ROUTE.VENDORDETAILS}/${item.slug}`} className="button button--theme 
                                                overflow-hidden bg-[#9c0] hover:bg-secondary text-white text-bold transition-all ease-in-out duration-300 rounded py-2 px-3">
                                                Vendor Details</Link>
                                            </div>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        )
    } else {
        vendorsList =  (
            <div className="flex flex-col space-y-5 -mt-36 justify-center items-center text-xl font-medium tracking-wide text-green-700 text-center h-screen">
                <FaBlog className="w-32 h-32"/>
                <h1 className="font-semibold text-3xl tracking-wider">No Vendors added yet</h1>
            </div>
        )
    }
    let vendorsLoading = (
    <>
      <section className="bg-gray-100 pt-20 md:pt-30">
          <div className="px-36 py-[100px] pb-[80px] luday_wrap">                    
              <div className="grid grid-cols-3 gap-9 luday_grid_wrap"> 
                  <div className="group bg-white">    
                      <article>
                          <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                          </div>
                          <div className="p-[30px]">
                              <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                              <div>
                                  <p  data-paceholder className="h-6 py-3 font-bold text-sm"></p>
                              </div>
                              <div data-placeholder className="w-full h-32 bg-gray-200 overflow-hidden relative">
                              </div>
                              <div className="button-wrap mt-5 w-32">
                                  <p className="button button--theme 
                                  overflow-hidden bg-gray-200 transition-all ease-in-out duration-300 rounded py-3 px-7">
                                  </p>
                              </div>
                          </div>
                      </article>
                  </div>
                  <div className="group bg-white">    
                      <article>
                          <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                          </div>
                          <div className="p-[30px]">
                              <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                              <div>
                                  <p  data-paceholder className="h-6 py-3 font-bold text-sm"></p>
                              </div>
                              <div data-placeholder className="w-full h-32 bg-gray-200 overflow-hidden relative">
                              </div>
                              <div className="button-wrap mt-5 w-32">
                                  <p className="button button--theme 
                                  overflow-hidden bg-gray-200 transition-all ease-in-out duration-300 rounded py-3 px-7">
                                  </p>
                              </div>
                          </div>
                      </article>
                  </div>
                  <div className="group bg-white">    
                      <article>
                          <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                          </div>
                          <div className="p-[30px]">
                              <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                              <div>
                                  <p  data-paceholder className="h-6 py-3 font-bold text-sm"></p>
                              </div>
                              <div data-placeholder className="w-full h-32 bg-gray-200 overflow-hidden relative">
                              </div>
                              <div className="button-wrap mt-5 w-32">
                                  <p className="button button--theme 
                                  overflow-hidden bg-gray-200 transition-all ease-in-out duration-300 rounded py-3 px-7">
                                  </p>
                              </div>
                          </div>
                      </article>
                  </div>
              </div>
          </div>
      </section>
    </>
  )

  return (
    <>
      <div className="bg-gray-100">
          
      <Banner subtitleText="Vendors" backgroundImage={img} />
      {/* <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
            <div className="flex items-center space-x-2 text-gray-400">
              <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
              <span>/</span>
              <span className="tracking-wide text-md">
                  <span className="text-base">Blogs</span>
              </span>
              <span>/</span>
            </div>
      </div> */}
          { loading
          ? vendorsLoading
          : vendorsList }
      </div>    
    </>
  );  
}
export default Vendors;
 