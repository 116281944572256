
import React, { useState } from 'react';
// import * as ROUTE from '../../constants/routes';
import { useDocumentTitle, useScrollTop } from '../../hooks';
import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/bdn_about_image_2.jpg';
import Topic from '../../components/default/Topic';
import Topic2 from '../../components/default/Topic2';
import Topic3 from '../../components/default/Topic3';
import Topic4 from '../../components/default/Topic4';
import Topic5 from '../../components/default/Topic5';

const UsersGuide = () => { 
    const title = 'B E S T &nbsp;  D E A L S &nbsp; I N &nbsp; N I G E R I A';
    const subtitle = 'Users Guide';
    useDocumentTitle('Users Guide | BestDealNaija');
    useScrollTop();
    const [check, setCheck] = useState(null);
    
    const guide = () => {
        setCheck(<Topic />);
    } 
    const guide2 = () => {
      setCheck(<Topic2 />);
    }
    const guide3 = () => {
      setCheck(<Topic3 />);
    }
    const guide4 = () => {
        setCheck(<Topic4 />);
    }
    const guide5 = () => {
        setCheck(<Topic5 />);
    }

    return (
      <>
        <Banner backgroundImage={bannerImage} titleText={title} subtitleText={subtitle} /> 
        <section className="pt-20 md:pt-30">
            <div className="lg:px-16">
                <div className="grid grid-cols-5 gap-9 luday_grid_wrap"> 
                    <div className="group col-span-2"> 
                    <div id="accordion-arrow-icon" data-accordion="collapse">
                        <p className="text-xl" id="accordion-arrow-icon-heading-1">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-900 bg-gray-100 border border-b-0 border-gray-200 rounded-t-xl focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-white dark:bg-gray-800 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-1" 
                                aria-expanded="true" 
                                aria-controls="accordion-arrow-icon-body-1"
                            >
                                <span>Overview of BestDealNaija</span>
                                <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-1" aria-labelledby="accordion-arrow-icon-heading-1">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700 dark:bg-gray-900">
                                <p>How to get started:</p>
                                <li><a onClick={guide} href="#1.2" className="accordion_links">Step 1: Sign up for an account</a></li>
                                <li><a onClick={guide} href="#1.3" className="accordion_links">Step 2: Search for deals and discounts</a></li>
                                <li><a onClick={guide} href="#1.4" className="accordion_links">Step 3: Browse and select deals</a></li>
                                <li><a onClick={guide} href="#1.5" className="accordion_links">Step 4: Promote your business</a></li>
                                <li><a onClick={guide} href="#1.6" className="accordion_links">1. How can I find a product on BestDealNaija?</a></li>
                                <li><a onClick={guide} href="#1.7" className="accordion_links">2. How do I search for deals and discounts?</a></li>
                                <li><a onClick={guide} href="#1.8" className="accordion_links">3. How can I create my own listing?</a></li>
                                <li><a onClick={guide} href="#1.9" className="accordion_links">4. How do I pay for products on BestDealNaija?</a></li>
                                <li><a onClick={guide} href="#1.10" className="accordion_links">5. How can I contact customer service?</a></li>
                                <li><a onClick={guide} href="#1.11" className="accordion_links">6. How do I change my account settings?</a></li>
                                <li><a onClick={guide} href="#1.12" className="accordion_links">7. What are the terms and conditions for using BestDealNaija?</a></li>
                            </div>
                        </div>
                        <p className="text-xl" id="accordion-arrow-icon-heading-2">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-b-0 border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-2" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-2"
                            >
                            <span>User Accounts</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-2" className="hidden" aria-labelledby="accordion-arrow-icon-heading-2">
                            <div className="p-5 font-light border border-b-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide2} href="#2.1" className="accordion_links">1. Creating a new user account?</a></li>
                                <li><a onClick={guide2} href="#2.2" className="accordion_links">2. How long does it take for a user account to be approved?</a></li>
                                <li><a onClick={guide2} href="#2.3" className="accordion_links">3. What information is required to create a user account?</a></li>
                                <li><a onClick={guide2} href="#2.4" className="accordion_links">4. How can users reset or change their passwords?</a></li>
                                <li><a onClick={guide2} href="#2.5" className="accordion_links">5. Is there a limit to the number of accounts that can be created?</a></li>
                                <li><a onClick={guide2} href="#2.6" className="accordion_links">6. Are user accounts associated with any form of payment?</a></li>
                                <li><a onClick={guide2} href="#2.7" className="accordion_links">7. Are user accounts linked to any third-party services?</a></li>
                                <li><a onClick={guide2} href="#2.8" className="accordion_links">8. Are user accounts subject to any privacy policies?</a></li>
                                <li><a onClick={guide2} href="#2.9" className="accordion_links">9. Is it possible to delete a user account?</a></li>
                                <li><a onClick={guide2} href="#2.10" className="accordion_links">10. Are there any rules regarding the use of user accounts?</a></li>
                            </div>
                        </div>
                        <p className="text-xl" id="accordion-arrow-icon-heading-3">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-3" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-3"
                            >
                            <span>Vendor Accounts</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-3" className="hidden" aria-labelledby="accordion-arrow-icon-heading-3">
                            <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide3} href="#3.1" className="accordion_links">1. Creating a Vendor Account</a></li>
                                <li><a onClick={guide3} href="#3.2" className="accordion_links">2. How do I list my products on Best Deal Naija?</a></li>
                                <li><a onClick={guide3} href="#3.3" className="accordion_links">3. What types of payment methods are accepted?</a></li>
                                <li><a onClick={guide3} href="#3.4" className="accordion_links">4. What fees are associated with becoming a vendor?</a></li>
                                <li><a onClick={guide3} href="#3.5" className="accordion_links">5. How do I handle customer inquiries about my products?</a></li>  
                                <li><a onClick={guide3} href="#3.6" className="accordion_links">6. What customer support services does bestdealnaija offer?</a></li>
                                <li><a onClick={guide3} href="#3.7" className="accordion_links">7. How do I manage shipping and fulfillment of orders?</a></li>
                                <li><a onClick={guide3} href="#3.8" className="accordion_links">8. How often do I receive payments from the marketplace website?</a></li>
                                <li><a onClick={guide3} href="#3.9" className="accordion_links">9. What are the marketing opportunities available to vendors?</a></li>
                                <li><a onClick={guide3} href="#3.10" className="accordion_links">10. Are there any restrictions on what products I can list on the marketplace website?</a></li>
                                <li><a onClick={guide3} href="#3.11" className="accordion_links">11. How do I track sales and customer feedback?</a></li>
                            </div>
                        </div>
                        <p className="text-xl" id="accordion-arrow-icon-heading-4">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-4" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-4"
                            >
                            <span>Managing your Account</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-4" className="hidden" aria-labelledby="accordion-arrow-icon-heading-4">
                            <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide4} href="#4.1" className="accordion_links">1. Log in to your account</a></li>
                                <li><a onClick={guide4} href="#4.2" className="accordion_links">2. View your transaction history</a></li>
                                <li><a onClick={guide4} href="#4.3" className="accordion_links">3. View your vendor accounts</a></li>
                                <li><a onClick={guide4} href="#4.4" className="accordion_links">4. Editing profile information</a></li>
                                <li><a onClick={guide4} href="#4.5" className="accordion_links">5. Change your password</a></li>  
                                <li><a onClick={guide4} href="#4.6" className="accordion_links">6. Manage your payment information</a></li>
                            </div>
                        </div>
                        <p className="text-xl" id="accordion-arrow-icon-heading-5">
                            <button type="button" className="bg-primary text-black flex items-center justify-between w-full p-5 font-medium text-left text-gray-500 border border-gray-200 focus:ring-4 focus:ring-gray-200 dark:focus:ring-gray-800 dark:border-gray-700 dark:text-gray-400 hover:bg-gray-100 dark:hover:bg-gray-800" 
                                data-accordion-target="#accordion-arrow-icon-body-5" 
                                aria-expanded="false" 
                                aria-controls="accordion-arrow-icon-body-5"
                            >
                            <span>Managing Orders</span>
                            <svg data-accordion-icon className="w-6 h-6 shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13l-3 3m0 0l-3-3m3 3V8m0 13a9 9 0 110-18 9 9 0 010 18z"></path></svg>
                            </button>
                        </p>
                        <div id="accordion-arrow-icon-body-5" className="hidden" aria-labelledby="accordion-arrow-icon-heading-5">
                            <div className="p-5 font-light border border-t-0 border-gray-200 dark:border-gray-700">
                                <li><a onClick={guide5} href="#5.1" className="accordion_links">1. How do I process customer orders?</a></li>
                                <li><a onClick={guide5} href="#5.2" className="accordion_links">2. How do I set shipping and pricing information?</a></li>
                                <li><a onClick={guide5} href="#5.3" className="accordion_links">3. How can I manage inventory?</a></li>
                                <li><a onClick={guide5} href="#5.4" className="accordion_links">4. How do I issue refunds?</a></li>
                                <li><a onClick={guide5} href="#5.5" className="accordion_links">5. How do I track customer payments?</a></li>
                                <li><a onClick={guide5} href="#5.6" className="accordion_links">6. How do I set up email notifications for orders?</a></li>
                                <li><a onClick={guide5} href="#5.7" className="accordion_links">7. What security measures are in place to protect customer information?</a></li>
                            </div>
                        </div>
                      </div>                 
                    </div>
                    <div className="group col-span-3"> 
                    <div className="p-[15px]">    
                        <h1 className="userguide_header text-green-600">Users Guide</h1> 
                        {check}
                    </div>
                    </div>
                </div>
            </div>
        </section>
      </>
    );
}
export default UsersGuide;
 