
import PropType from 'prop-types';
import React from 'react';

const Badge = ({ count }) => (
  <>
    {count >= 1 &&
      (<span className='bg-[#98c01d] rounded-full text-white pt-0.5 pl-[6px] h-5 w-5 absolute md:bottom-[50px] lg:bottom-8 text-xs ml-4'>
        {count}
      </span>)
    }
  </>
);

Badge.propTypes = {
  count: PropType.number.isRequired
};

export default Badge;
 