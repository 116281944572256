
import React from 'react';
import { useSelector } from 'react-redux';
import Banner from '../../components/default/Banner/index';
import bannerImage from '../../images/bdn_about_image_2.jpg';
import Accnav from '../../components/default/Navigation/account/index';
import { useDocumentTitle, useOrders } from '../../hooks';
import OrderItem from './orders/OrderItem';

const OrderHistory = () => {
    useDocumentTitle('Order History | BestDealNaija');
    const { token } = useSelector((state) => ({
		token: state.profile.access_token
	}));

    const { orders, isLoading } = useOrders(token);
 
	return (
		<>
			<Banner backgroundImage={bannerImage} subtitleText='My Order History'/>
			<div className="py-2 px-[10px] mt-10 md:px-[60px] lg:px-[90px]">                    
				<div className="flex flex-col md:flex-row py-[50px]">
					<div className="w-full md:w-1/3 m-2 border border-black-600 drop-shadow-md p-5 h-1/2">
					<Accnav />
					</div>
					<div className="w-full md:w-2/3 m-2 border border-black-600 drop-shadow-md p-5">
						<div className="overflow-x-auto">
							
							<table className="divide-y divide-gray-300 w-full">
								<thead className="bg-gray-50" >
									<tr>
										<th className="px-6 py-2 text-center whitespace-nowrap">Order ID</th>
										<th className="px-6 py-2 text-center">Order Status</th>
										<th className="px-6 py-2 text-center">Delivery Status</th>
										<th className="px-6 py-2 text-center">Total price</th>
										<th className="px-6 py-2 text-center">Date</th>
										<th className="px-6 py-2 text-center">View Details</th>
									</tr>
								</thead>
								{orders.length > 0 ?
                                    <tbody>
                                        {orders?.map((order) => (
                                            <>
                                                <tr className="border border-black-600 whitespace-nowrap">
                                                    <OrderItem key={order.order_id} order={order} />
                                                </tr>
                                            </>
                                        ))}
                                    </tbody>
								:
                                    <tbody>
                                        <tr className="border border-black-600 text-center">
                                            <td className="items-cente p-3 w-[200px]" colSpan={6}>
                                                <p className="text-lg">{isLoading ? 'Fetching...' : 'No order found'}</p>
                                            </td>
                                        </tr>
                                    </tbody>
								}
							</table>
						</div>
					</div>
				</div>
			</div>
		</>
	);  
}
export default OrderHistory;
 