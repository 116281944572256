
import React from 'react';
const Topic3 = () => {
  return (
    <>
      <div>
        <p className="userguide_header">Vendor Accounts</p>
        <p className="userguide_subheader anchor_link_section" id="3.1">1. Creating a Vendor Account</p>
        <p>a. To become a vendor, create a vendor account by visiting [VENDOR ACCOUNT CREATION LINK] and filling out the form with the relevant information.</p>
        <p>b. You will be routed to a Your vendor profile page where you will be required to complete your account by providing additional information about your business.</p>
        <p>c. Once your information is complete and submitted, your account will undergo a period during which it will be reviewed. After the review, if your account is approved, 
        you may begin selling on Best deal Naija, otherwise, you may need to apply for another review by updating your vendor profile details.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.2">2. How do I list my products on Best Deal Naija?</p>
        <p>You can list your products on the Best Deal Naija marketplace by signing up as a vendor. Once you have created and 
        verified your vendor status, you can begin to make product listings on bestdealnaija.
        </p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.3">3. What types of payment methods are accepted?</p>
        <p>Purchases can be made from bestdealnaija using Debit Cards, Bank transfers, or USSD codes. When a user makes a purchase, 
        the vendors payment is made to the vendor.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.4">4. What fees are associated with becoming a vendor?</p>
        <p>There are no fees associated with being a vendor on bestdealnaija. Vendor accounts are free and do not come with any charges.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.5">5. How do I handle customer inquiries about my products?</p>
        <p>Customer inquiries about your products are made to you directly via your business contact information provided when creating a vendor account. You can handle 
        these inquiries by contacting the customer to provide feedback either by call or by replying by email.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.6">6. What customer support services does bestdealnaija offer?</p>
        <p>Our online marketplace offers customer support services to ensure our clients satisfaction. To address any questions or concerns our customers may have, we offer 24/7 customer service, live chat support, email support, and phone support. 
        We also have a large knowledge base and FAQs to help customers find answers to their questions quickly.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.7">7. How do I manage shipping and fulfillment of orders?</p>
        <p>Our online marketplace makes managing shipping and fulfillment easy. Customers can easily set up their preferred shipping method, view tracking information, and print shipping labels. We also offer third-party support for customers who choose to use a different provider. 
        Our intuitive dashboard makes it simple to track orders and manage fulfillment. Customers can also access their order history, view estimated delivery times, and track their shipments in real-time.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.8">8. How often do I receive payments from the marketplace website?</p>
        <p>Payments from bestdealnaija are forwarded to the vendor account as soon as the customer confirms receipt of the goods they ordered. Payment 
        typically takes the amount of time required to deliver the product to the client.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.9">9. What are the marketing opportunities available to vendors?</p>
        <p>BestDealNaija offers comprehensive marketing services to help local businesses in Nigeria reach their target audience and maximize their online presence. Services include search engine optimization, local search, display advertising, social media promotion, digital promotion, and discount and deal listings. 
        With these services, businesses can attract more customers, build brand awareness and increase their sales.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.10">10. Are there any restrictions on what products I can list on the marketplace website?</p>
        <p>Yes, there are restrictions on the kind of products that can be listed on bestdealnaija. Illegal items 
        such as weapons and drugs are not permitted on the website. Any account caught posting offensive content 
        will be blocked permanently</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="3.11">11. How do I track sales and customer feedback?</p>
        <p>BestDealNaija offers several tools to help businesses track sales and customer feedback. Businesses can 
        set up tracking pixels on their website to measure conversions and track performance. They can also use 
        analytics tools to monitor website traffic and measure the effectiveness of their campaigns. Additionally, 
        BestDealNaija provides customer feedback forms so businesses can collect customer reviews and ratings to 
        measure customer satisfaction.</p>
      </div>
    </>
  )
}
export default Topic3
 