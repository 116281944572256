
import React, { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import RecommendedDeals from '../deals/FindDeal';
import { addToCart } from '../../redux/actions/cartActions';

import {Banner, Search} from '../../components/default/index'
import img from '../../images/tradefair/tradefairbg.jpg';
import * as CONSTANT from '../../constants/constants'
import * as ROUTE from '../../constants/routes';
import './styles/CategoryProduct.css';
import { searchProduct } from '../../redux/actions/productActions';
import { useDidMount } from '../../hooks';
import { setLoading, setRequestStatus } from '../../redux/actions/miscActions';
import { displayMoney } from '../../helpers/utils';

const SearchProduct = () => {
    const {searchQuery} = useParams();
    const dispatch = useDispatch();
    const didMount = useDidMount(true);

    const store = useSelector((state) => ({
        isLoading: state.app.loading,
        products: state.products.searchedProducts.items,
        search: state.products.searchedProducts,
        basket: state.basket,
        requestStatus: state.app.requestStatus
    }));
    const lastRefKey = store.search.lastRefKey

    useEffect(() => {
        if (store?.products?.length === 0 || !lastRefKey) {
            if (didMount && searchQuery && !store.isLoading) {
                dispatch(searchProduct(searchQuery));
            }
            window.scrollTo(0, 0);
            return () => dispatch(setLoading(false));
        }
    }, [searchQuery]);

    useEffect(() => {
        dispatch(setRequestStatus(''));
    }, [lastRefKey]);

    const getSearchProducts = () => {
		dispatch(searchProduct(searchQuery, lastRefKey));
    }

    const btnIncreaseQtyCartList = (product) => {
        dispatch(addToCart(product));
    }

    let productsList = ''
    if (store.products.length !== 0) {
        productsList = (<div className="px-3 md:px-[120px] lg:px-[120px] py-[20px] pb-[40px] luday_wrap">  
        <div className="text-center">
            <div className="px-3 md:px-10 lg:px-36 py-[20px] pb-[40px] luday_wrap">
                <h1 className="text-4xl">Showing search for &quot; {searchQuery} &quot;</h1>
            </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2">                        
            {store.products.map(product =>
                <div className="place-self-stretch" key={product.id}>
                    <div className="group mx-0 px-2 py-2">
                        <div className='products'>
                            <Link to={`${ROUTE.PRODUCT_DETAILS}/${product.slug}`} state={product}>
                                <div className="pb-[5px] text-center bg-white shadow-xl">
                                    <img className="lazy h-60 w-full" src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`} alt={product.name} />            
                                    <div className="gis-text text-center pt-4">
                                        <p className="text-lg md:text-xl lg:text-xl font-bold overflow-hidden text-ellipsis whitespace-nowrap px-3">{product.name}</p>
                                        <div className="row text-center">
                                            <p className="text-md font-medium order-first text-bold text-center">
                                                {product.sale_price ? (
                                                    <span>&#8358;{displayMoney(product.sale_price)}</span>
                                                ) : ""
                                                } &nbsp;  
                                                <span className={
                                                product.sale_price ? "line-through font-light text-center text-sm mr-1 text-gray-500" : "" }>
                                                    &#8358;{displayMoney(product.price)}
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                            <div className="row px-[10px] py-2 text-center bg-white">                                       
                                <div className="custom-number-input w-full pb-3">
                                    <div className="row w-full rounded-lg relative bg-transparent">
                                        <button onClick={() => btnIncreaseQtyCartList(product)} className="w-full mt-1 font-semibold leading-none text-white py-4 px-10 bg-[#98c01d] rounded-md hover:bg-[#88af14] focus:ring-2 focus:ring-offset-2 focus:outline-none cursor-pointer md:w-2/3">Add to cart</button>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            )}
        </div><br />
        {store.search.nextPage && (
            <div className="flex space-x-2 justify-center pb-10">
                <button 
                    type="button"
                    disabled={store.isLoading}
                    onClick={getSearchProducts}
                    className="inline-block px-6 py-2.5 bg-[#98c01d] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-[#88af14] hover:shadow-lg focus:[#88af14] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#98c01d] active:shadow-lg transition duration-150 ease-in-out">
                    {store.isLoading ? 'Fetching Items...' : 'Show More Items'}
                </button>
            </div>
        )}

        <div className="justify-center mx-auto text-center">Click <Link to={`${ROUTE.SIGNUP_VENDOR}`} className="text-[#88af14]"> HERE</Link> to start SELLING your PRODUCTS now  </div>
    </div>) 
    }
    else {
        productsList = (
            <div className="text-center">
                <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">
                    <h1 className="text-4xl">No products matched the search &quot; {searchQuery} &quot;</h1>
                </div>
            </div>
        )
    }

    return (
        <>
        <Banner subtitleText='Products' backgroundImage={img} />
        <div className="text-center mt-12 md:mt-5">
            <Search />
        </div>
       
        <div className="">
            {store.isLoading ?
                <div className="py-2">
                    <div className="px-3 md:px-10 lg:px-36 py-[50px] pb-[80px] luday_wrap">  
                        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-2 gap-9">
                            <div className="w-full flex items-stretch flex-col p-[10px] pb-[5px]">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full flex items-stretch flex-col p-[10px] pb-[5px]">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full  items-stretch flex-col p-[10px] pb-[5px] hidden md:flex">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="w-full items-stretch flex-col p-[10px] pb-[5px] hidden md:flex">
                                <div className="bg-white shadow rounded">
                                    <div data-placeholder className="w-full h-60 bg-gray-200 overflow-hidden relative">
                                    </div>
                                    <div className="pt-4 p-3">
                                        <p data-placeholder className="h-7 mb-2 w-full md:w-4/5 bg-gray-200 overflow-hidden relative"></p>
                                        <p data-placeholder className="h-4 mb-1 w-1/3 bg-gray-200 overflow-hidden relative"></p>
                                        <span data-placeholder className="h-6 mt-4 w-20 rounded-md bg-gray-200 overflow-hidden relative block px-[10px]">  
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            :
                (
                    <section className="bg-gray-50">
                        { productsList }
                    </section> 
                )
            }
        </div>
        <RecommendedDeals />
        </>
    )
}
export default SearchProduct;
 