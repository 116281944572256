
import React, { useEffect, useState, useCallback } from 'react';
import { Link, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import * as ROUTE from '../../../constants/routes'
import swal from 'sweetalert'
import axios from 'axios'
import { FaAngleLeft } from 'react-icons/fa'
import * as CONSTANT from '../../../constants/constants'

const EditCategories = () => {
const [categoryId, setCategoryId] = useState("");
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [description, setDescription] = useState("");
    const [image, setImage] = useState();
    const {register, handleSubmit, reset, setValue, formState: { errors } } = useForm();
    const [selectedImage, setSelectedImage] = useState();
    const [categoryImage, setCategoryImage] = useState();
    let formData = new FormData();

    const { id } = useParams();
    const getData = async () => {
        setLoading(true)
        await fetch(`${ROUTE.PRODUCTS_API}/categories/${id}`)
          .then((res) => res.json())
          .then((res) => {
            setCategoryId(res.data.id)
            setName(res.data.name)
            setDescription(res.data.description)
            setImage(res.data.image_path)
            setLoading(false)
          })
    }

    useEffect(() => {
        getData()
    }, [])
    useEffect(() => {
        setTimeout(() => 
            setValue("name", name),
            setValue("description", description)
        );
    }, [loading]);

    const onSelectFile = useCallback( async (e)  => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setCategoryImage(file)
        reader.onloadend = () => {
            setSelectedImage(reader.result);
        };
    }, []);
    const submitForm = (data) => {
        formData.append('category_image', categoryImage)
        formData.append('name', data.name);
        formData.append('description', data.description)        

        const requestOptions = {
            method: "POST",
            headers: {
              'Content-type': 'multipart/form-data'
            }
        }
        axios.put(
            `${ROUTE.PRODUCTS_API}/categories/${categoryId}`,
            formData,
            requestOptions
        ).then(res => res)
        .then(data =>{
          successAlert(data)
        })
        .catch(err => errorAlert(err))
  
      reset()
      setSelectedImage('');
    }

    const successAlert = (response) => {
        return(
          swal({
              title: "Info!",
              text: response.data.message,
              icon: "success"
          }).then(function () {
                getData()
          })
        )
    }
    const errorAlert = (error) => {
        return(
          swal({
              title: "Error!",
              text: error,
              icon: "error"
          }).then(function () {
            getData()
          })          
        )
    }
  return (
    <div className="bg-gray-100">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
              <div className="flex items-center space-x-2 text-gray-400">
                <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.ADMIN_DASHBOARD}>Home</Link></span>
                <span>/</span>
                <span className="text-green-700 tracking-wider font-thin text-md">
                    <Link to={ROUTE.ADMIN_CATEGORIES}>Back to Categories</Link>
                </span>
                <span>/</span>
                <span className="tracking-wide text-md">
                    <span className="text-base">Edit Categories</span>
                </span>
                <span>/</span>
              </div>
        </div>
        <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
                <h1 className="font-medium text-2xl">Edit Categories</h1>
                <Link to={ROUTE.ADMIN_CATEGORIES} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
                </span> Back to Categories</Link>
            </div>
        {loading
        ? (<div className="h-full flex justify-center items-center">
                <h3 className="font-bold text-green-600 text-2xl mx-auto ">Loading...</h3>
            </div>
        )
        :
        (<div className="w-full lg:w-1/2 mx-auto m-1">
            {/* Display edit category form */}
            <form className="w-full bg-white shadow-md p-6" encType="multipart/form-data">
                <div className="flex flex-wrap -mx-3 mb-6">
                        <div className="w-full md:w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='name'>Category Name</label>
                            <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="name" placeholder="Category Name"  {...register("name", { required: true})}
                            required onChange={(e) => setName(e.target.value)} />
                            {errors.name && <small className="text-red-500 text-xs italic">Category name is required</small>}
                            {errors.name?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 25 </small></p>}
                        </div>
                        
                        <div className="w-full px-3 mb-6">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Description</label>
                            <textarea rows="4" className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="description" {...register("description", { required: true })} required onChange={(e) => setDescription(e.target.value)} />
                            {errors.description && <small className="text-red-500 text-xs italic">Description is required</small>}
                        </div>
                        <div className="w-full px-3 mb-8">
                            <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='dropzone-file'>
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                            </svg>

                            <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Category Image</h2>

                            <p className="mt-2 text-gray-500 tracking-wide">Upload or drag & drop your file SVG, PNG, JPG or GIF. </p>

                            <input id="dropzone-file" type="file" className="hidden" name="category_image" onChange={onSelectFile} accept="image/png, image/jpeg, image/webp"/>
                            </label>
                            {errors.category_image && <small className="text-red-500 text-xs italic">Category image is required</small>}
                        </div>
                        <div className="w-full mx-12 flex justify-center mb-5">
                            {image &&
                                (
                                    <img src={`${CONSTANT.IMAGE_STORE}/${image}`} className={selectedImage ? "hidden" : `w-32 h-32 $`}/>
                                )

                            }
                            
                            {selectedImage &&
                                (
                                    <img src={selectedImage} className="w-32 h-32"/>
                                )

                            } 
                        </div>
                        <div className="w-full md:w-full px-3 mb-6">
                            <button className="appearance-none block w-full bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                            onClick={handleSubmit(submitForm)}
                            >Update Category</button>
                        </div>
                    </div>
            </form>
        </div>
        )

        }
    
    </div>
  )
}
export default EditCategories;
 