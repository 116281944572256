
import {
  CLEAR_PROFILE,
  SET_PROFILE,
  UPDATE_EMAIL,
  UPDATE_PROFILE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_ADDRESS_SUCCESS,
  UPDATE_VENDOR_PROFILE
} from '../../constants/constants';

export const clearProfile = () => ({
  type: CLEAR_PROFILE
});

export const setProfile = (user, token) => ({
  type: SET_PROFILE,
  payload: user,
  token: token
});

export const updateEmail = (password, newEmail) => ({
  type: UPDATE_EMAIL,
  payload: {
    password,
    newEmail
  }
});

export const updateProfile = (newProfile) => ({
  type: UPDATE_PROFILE,
  payload: {
    updates: newProfile.updates,
    address: newProfile.address,
    credentials: newProfile.credentials
  }
});

export const updateVendorProfile = (updates) => ({
  type: UPDATE_VENDOR_PROFILE,
  payload: updates
});

export const updateProfileSuccess = (updates) => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: updates
});

export const updateProfileAddressSuccess = (profile, address) => ({
  type: UPDATE_PROFILE_ADDRESS_SUCCESS,
  payload: {
    user: profile,
    address: address
  }
});
 