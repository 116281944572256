
import React from 'react';
const Owners_Topic4 = () => {
  return (
    <>
      <div><p className="userguide_header">Managing Categories</p></div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.1">Understanding categories</p>
<p>Categories enable products and vendors to be organized according to product type under a spectrum. Each vendor and product has a category it belongs to which makes it easy to find a specific set of products within a specific scope.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.2">Creating Categories</p>
<p>To create a new category click on the categories link on the navigation menu. You will be routed to a categories page where you can add a new category. To add a new category fill in the category name, a brief description of the category and upload a category image then click “add category”.</p>
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.3">Editing Categories</p>
<p>To edit any category click on the categories link from the menu and navigate to the categories page. On the categories page, a tabulated list of all created categories is visible. Under the action column, you can find an option to edit the category on that row. Click edit and fill out the new category information click save </p>  
      </div>
      <div>
        <p className="userguide_subheader anchor_link_section" id="4.4">Deleting Categories</p> 
<p>To delete any category click on the categories link from the menu and navigate to the categories page. On the categories page, a tabulated list of all created categories is visible. Under the action column, you can find an option to delete the category on that row. Click delete to remove the category</p>     
      </div>
    </>
  )
}
export default Owners_Topic4
 