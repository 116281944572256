
import React, { useEffect, useState }  from 'react';
import { Link } from 'react-router-dom';

import { useDocumentTitle, useScrollTop } from '../../hooks';
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';

const FindDeal = () => {
  useDocumentTitle('Find your deals in Nigeria | BestDealNaija');
  useScrollTop();

  const [categories, fetchcategories] = useState([]);
  const getData = () => {
    fetch(`${ROUTE.PRODUCTS_API}/categories`)
      .then((res) => res.json())
      .then((res) => {
        fetchcategories(res.results)
      })
  }

  useEffect(() => {
      getData()
  }, [])

  return (
    <div className=''>
      <div className="px-10 luday_wrap mb-10 ">
          <div className="text-center py-7 mt-24 md:mt-30">
            <p className="tracking-widest text-xs text-gray-500 mb-5">B E S T  &nbsp;  D E A L S  &nbsp;  I N  &nbsp;  N I G E R I A</p>
            <h3 className="text-6xl luday_deals">Recommended Deals!</h3>
          </div>

          <div className="w-full grid grid-cols-1 gap-4 md:grid-cols-5 md:gap-7 ">
          {categories && (
              categories.map(category =>
              <div className="group" key={category.id}>
                  <div className='products'>
                      <Link to={`${ROUTE.CATEGORY_PRODUCTS}/${category.slug}`} className="luday_grid_links">
                      <h4 className="luday_grid_texts" >{category.name}</h4>
                      <div className='find_deal_bg'></div>
                      {/* <img src={slider2} className="luday_grid_images" /> */}
                      <img src={`${CONSTANT.IMAGE_STORE}/${category.image_path}`} className="luday_grid_images" />
                      </Link>
                      
                  </div> 
              </div>
            )
          )}
          </div>
      </div>
    </div>
  )
}
export default FindDeal;
 