
import React, {useCallback, useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { FaAngleLeft, FaPlus } from 'react-icons/fa';
import * as ROUTE from '../../../constants/routes';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const VendorAddProduct = () => {
  const {register, handleSubmit, reset, formState: { errors }, clearErrors } = useForm();
  const [categories, fetchCategories] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [productImage, setProductImage] = useState();
  const [descriptionContent, setDescriptionContent] = useState('');
  let formData = new FormData();

  const handleChange = (e, editor) => {
    clearErrors('description');
    setDescriptionContent(editor.getData())
  }

  const { userId } = useSelector((state) => ({
		userId: state.profile.id
	}));
  const [selectedImages, setSelectedImages] = useState([]);
  const [productGalleryImage, setProductGalleryImage] = useState([]);
    
  const onSelectMultipleImages = useCallback( async (e)  => {
    const selectedFilesArray = Array.from(e.target.files)
    selectedFilesArray.map((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        setProductGalleryImage((productGalleryImage) =>[...productGalleryImage, file]);     
        reader.onloadend = () => {
            setSelectedImages((selectedImages) =>[...selectedImages, reader.result]);
        };
    })
  }, []);

  const onSelectFile = useCallback( async (e)  => {
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setProductImage(file)
      reader.onloadend = () => {
          setSelectedImage(reader.result);
      };
  }, []);

  const submitForm = (data) => {
      formData.append('product_image', productImage)
      formData.append('name', data.product_name);
      formData.append('description', descriptionContent)
      formData.append('price', data.price)
      formData.append('sale_price', data.sale_price)
      formData.append('quantity', data.quantity)
      formData.append('category_id', data.category)
      formData.append('user_id', userId)
      Array.from(productGalleryImage).forEach(item => {
          formData.append('product_gallery_image', item)
      })

      const requestOptions = {
          headers: {
            'Content-type': 'multipart/form-data'
          }
      }
      axios.post(
          `${ROUTE.PRODUCTS_API}/products`,
          formData,
          requestOptions
      ).then(res => res)
      .then(data =>{
        if (data.status == 201 || data.status == 302) {
              successAlert(data)
          }
          else {
              errorAlert(data.message)
          }
      })
      .catch(err => errorAlert(err))
  }
  
  const successAlert = (response) => {
      return(
        swal({
            title: "Info!",
            text: response.data.message,
            icon: "success"
        }).then(function() {
          reset()
          setSelectedImage('')
          setSelectedImages('')
          setProductImage('')
          setProductGalleryImage('')
        })           
      )
  }

  const errorAlert = (error) => {
    return(
      swal({
          title: "Error!",
          text: error.message,
          icon: "error"
      })              
    )
  }
  const getCategories = () => {
    fetch(`${ROUTE.PRODUCTS_API}/categories`)
      .then((res) => res.json())
      .then((res) => {
      fetchCategories(res.results)
    })
  }

  useEffect(() => {
    getCategories()
  }, [])

  let categoryDropdown = ''
  categoryDropdown = categories.map((item, i) => {
    return (<option className="my-2 hover:bg-gray-50" key={i} value={item.id}> {item.name} </option>)
  }) 

  return (
    <div className="bg-gray-100">
        <div className="header bg-white h-16 px-10 py-8 border-b-2 border-gray-200 flex items-center justify-between">
          <div className="flex items-center space-x-2 text-gray-400">
            <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.VENDOR_DASHBOARD}>Home</Link></span>
            <span>/</span>
            <span className="tracking-wide text-md">
                <span className="text-green-700 tracking-wider font-thin text-md"><Link to={ROUTE.VENDOR_PRODUCTS}>Products</Link></span>
            </span>
            <span>/</span>
            <span className="tracking-wide text-md">
                <span className="text-base">Add Products</span>
            </span>
          </div>
        </div>
        <div className="header my-3 h-12 px-10 py-8  flex items-center justify-between">
            <h1 className="font-medium text-2xl">Add Products</h1>
            <Link to={ROUTE.VENDOR_PRODUCTS} className="focus:outline-none text-white m-4 p-3 font-semibold rounded-md bg-green-700 hover:bg-green-600 hover:shadow-lg transition-all duration-100"> <span><FaAngleLeft className="inline-block w-5 h-5"/>
            </span> Back to products</Link>
        </div>
        <div className="flex flex-col mx-3 lg:flex-row">
            <form className="w-full lg:w-3/5 bg-white shadow-md p-6" encType="multipart/form-data">
                <div className="flex flex-wrap -mx-3 mb-6">
                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='product_name'>Product Name</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='text' name="product_name" placeholder="Enter product Name"
                        {...register("product_name", { required: true})}
                          />
                        {errors.product_name && <small className="text-red-500 text-xs italic">Product name is required</small>}
                        {errors.product_name?.type === "maxLength" && <p style={{ color: "red" }}><small>Max characters should be 25 </small></p>}
                    </div>

                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='category'>Category</label>
                        <select className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" name="category" {...register("category", { required: true })}
                        >
                            <option value={''}>Select category</option>
                            { categoryDropdown }
                        </select>

                        {errors.category && <small className="text-red-500 text-xs italic">Select at least one category</small>}
                    </div>

                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='price'>Price</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type='number' name="price" placeholder="Enter price" {...register("price", { required: true })}
                        />
                        {errors.price && <small className="text-red-500 text-xs italic">Price is required</small>}
                    </div>

                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='sale_price'>Discount Price</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" type="number" inputMode="numeric" name="sale_price" placeholder="Enter sale price" {...register("sale_price")}
                        />
                    </div>

                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='quantity'>Count in stock</label>
                        <input className="appearance-none block w-full bg-white text-gray-900 font-medium border border-gray-400 rounded-lg py-3 px-3 leading-tight focus:outline-none focus:border-[#98c01d]" inputMode="numeric" type='number' name="quantity" placeholder="Enter count in stock"
                        {...register("quantity", { required: true })}
                        />
                        {errors.quantity && <small className="text-red-500 text-xs italic">Count in stock is required</small>}
                    </div>
                    
                    <div className="w-full px-3 mb-6">
                        <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" htmlFor='description'>Description</label>
                        <CKEditor 
                            editor={ClassicEditor}
                            onChange={handleChange} data={descriptionContent} className="w-full"
                        />
                        {errors.description && <small className="text-red-500 text-xs italic">Description is required</small>}
                    </div>
                    <div className="w-full mx-auto px-3 mb-12">
                        <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='product_image'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>

                        <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Product image</h2>

                        <p className="mt-2 text-gray-500 tracking-wide">Upload or drag & drop your file SVG, PNG, JPG or GIF. </p>

                        <input name="product_image" id="product_image" type="file" className="hidden" onChange={onSelectFile} accept="image/png, image/jpeg, image/webp"/>
                        </label>
                        {errors.product_image && <small className="text-red-500 text-xs italic">Product image is required</small>}
                    </div>
                    <div className="w-full flex justify-center mb-5">
                        {selectedImage &&
                            (
                                <img src={selectedImage} className="w-32 h-32"/>
                            )
                        } 
                    </div>
                    <div className="w-full px-3 flex justify-end mb-5 text-green-500">
                        <p className="flex mx-4 "> <span> <FaPlus className="w-4 h-4 mr-1 inline-flex" /> </span>Add product gallery images</p>
                    </div>

                    <div className="w-full px-3 mb-6">
                    <label className="mx-auto cursor-pointer flex w-full max-w-lg flex-col items-center justify-center rounded-xl border-2 border-dashed border-green-400 bg-white p-6 text-center" htmlFor='product_gallery_images'>
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-10 w-10 text-green-800" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                        </svg>

                        <h2 className="mt-4 text-xl font-medium text-gray-700 tracking-wide">Product gallery images</h2>

                        <p className="mt-2 text-gray-500 tracking-wide">Upload or drag multiple images SVG, PNG, JPG or GIF. </p>

                        <input type="file" className="hidden" name="product_gallery_images" id="product_gallery_images" onChange={onSelectMultipleImages} multiple accept="image/png, image/jpeg, image/webp"/>
                        </label>
                    </div>
                    <div className="overflow-x-auto flex justify-center mb-5 mx-auto">
                        {selectedImages &&
                            selectedImages.map((image, index) => {
                                return (
                                        <>
                                            <img key={index} src={image} className="w-32 h-32 relative"/>
                                            {/* <span className="relative"><button className="text-red-500 font-weight-bolder top-0" onClick={(e)=>removeImages(image, e)}><FaTimes/></button></span>   */}
                                        </>
                                )                                    
                            })
                        }
                    </div>
                    <div className="w-full px-3 mb-6">
                        <button className="appearance-none w-full block bg-green-700 text-gray-100 font-bold border border-gray-200 rounded-lg py-3 px-3 leading-tight hover:bg-green-600 focus:outline-none focus:bg-white focus:border-gray-500"
                        onClick={handleSubmit(submitForm)}>Add Product</button>
                    </div>
                </div>
            </form>          
        </div>        
    </div>
  )  
}
export default VendorAddProduct;
 