

import { useCallback, useEffect, useState } from 'react';

import useDidMount from './useDidMount';
import * as ROUTE from '../constants/routes';

const useVendorByUserId = () => {      
	const [vendor, setVendor] = useState([]);
	const [error, setError] = useState('');
	const [isLoading, setLoading] = useState(false);
	const didMount = useDidMount(true);

	const fetchVendorByUserId = useCallback(async (user_id) => {
		try {
			setError('');
			const requestOptions = {
				method: "GET",
				headers: {
					'Content-Type': 'application/json',
					'Accept': 'application/json'
				}
			}
			setLoading(true)
			const _order =  await fetch(`${ROUTE.USER_API}/user/vendor?user_id=${user_id}`, 
								requestOptions)
				.then(res => res.json())
				.then(data =>{
					return data
				})
				.catch(err => console.log(err))

			if (_order.length == 0) {
				if (didMount) {
					setError('No order for guest user.');
					setLoading(false);
				}
			} else {
				const data = _order;
				if (didMount) {
					setLoading(false);
					setVendor(data);
				}
			}
		} catch (e) {
			if (didMount) {
				setError('Failed to fetch order');
			}
		}
	}, [didMount]);

	useEffect(() => {
		if (vendor.length === 0 && didMount) {
			fetchVendorByUserId();
		}
	}, []);

	return {
		vendor, fetchVendorByUserId, isLoading, error
	};
};

export default useVendorByUserId;
 