
import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import PropType from 'prop-types';

import * as ROUTE from '../../../constants/routes';
import { getProducts } from "../../../redux/actions/productActions";
import { setLoading } from "../../../redux/actions/miscActions";
import { useDispatch } from "react-redux";

const ProductList = (props) => {
	const {
		products, children
	} = props;
	const [isFetching, setFetching] = useState(false);
	const dispatch = useDispatch();

	const fetchProducts = () => {
		setFetching(true);
		dispatch(getProducts(products.lastRefKey));
	};

	useEffect(() => {
		if (products.items.length === 0 || !products.lastRefKey) {
			fetchProducts();
		}
		
		window.scrollTo(0, 0);
		return () => dispatch(setLoading(false));
	}, []);
	
	useEffect(() => {
		setFetching(false);
	}, [products.lastRefKey]);

    return (
		<>
			{children}
			{products.nextPage && (
				<div className="flex space-x-2 justify-center pb-10">
					<button 
						type="button"
						disabled={isFetching}
						onClick={fetchProducts}
						className="inline-block px-6 py-2.5 bg-[#98c01d] text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-[#88af14] hover:shadow-lg focus:[#88af14] focus:shadow-lg focus:outline-none focus:ring-0 active:bg-[#98c01d] active:shadow-lg transition duration-150 ease-in-out">
						{isFetching ? 'Fetching Items...' : 'Show More Items'}
					</button>
				</div>
			)}
            {products?.items.length === 0 && (
				<div className="justify-center mx-auto text-center text-xl mb-5">No product found.</div>
			)}
			<div className="justify-center mx-auto text-center">Click <Link to={`${ROUTE.SIGNUP_VENDOR}`} className="text-[#88af14]"> HERE</Link> to start SELLING your PRODUCTS now </div>
		</>
    )
};

ProductList.defaultProps = {
	requestStatus: null
};

ProductList.propTypes = {
	products: PropType.object.isRequired,
	requestStatus: PropType.any,
	children: PropType.oneOfType([
		PropType.arrayOf(PropType.node),
		PropType.node
	]).isRequired
  };

export default ProductList;
 