const Paymentlist =[
   
    {
        label: "New",
        value: "New"
    },
    {
        label: "Pending",
        value: "Pending"
    },
    {
        label: "Failed",
        value: "Failed"
    },
    {
        label: "Success",
        value: "Successful"
    }
];

export default Paymentlist;