
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

import PropType from 'prop-types';

import { Sidebar, Header } from '../components/admin';
import * as CONSTANT from '../constants/constants';
import './styles/admin_route.css';
import { useDidMount } from '../hooks';

/**
 * Authentication guard for routes
 * 
 * @param {PropTypes.bool} isAuth element/bool
 * @param {PropTypes.string} user_type element/string
 * @param {PropTypes.func} component element/func
 * 
 */

const AdminRoute = ({ isAuth, user_type, component: Component }) => {

  const didMount = useDidMount(true);

	if(didMount && isAuth && user_type === CONSTANT.ADMIN_ROLE || didMount && isAuth && user_type === CONSTANT.SUB_ADMIN_ROLE) {
    return (
      <>
        <Sidebar />
        <main className="main-wrap bg-gray-100 h-screen overflow-y-auto">
          <Header />
          <Component />
        </main>
      </>
    );
  }
  return (
    <Navigate to="/" />
  );
  
}

AdminRoute.defaultProps = {
  isAuth: false,
  user_type: CONSTANT.USER_ROLE
};

AdminRoute.propTypes = {
  isAuth: PropType.bool,
  user_type: PropType.string,
  component: PropType.func.isRequired
};

const mapStateToProps = ({ auth }) => ({
  isAuth: !!auth,
  user_type: auth?.user_type || ''
});

export default connect(mapStateToProps)(AdminRoute);
 