
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { Field, Form, Formik } from 'formik';
import { LoadingOutlined } from '@ant-design/icons';
import * as Yup from 'yup';

import { resetPassword } from '../../redux/actions/authActions';
import { setLoading } from '../../redux/actions/miscActions';
import { CustomInput } from '../../components/formik';
import { useDocumentTitle, useScrollTop } from '../../hooks';


const ResetPasswordSchema = Yup.object().shape({
    new_password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password length should be at least 8 characters.')
      .matches(/[A-Z\W]/g, 'Password should contain at least 1 uppercase letter.'),
    password_confirmation: Yup.string()
      .required('Confirm password is required.')
      .oneOf([Yup.ref('new_password')], 'Passwords does not match')
});

const ResetPassword = () => {
  const { token } = useParams();
    const { isAuthenticating } = useSelector((state) => ({
        isAuthenticating: state.app.isAuthenticating
    }));
    const dispatch = useDispatch();
    
	useDocumentTitle('Reset Password | BestDealNaija');
	useScrollTop();

    useEffect(() => () => {
		dispatch(setLoading(false));
	}, []);
    const onFormSubmit = (form) => {
        dispatch(resetPassword({
            token: token,
            new_password: form.new_password}));
    };

	return (
		<>
            <div className='pt-20 md:pt-2 bg-gray-50'>
            <div className="flex flex-col items-center min-h-screen sm:justify-center sm:pt-0">
                <div>
                    <h1 className="text-4xl font-bold text-[#9c0]">
                        Reset Password
                    </h1>
                </div>
                <Formik
                    initialValues={{
                        new_password: ''
                    }}
                    validateOnChange
                    validationSchema={ResetPasswordSchema}
                    onSubmit={onFormSubmit}
                >
                    <div className="w-full px-6 py-4 my-6 overflow-hidden bg-white shadow-md sm:max-w-lg sm:rounded-lg">
                        <Form>
                        <div className="mt-4">
                                <Field
                                    disabled={isAuthenticating}
                                    id="new_password" 
                                    name="new_password"
                                    placeholder="Your Password"
                                    type="password"
                                    label="Password"
                                    component={CustomInput}
                                />
                            </div>
                            <div className="mt-4">
                                <Field
                                    disabled={isAuthenticating}
                                    id="password-confirmation" 
                                    name="password_confirmation"
                                    placeholder="Confirm Password"
                                    type="password"
                                    label="Confirm Password"
                                    component={CustomInput}
                                />
                            </div>
                            <div className="flex items-center mt-4">
                                <button 
                                    type="submit"
                                    className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-primary rounded-md hover:bg-secondary focus:outline-none focus:bg-green-600">
                                    {isAuthenticating ? <LoadingOutlined /> : ''}
                                    &nbsp;
                                    {isAuthenticating ? 'Reseting password...' : 'Reset'}
                                </button>
                            </div>
                        </Form>
                    </div>
                </Formik>
            </div>
        </div>
		</>
	);
}
export default ResetPassword;
 