
import React from 'react';
import slider1 from "../../images/sliders/slider1_bdn.jpg";
import slider1_mobile from "../../images/sliders/slider1_bdn_mobile.jpg";
import slider2 from "../../images/sliders/slider2_bdn.jpg";
import slider2_mobile from "../../images/sliders/slider2_bdn_mobile.jpg";
import slider3 from "../../images/sliders/slider3_bdn.jpg";
import slider3_mobile from "../../images/sliders/slider3_bdn_mobile.jpg";
import slider4 from "../../images/sliders/slider4_bdn.jpg";
import slider4_mobile from "../../images/sliders/slider4_bdn_mobile.jpg";
import slider5 from "../../images/sliders/slider5_bdn.jpg";
import slider5_mobile from "../../images/sliders/slider5_bdn_mobile.jpg";
import * as ROUTE from '../../constants/routes'
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Parallax } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';

const Slider = () => {
  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#9c0"
      }}
      spaceBetween={0}
      centeredSlides={true}
      loop={true}
      speed={600}
      parallax={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false
      }}
      pagination={{
        clickable: true
      }}
      navigation={false}
      modules={[Autoplay, Pagination, Parallax]}
      className="mySwiper"
    >
      <SwiperSlide>
        <div className="relative h-[40svh] sm:h-[50svh] md:h-[60svh] xl:h-[100svh] w-full">
          <picture>
            <source media="(max-width: 500px)" srcSet={slider1_mobile} />

            <img
              src={slider1}
              className="h-full w-full pt-8 object-cover absolute top-0"
              alt=""
            />
            <div className="w-full h-full grid place-content-center">
              <Link
                to={`${ROUTE.PRODUCTS}`}
                className="relative text-white px-8 py-3 rounded-md font-bold text-[13px] tracking-wide bg-[#9c0]"
              >
                PLACE YOUR ORDER NOW
              </Link>
            </div>
          </picture>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative h-[40svh] sm:h-[50svh] md:h-[60svh] xl:h-[100svh] w-full">
          <picture>
            <source media="(max-width: 500px)" srcSet={slider2_mobile} />
            <img
              src={slider2}
              className="h-full w-full pt-8 object-cover absolute top-0"
              alt=""
            />
          </picture>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative h-[40svh] sm:h-[50svh] md:h-[60svh] xl:h-[100svh] w-full">
          <picture>
            <source media="(max-width: 500px)" srcSet={slider3_mobile} />
            <img
              src={slider3}
              className="h-full w-full pt-8 object-cover absolute top-0"
              alt=""
            />
          </picture>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative h-[40svh] sm:h-[50svh] md:h-[60svh] xl:h-[100svh] w-full">
          <picture>
            <source media="(max-width: 500px)" srcSet={slider4_mobile} />
            <img
              src={slider4}
              className="h-full w-full pt-8 object-cover absolute top-0"
              alt=""
            />
          </picture>
        </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="relative h-[40svh] sm:h-[50svh] md:h-[60svh] xl:h-[100svh] w-full">
          <picture>
            <source media="(max-width: 500px)" srcSet={slider5_mobile} />
            <img
              src={slider5}
              className="h-full w-full pt-8 object-cover absolute top-0"
              alt=""
            />
          </picture>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Slider
 