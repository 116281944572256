
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import PropType from 'prop-types';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { addToCart } from '../../redux/actions/cartActions';
import * as ROUTE from '../../constants/routes';
import * as CONSTANT from '../../constants/constants';
import { displayMoney } from '../../helpers/utils'
import { setRequestStatus } from '../../redux/actions/miscActions';

const ProductItem = ({ product }) => {
	const { cart } = useSelector((state) => ({
		cart: state.cart,
		requestStatus: state.app.requestStatus
	}));

	// const productInCart = (productId) =>{
	// 	if (cart) {
	// 		return cart.filter((cartItem) => productId === cartItem.id);
	// 	}
	// };

	const dispatch = useDispatch();

	useEffect(() => {
        dispatch(setRequestStatus({}));
    }, []);

	const btnAddToCart = (product) => {
        dispatch(addToCart(product));
		cart?.some((cartItem) => product.id === cartItem.id) ? console.log('Item in cart') :
		dispatch(setRequestStatus({
			success: true,
			type: 'cart',
			isError: false,
			status: 'success',
			message: 'Added to cart.'
		}))
    }

	// const btnIncreaseQtyCartList = (product) => {
	// 	if(productInCart(product.id).length >= 1){
	// 		const cartItem = productInCart(product.id)[0]
	// 		const cart_quantity = cartItem['cart_quantity']
	// 		const quantity = cartItem['quantity']
	// 		if (cart_quantity < quantity) {
	// 			dispatch(addQtyItem(cartItem['id']));
	// 			dispatch(setRequestStatus({
	// 				success: true,
	// 				type: 'cart',
	// 				isError: false,
	// 				status: 'success',
	// 				message: 'Quantity added.'
	// 			}))
	// 		}
	// 	} else {
	// 		dispatch(addToCart(product));
	// 		dispatch(setRequestStatus({
	// 			success: true,
	// 			type: 'cart',
	// 			isError: false,
	// 			status: 'success',
	// 			message: 'Added to cart.'
	// 		}))
	// 	}
    // }

	// const btnDecreaseQtyCartList = (product) => {
	// 	if(productInCart(product.id).length >= 1){
	// 		const cartItem = productInCart(product.id)[0]
	// 		const cart_quantity = cartItem['cart_quantity']
	// 		const quantity = cartItem['quantity']
	// 		if ((quantity >= cart_quantity) && cart_quantity !== 0) {
	// 			dispatch(minusQtyItem(cartItem['id']));
	// 			dispatch(setRequestStatus({
	// 				success: true,
	// 				type: 'cart',
	// 				isError: false,
	// 				status: 'success',
	// 				message: 'Quantity removed.'
	// 			}))
	// 		}
	// 	}
    // }

	return (
		<SkeletonTheme baseColor="#e1e1e1" highlightColor="#f2f2f2">
			<div className="place-self-stretch" key={product.id}>
				<div className="group mx-0 px-2 py-2">
					<div className='products '>
						<Link to={`${ROUTE.PRODUCT_DETAILS}/${product.slug}`} state={product}>
							<div className="pb-[5px] text-center bg-white shadow-xl">
								{product.name ? (
												<img className="lazy h-60 w-full object-cover" src={`${CONSTANT.IMAGE_STORE}/${product.image_path}`} alt={product.name} />
											): <Skeleton className='w-full' height={200} />}
								<div className="gis-text text-center pt-4 overflow-hidden w-full h-20">
									<p className="text-lg md:text-xl lg:text-xl font-bold overflow-hidden text-ellipsis whitespace-nowrap px-3">
										{product.name ? (
											product.name
										): <Skeleton className='w-full' height={50} />}
									</p>
									<div className="row text-center">
                                        <p className="text-md font-medium order-first text-bold text-center">
											{product.price ? (
                                                    <span>&#8358;{displayMoney(
														product.sale_price ? product.sale_price : product.price
													)}</span>
                                                ) : (<span><br/></span>)
                                            } &nbsp;
											{product.sale_price >= 1 && ( 
												<span className={"line-through font-light text-center text-sm mr-1 text-gray-500"}>
													<>&#8358;{displayMoney(product.price)}</>
												</span>
											)}
										</p>
									</div>
								</div>
							</div>
						</Link>
						
						<div className="row px-[10px] py-2 text-center w-full bg-white shadow-xl"> 
							{/* <div className="custom-number-input w-full items-center">
								<div className="flex flex-row h-10 relative rounded-lg bg-transparent mt-1 mb-4">
									<button onClick={() => btnDecreaseQtyCartList(product)} className="bg-[#98c01d] text-2xl border-[#98c01d] hover:bg-[#88af14] 
										text-white h-full w-32 rounded-l cursor-pointer outline-none"
										disabled={productInCart(product.id).length >= 1 && productInCart(product.id)[0]['cart_quantity'] === 1}>
									<span className="m-auto text-2xl font-thin">−</span>
									</button>
									<input type="text" 
										value={productInCart(product.id).length >= 1 ? productInCart(product.id)[0]['cart_quantity'] : 0} 
										className="border-[#98c01d] text-center w-full bg-white font-semibold text-md hover:text-black 
										focus:text-black md:text-basecursor-default flex items-center text-gray-700" 
										name="custom-input-number" />
									<button onClick={() => btnIncreaseQtyCartList(product)} className="bg-[#98c01d] text-2xl border-[#88af14] 
										hover:bg-[#9c0] text-white h-full w-32 rounded-r cursor-pointer"
										disabled={productInCart(product.id).length >= 1 && 
											productInCart(product.id)[0]['quantity'] === productInCart(product.id)[0]['cart_quantity']
										}>
										<span className="m-auto text-2xl font-thin">+</span>
									</button>
								</div>
							</div> */}
							<div className="custom-number-input w-full pb-3">
								<div className="row w-full rounded-lg relative bg-transparent">
									{product.name ? (
												<button onClick={() => btnAddToCart(product)} className="w-full mt-1 font-semibold leading-none text-white py-4 bg-[#98c01d] rounded-md hover:bg-[#88af14] focus:ring-2 focus:ring-offset-2 focus:outline-none cursor-pointer md:w-2/3">Add to cart</button>
											): <Skeleton width={80} height={40} />}
									
								</div>
							</div>
						</div>
					</div> 
				</div>
			</div>
		</SkeletonTheme>
	);
}

 ProductItem.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  product: PropType.object.isRequired
};

export default ProductItem;
 